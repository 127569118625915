import yasml from '@thirtytech/yasml';
import {
  BarkerCoreModelsInventoryDtiSplitRules,
  BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal,
  usePostDtiEventsEventIdIssue,
  usePostDtiItemsAccountIdSellPrivately,
  usePostDtiListingsAccountIdDelete,
  usePostDtiListingsAccountIdIssue,
  usePutDtiListingsAccountId,
  usePutDtiListingsAccountIdEditListing,
  usePutDtiListingsAccountIdEditState,
} from '../../api';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { selectedMergedListingAtom, updateListingsAtom } from '../../data/atoms';
import { TenantIdListingId } from '../../models/tenantIdListingId';

const DTIInventoryState = () => {
  const updateLocalListing = useSetAtom(updateListingsAtom);
  const selectedListing = useAtomValue(selectedMergedListingAtom);

  const { mutateAsync: sellPrivately, isLoading: isSellingPrivately } = usePostDtiItemsAccountIdSellPrivately();
  const { mutateAsync: bulkUpdateState, isLoading: isBulkUpdating } = usePutDtiListingsAccountIdEditState();
  const { mutateAsync: deleteListings, isLoading: isDeletingListings } = usePostDtiListingsAccountIdDelete();
  const { mutateAsync: reportListingIssue, isLoading: isReportingIssue } = usePostDtiListingsAccountIdIssue();
  const { mutateAsync: editListing, isLoading: isEditLoading } = usePutDtiListingsAccountIdEditListing();
  const { mutateAsync: updateListing, isLoading: isUpdatingListing } = usePutDtiListingsAccountId();
  const { mutateAsync: reportEventIssue } = usePostDtiEventsEventIdIssue();

  const vendorProperties = selectedListing?.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal | undefined;

  const accountId = vendorProperties?.ownerId;

  const reportEventOccurred = useCallback(
    async (eventId: number) => {
      await reportEventIssue({
        eventId,
        data: {
          issue: 2,
        },
      });
    },
    [reportEventIssue],
  );

  const reserveListing = useCallback(async () => {
    if (!accountId || !selectedListing) return;
    await bulkUpdateState({
      accountId,
      data: [
        {
          listing_id: selectedListing.listingId,
          checksum: vendorProperties.checksum,
          state: 1, // Reserved
        },
      ],
    });

    updateLocalListing({ ...selectedListing, quantityReserved: selectedListing.quantityRemaining });
  }, [bulkUpdateState, selectedListing, updateLocalListing, vendorProperties?.checksum, accountId]);

  const unreserveListing = useCallback(async () => {
    if (!accountId || !selectedListing) return;
    await bulkUpdateState({
      accountId,
      data: [
        {
          listing_id: selectedListing.listingId,
          checksum: vendorProperties.checksum,
          state: 0, // Available
        },
      ],
    });

    updateLocalListing({ ...selectedListing, quantityReserved: 0 });
  }, [accountId, bulkUpdateState, selectedListing, updateLocalListing, vendorProperties?.checksum]);

  const deleteListing = useCallback(async () => {
    if (!accountId || !selectedListing) return;
    await deleteListings({
      accountId,
      data: [
        {
          listing_id: selectedListing.listingId,
          checksum: vendorProperties.checksum,
          owner_id: accountId,
        },
      ],
    });

    updateLocalListing({ listingsToUpdate: [], listingsToRemove: [new TenantIdListingId(selectedListing.tenantIdListingId)] });
  }, [accountId, selectedListing, deleteListings, vendorProperties?.checksum, updateLocalListing]);

  const editSharing = useCallback(
    async (isBroadcasting: boolean, omittedMarketplaces: string) => {
      if (!accountId || !selectedListing) return;
      const changes = ['share_exchange', 'exchange_sharing', 'allowed_splits'];

      const { splitType } = selectedListing.splits as BarkerCoreModelsInventoryDtiSplitRules;

      const splits = splitType === 'CUSTOM' ? 2 : splitType === 'NEVERLEAVEONE' ? -1 : splitType === 'NONE' ? 0 : splitType === 'ANY' ? 1 : 0;

      if (splits === -1) {
        changes.push('not_leave_one_share');
      } else if (splits === 0) {
        changes.push('no_share');
      } else if (splits === 1) {
        changes.push('any_share');
      } // Splits === 2 is allowed, but no special handling is needed

      await updateListing({
        accountId,
        data: [
          {
            listing_id: selectedListing.listingId,
            checksum: vendorProperties.checksum,
            exchange_sharing: omittedMarketplaces,
            share_exchange: isBroadcasting,
            share_splits: splits,
            user_initiated_changes: changes,
          },
        ],
      });
    },
    [accountId, updateListing, selectedListing, vendorProperties?.checksum],
  );

  return {
    selectedListing,
    sellPrivately,
    isSellingPrivately,
    reserveListing,
    unreserveListing,
    isBulkUpdating,
    deleteListing,
    isDeletingListings,
    reportIssue: reportListingIssue,
    isReportingIssue,
    accountId,
    editListing,
    isEditLoading,
    vendorProperties,
    editSharing,
    isUpdatingListing,
    reportEventOccurred,
  };
};

export const { Provider: DTIInventoryStateProvider, useSelector: useDTIInventory } = yasml(DTIInventoryState);
