import { Flex, Tooltip } from '@mantine/core';
import PictureAsPDFIcon from '../../../components/icons/PictureAsPDF';
import FlashOnIcon from '../../../components/icons/FlashOn';
import QrCodeScannerIcon from '../../../components/icons/QrCodeScanner';
import LocalActivityIcon from '../../../components/icons/LocalActivity';
import OpenInPhoneIcon from '../../../components/icons/OpenInPhone';
import P2PIcon from '../../../components/icons/P2P';
import WarningIcon from '../../../components/icons/Warning';

export function LocationProperty({ location }: { location: string }) {
  const friendlyName =
    location === 'MOBILE_XFR'
      ? 'Mobile Transfer'
      : location === 'MD'
        ? 'Managed Delivery'
        : location === 'FLASH'
          ? 'AXS Mobile ID'
          : location === 'TF'
            ? 'PDF'
            : location === 'MOBILE'
              ? 'Mobile QR'
              : location === 'HARD'
                ? 'Hard Stock'
                : 'Unknown';

  return (
    <Tooltip label={friendlyName} withinPortal withArrow>
      <Flex w={24} h={24} align="center" justify="center" style={{ borderRadius: 28 }} c="currentcolor">
        {location === 'MOBILE_XFR' ? (
          <OpenInPhoneIcon size={16} />
        ) : location === 'TF' ? (
          <PictureAsPDFIcon size={16} />
        ) : location === 'FLASH' ? (
          <FlashOnIcon size={16} />
        ) : location === 'MOBILE' ? (
          <QrCodeScannerIcon size={16} />
        ) : location === 'HARD' ? (
          <LocalActivityIcon size={16} />
        ) : location === 'MD' ? (
          <P2PIcon size={16} />
        ) : (
          <WarningIcon size={16} />
        )}
      </Flex>
    </Tooltip>
  );
}
