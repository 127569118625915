import { Box, Center, Container, Group, Modal, Skeleton, Tabs, Text } from '@mantine/core';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import StadiumIcon from '../../components/icons/Stadium';
import HistoryIcon from '../../components/icons/History';
import { useInventory } from './Inventory.hooks';
import {
  BarkerCoreModelsInventoryListingChange,
  BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal,
  useGetApiInventoryListingsListingIdChanges,
  useGetDtiItemsAccountId,
  useGetDtiItemsAccountIdItemIdHistory,
} from '../../api';
import dayjs from 'dayjs';
import { DateFormats } from '../../utils/globals';
import { useGlobalState } from '../../data/GlobalState';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FixedSizeList } from 'react-window';
import { CellProps, TableState, useSortBy, useTable } from 'react-table';
import { formatCurrency, formatDate } from '../../utils/formatters';
import InfoIcon from '../../components/icons/Info';
import classes from './Inventory.styles.tsx.module.css';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import { useDTIInventory } from '../DTI/DTI.Inventory.hooks';

function isGUID(str: string) {
  const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidPattern.test(str);
}

export function ListingHistoryDialogDTI() {
  const { selectedListing } = useDTIInventory('selectedListing');
  const {
    selectedHistoryListing: { isOpen },
  } = useInventory('selectedHistoryListing');

  const { data: dtiItems } = useGetDtiItemsAccountId(
    (selectedListing?.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal)?.ownerId,
    {
      listing_id: selectedListing?.listingId,
    },
    {
      query: {
        enabled: !!selectedListing && isOpen,
        select(data) {
          return data.data.items;
        },
      },
    },
  );

  const firstItem = dtiItems?.[0];

  return <ListingHistoryDialog ownerId={firstItem?.owner_id ?? 0} itemId={firstItem?.item_id ?? 0} />;
}

export function ListingHistoryDialog({ ownerId = 0, itemId = 0 }: { ownerId?: number; itemId?: number }) {
  const {
    selectedHistoryListing: { listing, isOpen },
    setSelectedHistoryListing: setListing,
  } = useInventory('selectedHistoryListing', 'setSelectedHistoryListing');
  const { getUserName } = useGlobalState('getUserName');
  const {
    data: history,
    isLoading,
    refetch,
  } = useGetApiInventoryListingsListingIdChanges(listing?.listingId ?? '', {
    query: {
      enabled: !!listing,
    },
    axios: {
      headers: {
        'x-tenant-id': listing?.tenantId,
      },
    },
  });

  const {
    data: dtiHistory,
    isLoading: isDtiLoading,
    refetch: refetchDti,
  } = useGetDtiItemsAccountIdItemIdHistory(ownerId, itemId, {
    query: {
      enabled: !!listing && isDtiHosted && ownerId > 0 && itemId > 0,
    },
  });

  // TODO: Need to fix the loading indicators so they don't have a render frame where nothing is loading before DTI starts loading
  // console.log('isLoading', isLoading, 'isDtiLoading', isDtiLoading, 'dtiHistoryEnabled', dtiHistoryEnabled);

  useEffect(() => {
    if (isOpen) {
      refetch();
      if (isDtiHosted) {
        refetchDti();
      }
    }
  }, [isOpen, refetch, listing, refetchDti]);

  const columns = useMemo(
    () => [
      {
        Header: 'Date & Time',
        accessor: 'changedAt' as keyof BarkerCoreModelsInventoryListingChange,
        Cell: ({ value }: CellProps<BarkerCoreModelsInventoryListingChange>) => dayjs(value).format('MM/DD/YYYY h:mma'),
      },
      {
        Header: 'Description',
        accessor: 'description' as keyof BarkerCoreModelsInventoryListingChange,
        Cell: ({ value }: CellProps<BarkerCoreModelsInventoryListingChange>) => value,
      },
      {
        Header: 'User',
        accessor: 'changedBy' as keyof BarkerCoreModelsInventoryListingChange,
        Cell: ({ value }: CellProps<BarkerCoreModelsInventoryListingChange>) => (isGUID(value) ? getUserName(value) : value), // Barker only returns the userId (GUID) but DTI returns the email/username
      },
    ],
    [getUserName],
  );

  function EventTitle() {
    if (!listing) {
      return null;
    }
    return (
      <Group>
        <Group>
          <StadiumIcon color="var(--colors-iconFill)" size={32} />
          <Box>
            <Text size="sm" fw={600} className={classes.name}>
              {listing.event.name}
            </Text>
            <Text p={0} c="var(--colors-gray-5)" size="xs" fw={500} className={classes.descWrap}>
              <span>
                <span className={classes.desc}>{formatDate(listing.event.localDateTime, DateFormats.Extended)}</span> - {listing.event.venue.name} · {listing.event.venue.city},{' '}
                {listing.event.venue.state}
              </span>
            </Text>
          </Box>
        </Group>
      </Group>
    );
  }

  function ChangeTable({ changes }: { changes: BarkerCoreModelsInventoryListingChange[] }) {
    // const scrollBarSize = useMemo(() => scrollbarWidth(), []);

    const defaultSort = useMemo(() => [{ id: 'changedAt', desc: true }], []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
      {
        columns,
        data: changes ?? [],
        initialState: {
          sortBy: defaultSort,
        } as Partial<TableState<BarkerCoreModelsInventoryListingChange>>,
      },
      useSortBy,
    );

    function CustomNoRowsOverlay() {
      return (
        <Center h={230} pl={24} pr={24} pb={20} style={{ flexDirection: 'column', textAlign: 'center' }}>
          <Box
            bg="var(--colors-darkPaper)"
            h={44}
            w={44}
            mb={16}
            style={{
              border: '1px solid var(--colors-paper)',
              borderRadius: 44,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <HistoryIcon color="var(--colors-iconFill)" size={28} />
          </Box>
          <Text size="md" fw={600} c="var(--colors-gray-9)">
            No History Available
          </Text>
          <Text size="xs" c="var(--colors-gray-5)">
            Any changes made will appear here
          </Text>
        </Center>
      );
    }

    const SkeletonRow = () => (
      <div className={classes.tr}>
        <div className={classes.td}>
          <span>
            <Skeleton height={8} my={5.5} width="80%" />
          </span>
        </div>
        <div className={classes.td}>
          <span>
            <Skeleton height={8} my={5.5} width="80%" />
          </span>
        </div>
        <div className={classes.td}>
          <span>
            <Skeleton height={8} my={5.5} width="80%" />
          </span>
        </div>
      </div>
    );
    const SkeletonLoader = () => (
      <Box h={230} w="100%" style={{ overflow: 'auto' }}>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </Box>
    );

    const PriceRow = useCallback(
      ({ index, style }: { index: number; style: React.CSSProperties }) => {
        const row = rows[index];
        prepareRow(row);
        const { key: rowKey, ...rowProps } = row.getRowProps({ style });
        return (
          <div key={rowKey} {...rowProps} className={classes.tr}>
            {row.cells.map((cell) => {
              const { key: cellKey, ...rest } = cell.getCellProps();
              return (
                <div {...rest} key={cellKey} className={classes.td}>
                  <span>{cell.render('Cell')}</span>
                </div>
              );
            })}
          </div>
        );
      },
      [prepareRow, rows],
    );

    return (
      <div {...getTableProps()} className={classes.table}>
        <div>
          {headerGroups.map((headerGroup) => (
            <div className={classes.tr} {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => (
                <div className={classes.th} {...column.getHeaderProps()} key={column.getHeaderProps().key}>
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>
        {(isLoading || (isDtiLoading && isDtiHosted)) && <SkeletonLoader />}
        {!(isLoading || (isDtiLoading && isDtiHosted)) && changes.length === 0 && <CustomNoRowsOverlay />}
        {!(isLoading || (isDtiLoading && isDtiHosted)) && changes.length > 0 && (
          <div {...getTableBodyProps()}>
            <FixedSizeList itemSize={36} height={230} itemCount={changes.length} width="100%" layout="vertical" overscanCount={100}>
              {PriceRow}
            </FixedSizeList>
          </div>
        )}
      </div>
    );
  }

  const parseDtiHistory = useCallback((value: string, changeType: string) => {
    const parts = value.split(' - ');
    const dateTime = dayjs(parts[0]).toDate();
    const user = parts[1];
    const message = parts.slice(2).join(' - ');
    return {
      changedAt: dateTime,
      changedBy: user,
      description: message,
      changeType,
      listingId: '0',
      listingChangeId: 0,
      tenantId: '',
    } satisfies BarkerCoreModelsInventoryListingChange;
  }, []);

  const dtiPriceHistory = useMemo(
    () =>
      dtiHistory?.data?.history
        ?.filter((h) => h.includes('Price changed') || h.includes('Changed the price') || h.includes('Listing price updated to'))
        .map((h) => parseDtiHistory(h, 'Price')) ?? [],
    [parseDtiHistory, dtiHistory],
  );

  const dtiNotesHistory = useMemo(
    () => dtiHistory?.data?.history?.filter((h) => h.includes('External notes changed') || h.includes('Internal notes changed')).map((h) => parseDtiHistory(h, 'Notes')) ?? [],
    [parseDtiHistory, dtiHistory],
  );

  const dtiBroadcastHistory = useMemo(
    () =>
      dtiHistory?.data?.history
        ?.filter((h) => h.includes('shared to') || h.includes('Set hide seats') || h.includes('Set exchange sharing'))
        .map((h) => parseDtiHistory(h, 'IsBroadcasting')) ?? [],
    [parseDtiHistory, dtiHistory],
  );

  const dtiSplitsHistory = useMemo(
    () => dtiHistory?.data?.history?.filter((h) => h.includes('Set allowed splits')).map((h) => parseDtiHistory(h, 'Splits')) ?? [],
    [parseDtiHistory, dtiHistory],
  );

  const dtiOtherHistory = useMemo(
    () =>
      dtiHistory?.data?.history
        ?.filter(
          (h) =>
            !h.includes('Price changed') &&
            !h.includes('Changed the price') &&
            !h.includes('Listing price updated to') &&
            !h.includes('External notes changed') &&
            !h.includes('Internal notes changed') &&
            !h.includes('shared to') &&
            !h.includes('Set hide seats') &&
            !h.includes('Set exchange sharing') &&
            !h.includes('Set allowed splits'),
        )
        .map((h) => parseDtiHistory(h, 'Other'))
        .sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [parseDtiHistory, dtiHistory],
  );

  const priceHistory = useMemo(
    () =>
      history?.data
        ?.filter((h) => h.changeType === 'Price')
        .concat(dtiPriceHistory)
        .sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [history, dtiPriceHistory],
  );
  const broadcastHistory = useMemo(
    () =>
      history?.data
        ?.filter((h) => h.changeType === 'IsBroadcasting')
        .concat(dtiBroadcastHistory)
        .sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [history, dtiBroadcastHistory],
  );
  const splitsHistory = useMemo(
    () =>
      history?.data
        ?.filter((h) => h.changeType === 'Splits')
        .concat(dtiSplitsHistory)
        .sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [history, dtiSplitsHistory],
  );
  const notesHistory = useMemo(
    () =>
      history?.data
        ?.filter((h) => h.changeType === 'Notes')
        .concat(dtiNotesHistory)
        .sort((a, b) => dayjs(a.changedAt).unix() - dayjs(b.changedAt).unix()) ?? [],
    [history, dtiNotesHistory],
  );

  const appName = isDtiHosted ? 'DTI Member Portal' : 'Broker Nerds Pricer';

  return (
    <Modal
      centered
      size="xl"
      opened={isOpen}
      closeButtonProps={{ size: 'md' }}
      onClose={() => setListing({ listing, isOpen: false })}
      title={<EventTitle />}
      classNames={{ header: classes.modalHeader, body: classes.modalBody }}
    >
      <Group mx="-1rem" px="md" py="sm" mb="sm" className={classes.gradient}>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Section
          </Text>
          <Text size="md">{listing?.section}</Text>
        </div>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Row
          </Text>
          <Text size="md">{listing?.row}</Text>
        </div>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Seats
          </Text>
          <Text size="md">{listing?.quantityRemaining ?? 0 > 1 ? `${listing?.seatFrom}-${listing?.seatThru}` : listing?.seatFrom}</Text>
        </div>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Qty
          </Text>
          <Text size="md">{listing?.quantityRemaining}</Text>
        </div>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Unit Cost
          </Text>
          <Text size="md">{formatCurrency(listing?.unitCost ?? 0)}</Text>
        </div>
        <div>
          <Text style={{ lineHeight: 1 }} size="xs" color="var(--colors-gray-5)">
            Current Price
          </Text>
          <Group>
            <Text size="md">{formatCurrency(listing?.unitPrice ?? 0)}</Text>
            {listing?.pricerStatusId === 'AutoPriced' && <AutoPriceIcon size={20} />} {/* TODO: Replace this with the logic for auto pricing indicators to be developed later */}
          </Group>
        </div>
      </Group>
      <Container p={0} mx="-1rem">
        <Tabs defaultValue="pricing">
          <Tabs.List pl="md" pr="md" className={classes.tabList}>
            <Tabs.Tab value="pricing">Pricing</Tabs.Tab>
            <Tabs.Tab value="broadcasting">Broadcasting</Tabs.Tab>
            <Tabs.Tab value="splits">Splits</Tabs.Tab>
            <Tabs.Tab value="notes">Notes</Tabs.Tab>
            {isDtiHosted && <Tabs.Tab value="other">Other</Tabs.Tab>}
          </Tabs.List>

          <Tabs.Panel value="pricing" pl="md" pr="md" pt="md" pb="md">
            <ChangeTable changes={priceHistory} />
          </Tabs.Panel>

          <Tabs.Panel value="broadcasting" pl="md" pr="md" pt="md" pb="md">
            <ChangeTable changes={broadcastHistory} />
          </Tabs.Panel>

          <Tabs.Panel value="splits" pl="md" pr="md" pt="md" pb="md">
            <ChangeTable changes={splitsHistory} />
          </Tabs.Panel>

          <Tabs.Panel value="notes" pl="md" pr="md" pt="md" pb="md">
            <ChangeTable changes={notesHistory} />
          </Tabs.Panel>

          {isDtiHosted && (
            <Tabs.Panel value="other" pl="md" pr="md" pt="md" pb="md">
              <ChangeTable changes={dtiOtherHistory} />
            </Tabs.Panel>
          )}
        </Tabs>
        <Group p="md" px="md" bg="var(--colors-darkPaper)" wrap="nowrap" align="flex-start" gap={8}>
          <InfoIcon color="var(--colors-gray-5)" />
          <Text size="xs" c="var(--colors-gray-5)">
            The changes listed here only contain records of activity within the {appName}. Changes made from your point of sale or other external tools will not be accounted for.
          </Text>
        </Group>
      </Container>
    </Modal>
  );
}
