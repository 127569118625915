import { Box, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import { DateFormats } from '../../utils/globals';
import { useGlobalState } from '../../data/GlobalState';
import { formatCurrency, formatPricedAt } from '../../utils/formatters';
import { memo, useMemo } from 'react';
import { BarkerCoreModelsInventoryListing } from '../../api';
import { useHover } from '@mantine/hooks';
import { weakEquals } from '../../utils/weakEquals';

export const RePrice = memo(
  ({
    posPricedAt,
    pricedAt,
    unitPrice,
    previousPrice,
    pricedBy,
    textOnly,
    latestPrice,
  }: Pick<BarkerCoreModelsInventoryListing, 'posPricedAt' | 'pricedAt' | 'unitPrice' | 'previousPrice' | 'pricedBy' | 'latestPrice'> & { textOnly?: boolean }) => {
    const { users, getUserName } = useGlobalState('users', 'getUserName');
    const { hovered, ref } = useHover();
    const posNewerThanPricedAt =
      !pricedAt || (latestPrice && latestPrice !== unitPrice) || (latestPrice && latestPrice !== unitPrice && dayjs(posPricedAt).isAfter(dayjs(pricedAt).add(60, 'seconds'))); // Add 60 seconds to account for time differences
    const repricedLabel = formatPricedAt(pricedAt, posPricedAt, unitPrice, latestPrice);

    const repricedTooltip = useMemo(() => {
      if (!posPricedAt && !pricedAt) {
        return 'Never Priced';
      }

      const formattedDate = posNewerThanPricedAt ? (posPricedAt ? dayjs(posPricedAt).format(DateFormats.Extended) : 'Unknown Date') : dayjs(pricedAt).format(DateFormats.Extended);
      const priceDiff = (unitPrice || 0) - (previousPrice || 0);

      const user = getUserName(pricedBy);
      return `${formattedDate}: ${
        posNewerThanPricedAt ? 'Price changed by POS user' : `${Math.sign(priceDiff) >= 0 ? '+' : '-'}${formatCurrency(Math.abs(priceDiff))} by ${user}`
      }`;
    }, [getUserName, posNewerThanPricedAt, posPricedAt, previousPrice, pricedAt, pricedBy, unitPrice]);

    if (!users || users.length === 0) return <div>Loading...</div>;

    if (textOnly) {
      return <div>{repricedTooltip}</div>;
    }

    return (
      <Box ref={ref}>
        <Tooltip withinPortal withArrow label={repricedTooltip} opened={hovered}>
          <div>{repricedLabel}</div>
        </Tooltip>
      </Box>
    );
  },
  (a, b) => weakEquals(a, b),
);
