import { Grid, Group, SimpleGrid } from '@mantine/core';
import { BNDatePicker } from '../../components/DatePicker/DatePicker';
import { BNTextInput } from '../../components/TextInput/TextInput';
import DateRangeIcon from '../../components/icons/DateRange';
import SearchIcon from '../../components/icons/Search';
import { BNButton } from '../../components/Button/Button';
import { useSales } from './Sales.hooks';

export function SalesSearch() {
  const { form, search, reset, isLoading } = useSales('form', 'search', 'reset', 'isLoading');

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      search(form.values);
    }
  }

  return (
    <Group p={16} gap={12} className="searchArea" style={{ flexDirection: 'column' }}>
      <SimpleGrid cols={2} spacing={12} style={{ flexGrow: 1 }}>
        <BNDatePicker
          size="xs"
          variant="default"
          {...form.getInputProps('fromDate')}
          clearable
          valueFormat="MM/DD/YY"
          leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
          selectOnFocus
          placeholder="from Date"
          onChange={(dateValue) => {
            if (dateValue === null) {
              form.setFieldValue('fromDate', dateValue);
              return;
            }

            form.setFieldValue('fromDate', dateValue);

            if (form.values.toDate && form.values.toDate < dateValue) {
              form.setFieldValue('toDate', dateValue);
            } else if (!form.values.toDate) {
              form.setFieldValue('toDate', dateValue);
            }
          }}
          onKeyUp={handleKeyUp}
        />
        <BNDatePicker
          size="xs"
          variant="default"
          {...form.getInputProps('toDate')}
          clearable
          valueFormat="MM/DD/YY"
          leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
          selectOnFocus
          placeholder="To Date"
          onChange={(dateValue) => {
            if (dateValue === null) {
              form.setFieldValue('toDate', dateValue);
              return;
            }

            form.setFieldValue('toDate', dateValue);

            if (form.values.fromDate && form.values.fromDate > dateValue) {
              form.setFieldValue('fromDate', dateValue);
            } else if (!form.values.fromDate) {
              form.setFieldValue('fromDate', dateValue);
            }
          }}
          onKeyUp={handleKeyUp}
        />
      </SimpleGrid>
      <Grid columns={24} gutter={0} w="100%">
        <Grid.Col span="content" style={{ flex: 1 }}>
          <BNTextInput
            {...form.getInputProps('query')}
            size="xs"
            variant="default"
            leftSection={<SearchIcon size={20} />}
            placeholder="Search Sales"
            type="search"
            clearable
            clearableOnClick={() => form.setValues({ query: '' })}
            onKeyUp={handleKeyUp}
            data-pendo-name="Sales Search Button"
          />
        </Grid.Col>
      </Grid>
      <SimpleGrid
        cols={2}
        style={{
          gap: '0.75rem',
          width: '100%',
        }}
      >
        <BNButton
          size="xs"
          fullWidth
          variant="default"
          onClick={() => {
            reset();
          }}
        >
          Reset
        </BNButton>
        <BNButton
          disabled={!form.isValid()}
          loading={isLoading}
          size="xs"
          variant="filled"
          color="gray"
          fullWidth
          onClick={() => {
            search(form.values);
          }}
        >
          Search
        </BNButton>
      </SimpleGrid>
    </Group>
  );
}
