import { Box, Card, Divider, Group, List, SimpleGrid, Table, Text, Title } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import GroupIcon from '../../components/icons/Group';
import GroupsIcon from '../../components/icons/Groups';
import PersonIcon from '../../components/icons/Person';
import PriorityIcon from '../../components/icons/Priority';
import { AdminContentHeader } from '../../hoc/Admin/AdminContentHeader';
import { useBilling } from '../../hoc/Admin/Billing.hooks';
import { BarkerCoreModelsAdministrativePlan } from '../../api';
import { formatCurrency } from '../../utils/formatters';
import classes from './Plan.module.css';
import { supportEmail } from '../../utils/whitelabel-consts';

function generateBillingDescriptor(plan: BarkerCoreModelsAdministrativePlan) {
  if (plan.planTypeId === 'FixedRate') {
    return `${formatCurrency(plan.value)}/month`;
  }

  if (plan.planTypeId === 'PercentageOfAutoPricedSales') {
    return `${(plan.value * 100).toFixed(2)}%/sale`;
  }

  if (plan.planTypeId === 'PercentageOfSales') {
    return `${(plan.value * 100).toFixed(2)}%/sale`;
  }

  if (plan.planTypeId === 'SalesBrackets') {
    return 'Tiered Rates';
  }

  return 'Unknown';
}

export default function Plan() {
  const backlinkTo = location.pathname.replace(/\/plan/, '');
  const { currentPlan } = useBilling('currentPlan');

  return (
    <>
      <AdminContentHeader sticky title="Plan Details" backlinkTo={backlinkTo} backlinkTitle="Billing Overview" />

      {currentPlan && (
        <>
          <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="xl">
            <Card px="xl" pb="xl" withBorder radius="xs" maw={480} mt={32} className={classes.card}>
              <Box>
                <Group justify="space-between">
                  <Title order={4}>{currentPlan.plan.name}</Title>
                  <Text c="var(--colors-brandcolor-5)" fw="bold">
                    {generateBillingDescriptor(currentPlan.plan)}
                  </Text>
                </Group>
                <Divider my={16} mx={-24} color="var(--colors-darkPaper)" />
                <Group wrap="nowrap" align="center" gap="sm">
                  <Group align="center" c="var(--colors-iconFill)">
                    <PersonIcon size={24} />
                  </Group>
                  <Text c="var(--colors-gray-5)" size="xs">
                    {currentPlan.plan.description}
                  </Text>
                </Group>
                <Divider variant="dotted" my={16} mr={-24} color="var(--colors-darkPaper)" />
                <List center spacing="xs" size="sm" icon={<PriorityIcon color="var(--colors-iconFill)" size={24} />}>
                  <List.Item>Manual & Auto-Pricing</List.Item>
                  <List.Item>SeatGeek, Ticketmaster, and TEVO Marketplaces</List.Item>
                  <List.Item>Historical Trend Data</List.Item>
                </List>
              </Box>
              <Text bg="var(--colors-paperHover)" c="gray.7" p="sm" py="sm" size="xs" mt="xl" ta="center" style={{ borderRadius: 3 }}>
                Please contact{' '}
                <Text component="a" href={`mailto:${supportEmail}`} td="underline" c="var(--colors-brandcolor-5)" inline>
                  {supportEmail}
                </Text>{' '}
                with any questions.
              </Text>
            </Card>
            {/* <Rates /> */}
            {/* <PlanOptions /> */}
          </SimpleGrid>
        </>
      )}
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Rates() {
  return (
    <Card px="xl" pb="xl" withBorder radius="xs" maw={480} mt={32} className={classes.card}>
      <Box>
        <Group justify="space-between">
          <Title order={4}>Rates</Title>
          <Group gap="xs">
            <Box h={16} w={16} bg="var(--colors-brandcolor-5)" className={classes.rateMarker} />
            <Text c="var(--colors-gray-7)" size="xs">
              Your Projected Rate
            </Text>
          </Group>
        </Group>
        <Divider my={16} mx={-24} color="var(--colors-darkPaper)" />
        <Table mx={-24} w="calc(100% + 48px)" mt={-16} mb={-24} striped className={classes.rateTable}>
          <thead>
            <tr>
              <th>Monthly Usage</th>
              <th>Rate</th>
            </tr>
          </thead>
          <tbody>
            {/* add className="projected-rate" to any <tr> here to give it the projected/highligted style */}
            <tr className="projected-rate">
              <td>Any</td>
              <td>1.49%</td>
            </tr>
            <tr>
              <td>$41,667</td>
              <td>1.42%</td>
            </tr>
            <tr>
              <td>$83,333</td>
              <td>1.19%</td>
            </tr>
            <tr>
              <td>$166,667</td>
              <td>0.97%</td>
            </tr>
            <tr>
              <td>$333,333</td>
              <td>0.82%</td>
            </tr>
            <tr>
              <td>$500,000</td>
              <td>0.78%</td>
            </tr>
            <tr>
              <td>$750,000</td>
              <td>0.74%</td>
            </tr>
            <tr>
              <td>$1,000,000</td>
              <td>0.48%</td>
            </tr>
            <tr>
              <td>$2,500,000</td>
              <td>0.37%</td>
            </tr>
            <tr>
              <td>$5,000,000+</td>
              <td>Contact Us</td>
            </tr>
          </tbody>
        </Table>
      </Box>
    </Card>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PlanOptions() {
  return (
    <SimpleGrid cols={3} spacing="xl" hidden>
      <Card px="xl" pb="xl" withBorder radius="xs" className={classes.card}>
        <Box>
          <Group justify="space-between">
            <Title order={4}>Starter</Title>
            <Text c="var(--colors-brandcolor-5)" fw="bold">
              $10/mo
            </Text>
          </Group>
          <Divider my={16} mx={-24} color="var(--colors-divider)" />
          <Group wrap="nowrap" align="center" gap="sm">
            <Group align="center" c="var(--colors-brandcolor-5)">
              <PersonIcon size={24} />
            </Group>
            <Text c="var(--colors-gray-5)" size="xs">
              Made for solo brokers with less than $250k of monthly volume.
            </Text>
          </Group>
          <Divider variant="dotted" my={16} mr={-24} color="var(--colors-divider)" />
          <List center size="sm" icon={<PriorityIcon color="var(--colors-brandcolor-5)" size={24} />}>
            <List.Item>Feature number one</List.Item>
            <List.Item>Feature number two</List.Item>
            <List.Item>Feature number three</List.Item>
          </List>
        </Box>
        <Box>
          <BNButton variant="filled" color="brandcolor" fullWidth size="sm" disabled>
            Current Plan
          </BNButton>
        </Box>
      </Card>
      <Card px="xl" pb="xl" withBorder radius="xs" className={classes.card}>
        <Box>
          <Group justify="space-between">
            <Title order={4}>Team</Title>
            <Text c="var(--colors-brandcolor-5)" fw="bold">
              $10/mo
            </Text>
          </Group>
          <Divider my={16} mx={-24} color="var(--colors-divider)" />
          <Group wrap="nowrap" align="center" gap="sm">
            <Group align="center" c="var(--colors-brandcolor-5)">
              <GroupIcon size={24} />
            </Group>
            <Text c="var(--colors-gray-5)" size="xs">
              Made for small teams of brokers with less than $250k of monthly volume.
            </Text>
          </Group>
          <Divider variant="dotted" my={16} mr={-24} color="var(--colors-divider)" />
          <List center size="sm" icon={<PriorityIcon color="var(--colors-brandcolor-5)" size={24} />}>
            <List.Item>Feature number one</List.Item>
            <List.Item>Feature number two</List.Item>
            <List.Item>Feature number three</List.Item>
            <List.Item>Feature number four</List.Item>
          </List>
        </Box>
        <Box>
          <BNButton variant="filled" color="brandcolor" fullWidth size="sm">
            Switch to Team
          </BNButton>
        </Box>
      </Card>
      <Card px="xl" pb="xl" withBorder radius="xs" className={classes.card}>
        <Box>
          <Group justify="space-between">
            <Title order={4}>Enterprise</Title>
            <Text c="var(--colors-brandcolor-5)" fw="bold">
              $10/mo
            </Text>
          </Group>
          <Divider my={16} mx={-24} color="var(--colors-divider)" />
          <Group wrap="nowrap" align="center" gap="sm">
            <Group align="center" c="var(--colors-brandcolor-5)">
              <GroupsIcon size={24} />
            </Group>
            <Text color="var(--colors-gray-5)" size="xs">
              Made for large teams of brokers with more than $250k of monthly volume.
            </Text>
          </Group>
          <Divider variant="dotted" my={16} mr={-24} color="var(--colors-divider)" />
          <List center size="sm" pb="sm" mb={36} icon={<PriorityIcon color="var(--colors-brandcolor-5)" size={24} />}>
            <List.Item>Feature number one</List.Item>
            <List.Item>Feature number two</List.Item>
            <List.Item>Feature number three</List.Item>
            <List.Item>Feature number four</List.Item>
            <List.Item>Feature number five</List.Item>
          </List>
        </Box>
        <Box>
          <BNButton variant="filled" color="brandcolor" fullWidth size="sm">
            Switch to Enterprise
          </BNButton>
        </Box>
      </Card>
    </SimpleGrid>
  );
}
