import { IconPropsBase } from './IconPropsBase';

export default function SmartphoneIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M292.31-60Q262-60 241-81q-21-21-21-51.31v-695.38Q220-858 241-879q21-21 51.31-21h375.38Q698-900 719-879q21 21 21 51.31v695.38Q740-102 719-81q-21 21-51.31 21H292.31ZM280-170v37.69q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h375.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-170H280Zm0-60h400v-500H280v500Zm0-560h400v-37.69q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H292.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46V-790Zm0 0v-50 50Zm0 620v50-50Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 -960 960 960">
        <path
          fill={color}
          d="M276.31-68q-27.01 0-45.66-18.65Q212-105.3 212-132.31v-695.38q0-27.01 18.65-45.66Q249.3-892 276.31-892h407.38q27.01 0 45.66 18.65Q748-854.7 748-827.69v695.38q0 27.01-18.65 45.66Q710.7-68 683.69-68H276.31ZM264-178v45.69q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h407.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-178H264Zm0-52h432v-500H264v500Zm0-552h432v-45.69q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H276.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46V-782Zm0 0v-58 58Zm0 604v58-58Z"
        />
      </svg>
    );
  }
}
