import { ActionIcon, Box, Button, Center, Divider, Flex, Group, HoverCard, Menu, Modal, Text, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import { DateFormats } from '../../utils/globals';
import { useGlobalState } from '../../data/GlobalState';
import { useMemo } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  mergedEventListingsUnfilteredRulesAtom,
  searchResultsAtom,
  selectedEventAtom,
  selectedTenantListingIdAtom,
  showEventPerformanceAtom,
  transientGlobalStateAtom,
  updateListingsAtom,
} from '../../data/atoms';
import { formatDate } from '../../utils/formatters';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import RemoveIcon from '../../components/icons/Remove';
import { useInventory } from './Inventory.hooks';
import classes from './Inventory.GroupHeader.module.css';
import cx from 'clsx';
import { BNButton } from '../../components/Button/Button';
import ForumIcon from '../../components/icons/Forum';
import { EventCommentsProvider } from '../EventComments/EventComments.hooks';
import { EventComments } from '../EventComments/EventComments';
import { ListingCostIconLabel } from '../../components/Label/ListingCostLabel';
import { OpenlistingsLabel } from '../../components/Label/OpenListingsLabel';
import { OpenticketsLabel } from '../../components/Label/OpenTicketsLabel';
import MoreVertIcon from '../../components/icons/MoreVert';
import OpenNewIcon from '../../components/icons/OpenNew';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import DownloadIcon from '../../components/icons/Download';
import AddComment from '../../components/icons/AddComment';
import { SupportForm } from '../Support/SupportForm';
import pluralize from 'pluralize';
import { BarkerCoreEnumsShowWeather, BarkerCoreModelsInventoryEventVendorPropertiesDtiPortal, deleteApiPricingRulesRuleId, patchApiInventoryListingsListingId } from '../../api';
import SunnyIcon from '../../components/icons/Sunny';
import PartlyCloudyDayIcon from '../../components/icons/PartlyCloudyDay';
import CloudIcon from '../../components/icons/Cloud';
import FoggyIcon from '../../components/icons/Foggy';
import RainyIcon from '../../components/icons/Rainy';
import WeatherSnowyIcon from '../../components/icons/WeatherSnowy';
import WeatherMixIcon from '../../components/icons/WeatherMix';
import ThunderstormIcon from '../../components/icons/Thunderstorm';
import ExampleWeatherImage from '../../img/116.png';
import ClearNightIcon from '../../components/icons/ClearNight';
import { StringWithPipe } from '../../ts-utils';
import { useRuleState } from '../../data/RuleState';
import { useFlag } from '@unleash/proxy-client-react';
import AnalyticsIcon from '../../components/icons/Analytics';
import DeleteIcon from '../../components/icons/Delete';
import AddIcon from '../../components/icons/Add';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import { ContingentEventMenuButton } from '../DTI/ContingentEvent/ContigentEventMenuButton';

export const GroupHeader = ({ tenantId, eventId }: { tenantId: string; eventId: string }) => {
  const hideEventPerformanceFlag = useFlag('hide-event-performance');
  const { tenants, principal } = useGlobalState('tenants', 'principal');
  const [selectedTenantListingId, setSelectedListingId] = useAtom(selectedTenantListingIdAtom);
  const events = useAtomValue(searchResultsAtom)?.events;
  const event = useMemo(() => events?.find((e) => e.tenantId === tenantId && e.eventId === eventId), [eventId, events, tenantId]);
  const { currentUser } = useGlobalState('currentUser');
  const currentYear = useMemo(() => dayjs().year() === dayjs(event?.localDateTime).year(), [event?.localDateTime]);
  const eventTenant = useMemo(() => tenants?.find((t) => t.tenantId === event?.tenantId), [event?.tenantId, tenants]);
  const tenantPointOfSale = eventTenant?.pointOfSaleId || 'Unknown';
  const [actionMenuOpened, { open: openActionMenu, close: closeActionMenu }] = useDisclosure();
  const actionMenuRef = useClickOutside(() => closeActionMenu());
  const tenantName = useMemo(() => tenants?.find((t) => t.tenantId === event?.tenantId)?.name || 'Unknown', [event?.tenantId, tenants]);
  const isMultiTenant = tenants && tenants.length > 1;
  const [showEventPerformance, setShowEventPerformance] = useAtom(showEventPerformanceAtom);
  const setTransientGlobalState = useSetAtom(transientGlobalStateAtom);
  const { validateDirtyRuleState, triggerSaveRule } = useRuleState('validateDirtyRuleState', 'triggerSaveRule');
  const mergedEventListingsUnfiltered = useAtomValue(mergedEventListingsUnfilteredRulesAtom);
  const updateListing = useSetAtom(updateListingsAtom);
  const purchaseFlag = useFlag('consign-purchase') && isDtiHosted;

  const tenantColor = !event ? 'var(--colors-brandcolor-5)' : principal?.settings?.tenantColors[event.tenantId];
  // Set tenant color name by searching through color arrays
  const tenantColorName = useMemo(() => {
    if (!event || !isMultiTenant) return 'brandcolor';

    // List all possible tenant colors from previous options
    const tenantRed = ['#C92A2A', '#F03E3E', '#FF6B6B', '#FFA8A8', 'var(--colors-tenant-red-5)', 'red'];
    const tenantOrange = ['#D9480F', '#F76707', '#FF922B', '#FFC078', 'var(--colors-tenant-orange-5)', 'orange'];
    const tenantYellow = ['#E67700', '#F59F00', '#FCC419', '#FFE066', 'var(--colors-tenant-yellow-5)', 'yellow'];
    const tenantGreen = ['#2B8A3E', '#37B24D', '#51CF66', '#8CE99A', 'var(--colors-tenant-green-5)', 'green'];
    const tenantCyan = ['#0B7285', '#1098AD', '#22B8CF', '#66D9E8', 'var(--colors-tenant-cyan-5)', 'cyan'];
    const tenantBlue = ['#1864AB', '#1C7ED6', '#339AF0', '#74C0FC', 'var(--colors-tenant-blue-5)', 'blue'];
    const tenantGrape = ['#862E9C', '#AE3EC9', '#CC5DE8', '#E599F7', 'var(--colors-tenant-grape-5)', 'grape'];
    const tenantPink = ['#A61E4D', '#D6336C', '#F06595', '#FAA2C1', 'var(--colors-tenant-pink-5)', 'pink'];
    const tenantBrandColor = ['#3d8161', '#63b187', 'var(--colors-brandgreen-5)', 'brandgreen', 'var(--colors-brandcolor-5)', 'brandcolor'];
    const tenantBlack = ['#000000', 'black'];
    const tenantGray4 = ['#333333', 'gray4'];
    const tenantGray3 = ['#666666', 'gray3'];
    const tenantGray2 = ['#999999', 'gray2'];
    const tenantGray1 = ['#E0E0E0', 'gray1'];

    if (tenantColor) {
      if (tenantBrandColor.includes(tenantColor)) {
        return 'brandcolor';
      }
      if (tenantRed.includes(tenantColor)) {
        return 'red';
      }
      if (tenantOrange.includes(tenantColor)) {
        return 'orange';
      }
      if (tenantYellow.includes(tenantColor)) {
        return 'yellow';
      }
      if (tenantGreen.includes(tenantColor)) {
        return 'green';
      }
      if (tenantCyan.includes(tenantColor)) {
        return 'cyan';
      }
      if (tenantBlue.includes(tenantColor)) {
        return 'blue';
      }
      if (tenantGrape.includes(tenantColor)) {
        return 'grape';
      }
      if (tenantPink.includes(tenantColor)) {
        return 'pink';
      }
      if (tenantBlack.includes(tenantColor)) {
        return 'black';
      }
      if (tenantGray4.includes(tenantColor)) {
        return 'gray4';
      }
      if (tenantGray3.includes(tenantColor)) {
        return 'gray3';
      }
      if (tenantGray2.includes(tenantColor)) {
        return 'gray2';
      }
      if (tenantGray1.includes(tenantColor)) {
        return 'gray1';
      }
    }
    return 'brandcolor';
  }, [event, isMultiTenant, tenantColor]);

  const tenantClassName = isMultiTenant ? `tenant-${tenantColorName}` : 'tenant-single';

  const [supportOpen, { open: openSupport, close: closeSupport }] = useDisclosure();
  const [selectedEvent, setSelectedEvent] = useAtom(selectedEventAtom);

  const { gridRef, openPurchaseModal, setInitialPurchaseEvent } = useInventory('gridRef', 'openPurchaseModal', 'setInitialPurchaseEvent');

  const [commentsOpen, { open: openComments, close: closeComments }] = useDisclosure();
  const numComments = event?.numComments || 0;

  const lockedFiltersFlag = useFlag('locked-filters');

  if (!event) return null;

  const isLikelyPrecipitation = (event?.weatherForecast?.chanceOfRain ?? 0) >= 70 || (event?.weatherForecast?.chanceOfSnow ?? 0) >= 70;
  const hasWeatherAlerts = (event?.weatherForecast?.alerts?.length ?? 0) > 0;
  const venueNotEnclosed = event?.venue?.enrichmentData?.enclosure !== 'enclosed';
  const hasImportantWeather = hasWeatherAlerts || (isLikelyPrecipitation && venueNotEnclosed);
  const showWeatherSetting = principal?.settings?.pricerSettings?.appearanceSettings?.showWeather ?? BarkerCoreEnumsShowWeather.Never;

  const showWeather = showWeatherSetting === BarkerCoreEnumsShowWeather.Always || (showWeatherSetting === BarkerCoreEnumsShowWeather.OnlyImportant && hasImportantWeather);

  const showEventPerformanceSelectedState = showEventPerformance && selectedEvent?.eventId === eventId;

  let isContingent = false;

  if (isDtiHosted && event?.vendorProperties?.pointOfSaleId === 'DtiPortal') {
    const vendorProperties = event?.vendorProperties as BarkerCoreModelsInventoryEventVendorPropertiesDtiPortal;
    isContingent = vendorProperties?.isContingent;
  }

  const materialWeatherIcon = () => {
    if (!event.weatherForecast || !event.weatherForecast?.condition.code) {
      return <PartlyCloudyDayIcon />;
    }
    switch (event.weatherForecast?.condition.code) {
      case 1000:
        if (event.weatherForecast?.condition.text === 'Clear ') {
          return <ClearNightIcon />;
        }
        return <SunnyIcon />;
      case 1003:
        return <PartlyCloudyDayIcon />;
      case 1006:
        return <CloudIcon />;
      case 1009:
        return <CloudIcon />;
      case 1030:
        return <FoggyIcon />;
      case 1063:
        return <RainyIcon />;
      case 1066:
        return <WeatherSnowyIcon />;
      case 1069:
        return <WeatherMixIcon />;
      case 1072:
        return <WeatherMixIcon />;
      case 1087:
        return <ThunderstormIcon />;
      case 1114:
        return <WeatherSnowyIcon />;
      case 1117:
        return <WeatherSnowyIcon />;
      case 1135:
        return <FoggyIcon />;
      case 1147:
        return <FoggyIcon />;
      case 1150:
        return <RainyIcon />;
      case 1153:
        return <RainyIcon />;
      case 1168:
        return <WeatherMixIcon />;
      case 1171:
        return <WeatherMixIcon />;
      case 1180:
        return <RainyIcon />;
      case 1183:
        return <RainyIcon />;
      case 1186:
        return <RainyIcon />;
      case 1189:
        return <RainyIcon />;
      case 1192:
        return <RainyIcon />;
      case 1195:
        return <RainyIcon />;
      case 1198:
        return <WeatherMixIcon />;
      case 1201:
        return <WeatherMixIcon />;
      case 1204:
        return <WeatherMixIcon />;
      case 1207:
        return <WeatherMixIcon />;
      case 1210:
        return <WeatherSnowyIcon />;
      case 1213:
        return <WeatherSnowyIcon />;
      case 1216:
        return <WeatherSnowyIcon />;
      case 1219:
        return <WeatherSnowyIcon />;
      case 1222:
        return <WeatherSnowyIcon />;
      case 1225:
        return <WeatherSnowyIcon />;
      case 1237:
        return <WeatherSnowyIcon />;
      case 1240:
        return <RainyIcon />;
      case 1243:
        return <RainyIcon />;
      case 1246:
        return <RainyIcon />;
      case 1249:
        return <WeatherMixIcon />;
      case 1252:
        return <WeatherMixIcon />;
      case 1255:
        return <WeatherSnowyIcon />;
      case 1258:
        return <WeatherSnowyIcon />;
      case 1261:
        return <WeatherMixIcon />;
      case 1264:
        return <WeatherMixIcon />;
      case 1279:
        return <ThunderstormIcon />;
      case 1282:
        return <WeatherSnowyIcon />;
      default:
        return <PartlyCloudyDayIcon />;
    }
  };

  const chanceOfPrecipitationText = () => {
    if ((event.weatherForecast?.chanceOfRain || event.weatherForecast?.chanceOfSnow) && (event.weatherForecast?.chanceOfRain > 0 || event.weatherForecast?.chanceOfSnow > 0)) {
      if (event.weatherForecast?.chanceOfRain > event.weatherForecast?.chanceOfSnow) {
        return `${event.weatherForecast?.chanceOfRain}% Chance of Rain`;
      }
      return `${event.weatherForecast?.chanceOfSnow}% Chance of Snow`;
    }
    return '0% Chance of Precipitation';
  };

  return (
    <Box
      className={cx([
        hideEventPerformanceFlag ? classes.disableGroupHeaderWrapper : classes.groupHeaderWrapper,
        'listingsGroupHeaderWrapper',
        isMultiTenant ? tenantClassName : null,
      ])}
    >
      <Group className="dragOverlay" data-overlay-id={event.eventId} justify="space-between" pos="absolute" top={4} bottom={4} right={5} left={8} bg="var(--colors-red-0)">
        <Box
          className="icon"
          style={{
            border: '1px solid var(--colors-red-5)',
            borderRadius: 100,
            height: 18,
            width: 18,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RemoveIcon color="var(--colors-red-5)" />
        </Box>
        <Text className="dragOverlayText" fw={700} ta="center" px="xs" lh={1.3} style={{ display: 'flex', whiteSpace: 'normal' }}>
          Drop Here to Remove from Group
        </Text>
        <Box
          className="icon"
          style={{
            border: '1px solid var(--colors-red-5)',
            borderRadius: 100,
            height: 18,
            width: 18,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RemoveIcon color="var(--colors-red-5)" />
        </Box>
      </Group>
      <Group
        m={0}
        p={0}
        px={16}
        className={
          !selectedTenantListingId.hasValue() && selectedEvent && selectedEvent.tenantId === event.tenantId && selectedEvent.eventId === event.eventId
            ? 'listingsGroupHeader selected-event'
            : 'listingsGroupHeader'
        }
        onClick={async () => {
          if (lockedFiltersFlag) {
            setSelectedListingId(null);
            setSelectedEvent(event, { lockedFiltersFlag });
            gridRef?.current?.api.deselectAll();
            return;
          }
          if (hideEventPerformanceFlag) {
            return;
          }

          if (showEventPerformance && selectedEvent && selectedEvent.tenantId === event.tenantId && selectedEvent.eventId === event.eventId) {
            return;
          }
          if (await validateDirtyRuleState()) {
            await triggerSaveRule();
            gridRef.current?.api.getRowNode(selectedTenantListingId.toString())?.setExpanded(false);
            gridRef?.current?.api.deselectAll();
            setSelectedListingId(null);
            setSelectedEvent(event);
            setShowEventPerformance(true);
            setTransientGlobalState((x) => ({ ...x, expandGroupByListingId: `${event.tenantId}|${event.eventId}|sales` as StringWithPipe }));
          }
        }}
      >
        {tenants && tenants.length > 1 ? (
          <Tooltip
            position="right"
            label={
              <Group wrap="nowrap" gap={8}>
                <Text>{tenantName}</Text>
                <Box h={13} w={13} pos="relative" className={cx([classes.tenantDot, `tenant-${tenantColorName}`])} />
              </Group>
            }
            withArrow
          >
            <Box className={`${classes.sideStripe} side-stripe`} />
          </Tooltip>
        ) : (
          <Box className={`${classes.sideStripe} side-stripe side-stripe-no-hover`} />
        )}
        <Box py={4} className={classes.leftSide}>
          <Box className={classes.dateTime}>
            <Text className="listingsDate1" size="sm" fw={600}>
              {formatDate(event.localDateTime, currentYear ? DateFormats.MonthDay : DateFormats.MonthDayYear)}
            </Text>
            <Text className="listingsDate2" mt={-2} fw={500} fz="xs">
              {formatDate(event.localDateTime, DateFormats.Short)}
            </Text>
          </Box>
          <Box className={classes.overflowHidden}>
            <Text size="sm" className="listingsTitle" fw={600}>
              {event.name}
            </Text>
            <Text mt={-2} className="listingsDesc" fw={500} fz="xs">
              {`${event.venue?.name} · ${event.venue?.city}, ${event.venue?.state}`}
            </Text>
          </Box>
        </Box>
        <Group pl={8} gap="xs" c="var(--colors-gray-5)">
          <Group pl={8} gap="xs" c="var(--colors-gray-5)">
            {showWeather && event.weatherForecast && (
              <>
                <HoverCard withArrow withinPortal>
                  <HoverCard.Target>
                    <ActionIcon className={classes.weatherButton} data-pendo-name="Weather Hover">
                      {materialWeatherIcon()}
                    </ActionIcon>
                  </HoverCard.Target>
                  <HoverCard.Dropdown p="xs" pb={0}>
                    <Box w={164}>
                      <Flex justify="space-between" align="center">
                        <Flex align="center" gap={4} style={{ overflow: 'hidden' }}>
                          <img src={event.weatherForecast?.condition.icon || ExampleWeatherImage} height={24} width={24} alt="Partly Cloudy" />
                          <Text size="xs" fw={600} truncate pr={2}>
                            {event.weatherForecast?.condition.text || 'Unknown'}
                          </Text>
                        </Flex>
                        <Text size="xs" fw={600}>
                          {`${event.weatherForecast?.temperatureF}°` || '??°'}
                        </Text>
                      </Flex>
                      {(event.weatherForecast?.chanceOfRain > 0 || event.weatherForecast?.chanceOfSnow > 0) && (
                        <Center bg="var(--colors-gray-0)" py={4} px="xs" my={6} style={{ borderRadius: 3 }}>
                          <Text size="xs" truncate ta="center">
                            {chanceOfPrecipitationText()}
                          </Text>
                        </Center>
                      )}
                      {event.weatherForecast?.alerts && event.weatherForecast?.alerts.length > 0 && (
                        <Center bg="var(--colors-yellow-0)" py={4} px="xs" my={6} style={{ borderRadius: 3, border: '1px solid var(--colors-yellow-warning)' }}>
                          <Text size="xs" ta="center">
                            <strong>Alert:</strong>&nbsp;{event.weatherForecast.alerts[0]}
                          </Text>
                        </Center>
                      )}
                      <Divider color="var(--colors-divider)" mt="xs" mx={-10} />
                      <Button
                        variant="subtle"
                        component="a"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        href={`https://wunderground.com/hourly/us/${event.venue?.state?.toLowerCase()}/${event.venue?.city?.toLowerCase()}/date/${dayjs(event.localDateTime).format('YYYY-M-D')}`}
                        target="_blank"
                        size="xs"
                        fullWidth
                        mx={-10}
                        w="calc(100% + 20px)"
                        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                      >
                        Weather Underground &nbsp;&nbsp;
                        <OpenNewIcon />
                      </Button>
                    </Box>
                  </HoverCard.Dropdown>
                </HoverCard>
                <Divider orientation="vertical" color="var(--colors-divider)" opacity={0.5} h={20} mt={4} />
              </>
            )}
            {isContingent && <ContingentEventMenuButton eventId={eventId} />}
            {!commentsOpen && numComments && (
              <Tooltip label={`${numComments} event ${pluralize('comment', numComments)}`} withArrow withinPortal>
                <BNButton
                  className={classes.commentButton}
                  variant="subtle"
                  size="compact-xs"
                  c="currentColor"
                  fw={500}
                  fz={11}
                  px={4}
                  h={28}
                  leftSection={<ForumIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    commentsOpen ? closeComments() : openComments();
                  }}
                >
                  {numComments}
                </BNButton>
              </Tooltip>
            )}
            {commentsOpen && (
              <EventCommentsProvider event={event} principal={principal} numComments={numComments}>
                <EventComments commentsOpen={commentsOpen} closeComments={closeComments} numComments={numComments} openComments={openComments} />
              </EventCommentsProvider>
            )}
            {(numComments || isContingent) && <Divider orientation="vertical" color="var(--colors-divider)" opacity={0.5} h={20} mt={4} />}
            {lockedFiltersFlag && (
              <>
                <Tooltip label="Event Performance" withArrow withinPortal>
                  <ActionIcon
                    className={cx(classes.eventPerformanceButton, showEventPerformanceSelectedState && classes.eventPerformanceActive)}
                    data-pendo-name="Event Performance"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowEventPerformance(selectedEvent?.eventId === eventId ? !showEventPerformance : true);
                      setSelectedEvent(event);
                    }}
                  >
                    <AnalyticsIcon size={21} />
                  </ActionIcon>
                </Tooltip>
                <Divider orientation="vertical" color="var(--colors-divider)" opacity={0.5} h={20} mt={4} />
              </>
            )}
            <ListingCostIconLabel cost={event.openCost} />
            <OpenlistingsLabel listings={event.openListings} />
            <OpenticketsLabel tickets={event.openTickets} />
          </Group>
          {!actionMenuOpened && (
            <Group>
              <ActionIcon
                tabIndex={-1}
                onClick={(e) => {
                  e.stopPropagation();
                  openActionMenu();
                }}
                data-pendo-name="Inventory Event Action Menu Button"
              >
                <MoreVertIcon size={20} />
              </ActionIcon>
            </Group>
          )}
          {actionMenuOpened && (
            <Menu withinPortal withArrow opened width={200}>
              <Menu.Target>
                <ActionIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    closeActionMenu();
                  }}
                >
                  <MoreVertIcon size={20} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Box ref={actionMenuRef}>
                  {tenantPointOfSale === 'SkyBox' && eventTenant?.name.includes('KP3') && (
                    <Menu.Item
                      component="a"
                      target="_blank"
                      href={`https://portal.theticketsuite.com/Inventory/Index/V2?ToDate=12/31/2099&PageSize=500&PageNumber=1&EventId=${event.eventId}`}
                      leftSection={<OpenNewIcon />}
                    >
                      View in Ticket Suite
                    </Menu.Item>
                  )}
                  {tenantPointOfSale === 'SkyBox' && !eventTenant?.name.includes('KP3') && (
                    <Menu.Item
                      // TODO: Figure out how to handle anchor tags on click and close menu. Make sure link is clicked before closing.
                      component="a"
                      target="_blank"
                      href={`https://skybox.vividseats.com/inventory?groupBy=event&sortedBy=event_date&sortDir=desc&limit=100&pageNumber=1&sortedByB=section&sortDirB=asc&ef.eventId=${event.eventId}`}
                      leftSection={<OpenNewIcon />}
                    >
                      View in SkyBox
                    </Menu.Item>
                  )}
                  {tenantPointOfSale !== 'SkyBox' && !isDtiHosted && (
                    <Menu.Item
                      disabled
                      // onClick={() => {
                      //   close();
                      // }}
                      leftSection={<OpenNewIcon />}
                    >
                      View in POS
                    </Menu.Item>
                  )}
                  {/*<Menu.Item*/}
                  {/*  // onClick={() => {*/}
                  {/*  //   close();*/}
                  {/*  // }}*/}
                  {/*  leftSection={<WifiOffIcon />}*/}
                  {/*  disabled*/}
                  {/*  pos="relative"*/}
                  {/*>*/}
                  {/*  Unbroadcast All*/}
                  {/*  <Badge size="xs" variant="transparent" color="var(--colors-paperReverse)" className={classes.comingSoonBadge}>*/}
                  {/*    Coming Soon*/}
                  {/*  </Badge>*/}
                  {/*</Menu.Item>*/}
                  {purchaseFlag && (
                    <Menu.Item
                      leftSection={<AddIcon />}
                      pos="relative"
                      onClick={() => {
                        closeActionMenu();
                        setInitialPurchaseEvent(event);
                        openPurchaseModal();
                      }}
                      data-pendo-name="Add Inventory MenuItem"
                    >
                      Add Inventory
                    </Menu.Item>
                  )}
                  <Menu.Item
                    leftSection={<AddComment />}
                    pos="relative"
                    onClick={() => {
                      closeActionMenu();
                      openComments();
                    }}
                    data-pendo-name="Add Event Comment MenuItem"
                  >
                    Add Comment
                  </Menu.Item>
                  <Menu.Item
                    leftSection={<LiveHelpIcon />}
                    pos="relative"
                    onClick={() => {
                      closeActionMenu();
                      openSupport();
                    }}
                  >
                    Request Support
                  </Menu.Item>
                  {currentUser?.roleId === 'GlobalAdmin' && (
                    <>
                      <Menu.Label>Admin Functions</Menu.Label>
                      <Menu.Item
                        leftSection={<DownloadIcon />}
                        onClick={() => {
                          navigator.clipboard.writeText(event.eventId).then(() => closeActionMenu());
                        }}
                      >
                        Copy Event ID
                      </Menu.Item>
                      <Menu.Item
                        leftSection={<DeleteIcon />}
                        onClick={async () => {
                          const listingsByEventId = mergedEventListingsUnfiltered.filter(
                            (l) => l.eventId === event.eventId && l.tenantId === tenantId && !l.listingId.endsWith('sales'),
                          );
                          const tasks = listingsByEventId.map(async (_listing) => {
                            if (_listing.ruleId) {
                              await deleteApiPricingRulesRuleId(_listing.ruleId);
                            }
                            await patchApiInventoryListingsListingId(
                              _listing.listingId,
                              [
                                { op: 'remove', path: '/ruleId' },
                                { op: 'replace', path: '/pricerStatusId', value: 'None' },
                              ],
                              {
                                headers: {
                                  'x-tenant-id': _listing.tenantId,
                                },
                              },
                            );
                            updateListing({
                              ..._listing,
                              ruleId: null,
                              pricerStatusId: 'None',
                              floorPrice: null,
                              ceilingPrice: null,
                            });
                          });
                          await Promise.all(tasks);
                          closeActionMenu();
                        }}
                      >
                        Remove Listing Rules
                      </Menu.Item>
                    </>
                  )}
                </Box>
              </Menu.Dropdown>
            </Menu>
          )}
        </Group>
      </Group>
      {supportOpen && (
        <Modal
          opened={supportOpen}
          onClose={closeSupport}
          closeOnClickOutside={false}
          title={
            <Group gap="xs">
              <LiveHelpIcon color="var(--colors-iconFill)" />
              <Text size="sm">Support Request - Inventory</Text>
            </Group>
          }
          centered
          size="md"
        >
          <SupportForm type="inventoryEvent" event={event} onClose={closeSupport} pointOfSaleId={tenantPointOfSale} />
        </Modal>
      )}
    </Box>
  );
};
