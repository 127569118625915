import { Group } from '@mantine/core';
import { BNLabel } from '../../../components/Label/Label';
import pluralize from 'pluralize';
import BarcodeIcon from '../../../components/icons/Barcode';
import classes from './Attachments.Label.module.css';
import OpenInPhoneIcon from '../../../components/icons/OpenInPhone';
import PictureAsPDFIcon from '../../../components/icons/PictureAsPDF';
import P2PIcon from '../../../components/icons/P2P';

export function Attachments({
  quantity,
  numBarcodes,
  numPdfs,
  num1Tmds,
  numTransferUrls,
}: {
  quantity: number;
  numBarcodes: number;
  numPdfs: number;
  num1Tmds: number;
  numTransferUrls: number;
}) {
  return (
    <Group gap="xs" mx={-14} px={8} h="100%" w="100%" align="center" justify="flex-start" c="var(--colors-gray-5)">
      {numBarcodes > 0 && (
        <BNLabel
          className={classes.label}
          leftIcon={<BarcodeIcon size={20} />}
          text={numBarcodes}
          tooltip={`${numBarcodes.toLocaleString()} ${pluralize('barcode', numBarcodes)}`}
        />
      )}
      {numTransferUrls > 0 && (
        <BNLabel
          className={classes.label}
          leftIcon={<OpenInPhoneIcon size={20} />}
          text={numTransferUrls}
          tooltip={`${numTransferUrls.toLocaleString()} transfer ${pluralize('url', numBarcodes)}`}
        />
      )}
      {numPdfs > 0 && (
        <BNLabel className={classes.label} leftIcon={<PictureAsPDFIcon size={20} />} text={numPdfs} tooltip={`${numPdfs.toLocaleString()} ${pluralize('pdf', numBarcodes)}`} />
      )}
      {num1Tmds > 0 && (
        <BNLabel className={classes.label} leftIcon={<P2PIcon size={20} />} text={num1Tmds} tooltip={`${num1Tmds.toLocaleString()} ${numBarcodes === 1 ? '1TMD' : '1TMDs'}`} />
      )}
    </Group>
  );
}
