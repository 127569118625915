import { ActionIcon, Box, Divider, Flex, Group, Indicator, Paper, Popover, Tooltip } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import SettingsIcon from '../../components/icons/Settings';
import { BNTextInput } from '../../components/TextInput/TextInput';
import FilterListIcon from '../../components/icons/FilterList';
import { TotalEventsLabel } from '../../components/Label/TotalEventsLabel';
import { ListingCostIconLabel } from '../../components/Label/ListingCostLabel';
import { OpenlistingsLabel } from '../../components/Label/OpenListingsLabel';
import { OpenticketsLabel } from '../../components/Label/OpenTicketsLabel';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import CloseIcon from '../../components/icons/Close';
import { useInventoryHeader } from './Inventory.Header.hooks';
import EventListIcon from '../../components/icons/EventList';
import classes from './Inventory.styles.tsx.module.css';
import cx from 'clsx';
import { ViewOptions } from './Inventory.Header.ViewOptions';
import { useFlag } from '@unleash/proxy-client-react';
import RefreshIcon from '../../components/icons/Refresh';
import { onRefetchInventory, onRefetchInventoryCompleted } from '../EventSearch/SearchResults.hooks';
import { useGlobalState } from '../../data/GlobalState';
import { FeatureHintWrapper } from '../FeatureHint/FeatureHintWrapper';
import { useDisclosure } from '@mantine/hooks';
import AddIcon from '../../components/icons/Add';
import { BNButton } from '../../components/Button/Button';
import { isDtiHosted } from '../../utils/whitelabel-consts';

export function Header() {
  const {
    filter,
    setFilter,
    setInventoryQuickFilter,
    inventoryQuickFilter,
    summarizedValues,
    showSettings,
    toggleSettings,
    showBulkOptions,
    toggleBulkOptions,
    openPurchaseModal,
  } = useInventoryHeader(
    'filter',
    'setFilter',
    'setInventoryQuickFilter',
    'inventoryQuickFilter',
    'summarizedValues',
    'showSettings',
    'toggleSettings',
    'showBulkOptions',
    'toggleBulkOptions',
    'openPurchaseModal',
  );
  const { needsFeatureHint } = useGlobalState('needsFeatureHint');
  const inventoryEventsFlag = useFlag('split-event-inventory');
  const consignPurchaseFlag = useFlag('consign-purchase') && isDtiHosted;
  const showAddInventoryButton = true;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [filterSearchOpened, { close: closeFilterSearch, toggle: toggleFilterSearch }] = useDisclosure(false);

  const finalizeRefetchInventoryEvent = useCallback(() => {
    setIsRefreshing(false);
  }, [setIsRefreshing]);

  useEffect(() => {
    document.addEventListener(onRefetchInventoryCompleted.type, finalizeRefetchInventoryEvent);
    return () => {
      document.removeEventListener(onRefetchInventoryCompleted.type, finalizeRefetchInventoryEvent);
    };
  }, [finalizeRefetchInventoryEvent]);

  const needsBulkInventoryEditHint = needsFeatureHint('bulk-inventory-edit');

  return (
    <Paper>
      <Group px={16} className={cx(classes.headerWrapper, inventoryEventsFlag && showSettings && classes.settingsOpened)} justify="space-between" pos="relative">
        <Group gap="xs" wrap="nowrap" className={classes.leftSearch}>
          <Tooltip label="Bulk Actions" position="bottom" withArrow>
            <Indicator size={needsBulkInventoryEditHint ? 6 : 0} offset={0} color="blue" display="flex">
              <ActionIcon
                variant="default"
                onClick={toggleBulkOptions}
                h={30}
                miw={30}
                data-pendo-name="Bulk Actions Button"
                className={showBulkOptions ? classes.bulkActionsButtonActive : classes.bulkActionsButton}
              >
                <EventListIcon />
              </ActionIcon>
            </Indicator>
          </Tooltip>
          {consignPurchaseFlag && showAddInventoryButton && (
            <Tooltip label="Add Inventory" position="bottom" withArrow>
              <BNButton
                variant="default"
                size="xs"
                pl={4}
                pr={10}
                leftSection={<AddIcon size={21} />}
                h={30}
                miw={30}
                data-pendo-name="Add Inventory Button"
                className={classes.addInventoryButton}
                onClick={() => openPurchaseModal()}
              >
                <span className={classes.addText}>Add</span>
              </BNButton>
            </Tooltip>
          )}
          {/* Show full filter when there is room */}
          <Box className={classes.searchFilter}>
            <BNTextInput
              id="filter-text-box"
              leftSection={
                inventoryQuickFilter ? (
                  <Indicator color="var(--colors-selectedBorder)" display="flex" size={4} offset={1}>
                    <FilterListIcon color="var(--colors-selectedBorder)" />
                  </Indicator>
                ) : (
                  <FilterListIcon />
                )
              }
              value={filter}
              size="xs"
              onChange={(e) => {
                setFilter(e.currentTarget.value);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setInventoryQuickFilter(filter);
                } else if (e.key === 'Escape') {
                  setFilter('');
                  setInventoryQuickFilter('');
                }
              }}
              data-pendo-name="Inventory Quick Filter Input"
              placeholder="Filter Listings"
              aria-label="Filter Listings"
              className={inventoryQuickFilter ? `${classes.showRightSection}` : `${classes.clearableInputAndGo}`}
              rightSectionWidth={57}
              rightSection={
                <Group wrap="nowrap" gap={4} px="xs">
                  <ActionIcon
                    size="sm"
                    className="clearButton"
                    onClick={() => {
                      setFilter('');
                      setInventoryQuickFilter('');
                    }}
                  >
                    <CloseIcon />
                  </ActionIcon>
                  <ActionIcon variant="filled" size="sm" onClick={() => setInventoryQuickFilter(filter)}>
                    <ArrowForwardIcon color="var(--colors-paper)" size={20} />
                  </ActionIcon>
                </Group>
              }
              w="100%"
              maw={200}
            />
          </Box>
          {/* Show collapsed filter when there is limited room */}
          <Box className={classes.responsiveSearchFilter}>
            <Popover opened={filterSearchOpened} withArrow position="right" offset={3} withinPortal={false}>
              <Popover.Target>
                <Tooltip label="Filter Results" position="bottom" withArrow>
                  <ActionIcon
                    className={filterSearchOpened ? classes.filterActionIconActive : classes.filterActionIcon}
                    variant="default"
                    h={30}
                    w={30}
                    data-pendo-name="Inventory Filter Button"
                    style={{ flexShrink: 0 }}
                    onClick={toggleFilterSearch}
                  >
                    <Indicator color="var(--colors-selectedBorder)" display="flex" size={4} offset={1} disabled={!inventoryQuickFilter}>
                      <FilterListIcon />
                    </Indicator>
                  </ActionIcon>
                </Tooltip>
              </Popover.Target>
              <Popover.Dropdown p={0} className={classes.responsiveSearchFilterDropdown}>
                <BNTextInput
                  id="responsive-filter-text-box"
                  styles={{ input: { border: 'none' } }}
                  autoFocus
                  value={filter}
                  size="xs"
                  w={148}
                  onChange={(e) => {
                    setFilter(e.currentTarget.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      setInventoryQuickFilter(filter);
                    } else if (e.key === 'Escape') {
                      setFilter('');
                      setInventoryQuickFilter('');
                    }
                  }}
                  data-pendo-name="Inventory Quick Filter Input"
                  placeholder="Filter Listings"
                  aria-label="Filter Listings"
                  className={inventoryQuickFilter ? `${classes.showRightSection}` : `${classes.clearableInputAndGo}`}
                  rightSectionWidth={57}
                  rightSection={
                    <Group wrap="nowrap" gap={4} px="xs">
                      <ActionIcon
                        size="sm"
                        className="clearButton"
                        onClick={() => {
                          setFilter('');
                          setInventoryQuickFilter('');
                          closeFilterSearch();
                        }}
                      >
                        <CloseIcon color="var(--colors-gray-4)" />
                      </ActionIcon>
                      <ActionIcon variant="filled" size="sm" onClick={() => setInventoryQuickFilter(filter)}>
                        <ArrowForwardIcon color="var(--colors-paper)" size={20} />
                      </ActionIcon>
                    </Group>
                  }
                />
              </Popover.Dropdown>
            </Popover>
          </Box>
        </Group>
        <Group gap="xs" className={classes.headerRight}>
          <Group gap="xs" c="var(--colors-gray-5)">
            <TotalEventsLabel events={summarizedValues.totalEvents} />
            <ListingCostIconLabel cost={summarizedValues.totalCost} />
            <OpenlistingsLabel listings={summarizedValues.totalListings} />
            <OpenticketsLabel tickets={summarizedValues.totalTickets} />
          </Group>
          <Tooltip label="Refresh Inventory" position="right" withArrow>
            <ActionIcon
              loading={isRefreshing}
              onClick={() => {
                setIsRefreshing(true);
                document.dispatchEvent(onRefetchInventory);
              }}
              loaderProps={{ type: 'oval', color: 'gray' }}
              data-pendo-name="Inventory Grid Refresh Button"
            >
              <RefreshIcon size={21} />
            </ActionIcon>
          </Tooltip>
          {inventoryEventsFlag && (
            <Flex className={cx(classes.viewOptionsWrapper, showSettings && classes.viewOptionsWrapperOpen)} gap={12} mih={30} align="center">
              <Divider orientation="vertical" color="var(--colors-divider)" h={16} style={{ alignSelf: 'auto' }} />
              <Box opacity={showSettings ? 1 : 0}>
                {showSettings && (
                  <ViewOptions
                    afterChanged={() => {
                      toggleSettings();
                    }}
                  />
                )}
              </Box>
            </Flex>
          )}
          <Divider orientation="vertical" color="var(--colors-divider)" h={16} style={{ alignSelf: 'auto' }} />
          {inventoryEventsFlag ? (
            <FeatureHintWrapper name="split-view" enabled={inventoryEventsFlag}>
              <Tooltip label="Grid Settings" position="right" withArrow>
                <ActionIcon
                  data-pendo-name="Inventory Grid Settings Button"
                  className={showSettings ? classes.settingsButtonActive : classes.settingsButton}
                  onClick={() => {
                    toggleSettings();
                  }}
                >
                  <SettingsIcon size={20} color={showSettings ? 'var(--colors-selectedBorder)' : undefined} />
                </ActionIcon>
              </Tooltip>
            </FeatureHintWrapper>
          ) : (
            <Tooltip label="Grid Settings" position="right" withArrow>
              <ActionIcon
                data-pendo-name="Inventory Grid Settings Button"
                className={showSettings ? classes.settingsButtonActive : classes.settingsButton}
                onClick={() => toggleSettings()}
              >
                <SettingsIcon size={20} color={showSettings ? 'var(--colors-selectedBorder)' : undefined} />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
      </Group>
    </Paper>
  );
}
