import { Badge, Box, Center, Flex, Group, Loader, Modal, Skeleton, Text } from '@mantine/core';
import { BNButton } from '../../../components/Button/Button';
import AutoPriceIcon from '../../../components/icons/AutoPrice';
import EditNoteIcon from '../../../components/icons/EditNote';
import FlashOnIcon from '../../../components/icons/FlashOn';
import LocalActivityIcon from '../../../components/icons/LocalActivity';
import OpenInPhoneIcon from '../../../components/icons/OpenInPhone';
import PictureAsPDFIcon from '../../../components/icons/PictureAsPDF';
import { formatCurrency, formatDate } from '../../../utils/formatters';
import { DateFormats } from '../../../utils/globals';
import classes from './EditPropertiesDialog.module.css';
import { useDTIInventory } from '../DTI.Inventory.hooks';
import { BarkerEventListing } from '../../../types';
import { SyntheticEvent, useCallback, useRef } from 'react';
import { EditProperties_Form, EditPropertiesDialog_Handles } from './EditProperties.Form';
import { onRefetchInventory } from '../../EventSearch/SearchResults.hooks';
import { BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal, useGetDtiItemsAccountId } from '../../../api';
import AddIcon from '../../../components/icons/Add';

export function EditPropertiesDialog({ opened, onClose }: { opened: boolean; onClose: () => void }) {
  const { selectedListing, isEditLoading, vendorProperties } = useDTIInventory('selectedListing', 'isEditLoading', 'vendorProperties');
  const ref = useRef<EditPropertiesDialog_Handles>();

  const selectedEventId = parseInt(selectedListing?.eventId ?? '0');

  const { data: listingItems } = useGetDtiItemsAccountId(
    (selectedListing?.vendorProperties as BarkerCoreModelsInventoryListingVendorPropertiesDtiPortal | undefined)?.ownerId!,
    {
      listing_id: selectedListing?.listingId,
    },
    {
      query: {
        enabled: selectedEventId > 0 && !!selectedListing?.listingId,
        select: (data) => data.data?.items,
      },
    },
  );

  const deleteAllAssets = useCallback(() => {
    ref.current?.onDeleteAllAssets();
  }, []);

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (ref.current) {
      // Follow the ref to find onSubmit in {useImperativeHandle} in child component
      await ref.current.onSubmit();
      onClose();
      document.dispatchEvent(onRefetchInventory);
    }
  };

  const isPDF = false;

  return (
    <Modal
      centered
      styles={{ body: { display: 'flex', flex: 1, overflow: 'hidden', padding: 0 }, content: { display: 'flex', flexDirection: 'column', overflow: 'hidden' } }}
      size={840}
      opened={opened}
      closeButtonProps={{ size: 'md' }}
      onClose={onClose}
      title={
        <Group>
          <EditNoteIcon color="var(--colors-iconFill)" size={24} />
          Edit Properties
        </Group>
      }
    >
      <Flex flex={1} direction="column" pos="relative" style={{ overflow: 'hidden' }}>
        <form style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }} onSubmit={onSubmit}>
          <Box bg="var(--colors-paper)" p="lg" pt="md" pb={0} style={{ zIndex: 2 }}>
            {selectedListing && <EventTitle listing={selectedListing} />}
            <Group mx={-20} px="lg" pt="sm" pb="lg" className={classes.gradient}>
              <Group align="start" gap={0} justify="space-between" style={{ border: '1px solid var(--colors-gray-3)', borderRadius: 3 }} p="sm" w="100%">
                <Box maw="20%" style={{ overflow: 'hidden' }}>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Section
                  </Text>
                  <Text lh={1.25} pt={2} size="xs" style={{ whiteSpaceCollapse: 'collapse', overflowWrap: 'break-word' }}>
                    {selectedListing?.section}
                  </Text>
                </Box>
                <Box maw="20%" style={{ overflow: 'hidden' }}>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Row
                  </Text>
                  <Text lh={1.25} pt={2} size="xs" style={{ whiteSpaceCollapse: 'collapse', overflowWrap: 'break-word' }}>
                    {selectedListing?.row}
                  </Text>
                </Box>
                <Box>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Seats
                  </Text>
                  <Group>
                    <Text lh={1.25} pt={2} size="xs">
                      {selectedListing?.quantityRemaining ?? 0 > 1 ? `${selectedListing?.seatFrom}-${selectedListing?.seatThru}` : selectedListing?.seatFrom}
                    </Text>
                    {vendorProperties?.isOddEven && (
                      <Badge color="var(--colors-gray-4)" c="var(--colors-paper)" size="xs">
                        Odd/Even
                      </Badge>
                    )}
                  </Group>
                </Box>
                <Box>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Qty
                  </Text>
                  <Text lh={1.25} pt={2} size="xs">
                    {selectedListing?.quantityRemaining}
                  </Text>
                </Box>
                <Box>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Unit Cost
                  </Text>
                  <Text lh={1.25} pt={2} size="xs">
                    {formatCurrency(selectedListing?.unitCost ?? 0)}
                  </Text>
                </Box>
                <Box>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Current Price
                  </Text>
                  <Group gap={4}>
                    <Text size="xs">{formatCurrency(selectedListing?.unitPrice ?? 0)}</Text>
                    {selectedListing?.pricerStatusId === 'AutoPriced' && <AutoPriceIcon size={16} />}{' '}
                    {/* TODO: Replace this with the logic for auto pricing indicators to be developed later */}
                  </Group>
                </Box>
                <Box pr={0} w={108}>
                  <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
                    Stock
                  </Text>
                  {!selectedListing?.ticketStockId ? <Skeleton w={108} h={8} mt={5} color="var(--colors-gray-5)" /> : <LocationDisplay location={selectedListing.ticketStockId} />}
                </Box>
              </Group>
            </Group>
          </Box>
          {selectedListing && <EditProperties_Form ref={ref} selectedListing={selectedListing} listingItems={listingItems} />}
          <Box pos="relative" bg="var(--colors-paper)" py="1rem" style={{ flexShrink: 0, borderTop: '1px solid var(--colors-divider)' }}>
            <Flex w="100%" justify="space-between" gap={12} align="center" px={16}>
              <Group>
                {/* Add PDFs Button to be displayed only when relevant */}
                {isPDF && (
                  <BNButton size="xs" variant="default" leftSection={<AddIcon />}>
                    Add PDFs
                  </BNButton>
                )}
                {/* Clear Assets Button to be displayed only when relevant */}
                <BNButton size="xs" variant="subtle" disabled={!listingItems} onClick={() => deleteAllAssets()}>
                  Clear Assets
                </BNButton>
              </Group>
              <Group>
                <BNButton className={classes.btnFocusStyles} size="xs" w={80} onClick={onClose} disabled={isEditLoading}>
                  Cancel
                </BNButton>
                <BNButton className={classes.btnFocusStyles} loading={isEditLoading} disabled={!listingItems} type="submit" size="xs" variant="filled" color="green" w={80}>
                  Save
                </BNButton>
              </Group>
            </Flex>
          </Box>
        </form>
      </Flex>
    </Modal>
  );
}

function EventTitle({ listing: selectedListing }: { listing: BarkerEventListing }) {
  if (!selectedListing) {
    return null;
  }

  return (
    <Group mt={-2} pos="relative" style={{ zIndex: 1 }}>
      <Box>
        <Text size="xs" fw={600}>
          {selectedListing.event.name}
        </Text>
        <Text p={0} c="var(--colors-gray-5)" size="xs" fw={500}>
          <span>
            <span>{formatDate(selectedListing.event.localDateTime, DateFormats.Extended)}</span> - {selectedListing.event.venue.name} · {selectedListing.event.venue.city},{' '}
            {selectedListing.event.venue.state}
          </span>
        </Text>
      </Box>
    </Group>
  );
}

function LocationDisplay({ location }: { location: string }) {
  if (location === 'Qr') {
    return (
      <Group wrap="nowrap" gap={4}>
        <Text lh={1.25} pt={2}>
          PDF
        </Text>
        <Center style={{ flexShrink: 0 }}>
          <PictureAsPDFIcon color="var(--colors-iconFill)" size={16} />
        </Center>
      </Group>
    );
  }
  if (location === 'Hard') {
    return (
      <Group wrap="nowrap" gap={4}>
        <Text lh={1.25} pt={2}>
          Hard
        </Text>
        <Center style={{ flexShrink: 0 }}>
          <LocalActivityIcon color="var(--colors-iconFill)" size={16} />
        </Center>
      </Group>
    );
  }
  if (location === 'AxsMobile') {
    return (
      <Group wrap="nowrap" gap={4}>
        <Text lh={1.25} pt={2}>
          Flash
        </Text>
        <Center style={{ flexShrink: 0 }}>
          <FlashOnIcon color="var(--colors-iconFill)" size={16} />
        </Center>
      </Group>
    );
  }

  if (location === 'TicketmasterMobile') {
    return (
      <Group wrap="nowrap" gap={4}>
        <Text lh={1.25} pt={2}>
          Mobile Transfer
        </Text>
        <Center style={{ flexShrink: 0 }}>
          <OpenInPhoneIcon color="var(--colors-iconFill)" size={16} />
        </Center>
      </Group>
    );
  }

  return '';
}
