import {
  ActionIcon,
  Autocomplete,
  Avatar,
  Badge,
  Button,
  Checkbox,
  Combobox,
  Group,
  HoverCard,
  Menu,
  Modal,
  MultiSelect,
  NativeSelect,
  Notification,
  NumberInput,
  Paper,
  PillsInput,
  Popover,
  Select,
  Switch,
  Table,
  Tabs,
  Text,
  TextInput,
  Textarea,
  Tooltip,
  createTheme,
  rem,
} from '@mantine/core';
import { DateInput, DatePickerInput } from '@mantine/dates';

export const theme = createTheme({
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '77.5em',
    xl: '90em',
  },
  fontFamily: 'Inter, sans-serif',
  focusRing: 'never',
  colors: {
    brandgreen: [
      'var(--colors-brandgreen-0)',
      'var(--colors-brandgreen-1)',
      'var(--colors-brandgreen-2)',
      'var(--colors-brandgreen-3)',
      'var(--colors-brandgreen-4)',
      'var(--colors-brandgreen-5)',
      'var(--colors-brandgreen-6)',
      'var(--colors-brandgreen-7)',
      'var(--colors-brandgreen-8)',
      'var(--colors-brandgreen-9)',
    ],
    bngreen: [
      'var(--colors-bngreen-0)',
      'var(--colors-bngreen-1)',
      'var(--colors-bngreen-2)',
      'var(--colors-bngreen-3)',
      'var(--colors-bngreen-4)',
      'var(--colors-bngreen-5)',
      'var(--colors-bngreen-6)',
      'var(--colors-bngreen-7)',
      'var(--colors-bngreen-8)',
      'var(--colors-bngreen-9)',
    ],
    dtipurple: [
      'var(--colors-dtipurple-0)',
      'var(--colors-dtipurple-1)',
      'var(--colors-dtipurple-2)',
      'var(--colors-dtipurple-3)',
      'var(--colors-dtipurple-4)',
      'var(--colors-dtipurple-5)',
      'var(--colors-dtipurple-6)',
      'var(--colors-dtipurple-7)',
      'var(--colors-dtipurple-8)',
      'var(--colors-dtipurple-9)',
    ],
    green: [
      'var(--colors-green-0)',
      'var(--colors-green-1)',
      'var(--colors-green-2)',
      'var(--colors-green-3)',
      'var(--colors-green-4)',
      'var(--colors-green-5)',
      'var(--colors-green-6)',
      'var(--colors-green-7)',
      'var(--colors-green-8)',
      'var(--colors-green-9)',
    ],
    blue: [
      'var(--colors-blue-0)',
      'var(--colors-blue-1)',
      'var(--colors-blue-2)',
      'var(--colors-blue-3)',
      'var(--colors-blue-4)',
      'var(--colors-blue-5)',
      'var(--colors-blue-6)',
      'var(--colors-blue-7)',
      'var(--colors-blue-8)',
      'var(--colors-blue-9)',
    ],
    orange: [
      'var(--colors-orange-0)',
      'var(--colors-orange-1)',
      'var(--colors-orange-2)',
      'var(--colors-orange-3)',
      'var(--colors-orange-4)',
      'var(--colors-orange-5)',
      'var(--colors-orange-6)',
      'var(--colors-orange-7)',
      'var(--colors-orange-8)',
      'var(--colors-orange-9)',
    ],
    gray: [
      'var(--colors-gray-0)',
      'var(--colors-gray-1)',
      'var(--colors-gray-2)',
      'var(--colors-gray-3)',
      'var(--colors-gray-4)',
      'var(--colors-gray-5)',
      'var(--colors-gray-6)',
      'var(--colors-gray-7)',
      'var(--colors-gray-8)',
      'var(--colors-gray-9)',
    ],
    dark: [
      'var(--colors-gray-9)',
      'var(--colors-gray-8)',
      'var(--colors-gray-7)',
      'var(--colors-gray-6)',
      'var(--colors-gray-5)',
      'var(--colors-gray-4)',
      'var(--colors-gray-3)',
      'var(--colors-gray-2)',
      'var(--colors-gray-1)',
      'var(--colors-gray-0)',
    ],
    red: [
      'var(--colors-red-0)',
      'var(--colors-red-1)',
      'var(--colors-red-2)',
      'var(--colors-red-3)',
      'var(--colors-red-4)',
      'var(--colors-red-5)',
      'var(--colors-red-6)',
      'var(--colors-red-7)',
      'var(--colors-red-8)',
      'var(--colors-red-9)',
    ],
    warning: [
      'var(--colors-yellow-0)',
      'var(--colors-yellow-1)',
      'var(--colors-yellow-2)',
      'var(--colors-yellow-3)',
      'var(--colors-yellow-4)',
      'var(--colors-yellow-5)',
      'var(--colors-yellow-6)',
      'var(--colors-yellow-7)',
      'var(--colors-yellow-8)',
      'var(--colors-yellow-9)',
    ],
    yellow: [
      'var(--colors-yellow-0)',
      'var(--colors-yellow-1)',
      'var(--colors-yellow-2)',
      'var(--colors-yellow-3)',
      'var(--colors-yellow-4)',
      'var(--colors-yellow-5)',
      'var(--colors-yellow-6)',
      'var(--colors-yellow-7)',
      'var(--colors-yellow-8)',
      'var(--colors-yellow-9)',
    ],
    danger: [
      'var(--colors-red-0)',
      'var(--colors-red-1)',
      'var(--colors-red-2)',
      'var(--colors-red-3)',
      'var(--colors-red-4)',
      'var(--colors-red-5)',
      'var(--colors-red-6)',
      'var(--colors-red-7)',
      'var(--colors-red-8)',
      'var(--colors-red-9)',
    ],
    brandcolor: [
      'var(--colors-brandcolor-0)',
      'var(--colors-brandcolor-1)',
      'var(--colors-brandcolor-2)',
      'var(--colors-brandcolor-3)',
      'var(--colors-brandcolor-4)',
      'var(--colors-brandcolor-5)',
      'var(--colors-brandcolor-6)',
      'var(--colors-brandcolor-7)',
      'var(--colors-brandcolor-8)',
      'var(--colors-brandcolor-9)',
    ],
    selected: [
      'var(--colors-selected-0)',
      'var(--colors-selected-1)',
      'var(--colors-selected-2)',
      'var(--colors-selected-3)',
      'var(--colors-selected-4)',
      'var(--colors-selected-5)',
      'var(--colors-selected-6)',
      'var(--colors-selected-7)',
      'var(--colors-selected-8)',
      'var(--colors-selected-9)',
    ]
  },
  defaultRadius: 3,
  primaryColor: 'gray',
  primaryShade: { light: 5, dark: 5 },
  spacing: { xl: '1.5rem' },
  components: {
    ActionIcon: ActionIcon.extend({
      styles: {
        root: {},
        loader: {
          background: 'transparent',
        },
      },
    }),
    Autocomplete: Autocomplete.extend({
      defaultProps: {
        fw: 500,
      },
      styles: {
        root: { '--input-section-color': 'var(--colors-gray-4)' },
        dropdown: {
          backgroundColor: 'var(--colors-paper)',
        },
        input: {
          '--input-bg': 'var(--colors-paper)',
          '--input-bd': 'var(--colors-gray-3)',
          '--input-bd-hover': 'var(--colors-gray-4)',
        },
      },
    }),
    Avatar: Avatar.extend({
      defaultProps: {
        fw: 500,
      },
      styles: {
        root: {
          '--text-fz': '0.95rem',
          '--avatar-color': 'var(--colors-gray-6)',
          '--text-color': 'var(--colors-gray-6)',
        },
      },
    }),
    Badge: Badge.extend({
      vars: (theme, props) => {
        if (props.variant === 'light') {
          backgroundColor: `var(--colors-${props.color || 'gray'}-1)`;
          color: `var(--colors-${props.color || 'gray'}-6)`;
        }
        return { root: {} };
      },
      styles: {
        root: {
          '--mantine-color-gray-light-color': 'var(--colors-gray-5)',
          '--mantine-color-red-light-color': 'var(--colors-red-5)',
        },
      },
    }),
    Button: Button.extend({
      defaultProps: {
        fw: 600,
      },
      vars: (theme, props) => {
        if (props.variant === 'outline' && props.color === 'red') {
          return {
            root: {
              borderColor: 'var(--colors-red-error)',
              '--button-hover': 'var(--colors-red-error-hover)',
            },
          };
        }
        if (props.variant === 'outline') {
          return {
            root: {
              color: `var(--colors-${props.color || 'gray'}-5)`,
              borderColor: `var(--colors-${props.color || 'gray'}-4)`,
              '--button-hover': `var(--colors-${props.color || 'gray'}-6)`,
            },
          };
        }
        return { root: {} };
      },
      styles: {
        root: {
          '--text-fw': 600,
        },
      },
    }),
    Checkbox: Checkbox.extend({
      defaultProps: {
        display: 'flex',
        fw: 500,
        width: '100%',
      },
      styles: {
        root: {
          alignItems: 'center',
        },
        body: {
          alignItems: 'center',
          flex: 1,
        },
        input: {
          // backgroundColor: 'var(--colors-paper)',
          // borderColor: 'var(--colors-gray-3)',
        },
        labelWrapper: {
          display: 'flex',
          flex: 1,
          paddingTop: 10,
          paddingBottom: 10,
          color: 'var(--colors-paperReverse)',
        },
        label: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    }),
    Combobox: Combobox.extend({
      // defaultProps: {
      //   portalProps: {
      //     target: '#portalWrapper',
      //   },
      // },
      styles: {
        dropdown: {
          background: 'var(--colors-paper)',
          borderColor: 'var(--colors-gray-2)',
        },
      },
    }),
    DateInput: DateInput.extend({
      defaultProps: {
        fw: 500,
      },
      styles: {
        root: {
          '--input-section-color': 'var(--colors-gray-4)',
        },
        input: {
          '--input-bd': 'var(--colors-gray-3)',
          '--input-bd-hover': 'var(--colors-gray-4)',
          '--input-bg': 'transparent',
        },
        label: {
          lineHeight: '1.25rem',
        },
        day: {},
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      defaultProps: {
        fw: 500,
      },
      vars: (theme, props) => {
        if (props.size === 'xs') {
          return {
            input: {
              paddingTop: 5,
              height: 30,
              '--input-bd-hover': 'var(--colors-gray-4)',
            },
          };
        }
        return {};
      },
      styles: {
        root: {
          '--input-section-color': 'var(--colors-gray-4)',
        },
        input: {
          '--input-bd': 'var(--colors-gray-3)',
          '--input-bd-hover': 'var(--colors-gray-4)',
          '--input-bg': 'transparent',
        },
        wrapper: {
          borderColor: 'var(--colors-selectedBorder)',
        },
        label: {
          color: 'var(--colors-gray-5)',
          lineHeight: '1.25rem',
        },
      },
    }),
    Group: Group.extend({
      defaultProps: {
        gap: 12,
        wrap: 'nowrap',
        align: 'center',
      },
    }),
    HoverCard: HoverCard.extend({
      // defaultProps: {
      //   portalProps: {
      //     target: '#portalWrapper',
      //   },
      // },
      styles: {
        dropdown: {
          '--popover-border-color': 'var(--colors-gray-2)',
          backgroundColor: 'var(--colors-paper)',
          padding: '5px 10px',
          fontWeight: 500,
        },
        arrow: {},
      },
    }),
    Menu: Menu.extend({
      // defaultProps: {
      //   portalProps: {
      //     target: '#portalWrapper',
      //   },
      // },
      styles: {
        arrow: {
          '--popover-border-color': 'var(--colors-gray-3)',
        },
        dropdown: {
          backgroundColor: 'var(--colors-paper)',
          borderColor: 'var(--colors-gray-3)',
          '--popover-shadow': 'var(--bn-popover-shadow, var(--mantine-shadow-md))',
        },
        item: {
          // color: 'var(--colors-paperReverse)',
          fontSize: 12,
          fontWeight: 500,
          borderBottom: '1px solid var(--colors-paperHover)',
          borderRadius: 0,
          paddingLeft: 8,
          paddingRight: 8,
          height: 36,
        },
        itemSection: {
          color: 'var(--colors-iconFill)',
        },
      },
    }),
    Modal: Modal.extend({
      // defaultProps: { portalProps: { target: '#portalWrapper' } },
      styles: {
        content: {
          backgroundColor: 'var(--colors-paper)',
          borderRadius: 3,
          color: 'var(--colors-paperReverse)',
          padding: '0 !important',
        },
        header: {
          backgroundColor: 'var(--colors-paper)',
          borderBottom: '1px solid var(--colors-divider)',
          margin: 0,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingTop: 0,
          paddingBottom: 0,
          minHeight: '3.25rem',
          display: 'flex',
          alignItems: 'center',
        },
        body: {
          '&:not(:onlyChild)': {
            paddingTop: '1rem',
          },
        },
        title: {
          fontSize: 16,
          fontWeight: 600,
        },
        close: {
          alignSelf: 'center',
          color: 'var(--colors-gray-5)',
          marginRight: '-0.5rem',
        },
      },
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        fw: 500,
      },
      styles: {
        root: {
          '--input-section-color': 'var(--colors-gray-4)',
        },
        dropdown: {
          background: 'var(--colors-paper)',
          borderColor: 'var(--colors-gray-2)',
        },
        label: {
          color: 'var(--colors-gray-5)',
          lineHeight: '1.25rem',
        },
        input: {
          '--input-color': 'var(--colors-paperReverse)',
          '--input-bg': 'transparent',
          '--input-bd': 'var(--colors-gray-3)',
        },
      },
    }),
    Notification: Notification.extend({
      vars: (theme, props) => {
        // console.log(props.variant)
        if (props.color === 'yellow') {
          return {
            root: {
              '--notification-color': 'var(--colors-yellow-warning)',
            },
            icon: {
              color: 'var(--colors-paper-gray-2)',
            },
          };
        }
        if (props.color === 'red') {
          return {
            root: {
              '--notification-color': 'var(--colors-red-error)',
            },
          };
        }
        if (props.color === 'green') {
          return {
            root: {
              '--notification-color': 'var(--colors-successButton)',
            },
          };
        }
        if (props.color === 'brandgreen') {
          return {
            root: {
              '--notification-color': 'var(--colors-brandgreen-5-4)',
            },
          };
        }
        if (props.color === 'brandcolor') {
          return {
            root: {
              '--notification-color': 'var(--colors-brandcolor-5-4)',
            },
          };
        }
        return {
          root: {
            '--notification-color': 'var(--colors-gray-5-4)',
          },
        };
      },
      defaultProps: {
        bg: 'var(--colors-paper)',
        withBorder: true,
        radius: 3,
        fw: 500,
      },
      styles: (theme, props) => {
        return {
          root: {
            borderColor: 'var(--colors-gray-2)',
          },
          icon: {
            color: 'white',
            height: 36,
            width: 36,
          },
          title: {
            fontWeight: 700,
          },
          description: {
            fontWeight: 400,
            color: 'var(--colors-gray-5)',
          },
          loader: {
            stroke: 'var(--colors-gray-3)',
          },
        };
      },
    }),
    NativeSelect: NativeSelect.extend({
      styles: {
        root: { '--input-section-color': 'var(--colors-gray-4)' },
        input: { borderColor: 'var(--colors-gray-3)' },
        label: { color: 'var(--colors-gray-5)', lineHeight: '1.25rem' },
      },
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        fw: 500,
      },
      styles: {
        section: {
          '--input-bd': 'var(--colors-gray-3)',
        },
      },
    }),
    Paper: Paper.extend({
      defaultProps: {},
      styles: {
        root: {
          '--paper-radius': 0,
        },
      },
    }),
    PillsInput: PillsInput.extend({
      defaultProps: {
        fw: 500,
      },
      styles: {
        root: {
          '--input-bg': 'transparent',
          '--input-bd': 'var(--colors-gray-3)',
          '--input-section-color': 'var(--colors-gray-4)',
        },
        input: {
          '--input-bg': 'transparent',
          '--input-bd': 'var(--colors-gray-3)',
        },
        label: {
          color: 'var(--colors-gray-5)',
          lineHeight: '1.25rem',
        },
      },
    }),
    Popover: Popover.extend({
      styles: {
        arrow: {
          '--popover-border-color': 'var(--colors-gray-3)',
        },
        dropdown: {
          backgroundColor: 'var(--colors-paper)',
          borderColor: 'var(--colors-gray-3)',
          color: 'var(--colors-paperReverse)',
          '--popover-shadow': 'var(--bn-popover-shadow, var(--mantine-shadow-md))',
        },
      },
      // defaultProps: {
      //   portalProps: {
      //     target: '#portalWrapper',
      //   },
      // },
    }),
    Select: Select.extend({
      defaultProps: {
        fw: 500,
        scrollAreaProps: { pr: 4 },
      },
      styles: {
        root: { '--input-section-color': 'var(--colors-gray-4)' },
        dropdown: {
          background: 'var(--colors-paper)',
          borderColor: 'var(--colors-gray-2)',
        },
        input: {
          '--input-bg': 'transparent',
          '--input-bd': 'var(--colors-gray-3)',
        },
      },
    }),
    Switch: Switch.extend({
      defaultProps: {
        bg: 'transparent',
        fw: 500,
      },
      styles: {
        // track: {
        //   borderColor: 'var(--colors-gray-3)',
        // },
      },
    }),
    Table: Table.extend({
      defaultProps: {
        fw: 500,
      },
      styles: {
        table: {
          color: 'var(--colors-paperReverse)',
          border: '1px solid var(--colors-gray-2)',
          borderColor: 'var(--colors-gray-2)',
        },
        th: {
          backgroundColor: 'var(--colors-darkPaper)',
          borderColor: 'var(--colors-gray-2) !important',
        },
        td: {
          borderColor: 'var(--colors-gray-2) !important',
        },
        tr: {
          borderColor: 'var(--colors-gray-2)',
        },
      },
    }),
    Tabs: Tabs.extend({
      defaultProps: {
        fw: 500,
      },
      styles: {
        root: {},
        list: {
          '--tabs-list-border-size': '0 0 1px 0',
          '--tab-border-color': 'var(--colors-gray-2)',
        },
        tab: {
          color: 'var(--colors-paperReverse)',
          borderLeft: 'none',
          borderRight: 'none',
        },
        tabLabel: {
          // color: 'var(--colors-paperReverse)',
        },
        panel: {
          color: 'var(--colors-paperReverse)',
        },
      },
    }),
    Text: Text.extend({
      defaultProps: {
        fw: '500',
      },
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        fw: 500,
        w: '100%',
      },
      styles: {
        root: {
          '--input-section-color': 'var(--colors-gray-4)',
          width: '100%',
        },
        input: {
          '--input-bg': 'transparent',
          '--input-bd': 'var(--colors-gray-3)',
          '--input-bd-hover': 'var(--colors-gray-4)',
          color: 'var(--colors-paperReverse)',
        },
        label: {
          color: 'var(--colors-gray-5)',
          lineHeight: '1.25rem',
        },
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        fw: 500,
        w: '100%',
      },
      styles: {
        root: {
          '--input-section-color': 'var(--colors-gray-4)',
        },
        label: {
          color: 'var(--colors-gray-5)',
          lineHeight: '1.25rem',
        },
        input: {
          '--input-bg': 'transparent',
          '--input-bd': 'var(--colors-gray-3)',
          '--input-bd-hover': 'var(--colors-gray-4)',
          minHeight: rem(60),
          paddingRight: rem(24),
          paddingTop: rem(4),
          paddingBottom: rem(4),
          color: 'var(--colors-paperReverse)',
        },
      },
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        // portalProps: {
        //   target: '#portalWrapper',
        // },
        bg: 'var(--colors-gray-7)',
        c: 'var(--colors-paper)',
        fz: 12,
        fw: 500,
      },
    }),
  },
});
