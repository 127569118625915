import { Box, Container, Group, Text, Tooltip } from '@mantine/core';
import { BNNumberInput } from '../../../components/NumberInput/NumberInput';
import { BNButton } from '../../../components/Button/Button';
import classes from '../Inventory.MultiSelectActionbar.styles.tsx.module.css';
import { BarkerCoreEnumsAdjustmentType } from '../../../api';
import { PriceVariation } from '../../../types';
import { ReactNode, SetStateAction, useCallback, useState } from 'react';
import { BNSelect } from '../../../components/Select/Select';
import { PRICE_VARIATIONS } from '../../../utils/globals';
import { AdjustmentValueInputDiagonal } from '../../../components/AdjustmentInput/AdjustmentValueInputDiagonal';
import { BNAccordionMenu } from '../../../components/Accordion/BNAccordion';
import { useGlobalState } from '../../../data/GlobalState';

type Props = {
  /**
   * @property title - The title of the accordion item
   */
  title: string;
  /**
   * @property itemValue - The value of the accordion item id
   */
  leftSection: React.ReactNode;
  toggleAutoPriceDialog: (value?: SetStateAction<boolean> | undefined) => void;
  disabled?: boolean;
  errorMessage?: ReactNode;
} & (
  | {
      itemType: 'floor' | 'ceiling' | 'adjustment';
      updateFn: (variation: PriceVariation, adjustmentType: BarkerCoreEnumsAdjustmentType, price: number | '') => Promise<void>;
    }
  | {
      itemType: 'comparables';
      updateFn: (comparables: number) => Promise<void>;
    }
);

export function Accordion_Edit({ itemType, title, updateFn, toggleAutoPriceDialog, leftSection, disabled, errorMessage }: Props) {
  const [newValue, setNewPrice] = useState<number | ''>('');
  const [priceVariation, setPriceVariation] = useState<PriceVariation>(itemType === 'floor' || itemType === 'ceiling' ? 'Decrease by' : 'Set To');
  const [priceAdjustment, setPriceAdjustment] = useState<BarkerCoreEnumsAdjustmentType>(BarkerCoreEnumsAdjustmentType.Amount);
  const { currentUser } = useGlobalState('currentUser');

  const onEditValue = useCallback(async () => {
    if (itemType !== 'comparables' && (newValue !== '' || itemType === 'ceiling')) {
      await updateFn(priceVariation, priceAdjustment, newValue);
    } else if (itemType === 'comparables' && newValue !== '') {
      await updateFn(newValue);
    }
    toggleAutoPriceDialog(false);
  }, [itemType, newValue, priceAdjustment, priceVariation, toggleAutoPriceDialog, updateFn]);
  return (
    <BNAccordionMenu.Panel id={itemType} leftSection={leftSection} title={title}>
      <Group wrap="wrap" gap="sm" align="flex-end" justify="right">
        <Tooltip label="Adjustment value can only be set to an exact amount" hidden={itemType !== 'adjustment'} withArrow>
          {/* <Box hidden={itemType === 'adjustment'}> */}
          <Box>
            <BNSelect
              w={128}
              value={priceVariation}
              onChange={(value) => {
                setPriceVariation(value as PriceVariation);
              }}
              size="xs"
              data={PRICE_VARIATIONS}
              disabled={itemType === 'adjustment' || itemType === 'comparables' || disabled}
              defaultValue="Set Price"
              aria-label="Price Variation"
            />
          </Box>
        </Tooltip>
        <Group gap={0} wrap="nowrap">
          {priceVariation !== 'Set To' && (
            <BNSelect
              onChange={(value) => {
                setPriceAdjustment(value as BarkerCoreEnumsAdjustmentType);
              }}
              value={priceAdjustment}
              defaultValue={BarkerCoreEnumsAdjustmentType.Amount}
              disabled={disabled}
              m={0}
              p={0}
              size="xs"
              className={classes.staggerByType}
              data={[
                { value: BarkerCoreEnumsAdjustmentType.Amount, label: '$' },
                { value: BarkerCoreEnumsAdjustmentType.Percentage, label: '%' },
              ]}
              aria-label="Price Adjustment"
            />
          )}
          {itemType === 'adjustment' && (
            <Box w={128}>
              <AdjustmentValueInputDiagonal
                numInputWidth={78}
                disabled={disabled}
                onChange={(value) => {
                  setPriceAdjustment(value.type);
                  setNewPrice(value.value || 0);
                }}
                value={newValue}
                type={priceAdjustment}
                aria-label="Price Adjustment"
              />
            </Box>
          )}
          {itemType !== 'adjustment' && (
            <BNNumberInput
              onChange={(value) => {
                setNewPrice(value);
              }}
              value={newValue}
              leftSection={priceVariation === 'Set To' && itemType !== 'comparables' ? <Text size="xs">$</Text> : null}
              errorVariant="warning"
              decimalScale={itemType !== 'comparables' ? 2 : 0}
              fixedDecimalScale
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  onEditValue();
                }
              }}
              selectOnFocus
              step={1}
              min={itemType === 'comparables' ? 1 : undefined}
              stepHoldDelay={500}
              disabled={disabled}
              stepHoldInterval={100}
              w={priceVariation === 'Set To' ? 128 : 77}
              size="xs"
              aria-label="Price Variation"
              className={priceVariation === 'Set To' ? classes.numInputSingle : classes.numInputGrouped}
            />
          )}
        </Group>
        <Group gap="sm" align="flex-end" justify="right">
          <BNButton
            disabled={disabled}
            w={128}
            variant="default"
            size="xs"
            onClick={() => {
              toggleAutoPriceDialog(false);
            }}
          >
            Cancel
          </BNButton>
          <BNButton disabled={disabled || currentUser?.roleId === 'ReadOnlyUser'} w={128} variant="filled" color="green" size="xs" onClick={onEditValue}>
            Apply
          </BNButton>
        </Group>
      </Group>
      {disabled && (
        <Container ml={10} mt={6}>
          {errorMessage}
        </Container>
      )}
    </BNAccordionMenu.Panel>
  );
}
