import { Box, Divider, Flex, Group, Text } from '@mantine/core';
import classes from './EventPerformance.module.css';
import { Bar, CartesianGrid, ComposedChart, Line, ReferenceLine, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { useCallback } from 'react';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { useDisclosure } from '@mantine/hooks';
import { BNButton } from '../../components/Button/Button';
import { BNLoadingState } from '../../components/Loading/LoadingState';
import { useEventPerformance } from './EventPerformance.hooks';
import BNInputGroup from '../../components/Group/InputGroup';
import pluralize from 'pluralize';

export default function PerformanceChart() {
  const { performanceTrends, isPerformanceTrendsLoading, soldProfit, performanceSnapshotPeriod, setPerformanceSnapshotPeriod } = useEventPerformance(
    'performanceTrends',
    'isPerformanceTrendsLoading',
    'soldProfit',
    'performanceSnapshotPeriod',
    'setPerformanceSnapshotPeriod',
  );

  const isProfitable = soldProfit === undefined || soldProfit >= 0;

  const [showCurrentROI, currentROIHandlers] = useDisclosure(true);
  const [showUnrealizedROI, unrealizedROIHandlers] = useDisclosure(true);
  const [showPotentialROI, potentialROIHandlers] = useDisclosure(true);
  const [showTickets, ticketsHandlers] = useDisclosure(true);

  const color1 = !isProfitable ? 'var(--colors-chart-red-1)' : 'var(--colors-chart-green-1)';
  // const color2 = !isProfitable ? 'var(--colors-chart-red-2)' : 'var(--colors-chart-green-2)';
  const color3 = !isProfitable ? 'var(--colors-chart-red-3)' : 'var(--colors-chart-green-3)';

  const minUnrealizedRoi = performanceTrends?.reduce((min, p) => (p.unrealizedRoi < min ? p.unrealizedRoi : min), 0) ?? 0;
  const minPotentialRoi = performanceTrends?.reduce((min, p) => (p.potentialRoi < min ? p.potentialRoi : min), 0) ?? 0;
  const minCurrentRoi = performanceTrends?.reduce((min, p) => (p.currentRoi < min ? p.currentRoi : min), 0) ?? 0;
  const maxUnrealizedRoi = performanceTrends?.reduce((max, p) => (p.unrealizedRoi > max ? p.unrealizedRoi : max), 0) ?? 0;
  const maxPotentialRoi = performanceTrends?.reduce((max, p) => (p.potentialRoi > max ? p.potentialRoi : max), 0) ?? 0;
  const maxCurrentRoi = performanceTrends?.reduce((max, p) => (p.currentRoi > max ? p.currentRoi : max), 0) ?? 0;
  const minAllRoi = Math.min(minUnrealizedRoi, minPotentialRoi, minCurrentRoi);
  const maxAllRoi = Math.max(maxUnrealizedRoi, maxPotentialRoi, maxCurrentRoi);

  const roiDomain = [-100, 100];

  if (minAllRoi >= -50) {
    roiDomain[0] = -50;
  }

  if (maxAllRoi >= 300) {
    roiDomain[1] = 300;
  } else {
    // Round dataMax to the next 50
    const max = Math.ceil(maxAllRoi / 50) * 50;
    roiDomain[1] = max;
  }

  const CustomizedDot = (props: any) => {
    if (props.payload.date === '9/15') {
      return (
        <svg height="14" width="14" x={props.cx - 8} y={props.cy - 9}>
          <circle cx="8" cy="8" r="4" stroke="var(--colors-paper)" strokeWidth="2" fill={props.color} />
        </svg>
      );
    }
    return null;
  };

  const CustomizedLabel = (props: any) => (
    <svg height="24" width="200" x={props.viewBox.x - props.offset} y={10}>
      <text x="14" y="12" style={{ fontSize: 10, color: 'var(--colors-gray-4)', fill: 'var(--colors-gray-4)' }}>
        Projections
      </text>
    </svg>
  );

  const CustomTooltip = useCallback(
    ({ active, payload }: TooltipProps<ValueType, NameType>) => {
      if (active && payload && payload.length > 0) {
        if (typeof payload[0].value !== 'number') {
          return null;
        }

        if (payload[0].payload.openTickets) {
          return (
            <div className="custom-tooltip">
              <Group gap="xs">
                <Box h={2} w={12} bg={color1} />
                <Text className="label" component="p">
                  {isFinite(payload[0].payload.currentRoi) ? payload[0].payload.currentRoi.toFixed(0) : '--'}%{' '}
                  <span style={{ color: 'var(--colors-gray-5)', fontSize: 10 }}>(Current ROI)</span>
                </Text>
              </Group>
              <Group gap="xs">
                <Box h={2} w={12} bg="var(--colors-gray-5)" />
                <Text className="label" component="p">
                  {isFinite(payload[0].payload.unrealizedRoi) ? payload[0].payload.unrealizedRoi.toFixed(0) : '--'}%{' '}
                  <span style={{ color: 'var(--colors-gray-5)', fontSize: 10 }}>(Unrealized ROI)</span>
                </Text>
              </Group>
              <Group gap="xs">
                <Box h={2} w={12} bg="var(--colors-selectedBorder)" />
                <Text className="label" component="p">
                  {isFinite(payload[0].payload.potentialRoi) ? payload[0].payload.potentialRoi.toFixed(0) : '--'}%{' '}
                  <span style={{ color: 'var(--colors-gray-5)', fontSize: 10 }}>(Potential ROI)</span>
                </Text>
              </Group>
              <Divider color="var(--colors-divider)" my="xs" />
              <Group gap="xs">
                <Box h={12} w={12} bg="var(--colors-gray-2)" />
                <Text className="label" component="p">
                  {payload[0].payload.openTickets}{' '}
                  <span
                    style={{
                      color: 'var(--colors-gray-5)',
                      fontSize: 10,
                    }}
                  >
                    (Open {pluralize('Ticket', payload[0].payload.openTickets)})
                  </span>
                </Text>
              </Group>
              <Group gap="xs">
                <Box h={12} w={12} bg={color1} />
                <Text className="label" component="p">
                  {payload[0].payload.soldTickets}{' '}
                  <span
                    style={{
                      color: 'var(--colors-gray-5)',
                      fontSize: 10,
                    }}
                  >
                    (Sold {pluralize('Ticket', payload[0].payload.soldTickets)})
                  </span>
                </Text>
              </Group>
              <Group gap="xs">
                <Box h={12} w={12} bg="var(--colors-paper)" />
                <Text className="label" component="p">
                  {Math.round((payload[0].payload.soldTickets / (payload[0].payload.openTickets + payload[0].payload.soldTickets)) * 100)}
                  {'% '}
                  <span
                    style={{
                      color: 'var(--colors-gray-5)',
                      fontSize: 10,
                    }}
                  >
                    (Sold)
                  </span>
                </Text>
              </Group>
              <Divider color="var(--colors-divider)" my="xs" />
              {payload[0].payload.date && <p className="desc">{`${payload[0].payload.date}`}</p>}
            </div>
          );
        }
        return (
          <div className="custom-tooltip">
            <Group gap="xs">
              <Box h={2} w={12} bg={color1} opacity={0.5} />
              <Text className="label" component="p">
                {isFinite(payload[0].payload.roi4) ? payload[0].payload.roi4.toFixed(0) : '--'}% <span style={{ color: 'var(--colors-gray-5)', fontSize: 10 }}>(Current ROI)</span>
              </Text>
            </Group>
            <Group gap="xs">
              <Box h={2} w={12} bg="var(--colors-gray-5)" opacity={0.5} />
              <Text className="label" component="p">
                {isFinite(payload[0].payload.roi5) ? payload[0].payload.roi5.toFixed(0) : '--'}%{' '}
                <span style={{ color: 'var(--colors-gray-5)', fontSize: 10 }}>(Unrealized ROI)</span>
              </Text>
            </Group>
            <Group gap="xs">
              <Box h={2} w={12} bg="var(--colors-selectedBorder)" opacity={0.5} />
              <Text className="label" component="p">
                {isFinite(payload[0].payload.roi6) ? payload[0].payload.roi6.toFixed(0) : '--'}%{' '}
                <span style={{ color: 'var(--colors-gray-5)', fontSize: 10 }}>(Potential ROI)</span>
              </Text>
            </Group>
            <Divider color="var(--colors-divider)" my="xs" />
            <Group gap="xs">
              <Box h={12} w={12} bg="var(--colors-gray-3)" />
              <Text className="label" component="p">
                {payload[0].payload.amt4}{' '}
                <span
                  style={{
                    color: 'var(--colors-gray-5)',
                    fontSize: 10,
                  }}
                >
                  (Open {pluralize('Ticket', payload[0].payload.amt4)})
                </span>
              </Text>
            </Group>
            <Group gap="xs">
              <Box h={12} w={12} bg="var(--colors-gray-2)" />
              <Text className="label" component="p">
                {payload[0].payload.amt5}{' '}
                <span
                  style={{
                    color: 'var(--colors-gray-5)',
                    fontSize: 10,
                  }}
                >
                  (Sold {pluralize('Ticket', payload[0].payload.amt5)})
                </span>
              </Text>
            </Group>
            <Divider color="var(--colors-divider)" my="xs" />
            {payload[0].payload.date && <p className="desc">{`${payload[0].payload.date}`}</p>}
          </div>
        );
      }

      return null;
    },
    [color1],
  );

  if (isPerformanceTrendsLoading) {
    return <BNLoadingState border />;
  }

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Group justify="space-between" wrap="nowrap">
        <Text size="sm" fw={600} truncate>
          Performance Trends
        </Text>
        <Box mt={-4}>
          <BNInputGroup label="">
            <BNInputGroup.Item>
              <BNButton
                size="compact-xs"
                className={performanceSnapshotPeriod === '1w' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
                variant="default"
                onClick={() => setPerformanceSnapshotPeriod('1w')}
                px={4}
                fz={{ base: 10, lg: 11 }}
              >
                1w
              </BNButton>
            </BNInputGroup.Item>
            <BNInputGroup.Item>
              <BNButton
                size="compact-xs"
                className={performanceSnapshotPeriod === '1m' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
                variant="default"
                onClick={() => setPerformanceSnapshotPeriod('1m')}
                px={4}
                fz={{ base: 10, lg: 11 }}
              >
                1m
              </BNButton>
            </BNInputGroup.Item>
            <BNInputGroup.Item>
              <BNButton
                size="compact-xs"
                className={performanceSnapshotPeriod === '3m' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
                variant="default"
                onClick={() => setPerformanceSnapshotPeriod('3m')}
                px={4}
                fz={{ base: 10, lg: 11 }}
              >
                3m
              </BNButton>
            </BNInputGroup.Item>
            <BNInputGroup.Item>
              <BNButton
                size="compact-xs"
                className={performanceSnapshotPeriod === '1y' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
                variant="default"
                onClick={() => setPerformanceSnapshotPeriod('1y')}
                px={4}
                fz={{ base: 10, lg: 11 }}
              >
                1y
              </BNButton>
            </BNInputGroup.Item>
            <BNInputGroup.Item>
              <BNButton
                size="compact-xs"
                className={performanceSnapshotPeriod === 'all' ? `${classes.chartTimePeriodButton} ${classes.chartTimePeriodButtonSelected}` : `${classes.chartTimePeriodButton}`}
                variant="default"
                onClick={() => setPerformanceSnapshotPeriod('all')}
                px={4}
                fz={{ base: 10, lg: 11 }}
              >
                All
              </BNButton>
            </BNInputGroup.Item>
          </BNInputGroup>
        </Box>
      </Group>
      <Flex
        direction="column"
        pos="relative"
        h="100%"
        w="100%"
        style={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Box style={{ display: 'flex', flex: 1, position: 'relative' }}>
          <ResponsiveContainer width="100%" height="99%">
            <ComposedChart data={performanceTrends} className={classes.composedChart}>
              <defs>
                <linearGradient id="colorbar2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0" stopColor={color1} stopOpacity={0.2} />
                  <stop offset="100%" stopColor={color1} stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="colorbar" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0" stopColor={color1} stopOpacity={1} />
                  <stop offset="100%" stopColor={color1} stopOpacity={1} />
                </linearGradient>
                <linearGradient id="colorbarGray2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0" stopColor="var(--colors-gray-2)" stopOpacity={0.35} />
                  <stop offset="100%" stopColor="var(--colors-gray-2)" stopOpacity={0.35} />
                </linearGradient>
                <linearGradient id="colorbarGray" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0" stopColor="var(--colors-gray-2)" stopOpacity={1} />
                  <stop offset="100%" stopColor="var(--colors-gray-2)" stopOpacity={1} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="0" stroke="var(--colors-gray-0" vertical={false} />
              <XAxis minTickGap={48} dataKey="date" strokeWidth={0} style={{ fontSize: 8, opacity: 0.65 }} height={16} />
              <YAxis
                tickLine={false}
                axisLine={false}
                dataKey="currentRoi"
                id="left"
                yAxisId="left"
                orientation="left"
                domain={roiDomain}
                min={-100}
                max={200}
                width={32}
                type="number"
                fontSize={8}
                style={{ fontSize: 8, opacity: 0.65 }}
                tickFormatter={(value) => {
                  if (typeof value !== 'number') {
                    return value;
                  }

                  return `${value.toLocaleString('en-US')}%`;
                }}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                dataKey="totalTickets"
                id="right"
                yAxisId="right"
                orientation="right"
                width={32}
                type="number"
                fontSize={8}
                style={{ fontSize: 8, opacity: 0.65 }}
                tickFormatter={(value) => {
                  if (typeof value !== 'number') {
                    return value;
                  }

                  return value.toLocaleString('en-US');
                }}
              />
              <Bar maxBarSize={8} opacity={showTickets ? 1 : 0} yAxisId="right" stackId="a" dataKey="soldTickets" isAnimationActive={false} fill="url(#colorbar)" />
              <Bar maxBarSize={8} opacity={showTickets ? 1 : 0} yAxisId="right" stackId="a" dataKey="openTickets" isAnimationActive={false} fill="var(--colors-gray-2)" />
              <Bar maxBarSize={8} opacity={showTickets ? 1 : 0} yAxisId="right" stackId="a" dataKey="amt4" fill="var(--colors-gray-3)" />
              <Bar maxBarSize={8} opacity={showTickets ? 1 : 0} yAxisId="right" stackId="a" dataKey="amt5" fill="var(--colors-gray-2)" />
              <ReferenceLine x="9/15" yAxisId="left" label={<CustomizedLabel />} isFront stroke="var(--colors-gray-4)" />
              <Line
                activeDot={showCurrentROI}
                opacity={showCurrentROI ? 0.5 : 0}
                yAxisId="left"
                dot={false}
                type="monotone"
                dataKey="roi4"
                strokeWidth={1.5}
                // strokeDasharray="3 2.5"
                stroke={color1}
              />
              <Line
                activeDot={showUnrealizedROI}
                opacity={showUnrealizedROI ? 0.5 : 0}
                yAxisId="left"
                dot={false}
                type="monotone"
                dataKey="roi5"
                strokeWidth={1.5}
                strokeDasharray="1.5 1.5"
                stroke="var(--colors-gray-4)"
              />
              <Line
                activeDot={showPotentialROI}
                opacity={showPotentialROI ? 0.5 : 0}
                yAxisId="left"
                dot={false}
                type="monotone"
                dataKey="roi6"
                strokeWidth={1.5}
                strokeDasharray="2 1"
                stroke={color3}
              />
              <Line
                activeDot={showCurrentROI}
                opacity={showCurrentROI ? 1 : 0}
                yAxisId="left"
                isAnimationActive={false}
                dot={showCurrentROI && <></>}
                type="monotone"
                dataKey="currentRoi"
                strokeWidth={6}
                stroke="var(--colors-paper)"
              />
              <Line
                activeDot={showCurrentROI}
                opacity={showCurrentROI ? 1 : 0}
                yAxisId="left"
                dot={showCurrentROI && <CustomizedDot color={color1} />}
                isAnimationActive={false}
                type="monotone"
                dataKey="currentRoi"
                strokeWidth={1.5}
                stroke={color1}
              />
              <Line
                activeDot={showUnrealizedROI}
                opacity={showUnrealizedROI ? 1 : 0}
                yAxisId="left"
                dot={showUnrealizedROI && <></>}
                isAnimationActive={false}
                type="monotone"
                dataKey="unrealizedRoi"
                strokeWidth={6}
                stroke="var(--colors-paper)"
              />
              <Line
                activeDot={showUnrealizedROI}
                opacity={showUnrealizedROI ? 1 : 0}
                yAxisId="left"
                dot={showUnrealizedROI && <CustomizedDot color="var(--colors-gray-5)" />}
                isAnimationActive={false}
                type="monotone"
                dataKey="unrealizedRoi"
                strokeWidth={1.5}
                strokeDasharray="1.5 1.5"
                stroke="var(--colors-gray-5)"
              />
              <Line
                activeDot={showPotentialROI}
                opacity={showPotentialROI ? 1 : 0}
                yAxisId="left"
                dot={showPotentialROI && <></>}
                isAnimationActive={false}
                type="monotone"
                dataKey="potentialRoi"
                strokeWidth={6}
                stroke="var(--colors-paper)"
              />
              <Line
                activeDot={showPotentialROI}
                opacity={showPotentialROI ? 1 : 0}
                yAxisId="left"
                dot={showPotentialROI && <CustomizedDot color="var(--colors-selectedBorder)" />}
                isAnimationActive={false}
                type="monotone"
                dataKey="potentialRoi"
                strokeWidth={1.5}
                strokeDasharray="1.5 1.5"
                stroke="var(--colors-selectedBorder)"
              />
              <Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none' }} />
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
        <Flex w="100%" h={30} justify="space-between" style={{ flexShrink: 0 }} className={classes.fullLegendButtons}>
          <Group gap={0} wrap="nowrap">
            <BNButton size="compact-xs" variant="subtle" className={classes.headerSide} opacity={showCurrentROI ? 1 : 0.5} onClick={() => currentROIHandlers.toggle()}>
              <Box pos="relative">
                <Box w={16} h={6.5} bg={color1} style={{ border: '2px solid var(--colors-paper)' }} />
                <Box bg={color1} h={10} w={10} pos="absolute" left={11} top={-1.5} style={{ borderRadius: 100, border: '2px solid var(--colors-paper)' }} />
              </Box>
              <Text lts={-0.25} ml={8} fz={11} fw={600} c="var(--colors-gray-7)">
                Current ROI (%)
              </Text>
            </BNButton>
            <BNButton size="compact-xs" variant="subtle" className={classes.headerSide} opacity={showUnrealizedROI ? 1 : 0.5} onClick={() => unrealizedROIHandlers.toggle()}>
              <Box pos="relative" w={16}>
                <Group gap={0}>
                  <Box w={5} h={6.5} bg="var(--colors-gray-5)" style={{ border: '2px solid var(--colors-paper)', borderRight: 'none' }} />
                  <Box w={5} h={6.5} bg="var(--colors-gray-5)" style={{ border: '2px solid var(--colors-paper)', borderRight: 'none' }} />
                </Group>
                <Box bg="var(--colors-gray-5)" h={10} w={10} pos="absolute" left={10} top={-1.5} style={{ borderRadius: 100, border: '2px solid var(--colors-paper)' }} />
              </Box>
              <Text lts={-0.25} ml={8} fz={11} fw={600} c="var(--colors-gray-7)">
                Unrealized ROI (%)
              </Text>
            </BNButton>
            <BNButton size="compact-xs" variant="subtle" className={classes.headerSide} opacity={showPotentialROI ? 1 : 0.5} onClick={() => potentialROIHandlers.toggle()}>
              <Box pos="relative" w={16}>
                <Group gap={0}>
                  <Box w={5} h={6.5} bg="var(--colors-selectedBorder)" style={{ border: '2px solid var(--colors-paper)', borderRight: 'none' }} />
                  <Box w={5} h={6.5} bg="var(--colors-selectedBorder)" style={{ border: '2px solid var(--colors-paper)', borderRight: 'none' }} />
                </Group>
                <Box bg="var(--colors-selectedBorder)" h={10} w={10} pos="absolute" left={10} top={-1.5} style={{ borderRadius: 100, border: '2px solid var(--colors-paper)' }} />
              </Box>
              <Text lts={-0.25} ml={8} fz={11} fw={600} c="var(--colors-gray-7)">
                Potential ROI (%)
              </Text>
            </BNButton>
          </Group>
          <Group gap={0}>
            <BNButton size="compact-xs" variant="subtle" className={classes.headerSide} opacity={showTickets ? 1 : 0.5} onClick={() => ticketsHandlers.toggle()}>
              <Group gap={1.5}>
                <Divider mt={4} orientation="vertical" h={8} size={2.5} color={color1} />
                <Divider orientation="vertical" h={12} size={2.5} color={color1} />
                <Divider mt={4} orientation="vertical" h={8} size={2.5} color={color1} />
              </Group>
              <Text fz={11} ml={8} fw={600} color="var(--colors-gray-7)">
                Sold Tickets
              </Text>
              <Group gap={1.5} ml="md">
                <Divider mt={4} orientation="vertical" h={8} size={2.5} color="var(--colors-gray-3)" />
                <Divider orientation="vertical" h={12} size={2.5} color="var(--colors-gray-3)" />
                <Divider mt={4} orientation="vertical" h={8} size={2.5} color="var(--colors-gray-3)" />
              </Group>
              <Text fz={11} ml={8} fw={600} color="var(--colors-gray-7)">
                Open Tickets
              </Text>
            </BNButton>
            {/* <BNButton
            size="compact-xs"
            variant="subtle"
            className={classes.headerSide}
            opacity={showOpenTickets ? 1 : 0.5}
            onClick={() => openTicketsHandlers.toggle()}
          ></BNButton> */}
          </Group>
        </Flex>
        <Flex w="100%" h={30} justify="space-between" style={{ flexShrink: 0 }} className={classes.abbreviatedLegendButtons}>
          <BNButton
            size="compact-xs"
            variant="subtle"
            className={classes.headerSide}
            opacity={showCurrentROI ? 1 : 0.5}
            onClick={() => {
              currentROIHandlers.toggle();
              unrealizedROIHandlers.toggle();
              potentialROIHandlers.toggle();
            }}
          >
            <Box pos="relative">
              <Box w={16} h={6.5} bg={color1} style={{ border: '2px solid var(--colors-paper)' }} />
              <Box bg={color1} h={10} w={10} pos="absolute" left={11} top={-1.5} style={{ borderRadius: 100, border: '2px solid var(--colors-paper)' }} />
            </Box>
            <Text lts={-0.25} ml={8} fz={11} fw={600} color="var(--colors-gray-7)">
              ROI (%)
            </Text>
          </BNButton>
          <BNButton size="compact-xs" variant="subtle" className={classes.headerSide} opacity={showTickets ? 1 : 0.5} onClick={() => ticketsHandlers.toggle()}>
            <Group gap={1.5}>
              <Divider mt={4} orientation="vertical" h={8} size={2.5} color={color1} />
              <Divider orientation="vertical" h={12} size={2.5} color={color1} />
              <Divider mt={4} orientation="vertical" h={8} size={2.5} color={color1} />
            </Group>
            <Text fz={11} ml={8} fw={600} c="var(--colors-gray-7)">
              Open/Sold Tickets
            </Text>
          </BNButton>
        </Flex>
      </Flex>
    </>
  );
}
