import { Accordion, ActionIcon, Box, Center, CopyButton, Divider, Flex, Group, Loader, Stack, Text, Tooltip } from '@mantine/core';
import { BNTextInput } from '../../components/TextInput/TextInput';
import BarcodeIcon from '../../components/icons/Barcode';
import { UseFormReturnType } from '@mantine/form';
import { OrderEdit, TicketGroup, TicketGroupEdit } from '../../types';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import PictureAsPDFIcon from '../../components/icons/PictureAsPDF';
import DeleteIcon from '../../components/icons/Delete';
import ConfirmationNumberIcon from '../../components/icons/ConfirmationNumber';
import LinkIcon from '../../components/icons/Link';
import classes from './Purchase.Form.Barcodes.module.css';

export type BarcodeProps<T extends TicketGroup | TicketGroupEdit> = {
  group: T;
  groupIndex: number;
  order: T['orders'][0];
  index: number;
  form: UseFormReturnType<{ groups: T[] }>;
  isEditForm?: boolean;
  loading?: boolean;
};

export function Barcodes<T extends TicketGroup | TicketGroupEdit>({ group, groupIndex, order, index, form, isEditForm, loading }: BarcodeProps<T>) {
  const isOddEven = form.values.groups[groupIndex].orders[index].odd_even;
  const locationId = form.values.groups[groupIndex].orders[index].location_id;
  const showEditFields = isEditForm;
  const isPDF = locationId === '31';
  const isPDFempty = false;

  const hasPdf = (_index: number) => {
    const pdf = !!(order as OrderEdit).items?.[_index]?.pdf;
    const pdf_removed = !!(order as OrderEdit).pdfs?.[_index]?.item_id;
    return pdf && !pdf_removed;
  };

  return (
    <Accordion className={classes.assetsAccordion}>
      <Accordion.Item value="Barcodes" key="Barcodes">
        <Accordion.Control icon={<BarcodeIcon color="var(--colors-gray-5)" />}>
          <Text size="xs">Ticket Assets</Text>
        </Accordion.Control>
        <Accordion.Panel>
          {loading && (
            <Center h={144}>
              <Loader type="dots" color="gray" />
            </Center>
          )}
          {!loading && (
            <>
              {!showEditFields && (
                <Stack mx={-16} gap="xs" py="xs" style={{ borderRadius: 3, border: '1px solid var(--colors-gray-2)' }}>
                  {(!group.orders[index].quantity || !group.orders[index].seat_from) && <Text px="sm">Set a quantity & seat from to add barcodes.</Text>}
                  {!!group.orders[index].quantity &&
                    !!group.orders[index].seat_from &&
                    group.orders[index].barcodes?.map((__, barcodeIndex) => (
                      <Box key={barcodeIndex}>
                        {barcodeIndex !== 0 && <Divider color="var(--colors-borderDivider)" />}
                        <Group px="xs">
                          <Group pl={6} gap={6}>
                            <ConfirmationNumberIcon size={20} color="var(--colors-gray-5)" />
                            <Text w={100} truncate>
                              Seat {Number(order.seat_from) + (isOddEven ? barcodeIndex * 2 : barcodeIndex)}
                            </Text>
                          </Group>
                          <Flex gap="md" w="100%" flex={1}>
                            <Group w="100%" gap={2}>
                              <BNTextInput
                                w="100%"
                                size="xs"
                                aria-label="Barcode"
                                placeholder="Barcode"
                                leftSection={<BarcodeIcon size={16} />}
                                {...form.getInputProps(`groups.${groupIndex}.orders.${index}.barcodes.${barcodeIndex}`)}
                              />
                              <CopyButton value={form.getInputProps(`groups.${groupIndex}.orders.${index}.barcodes.${barcodeIndex}`).value} timeout={2000}>
                                {({ copied, copy }) => (
                                  <Tooltip label={copied ? 'Copied' : 'Copy Barcode'} withArrow position="right">
                                    <ActionIcon color={copied ? 'green' : 'gray'} variant="subtle" onClick={copy}>
                                      {copied ? <IconCheck style={{ width: 16 }} /> : <IconCopy style={{ width: 16 }} />}
                                    </ActionIcon>
                                  </Tooltip>
                                )}
                              </CopyButton>
                            </Group>
                          </Flex>
                        </Group>
                      </Box>
                    ))}
                </Stack>
              )}
              {showEditFields && (
                <Stack mx={-16} gap="xs" py="xs" style={{ borderRadius: 3, border: '1px solid var(--colors-gray-2)' }}>
                  {(group.orders[index] as OrderEdit).items?.map((item, barcodeIndex) => (
                    <Box key={barcodeIndex}>
                      {barcodeIndex !== 0 && <Divider color="var(--colors-divider)" />}
                      <Group px="md">
                        <Group gap={6}>
                          <ConfirmationNumberIcon size={20} color="var(--colors-gray-5)" />
                          <Text w={100} truncate>
                            Seat {Number(item.seat)}
                          </Text>
                        </Group>
                        <Flex justify="end" w="100%" flex={1} gap="lg">
                          <Group w="100%" maw={281} gap={2}>
                            <BNTextInput
                              w="100%"
                              size="xs"
                              aria-label="Barcode"
                              placeholder="Barcode"
                              leftSection={<BarcodeIcon size={16} />}
                              clearable
                              {...form.getInputProps(`groups.${groupIndex}.orders.${index}.items.${barcodeIndex}.barcode`)}
                              onChange={(e) => {
                                form.setFieldValue(`groups.${groupIndex}.orders.${index}.items.${barcodeIndex}.barcode`, e.currentTarget.value);
                                form.setFieldValue(`groups.${groupIndex}.orders.${index}.barcodes.${barcodeIndex}.barcode`, e.currentTarget.value);
                              }}
                            />
                            <CopyButton value={form.getInputProps(`groups.${groupIndex}.orders.${index}.barcodes.${barcodeIndex}`).value} timeout={2000}>
                              {({ copied, copy }) => (
                                <Tooltip label={copied ? 'Copied' : 'Copy Barcode'} withArrow position="right">
                                  <ActionIcon color={copied ? 'green' : 'gray'} variant="subtle" onClick={copy}>
                                    {copied ? <IconCheck style={{ width: 16 }} /> : <IconCopy style={{ width: 16 }} />}
                                  </ActionIcon>
                                </Tooltip>
                              )}
                            </CopyButton>
                          </Group>
                          {showEditFields && (
                            <Flex w="100%">
                              {/* Transfer URL version */}
                              {!isPDF && (
                                <Group w="100%" gap={2} justify="end">
                                  <Box w="100%" maw={322}>
                                    <BNTextInput
                                      clearable
                                      size="xs"
                                      aria-label="Transfer URL"
                                      placeholder="Transfer URL"
                                      leftSection={<LinkIcon />}
                                      {...form.getInputProps(`groups.${groupIndex}.orders.${index}.items.${barcodeIndex}.transferURL`)}
                                      onChange={(e) => {
                                        form.setFieldValue(`groups.${groupIndex}.orders.${index}.items.${barcodeIndex}.transferURL`, e.currentTarget.value);
                                        form.setFieldValue(`groups.${groupIndex}.orders.${index}.transferURLs.${barcodeIndex}.transferURL`, e.currentTarget.value);
                                      }}
                                    />
                                  </Box>
                                  <CopyButton value={form.getInputProps(`groups.${groupIndex}.orders.${index}.items.${barcodeIndex}.transferURL`).value} timeout={2000}>
                                    {({ copied, copy }) => (
                                      <Tooltip label={copied ? 'Copied' : 'Copy URL'} withArrow position="right">
                                        <ActionIcon color={copied ? 'green' : 'gray'} variant="subtle" onClick={copy}>
                                          {copied ? <IconCheck style={{ width: 16 }} /> : <IconCopy style={{ width: 16 }} />}
                                        </ActionIcon>
                                      </Tooltip>
                                    )}
                                  </CopyButton>
                                </Group>
                              )}
                              {/* PDF version */}
                              {isPDF && hasPdf(barcodeIndex) && (
                                <Group gap={2} justify="end" pr="xs">
                                  <Box>
                                    {!isPDFempty && (
                                      //  Filled State
                                      <Flex
                                        display="flex"
                                        pl="6"
                                        h={30}
                                        align="center"
                                        justify="space-between"
                                        style={{ border: '1px solid var(--colors-gray-3)', borderRadius: 3 }}
                                        maw={180}
                                        w="100%"
                                      >
                                        <Group gap={6} style={{ overflow: 'hidden' }}>
                                          <Center>
                                            <PictureAsPDFIcon color="var(--colors-iconFill)" />
                                          </Center>
                                          <Text truncate>PDF</Text>
                                        </Group>
                                        <Divider color="var(--colors-borderDivider)" orientation="vertical" ml="xs" mr="0" />
                                        <Tooltip label="Remove Attachment" withArrow>
                                          <ActionIcon
                                            variant="subtle"
                                            onClick={() => {
                                              const { item_id } = form.getInputProps(`groups.${groupIndex}.orders.${index}.items.${barcodeIndex}`).value;
                                              form.getInputProps(`groups.${groupIndex}.orders.${index}.pdfs.${barcodeIndex}`).onChange({ item_id, base64: null });
                                            }}
                                          >
                                            <DeleteIcon />
                                          </ActionIcon>
                                        </Tooltip>
                                      </Flex>
                                    )}
                                  </Box>
                                </Group>
                              )}
                            </Flex>
                          )}
                        </Flex>
                      </Group>
                    </Box>
                  ))}
                </Stack>
              )}
            </>
          )}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}
