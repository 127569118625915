import { ActionIcon, Flex, Modal, Paper, Text, UnstyledButton } from '@mantine/core';
import { SearchResultStateProvider, useSearchResults } from '../hoc/EventSearch/SearchResults.hooks';
import { RuleStateProvider } from '../data/RuleState';
import { BulletinModal } from '../hoc/Bulletin/BulletinModal';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import { VersionNotificationBanner } from '../hoc/Banners/VersionNotificationBanner';
import { SupportActivationBanner } from '../hoc/Banners/SupportActivationBanner';
import { BulkStateProvider } from '../data/BulkState';
import { PlanExpirationBanner } from '../hoc/Banners/PlanExpirationBanner';
import { UnpaidInvoiceBanner } from '../hoc/Banners/UnpaidInvoiceBanner';
import classes from './Mobile.module.css';
import SearchIcon from '../components/icons/Search';
import MenuIcon from '../components/icons/Menu';
import SelectArrowsIcon from '../components/icons/SelectArrows';
import { useDisclosure, usePrevious } from '@mantine/hooks';
import { useLocation, useOutlet } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { SeatingChartProvider } from '../hoc/SeatingChart/SeatingChart.hooks';
import { InventoryStateProvider } from '../hoc/Inventory/Inventory.hooks';
import { useSetAtom } from 'jotai';
import { mobileVersionAtom } from '../data/atoms';
import { useSwipeable } from 'react-swipeable';
import { ListingStateProvider } from '../data/ListingState';
import { HomeStateProvider } from './Home.hooks';
import { NavBarMobile } from '../hoc/NavBar/NavBar.Mobile';
import { SearchPillsArea } from '../hoc/EventSearch/Search.Mobile.PillsArea';
import { SearchResultsMobile } from '../hoc/EventSearch/SearchResults.Mobile';
import { SearchMobile } from '../hoc/EventSearch/Search.Mobile';

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
  }
}

export function MobileWrapper() {
  document.body.setAttribute('data-mobile', 'true');
  const location = useLocation();
  const previousPath = usePrevious(location.pathname);
  const direction = calculateDirection(location.pathname, previousPath);
  const setMobileVersion = useSetAtom(mobileVersionAtom);
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      if (location.pathname !== '/mobile') {
        window.history.back();
      }
    },
  });

  useEffect(() => {
    function listener(message: MessageEvent<any>) {
      if (message.data) {
        try {
          const payload = message.data;
          if (payload.type === 'appVersion') {
            setMobileVersion(payload.data);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Could not parse message', message.data);
        }
      }
    }
    window.addEventListener('message', listener);
    window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'getVersion' }));
    return () => window.removeEventListener('message', listener);
  }, [setMobileVersion]);

  return (
    <HomeStateProvider>
      <ListingStateProvider>
        <RuleStateProvider>
          <BulkStateProvider>
            <BulletinModal />
            <InviteModal />
            <div className="outer-appWrap">
              <VersionNotificationBanner />
              <SupportActivationBanner />
              <PlanExpirationBanner />
              <UnpaidInvoiceBanner />
              <SearchResultStateProvider>
                <SeatingChartProvider>
                  <InventoryStateProvider>
                    <AnimatePresence custom={{ direction }} initial={false}>
                      <motion.div
                        key={location.pathname}
                        custom={{ direction }}
                        {...swipeHandlers}
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={MOTION_VARIANTS}
                        style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden' }}
                      >
                        <AnimatedOutlet />
                      </motion.div>
                    </AnimatePresence>
                  </InventoryStateProvider>
                </SeatingChartProvider>
              </SearchResultStateProvider>
            </div>
          </BulkStateProvider>
        </RuleStateProvider>
      </ListingStateProvider>
    </HomeStateProvider>
  );
}

function AnimatedOutlet() {
  const o = useOutlet();
  const [outlet] = useState(o);
  return <>{outlet}</>;
}

export function Mobile() {
  const [menuOpened, { open: openMenu, close: closeMenu }] = useDisclosure(false);
  const [searchOpened, { open: openSearch, close: closeSearch }] = useDisclosure(false);

  document.body.style.background = 'var(--colors-paper)';

  return (
    <div className={classes.mobileAppWrap} style={{ background: 'var(--colors-paper)' }}>
      <NavBarMobile
        leftSide={
          <ActionIcon size={36} onClick={openMenu}>
            <MenuIcon size={24} />
          </ActionIcon>
        }
        titleArea={<SearchTitleArea openSearch={openSearch} />}
        rightSide={
          <ActionIcon size={36} onClick={openSearch} variant="default" className={classes.searchButton}>
            <SearchIcon size={24} />
          </ActionIcon>
        }
        bottomContent={<SearchPillsArea openSearch={openSearch} />}
        menuOpened={menuOpened}
        closeMenu={closeMenu}
      />
      <div className={classes.searchWrap}>
        <Paper pos="relative">
          <SearchResultsMobile openSearch={openSearch} />
        </Paper>
      </div>
      {searchOpened && (
        <Modal opened onClose={closeSearch} withCloseButton={false} className={classes.searchModal} title="Events Search" transitionProps={{ transition: 'slide-up' }}>
          <SearchMobile closeSearch={closeSearch} />
        </Modal>
      )}
    </div>
  );
}

type SearchTitleAreaProps = {
  openSearch: () => void;
};

function SearchTitleArea({ openSearch }: SearchTitleAreaProps) {
  const { mobileDateLabel } = useSearchResults('mobileDateLabel');
  const title =
    mobileDateLabel === 'Today' ? 'Events Today' : mobileDateLabel === 'All' ? 'All Events' : mobileDateLabel === 'Custom' ? 'Custom Date Range' : `Next ${mobileDateLabel}`;

  return (
    <UnstyledButton onClick={openSearch}>
      <Flex align="center" gap={4}>
        <Text fw={600} fz="md">
          {title}
        </Text>
        <SelectArrowsIcon />
      </Flex>
    </UnstyledButton>
  );
}

export const MOTION_VARIANTS = {
  initial: ({ direction }: { direction: 'forward' | 'backward' }) => ({
    x: direction === 'backward' ? '-100%' : '100%',
    transition: {
      type: 'spring',
      duration: 0.5,
      delay: 0,
      bounce: 0,
      ease: 'ease-out',
    },
  }),
  in: {
    x: 0,
    transition: {
      type: 'spring',
      duration: 0.5,
      delay: 0,
      bounce: 0,
      ease: 'ease-out',
    },
  },
  out: ({ direction }: { direction: 'forward' | 'backward' }) =>
    //console.log('going out', direction);
    ({
      x: direction === 'backward' ? '100%' : '-100%',
      transition: {
        type: 'spring',
        duration: 0.5,
        delay: 0,
        bounce: 0,
        ease: 'ease-out',
      },
    }),
};

function calculateDirection(currentLocation: string, previousLocation: string | undefined) {
  if (currentLocation === previousLocation) {
    return 'forward';
  }

  if (!previousLocation) {
    return 'backward';
  }

  if (currentLocation === '/mobile') {
    return 'backward';
  }

  if (currentLocation.endsWith('/listings') && previousLocation !== '/mobile') {
    return 'backward';
  }

  if (previousLocation.endsWith('/filters')) {
    return 'backward';
  }

  return 'forward';
}
