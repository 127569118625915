import { Link, Outlet, useParams } from 'react-router-dom';
import { useGlobalState } from '../../data/GlobalState';
import { updateTenantId } from '../../data/api-config';
import { Container, Group, Text } from '@mantine/core';
import CorporateIcon from '../../components/icons/Corporate';
import ChevronBackIcon from '../../components/icons/ChevronBack';
import { TableOfContents } from './TableOfContents';
import classes from './CompanyFrame.styles.tsx.module.css';
import { useFlag } from '@unleash/proxy-client-react';

let sidebarItems = [{ label: '', link: '', order: 0, disabled: false }];
let label: JSX.Element | boolean = false;
export default function CompanyFrame() {
  const { tenantId } = useParams();
  const { tenants } = useGlobalState('tenants');
  const hasAutomatorSettingsFlag = useFlag('automator-settings');

  if (tenantId !== undefined) {
    updateTenantId([tenantId]);
  }

  if (tenantId === undefined) {
    label = false;
    sidebarItems = [];
  } else {
    const selectedTenant = tenants?.find((tenant) => tenant.tenantId === tenantId);

    label = (
      <Group gap={4}>
        <CorporateIcon size={20} />
        <Text size="md" fw="bold" maw={190}>
          {selectedTenant?.name}
        </Text>
      </Group>
    );
    sidebarItems = [
      { label: 'Profile', link: 'profile', order: 1, disabled: false },
      { label: 'Billing', link: 'billing', order: 1, disabled: false },
      { label: 'Pricing Settings', link: 'settings/pricing', order: 1, disabled: false },
      { label: 'Users', link: 'users', order: 1, disabled: true },
      { label: 'Automator', link: 'automator', order: 1, disabled: !hasAutomatorSettingsFlag },
      { label: 'Activity', link: 'activity', order: 1, disabled: true },
    ];
  }

  return (
    <Group pb={48} className={classes.pageContentWrapper}>
      {sidebarItems.length > 0 && (
        <Container className={classes.tocWrapper} py={30} px={0}>
          <Group c="var(--colors-gray-5)" h={18} gap={0} align="center" justify="left" pl="md" mt={-48} mb={20} className={classes.backlink}>
            <ChevronBackIcon size={12} />
            <Link to="/companies">
              <Text size="xs">View All Companies</Text>
            </Link>
          </Group>
          <TableOfContents label={label} links={sidebarItems} />
        </Container>
      )}
      <Container className={sidebarItems.length > 0 ? `${classes.pageContent} ${classes.leftBorder} pageContent` : `${classes.pageContent} pageContent`}>
        <Outlet />
      </Container>
    </Group>
  );
}
