import { Box, Center, Divider, Flex, SimpleGrid, Space, Text, UnstyledButton } from '@mantine/core';
import { BNButton } from '../../../components/Button/Button';
import { MobileMarketButton } from '../../SeatingChart/SeatingChart.MarketplaceSelector.Mobile';
import MarketplaceStubHubIcon from '../../../components/icons/MarketplaceStubHub';
import MarketplaceVividIcon from '../../../components/icons/MarketplaceVivid';
import MarketplaceTicketmasterIcon from '../../../components/icons/MarketplaceTicketmaster';
import MarketplaceAXSIcon from '../../../components/icons/MarketplaceAXS';
import MarketplaceGoTicketsIcon from '../../../components/icons/MarketplaceGoTickets';
import MarketplaceSeatGeekIcon from '../../../components/icons/MarketplaceSeatGeek';
import MarketplaceTickPickIcon from '../../../components/icons/MarketplaceTickPick';
import MarketplaceTicketEvolutionIcon from '../../../components/icons/MarketplaceTicketEvolution';
import MarketplaceTicketNetworkIcon from '../../../components/icons/MarketplaceTicketNetwork';
import MarketplaceGametimeIcon from '../../../components/icons/MarketplaceGametime';
import { useState } from 'react';
import { BNSwitch } from '../../../components/Switch/Switch';
import SelectAllIcon from '../../../components/icons/SelectAll';

export type BroadcastingOptionsFormProps = {
  closeModal: () => void;
};

export function BroadcastingOptionsForm({ closeModal }: BroadcastingOptionsFormProps) {
  const [selectedMarketplaces, setSelectedMarketplaces] = useState(new Array(10).fill(false));
  const handleSelectionChange = (index: number) => {
    const newSelectedMarketplaces = [...selectedMarketplaces];
    newSelectedMarketplaces[index] = !newSelectedMarketplaces[index];
    setSelectedMarketplaces(newSelectedMarketplaces);
  };
  const toggleAllMarketplaces = () => {
    const allSelected = selectedMarketplaces.every((marketplace) => marketplace);
    setSelectedMarketplaces(new Array(10).fill(!allSelected));
  };
  const partialSelected = selectedMarketplaces.some((marketplace) => marketplace) && !selectedMarketplaces.every((marketplace) => marketplace);
  const allSelected = selectedMarketplaces.every((marketplace) => marketplace);
  const checkedCount = selectedMarketplaces.filter((checkbox) => checkbox).length;
  const isBroadcasting = true;

  return (
    <>
      <Flex p={0} h="100%" justify="space-between">
        <Flex py="sm" px="md" gap="xs" align="center" justify="space-between" style={{ flex: 1 }}>
          <Text size="md" fw={600}>
            Broadcasting
          </Text>
          <Flex align="center" pr={4}>
            <BNSwitch
              size="sm"
              label={
                <Text component="span" tt="uppercase" w={30}>
                  <Text component="span" fw={600} c="var(--colors-selectedBorder)">
                    {checkedCount}
                  </Text>
                  <Text component="span" c="var(--colors-gray-5)">
                    /{selectedMarketplaces.length}
                  </Text>
                </Text>
              }
              checked={isBroadcasting}
              onChange={() => {
                toggleAllMarketplaces();
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      <Divider color="var(--colors-divider)" />
      <Box px="md" pt="xs" pb="lg" w="100%">
        <Text c="var(--colors-gray-5)" py={8}>
          Marketplaces
        </Text>
        <SimpleGrid cols={4} px={4} spacing="xs">
          <MobileMarketButton selected={selectedMarketplaces[0]} onClick={() => handleSelectionChange(0)} title="StubHub" img={<MarketplaceStubHubIcon />} />
          <MobileMarketButton selected={selectedMarketplaces[1]} onClick={() => handleSelectionChange(1)} title="Vivid Seats" img={<MarketplaceVividIcon />} />
          <MobileMarketButton selected={selectedMarketplaces[2]} onClick={() => handleSelectionChange(2)} title="Ticketmaster" img={<MarketplaceTicketmasterIcon />} />
          <MobileMarketButton selected={selectedMarketplaces[3]} onClick={() => handleSelectionChange(3)} title="AXS" img={<MarketplaceAXSIcon />} />
          <MobileMarketButton selected={selectedMarketplaces[4]} onClick={() => handleSelectionChange(4)} title="GoTickets" img={<MarketplaceGoTicketsIcon />} />
          <MobileMarketButton selected={selectedMarketplaces[5]} onClick={() => handleSelectionChange(5)} title="SeatGeek" img={<MarketplaceSeatGeekIcon />} />
          <MobileMarketButton selected={selectedMarketplaces[6]} onClick={() => handleSelectionChange(6)} title="TickPick" img={<MarketplaceTickPickIcon />} />
          <MobileMarketButton selected={selectedMarketplaces[7]} onClick={() => handleSelectionChange(7)} title="Ticket Evolution" img={<MarketplaceTicketEvolutionIcon />} />
          <MobileMarketButton selected={selectedMarketplaces[8]} onClick={() => handleSelectionChange(8)} title="TicketNetwork" img={<MarketplaceTicketNetworkIcon />} />
          <MobileMarketButton selected={selectedMarketplaces[9]} onClick={() => handleSelectionChange(10)} title="Gametime" img={<MarketplaceGametimeIcon />} />
          <Space />
          <UnstyledButton
            h={96}
            py="sm"
            style={{ border: '1px solid transparent', borderRadius: 6, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}
            onClick={toggleAllMarketplaces}
          >
            <Center h={44} w={44} tt="uppercase" fz={12} fw="600" c="var(--colors-gray-5)" style={{ border: '1px solid var(--colors-gray-2)', borderRadius: 44 }}>
              <SelectAllIcon size={24} />
            </Center>
            <Text fz={10} truncate maw="100%" px={2}>
              {allSelected ? 'Clear All' : 'Select All'}
            </Text>
          </UnstyledButton>
        </SimpleGrid>
      </Box>
      <Divider color="var(--colors-gray-0)" />
      <Flex gap="sm" w="100%" py="xl" px="md" mb="var(--safe-area-inset-bottom, 0)">
        <BNButton fullWidth variant="default" onClick={closeModal}>
          Cancel
        </BNButton>
        <BNButton fullWidth variant="filled" color="green" type="submit" disabled>
          Save
        </BNButton>
      </Flex>
    </>
  );
}
