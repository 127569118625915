import { PlanExpirationBanner } from '../hoc/Banners/PlanExpirationBanner';
import { SupportActivationBanner } from '../hoc/Banners/SupportActivationBanner';
import { UnpaidInvoiceBanner } from '../hoc/Banners/UnpaidInvoiceBanner';
import { VersionNotificationBanner } from '../hoc/Banners/VersionNotificationBanner';
import { BulletinModal } from '../hoc/Bulletin/BulletinModal';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import { ActionIcon, Divider, Flex, Paper, Tooltip, Text } from '@mantine/core';
import MenuOpenIcon from '../components/icons/MenuOpen';
import { SalesGrid } from '../hoc/Sales/Sales.Grid';
import { SalesSearch } from '../hoc/Sales/Sales.Search';
import { SalesProvider, useSales } from '../hoc/Sales/Sales.hooks';
import { SalesPerformance } from '../hoc/Sales/Sales.Performance';
import cx from 'clsx';
import { SalesFilters } from '../hoc/Sales/Sales.Filters';
import { useFlag } from '@unleash/proxy-client-react';
import pluralize from 'pluralize';
import { BNSidebar } from '../hoc/NavBar/Sidebar';
import { NavBar } from '../hoc/NavBar/NavBar';

export function Sales() {
  return (
    <>
      <BulletinModal />
      <InviteModal />
      <div className="outer-appWrap">
        <VersionNotificationBanner />
        <SupportActivationBanner />
        <PlanExpirationBanner />
        <UnpaidInvoiceBanner />
        <SalesProvider>
          <SalesInterior />
        </SalesProvider>
      </div>
    </>
  );
}

export function SalesInterior() {
  const { showSidebar, setShowSidebar, filteredSales } = useSales('showSidebar', 'setShowSidebar', 'filteredSales');
  const newSidebarFlag = useFlag('updated-flyout-sidebar');

  return (
    <div className="inner-appWrap sales-page">
      <div className={cx('appWrap', showSidebar ? 'sidebar-open' : 'sidebar-collapse')}>
        <div className="col1">
          {newSidebarFlag ? (
            <BNSidebar>
              <Paper className="sidebarSearchWrapper" pos="relative">
                <SalesSearch />
                <Divider color="var(--colors-divider)" />
                <SalesFilters />
              </Paper>
            </BNSidebar>
          ) : (
            <Paper>
              <Tooltip label={showSidebar ? 'Collapse Sidebar' : 'Expand Sidebar'} position="right" withArrow>
                <ActionIcon className="menuTrigger" onClick={() => setShowSidebar(!showSidebar)}>
                  <MenuOpenIcon color="var(--colors-gray-5)" size={24} />
                </ActionIcon>
              </Tooltip>
              <NavBar
                title={
                  <Text
                    c="var(--colors-gray-5)"
                    style={{
                      textTransform: 'uppercase',
                      letterSpacing: '0.05em',
                      fontSize: '0.6875rem',
                    }}
                  >
                    {filteredSales.length.toLocaleString()} {pluralize('Sale', filteredSales.length)}
                  </Text>
                }
              />
              <Paper
                className="searchWrapper"
                pos="relative"
                onMouseLeave={() => {
                  document.body.classList.remove('flyout');
                }}
              >
                <SalesSearch />
                <Divider color="var(--colors-divider)" />
                <SalesFilters />
              </Paper>
            </Paper>
          )}
        </div>
        <Flex className="col2" direction="column">
          <SalesPerformance />
          <SalesGrid.Header />
          <SalesGrid />
        </Flex>
      </div>
    </div>
  );
}
