import { Container, Group, Text } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { TableOfContents } from './TableOfContents';
import classes from './CompanyFrame.styles.tsx.module.css';
import { useGlobalState } from '../../data/GlobalState';
import AccountIcon from '../../components/icons/Account';

export default function SettingsFrame() {
  const { currentUser } = useGlobalState('currentUser');

  return (
    <Group pb={48} className={classes.pageContentWrapper}>
      <Container className={classes.tocWrapper} py={30} px={0}>
        <TableOfContents
          label={
            <Group gap={4} mt={-10}>
              <AccountIcon color="var(--colors-iconFill)" size={20} />
              <Text size="md" fw={700} maw={190}>
                {currentUser?.givenName !== '' || currentUser?.familyName !== '' ? (
                  `${currentUser?.givenName} ${currentUser?.familyName}`
                ) : (
                  <Text component="span" display="block" truncate fw={700} maw={180}>
                    {currentUser?.email.toLowerCase()}
                  </Text>
                )}
              </Text>
            </Group>
          }
          links={[
            { label: 'Profile', link: 'settings/profile', order: 1, disabled: false },
            { label: 'Pricing', link: 'settings/pricing', order: 1, disabled: false },
            { label: 'Notifications', link: 'settings/notifications', order: 1, disabled: false },
            { label: 'Appearance', link: 'settings/appearance', order: 1, disabled: false },
          ]}
        />
      </Container>
      <Container className={`${classes.pageContent} ${classes.leftBorder} pageContent`}>
        <Outlet />
      </Container>
    </Group>
  );
}
