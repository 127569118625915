import { AgGridReact } from '@ag-grid-community/react';
import { Center } from '@mantine/core';
import { Loader } from 'tabler-icons-react';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import { SummaryGridItem, usePurchaseState } from './Purchase.hooks';
import classes from './Purchase.module.css';
import cx from 'clsx';
import { GroupCellRendererParams } from '@ag-grid-community/core';
import { GroupHeader } from './Purchase.GroupHeader';
import ListIcon from '../../components/icons/List';

export function SummaryGrid() {
  const { summaryGridRef, summaryColumnDefs, mergedEventsWithInventory } = usePurchaseState('summaryGridRef', 'summaryColumnDefs', 'mergedEventsWithInventory');
  function CustomNoRowsOverlay() {
    return (
      <Center>
        <BNEmptyState
          title="No Listings Found"
          description="Use the previous step to add inventory"
          // buttonText="Add Inventory"
          // buttonOnClick={() => prevStep()}
          icon={<ListIcon color="var(--colors-iconFill)" size={28} />}
          iconBg="var(--colors-opacity-hover)"
          h={320}
          w={320}
          border={false}
        />
      </Center>
    );
  }
  function CustomLoadingOverlay() {
    return (
      <div className={`ag-overlay-loading-center ${classes.agOverlayLoadingCenter}`}>
        <Loader color="var(--colors-gray-5)" type="dots" />
      </div>
    );
  }

  return (
    <div id="summary-events-grid" className={cx('ag-theme-alpine', classes['purchase-summary-grid'])}>
      <AgGridReact
        ref={summaryGridRef}
        rowData={mergedEventsWithInventory}
        suppressScrollOnNewData
        columnDefs={summaryColumnDefs}
        defaultColDef={{
          sortable: true,
          resizable: true,
        }}
        suppressRowClickSelection
        rowSelection="multiple"
        rowBuffer={50}
        getRowId={(params) => params.data.key}
        enableCellChangeFlash
        groupDisplayType="groupRows"
        groupDefaultExpanded={1}
        suppressGroupRowsSticky
        getRowHeight={(params) => (params.node.group ? 56 : 36)}
        headerHeight={32}
        rowHeight={36}
        rowStyle={{
          lineHeight: '28px',
          fontSize: '12px',
        }}
        groupRowRenderer={(params: GroupCellRendererParams<SummaryGridItem>) => {
          const event = params.node.allLeafChildren[0].data!;
          return <GroupHeader event={event} />;
        }}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        loadingOverlayComponent={CustomLoadingOverlay}
        suppressContextMenu
      />
    </div>
  );
}
