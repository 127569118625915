import classes from './Companies.tsx.module.css';
import { ColorPicker, ColorSwatch, Menu, Table, Text, Tooltip } from '@mantine/core';
import { Link } from 'react-router-dom';
import { BNButton } from '../components/Button/Button';
import { AdminContentHeader } from '../hoc/Admin/AdminContentHeader';
import { useGlobalState } from '../data/GlobalState';
import * as R from 'remeda';
import { useMemo } from 'react';
import {
  BarkerCoreModelsAdministrativePrincipalSettings,
  BarkerCoreModelsAdministrativeUserDetails,
  getApiTenantsTenantIdUsers,
  getGetApiPrincipalsSelfQueryKey,
  getGetApiTenantsTenantIdUsersQueryKey,
  getGetApiTenantsTenantIdUsersUserIdQueryKey,
  usePatchApiPrincipalsSelf,
  usePutApiTenantsTenantIdUsersUserId,
} from '../api';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import SelectArrowsIcon from '../components/icons/SelectArrows';
import { BNSwitch } from '../components/Switch/Switch';
import { useAtomValue } from 'jotai/index';
import { auth } from '../data/atoms.auth';

export default function Companies() {
  const { tenants, principal } = useGlobalState('tenants', 'principal');
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const allUsers = useQueries({
    queries:
      tenants?.map(({ tenantId }) => ({
        queryKey: getGetApiTenantsTenantIdUsersQueryKey(tenantId),
        enabled: !!apiToken,
        queryFn: () => getApiTenantsTenantIdUsers(tenantId),
      })) ?? [],
  });
  const users = allUsers?.reduce((acc, curr) => {
    if (curr.data?.data) {
      acc.push(...curr.data.data);
    }
    return acc;
  }, [] as BarkerCoreModelsAdministrativeUserDetails[]);

  const { mutateAsync: updateSelf } = usePatchApiPrincipalsSelf();
  const { mutateAsync: updateUser } = usePutApiTenantsTenantIdUsersUserId();
  const queryClient = useQueryClient();
  const principalSettings = useMemo(() => principal?.settings ?? ({} as BarkerCoreModelsAdministrativePrincipalSettings), [principal?.settings]);
  const rows = useMemo(
    () =>
      tenants
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((tenant) => {
          const color = principal?.settings?.tenantColors[tenant.tenantId] ?? 'var(--colors-brandcolor-5)';
          const members = R.filter(users, (user) => user.tenantId === tenant.tenantId);
          const receiveNotifications = users?.find((user) => user.tenantId === tenant.tenantId && user.principalId === principal?.principalId)?.receiveNotifications ?? false;

          // blue, red, pink, yellow, green, orange, cyan from mantine's colors
          // const row1 = ['#1864AB', '#C92A2A', '#A61E4D', '#E67700', '#2B8A3E', '#D9480F', '#0B7285'];
          // const row2 = ['#1C7ED6', '#F03E3E', '#D6336C', '#F59F00', '#37B24D', '#F76707', '#1098AD'];
          // const row3 = ['#339AF0', '#FF6B6B', '#F06595', '#FCC419', '#51CF66', '#FF922B', '#22B8CF'];
          // const row4 = ['#74C0FC', '#FFA8A8', '#FAA2C1', '#FFE066', '#8CE99A', '#FFC078', '#66D9E8'];

          // red, orange, yellow, green, cyan, blue, grape, pink from mantine's colors
          // const row1 = ['#C92A2A', '#D9480F', '#E67700', '#2B8A3E', '#0B7285', '#1864AB', '#862E9C', '#A61E4D'];
          // const row2 = ['#F03E3E', '#F76707', '#F59F00', '#37B24D', '#1098AD', '#1C7ED6', '#AE3EC9', '#D6336C'];
          // const row3 = ['#FF6B6B', '#FF922B', '#FCC419', '#51CF66', '#22B8CF', '#339AF0', '#CC5DE8', '#F06595'];
          // const row4 = ['#FFA8A8', '#FFC078', '#FFE066', '#8CE99A', '#66D9E8', '#74C0FC', '#E599F7', '#FAA2C1'];

          const row2 = [
            'var(--colors-tenant-red-5)',
            'var(--colors-tenant-orange-5)',
            'var(--colors-tenant-yellow-5)',
            'var(--colors-tenant-green-5)',
            'var(--colors-tenant-cyan-5)',
            'var(--colors-tenant-blue-5)',
            'var(--colors-tenant-grape-5)',
            'var(--colors-tenant-pink-5)',
          ];

          // grayscale & brand greens
          const row5 = ['#000000', '#333333', '#666666', '#999999', '#E0E0E0', 'var(--colors-brandcolor-5)'];

          // create a CSS class to help determine which swatch is active
          const activeColorClass = `active-swatch-${color}`;

          return (
            <tr key={tenant.name} style={{ position: 'relative' }}>
              <td>{tenant.name}</td>
              <td width={160} align="center" className="company-color-picker">
                <Menu withArrow width={270} closeOnItemClick>
                  <Menu.Target>
                    <BNButton px={8} size="xs" variant="default" color="gray">
                      <ColorSwatch size={16} color={color} mr={4} />
                      <SelectArrowsIcon color="var(--colors-gray-4)" />
                    </BNButton>
                  </Menu.Target>
                  <Menu.Dropdown pt={0} pl={8}>
                    <Menu.Item component="div" className={classes.colorMenuItem} style={{ borderBottom: 'none' }}>
                      <ColorPicker
                        size="lg"
                        withPicker={false}
                        swatchesPerRow={8}
                        classNames={{
                          wrapper: classes.colorPickerWrapper,
                          swatches: activeColorClass,
                          swatch: classes.colorPickerSwatch,
                        }}
                        swatches={[...row2, ...row5]}
                        onChange={(newColor) => {
                          const newSettings: BarkerCoreModelsAdministrativePrincipalSettings = {
                            ...principalSettings,
                            tenantColors: {
                              ...principalSettings.tenantColors,
                              [tenant.tenantId]: newColor,
                            },
                          };
                          updateSelf(
                            { data: [{ op: 'Replace', path: '/settings', value: newSettings }] },
                            {
                              onSuccess: (data) => {
                                queryClient.setQueryData(getGetApiPrincipalsSelfQueryKey(), () => data);
                              },
                            },
                          );
                        }}
                      />
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </td>
              <td width={160}>
                <BNSwitch
                  checked={receiveNotifications}
                  width={50}
                  className={classes.notificationSwitch}
                  size="sm"
                  onChange={(e) => {
                    const user = users?.find((u) => u.tenantId === tenant.tenantId && u.principalId === principal?.principalId);
                    if (!user) {
                      return;
                    }
                    const newUser = {
                      ...user,
                      receiveNotifications: e.currentTarget.checked,
                    };
                    updateUser(
                      { tenantId: tenant.tenantId, userId: principal!.principalId, data: newUser },
                      {
                        onSuccess: (data) => {
                          queryClient.setQueryData(getGetApiTenantsTenantIdUsersUserIdQueryKey(tenant.tenantId, principal!.principalId), () => data);
                        },
                      },
                    ).then(() => {
                      queryClient.invalidateQueries(getGetApiTenantsTenantIdUsersQueryKey(tenant.tenantId));
                    });
                  }}
                />
              </td>
              <td align="center" width={160}>
                {members.length}
              </td>
              <td width={160}>
                <Link to={`/companies/${tenant.tenantId}/profile`}>
                  <Text td="underline" mr={4} size="sm" c="var(--colors-brandcolor-5)">
                    View Company
                  </Text>
                </Link>
              </td>
            </tr>
          );
        }),
    [principal, principalSettings, queryClient, tenants, updateSelf, updateUser, users],
  );

  if (!tenants) {
    return null;
  }

  return (
    <>
      <div style={{ flex: 1 }}>
        <AdminContentHeader
          title="Companies"
          sidebarPage={false}
          rightSection={
            <>
              <Tooltip label="Coming Soon" withArrow>
                <span>
                  <BNButton size="xs" variant="default" disabled>
                    Add New
                  </BNButton>
                </span>
              </Tooltip>
            </>
          }
        />
        <Table verticalSpacing="md" className={classes.adminTable} width="100%">
          <thead>
            <tr>
              <th style={{ paddingLeft: 10 }}>Name</th>
              <th style={{ textAlign: 'center' }}>Color</th>
              <th style={{ textAlign: 'center' }}>Notifications</th>
              <th style={{ textAlign: 'center' }}>Members</th>
              <th style={{ paddingLeft: 10 }}>Options</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </div>
    </>
  );
}
