import { AgGridReact } from '@ag-grid-community/react';
import { Box, Center, Loader, Text, UnstyledButton } from '@mantine/core';
import SearchIcon from '../../components/icons/Search';
import { GroupHeader } from './Inventory.GroupHeader';
import { useInventory } from './Inventory.hooks';
import { ErrorBoundary } from 'react-error-boundary';
import { useEffect, useState } from 'react';
import { useSetAtom } from 'jotai';
import { rowDraggingAtom } from '../../data/atoms';
import classes from './Inventory.module.css';
import { InventorySeasonLocationExtended } from '../../types';
import { GetRowIdParams } from '@ag-grid-community/core';

export default function Grid() {
  const {
    gridRef,
    detailGridToAlign,
    mergedEventListings,
    columnDefs,
    onGridReady,
    onMasterRowDragEnd,
    onMasterRowDragMove,
    defaultColDef,
    onCellClickedWhenDirty,
    onComponentStateChanged,
    trySaveGridState,
    inventoryQuickFilter,
    setInventoryQuickFilter,
    setFilter,
    detailCellRendererParams,
  } = useInventory(
    'gridRef',
    'detailGridToAlign',
    'mergedEventListings',
    'columnDefs',
    'onGridReady',
    'onMasterRowDragEnd',
    'onMasterRowDragMove',
    'defaultColDef',
    'onCellClickedWhenDirty',
    'onComponentStateChanged',
    'trySaveGridState',
    'inventoryQuickFilter',
    'setInventoryQuickFilter',
    'setFilter',
    'detailCellRendererParams',
  );
  const [error, setError] = useState(0);

  function CustomNoRowsOverlay() {
    return (
      <Center className={classes.wrapper}>
        <Box className={classes.iconHolder}>
          <SearchIcon color="var(--colors-iconFill)" size={28} />
        </Box>
        <Text size="md" fw={600} c="var(--colors-paperReverse)">
          No Results Found
        </Text>
        <Text size="xs" c="var(--colors-gray-5)">
          Try adjusting the filter options
        </Text>
        {inventoryQuickFilter && (
          <UnstyledButton
            mt={10}
            onClick={() => {
              setFilter('');
              setInventoryQuickFilter('');
            }}
            className={classes.grayLink}
            fz={12}
            td="underline"
          >
            or clear quick filter
          </UnstyledButton>
        )}
      </Center>
    );
  }
  function CustomLoadingOverlay() {
    return (
      <div className={`ag-overlay-loading-center ${classes.agOverlayLoadingCenter}`}>
        <Loader color="var(--colors-gray-5)" type="dots" />
      </div>
    );
  }

  const setIsRowDragging = useSetAtom(rowDraggingAtom);

  // Remove any drag & drop classes when the drop happens outside of the grid or viewport
  useEffect(() => {
    const removeClasses = () => {
      // dir-down is used to determine drop point for details grid.
      // Delay removal of classes to allow the details grid to determine position
      // This is a catch all handler when dropped off the grid
      setTimeout(() => {
        document.querySelector('body')?.classList.remove('row-dragging');
        document.querySelector('.show-dropzone')?.classList.remove('show-dropzone');
        document.querySelector('.hover-over')?.classList.remove('hover-over');
        document.querySelector('.dir-up')?.classList.remove('dir-up');
        document.querySelector('.dir-down')?.classList.remove('dir-down');
        setIsRowDragging(false);
      }, 500);
    };
    window.addEventListener('mouseup', removeClasses);
    return () => {
      window.removeEventListener('mouseup', removeClasses);
    };
  }, [setIsRowDragging]);

  return (
    <div id="inventory-grid" className="ag-theme-alpine">
      <ErrorBoundary
        FallbackComponent={() => <div>Error</div>}
        resetKeys={[error]}
        onError={() => {
          if (!import.meta.env.DEV) {
            setError(error + 1);
          }
        }}
      >
        <AgGridReact
          ref={gridRef}
          key={error}
          rowData={mergedEventListings}
          suppressScrollOnNewData
          alignedGrids={detailGridToAlign}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowSelection="single"
          onRowDragEnd={onMasterRowDragEnd}
          onRowDragMove={onMasterRowDragMove}
          onComponentStateChanged={onComponentStateChanged}
          onRowSelected={(params) => {
            const { rowIndex } = params;
            const el = document.querySelector(`[row-index="${rowIndex}"]`);
            if (params.data) {
              el?.setAttribute('aria-label', `Location Section ${params?.data.section} Row ${params?.data.row}`);
            }
          }}
          detailRowAutoHeight
          rowBuffer={50}
          getRowId={(params: GetRowIdParams<InventorySeasonLocationExtended>) => params.data?.hashId}
          masterDetail
          groupDisplayType="groupRows"
          groupDefaultExpanded={1}
          onCellClicked={onCellClickedWhenDirty}
          detailCellRendererParams={detailCellRendererParams}
          onColumnMoved={trySaveGridState}
          onColumnResized={trySaveGridState}
          onSortChanged={trySaveGridState}
          onColumnVisible={trySaveGridState}
          defaultColDef={defaultColDef}
          suppressContextMenu
          suppressHorizontalScroll={false}
          suppressRowClickSelection
          enableCellChangeFlash
          suppressMoveWhenRowDragging
          headerHeight={32}
          isRowMaster={(data) => !!data.ruleId || false}
          getRowHeight={(params) => (params.node.group ? 56 : 36)}
          rowStyle={{
            lineHeight: '28px',
            fontSize: '12px',
          }}
          groupRowRenderer={() => <GroupHeader />}
          noRowsOverlayComponent={CustomNoRowsOverlay}
          loadingOverlayComponent={CustomLoadingOverlay}
          rowDragText={(params) => {
            const sectionName = params.rowNode?.data?.section;
            const rowName = params.rowNode?.data?.row;
            // const seatsFrom = params.rowNode?.data?.seatFrom;
            // const seatsTo = params.rowNode?.data?.seatThru;
            return `Section ${sectionName} · Row ${rowName}`;
          }}
        />
      </ErrorBoundary>
    </div>
  );
}
