import { Box, Center, Divider, Flex, Group, Text } from '@mantine/core';
import DateRangeIcon from '../../components/icons/DateRange';
import ReceiptIcon from '../../components/icons/Receipt';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import { ICellRendererParams } from '@ag-grid-community/core';
import dayjs from 'dayjs';
import { formatCurrency } from '../../utils/formatters';
import { MarketplaceColors } from '../../models/marketplaces';
import { getStandardMarketplaceName } from '../../utils/marketplace-utils';
import classes from './Inventory.SaleDetails.module.css';
import { BarkerCoreModelsSalesListingSold } from '../../api';

export const SaleDetails = ({ data }: ICellRendererParams<BarkerCoreModelsSalesListingSold>) => {
  if (!data) return null;
  const marketplaceName = data.sale?.marketplaceName as keyof typeof MarketplaceColors;
  const formattedMarketplaceName = getStandardMarketplaceName(marketplaceName);
  const marketplaceColor = MarketplaceColors[formattedMarketplaceName];
  const saleDate = dayjs(data.sale.createdAt).format('MM/DD/YY');
  const saleTime = dayjs(data.sale.createdAt).format('hh:mm A');
  const invoice = data.sale.saleId;
  const profit = (data.unitPrice - data.unitCost) * data.quantity;
  const total = data.unitPrice * data.quantity;
  const totalCost = data.unitCost * data.quantity;
  const roi = totalCost > 0 ? (profit / totalCost) * 100 : Infinity;
  return (
    <Flex m={0} fz={11} h={28} pr={40} pl={8} align="center" justify="space-between" miw={900}>
      <Box w={120}>
        <Group h={28} gap="xs" align="center" pl={16} wrap="nowrap" style={{ overflow: 'hidden' }}>
          <Box h={13} w={13} bg={marketplaceColor} className={classes.marketplaceDot} />
          <Text truncate>{formattedMarketplaceName}</Text>
        </Group>
      </Box>
      <Divider h={12} orientation="vertical" color="var(--colors-gray-3)" m="auto" />
      <Box w={180}>
        <Group h={28} gap={6} align="center" justify="center" wrap="nowrap" className={classes.overflowHidden}>
          <DateRangeIcon size={16} color="var(--colors-gray-5)" />
          <Text truncate>
            {' '}
            <Text component="span" c="var(--colors-gray-5)">
              Sold on&nbsp;
            </Text>
            {saleDate}{' '}
            <Text component="span" c="var(--colors-gray-5)">
              at
            </Text>{' '}
            {saleTime}
          </Text>
        </Group>
      </Box>
      <Divider h={12} orientation="vertical" color="var(--colors-gray-3)" m="auto" />
      <Box w={95}>
        <Group h={28} gap={6} align="center" justify="center" wrap="nowrap" className={classes.overflowHidden}>
          {/* <LocalAtmIcon size={16} color="var(--colors-gray-5)" /> */}
          <Text truncate>
            {formatCurrency(total)}{' '}
            <Text component="span" c="var(--colors-gray-5)">
              Total
            </Text>
          </Text>
        </Group>
      </Box>
      <Divider h={12} orientation="vertical" color="var(--colors-gray-3)" m="auto" />
      <Box w={100}>
        <Group h={28} gap={6} align="center" justify="center" wrap="nowrap" className={classes.overflowHidden}>
          <Center style={{ transform: profit >= 0 ? 'rotate(-90deg)' : 'rotate(90deg)' }}>
            <ArrowForwardIcon size={16} color={profit >= 0 ? 'var(--colors-successButton)' : 'var(--colors-red-error)'} />
          </Center>
          <Text truncate>
            {formatCurrency(profit)}{' '}
            <Text component="span" c="var(--colors-gray-5)">
              P/L
            </Text>
          </Text>
        </Group>
      </Box>
      <Divider h={12} orientation="vertical" color="var(--colors-gray-3)" m="auto" />
      <Box w={90}>
        <Group h={28} gap={6} align="center" justify="center" wrap="nowrap" className={classes.overflowHidden}>
          <Center style={{ transform: roi >= 0 ? 'rotate(-90deg)' : 'rotate(90deg)' }}>
            <ArrowForwardIcon size={16} color={roi >= 0 ? 'var(--colors-successButton)' : 'var(--colors-red-error)'} />
          </Center>
          <Text truncate>
            {roi === Infinity ? '--' : roi.toFixed(2)}%{' '}
            <Text component="span" c="var(--colors-gray-5)">
              ROI
            </Text>
          </Text>
        </Group>
      </Box>
      <Divider h={12} orientation="vertical" color="var(--colors-gray-3)" m="auto" />
      <Box w={140}>
        <Group h={28} gap={6} align="center" justify="center" wrap="nowrap" className={classes.overflowHidden}>
          <ReceiptIcon size={16} color="var(--colors-gray-5)" />
          <Text truncate>
            {' '}
            <Text component="span" c="var(--colors-gray-5)">
              Invoice
            </Text>{' '}
            #{invoice}
          </Text>
        </Group>
      </Box>
    </Flex>
  );
};
