import { AgGridReact } from '@ag-grid-community/react';
import { RefObject, useCallback, useMemo } from 'react';
import { BarkerCoreEnumsPricerStatus } from '../../api';
import { PriceDisplayImpl, PriceDisplayImplProps } from '../../components/PriceDisplay/PriceDisplay';
import { useRuleState } from '../../data/RuleState';
import { useSetAtom } from 'jotai';
import { rejectAllPendingUpdatesAtom } from '../../data/atoms';
import { useGlobalState } from '../../data/GlobalState';
import { useListingState } from '../../data/ListingState';
import { PriceDisplayStaged } from '../../components/PriceDisplay/PriceDisplayStaged';
import { useFlag } from '@unleash/proxy-client-react';

type PriceDisplayProps = {
  value: number;
  pricerStatusId: BarkerCoreEnumsPricerStatus;
  cost: number;
  listingId: string;
  tenantId: string;
  isPending: boolean;
  isAutoPriced: boolean;
  isDetailRow: boolean;
  gridRef: RefObject<AgGridReact>;
  isListingOnHold: boolean;
};

export const PriceDisplay = ({
  value,
  pricerStatusId,
  tenantId,
  listingId,
  cost,
  gridRef,
  isDetailRow,
  isListingOnHold,
}: Omit<PriceDisplayProps, 'isPending' | 'isAutoPriced'>) => {
  const stagedModeFlag = useFlag('staged-mode');
  const { pendingListingUpdatesByProperty, resetForm } = useRuleState('pendingListingUpdatesByProperty', 'resetForm');
  const { updatePrice } = useListingState('updatePrice');
  const pendingPrice = pendingListingUpdatesByProperty({ tenantId, listingId, property: 'unitPrice' });
  const pendingRule = pendingListingUpdatesByProperty({ tenantId, listingId, property: 'ruleId' });
  const pendingPricerStatus = pendingListingUpdatesByProperty({ tenantId, listingId, property: 'pricerStatusId' });
  const pickPrice = useMemo(() => pendingPrice?.value ?? value, [pendingPrice?.value, value]) as number;
  const reject = useSetAtom(rejectAllPendingUpdatesAtom);
  const onCancel = useCallback<Required<PriceDisplayImplProps>['onCancel']>(() => {
    reject(listingId);
  }, [listingId, reject]);
  const { currentUser } = useGlobalState('currentUser');

  const _updatePrice = useCallback<PriceDisplayImplProps['updatePrice']>(
    async (...props) => {
      await updatePrice(...props);
      resetForm(true);
    },
    [updatePrice, resetForm],
  );

  if (
    stagedModeFlag &&
    !pendingRule &&
    (!pendingPrice || pendingPrice?.inlinePriceChange) &&
    (pricerStatusId === BarkerCoreEnumsPricerStatus.None || pricerStatusId === BarkerCoreEnumsPricerStatus.Scheduled)
  ) {
    const pendingUpdate = !!pendingPrice?.inlinePriceChange;
    return (
      <PriceDisplayStaged
        key={value} // Forces component to remount when price is changed by outside factors.
        readOnly={currentUser?.roleId === 'ReadOnlyUser' || isListingOnHold}
        value={value}
        cost={cost}
        basePrice={value}
        updatePrice={_updatePrice}
        onCancel={onCancel}
        gridRef={gridRef}
        listingId={listingId}
        tenantId={tenantId}
        pricerStatusId={(pendingPricerStatus?.value as BarkerCoreEnumsPricerStatus) || pricerStatusId}
        isDetailRow={isDetailRow}
        isPending={pendingUpdate}
      />
    );
  }
  const pendingUpdate = !!pendingPrice || !!pendingRule || !!pendingPricerStatus;

  return (
    <PriceDisplayImpl
      key={pickPrice} // Note: This causes schedule pricing to remount. Comment out and use internal useEffect to fix
      readOnly={currentUser?.roleId === 'ReadOnlyUser' || isListingOnHold}
      value={pickPrice}
      cost={cost}
      basePrice={value}
      updatePrice={_updatePrice}
      onCancel={onCancel}
      gridRef={gridRef}
      listingId={listingId}
      tenantId={tenantId}
      pricerStatusId={(pendingPricerStatus?.value as BarkerCoreEnumsPricerStatus) || pricerStatusId}
      isDetailRow={isDetailRow}
      isPending={pendingUpdate}
    />
  );
};
