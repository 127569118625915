import classes from './HeaderAction.tsx.module.css';
import { Avatar, Box, Burger, Container, Group, Menu, Text, UnstyledButton, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ArrowDropdownIcon from '../../components/icons/ArrowDropdown';
import LogoutIcon from '../../components/icons/Logout';
import { NavLink, useNavigate } from 'react-router-dom';
import RoutineIcon from '../../components/icons/Routine';
import AccountIcon from '../../components/icons/Account';
import { useGlobalState } from '../../data/GlobalState';
import BNLogoHover from '../../components/icons/BNLogoHover';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import DTILogoHover from '../../components/icons/DTILogoHover';
import { useAuth0 } from '@auth0/auth0-react';

interface HeaderActionProps {
  links: { link: string; label: string; links: { link: string; label: string }[]; disabled?: boolean }[];
}

export function HeaderAction({ links }: HeaderActionProps) {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme();
  const dark = computedColorScheme === 'dark';
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
  };
  const { logout, user } = useAuth0();
  const [opened, { toggle }] = useDisclosure(false);
  const items = links.map((link) =>
    !link.disabled ? (
      <NavLink to={link.link} key={link.label} className={classes.link}>
        {link.label}
      </NavLink>
    ) : (
      <Text className={classes.disabledLink} key={link.label}>
        {link.label}
      </Text>
    ),
  );

  const { tenants } = useGlobalState('tenants');
  const navigate = useNavigate();

  return (
    <Box bg="var(--colors-siteBg)" h={52} mb={44} pos="sticky" top={0} style={{ borderBottom: '1px solid var(--colors-gray-1)', zIndex: 100 }}>
      <Container className={classes.inner} maw={1280} fluid>
        <Group gap={16} align="center">
          <Group align="center" justify="center">
            {items.length > 0 && <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />}
            <Group align="center" pt={4}>
              <UnstyledButton
                onClick={() => {
                  toggleColorScheme();
                }}
              >
                {isDtiHosted ? <DTILogoHover size={96} /> : <BNLogoHover size={53} />}
              </UnstyledButton>
            </Group>
          </Group>
          <Group gap={5} className={classes.links}>
            {items}
          </Group>
        </Group>

        <Menu width={180} position="bottom-end">
          <Menu.Target>
            <UnstyledButton>
              <Group gap={7}>
                <Avatar className={classes.avatar} placeholder="LW" radius="xl" size={32} />
                <Text fw={500} size="sm" lh={1} mr={3}>
                  {user?.email}
                </Text>
                <ArrowDropdownIcon />
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            {tenants !== undefined && tenants.length > 0 && (
              <Menu.Item leftSection={<AccountIcon />} pos="relative" onClick={() => navigate('/settings/profile')}>
                Profile
              </Menu.Item>
            )}
            <Menu.Item
              data-pendo-name="Color Scheme Toggle"
              onClick={() => {
                toggleColorScheme();
              }}
              leftSection={<RoutineIcon />}
            >
              {dark ? 'Light Mode' : 'Dark Mode'}
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                logout({ returnTo: window.location.origin });
              }}
              leftSection={<LogoutIcon />}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Container>
    </Box>
  );
}
