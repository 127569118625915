import { useDTIInventory } from '../DTI.Inventory.hooks';
import { Group, Stack, Text } from '@mantine/core';
import { BNButton } from '../../../components/Button/Button';

export function DeleteListingForm({ onClose }: { onClose: () => void }) {
  const { deleteListing, isDeletingListings, selectedListing } = useDTIInventory('deleteListing', 'isDeletingListings', 'selectedListing');

  return (
    <Group gap="xs" align="flex-end" justify="right" mt={-2}>
      <Stack gap="xs">
        <Text>Really delete these tickets?</Text>
        <Group gap="xs" align="flex-end" justify="right" wrap="nowrap" w={236}>
          <BNButton
            fullWidth
            variant="default"
            loading={isDeletingListings}
            disabled={false}
            size="xs"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </BNButton>
          <BNButton
            fullWidth
            variant="filled"
            disabled={(selectedListing?.quantityReserved ?? 0) > 0}
            loading={isDeletingListings}
            color="red"
            size="xs"
            onClick={async () => {
              await deleteListing();
              onClose();
            }}
          >
            Delete
          </BNButton>
        </Group>
      </Stack>
    </Group>
  );
}
