import { ActionIcon, Box, Center, Container, Flex, Group, Title, Tooltip } from '@mantine/core';
import { useSeatingChart } from './SeatingChart.hooks';
import { EventMappingProvider } from '../EventMapping/EventMapping.hooks';
import { EditMarketplaceLinksDialog } from './SeatingChart.MarketplaceLinksDialog';
import { MarketplaceTrendsProvider } from '../MarketplaceTrends/MarketplaceTrends.hooks';
import classes from './SeatingChart.module.css';
import ChevronForwardIcon from '../../components/icons/ChevronForward';
import cx from 'clsx';
import { useLocalStorage } from '@mantine/hooks';
import BarChartIcon from '../../components/icons/BarChart';
import { AdvancedFilters } from './SeatingChart.FiltersAdvanced';
import { useLocation } from 'react-router-dom';
import { Header } from './SeatingChart.Header';
import { CustomLoadingOverlay } from './SeatingChart.Loading';
import { Venue } from './SeatingChart.Venue';
import { EventMapping } from '../EventMapping/EventMapping';
import { NoEventSelected } from './SeatingChart.NoEventSelected';
import { SectionChecklist } from './SeatingChart.SectionChecklist';
import { EmptyState } from './SeatingChart.EmptyState';
import { MarketplaceTrends } from '../MarketplaceTrends/SeatingChart.MarketplaceTrends';

type SeatingChartProps = {
  isTrendsCollapsed?: boolean;
  handleTrendsToggle?: () => void;
};

export function SeatingChart({ isTrendsCollapsed = false, handleTrendsToggle = () => {} }: SeatingChartProps) {
  const {
    selectedMarketplaceEvent,
    sectionsWithListings,
    isLoading,
    isSeatingChartError,
    showEventMapping,
    isEditMarketplaceLinksDialogOpen,
    editMarketplaceLinksDialogHandler,
    forceSectionCheckList,
    selectedEvent,
    isSeasonView,
  } = useSeatingChart(
    'selectedMarketplaceEvent',
    'sectionsWithListings',
    'isLoading',
    'isSeatingChartError',
    'showEventMapping',
    'isEditMarketplaceLinksDialogOpen',
    'editMarketplaceLinksDialogHandler',
    'forceSectionCheckList',
    'selectedEvent',
    'isSeasonView',
  );
  const isRuleRoute = useLocation().pathname.includes('/rule');

  const [showAdvanced, setShowAdvanced] = useLocalStorage({
    key: 'setting-advancedFiltersPanel',
    defaultValue: false,
    getInitialValueInEffect: false,
  });

  return (
    <>
      <Header />
      <Container px={16} className={classes.mainContent}>
        <Box className={classes.topSection}>
          <div className={classes.seatingChartWrapper}>
            {isLoading && <CustomLoadingOverlay />}
            {!isLoading && selectedMarketplaceEvent.marketplaceEventId && !isSeatingChartError && (
              <Box display={forceSectionCheckList ? 'none' : 'block'}>
                <Venue />
              </Box>
            )}
            {!isLoading && selectedMarketplaceEvent.marketplaceEvent && (isSeatingChartError || forceSectionCheckList) && <SectionChecklist />}
            {!isLoading &&
              selectedEvent &&
              !selectedMarketplaceEvent.marketplaceEvent &&
              (!selectedMarketplaceEvent.marketplaceEventId || (isSeatingChartError && sectionsWithListings.length === 0)) && <EmptyState />}
            {!isLoading &&
            !selectedEvent &&
            !selectedMarketplaceEvent.marketplaceEvent &&
            (!selectedMarketplaceEvent.marketplaceEventId || (isSeatingChartError && sectionsWithListings.length === 0)) ? (
              isSeasonView ? (
                <CustomLoadingOverlay />
              ) : (
                <NoEventSelected />
              )
            ) : null}
          </div>
          <AdvancedFilters showAdvanced={isRuleRoute || isTrendsCollapsed || showAdvanced} setShowAdvanced={setShowAdvanced} />
        </Box>
        {/* Graph */}
        {!isSeasonView && (
          <Box className={classes.chartWrapper}>
            <>
              <Flex className={classes.collapsedView} align="center" justify="space-between" pb={4} gap={4} pr={48}>
                <Group gap={4}>
                  <BarChartIcon color="var(--colors-iconFill)" />
                  <Title fz="sm" fw={600} order={6}>
                    Trends
                  </Title>
                </Group>
              </Flex>
              <Tooltip label={isTrendsCollapsed ? 'Expand Trends Area' : 'Collapse Trends Area'}>
                <ActionIcon className={classes.trendsToggle} pos="absolute" top={-2} right={0} onClick={handleTrendsToggle} data-pendo-name="Trends Expand Collapse Toggle">
                  <Center style={{ transform: 'rotate(90deg)' }}>
                    <ChevronForwardIcon size={14} />
                  </Center>
                </ActionIcon>
              </Tooltip>
            </>
            <Box className={cx(classes.innerChartWrapper, classes.innerChartWrapperToggled)}>
              <MarketplaceTrendsProvider>
                <MarketplaceTrends />
              </MarketplaceTrendsProvider>
            </Box>
          </Box>
        )}
      </Container>
      {!isSeasonView && (showEventMapping || isEditMarketplaceLinksDialogOpen) && (
        <EventMappingProvider>
          {isEditMarketplaceLinksDialogOpen && <EditMarketplaceLinksDialog isOpened={isEditMarketplaceLinksDialogOpen} onClose={editMarketplaceLinksDialogHandler.close} />}
          {showEventMapping && <EventMapping />}
        </EventMappingProvider>
      )}
    </>
  );
}
