import { useForm } from '@mantine/form';
import { Box, Center, Checkbox, ComboboxItem, Divider, Flex, Group, Loader, Modal, Skeleton, Stack, Text } from '@mantine/core';
import { BNTextInput } from '../../../components/TextInput/TextInput';
import { BNNumberInput } from '../../../components/NumberInput/NumberInput';
import { BNButton } from '../../../components/Button/Button';
import { useMemo } from 'react';
import AutoPriceIcon from '../../../components/icons/AutoPrice';
import { formatCurrency, formatDate } from '../../../utils/formatters';
import { DateFormats } from '../../../utils/globals';
import { useDTIInventory } from '../DTI.Inventory.hooks';
import classes from './SellPrivately.styles.module.css';
import { BarkerCoreModelsDTICountry, BarkerCoreModelsDTIItem, BarkerCoreModelsDTISellPrivatelyListingItem, useGetDtiListingsAccountIdEventId } from '../../../api';
import { BNSelect } from '../../../components/Select/Select';
import PointOfSaleIcon from '../../../components/icons/PointOfSale';
import PersonPinIcon from '../../../components/icons/PersonPin';
import FlashOnIcon from '../../../components/icons/FlashOn';
import LocalActivityIcon from '../../../components/icons/LocalActivity';
import OpenInPhoneIcon from '../../../components/icons/OpenInPhone';
import SmartphoneIcon from '../../../components/icons/Smartphone';
import PictureAsPDFIcon from '../../../components/icons/PictureAsPDF';

export function SellPrivatelyDialog({ opened, onClose }: { opened: boolean; onClose: () => void }) {
  const { selectedListing, sellPrivately, isSellingPrivately, vendorProperties } = useDTIInventory('selectedListing', 'sellPrivately', 'isSellingPrivately', 'vendorProperties');

  const selectedEventId = parseInt(selectedListing?.eventId ?? '0');

  const { data: eventListings } = useGetDtiListingsAccountIdEventId(-100, selectedEventId, {
    query: {
      enabled: selectedEventId > 0,
      select: (data) => data.data,
    },
  });

  const selectedDtiListing = eventListings?.listings?.find((listing) => listing.listing_id?.split('-')[0] === selectedListing?.listingId);

  const mobileForm = useForm({
    initialValues: {
      keepCost: false,
      totalPrice: 0,
      email: '',
      firstName: '',
      lastName: '',
    },
    validate: {
      email: (value) => !value.includes('@') && 'Email is invalid',
    },
  });

  const hardForm = useForm({
    initialValues: {
      keepCost: false,
      totalPrice: 0,
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      region: '',
      postalCode: '',
      country: undefined as BarkerCoreModelsDTICountry | undefined,
    },
    validate: {
      firstName: (value) => value.length < 1 && 'First name is required',
      lastName: (value) => value.length < 1 && 'Last name is required',
      address1: (value) => value.length < 1 && 'Address is required',
      city: (value) => value.length < 1 && 'City is required',
      region: (value) => value.length < 1 && 'Region is required',
      postalCode: (value) => value.length < 1 && 'Postal code is required',
      country: (value) => !value && 'Country is required',
    },
  });

  const pdfForm = useForm({
    initialValues: {
      keepCost: false,
      totalPrice: 0,
    },
  });

  const accountId = vendorProperties?.ownerId ?? 0;

  const itemIds = selectedDtiListing?.items?.map((i) => i.item_id ?? 0);

  const location = vendorProperties?.location ?? '';

  const locationId = (() => {
    switch (location) {
      case 'TF':
        return 18;
      case 'HARD':
        return 19;
      case 'FLASH':
        return 22;
      case 'WILL CALL':
        return 23;
      case 'MOBILE_XFR':
        return 39;
      case 'MD':
        return 50;
      default:
        return 0;
    }
  })();

  // const location = (() => {
  //   switch (location_id) {
  //     case 18:
  //       return 'TF';
  //     case 19:
  //       return 'HARD';
  //     case 22:
  //       return 'FLASH';
  //     case 23:
  //       return 'WILL CALL';
  //     case 39:
  //       return 'MOBILE_XFR';
  //     case 50:
  //       return 'MD';
  //     default:
  //       return '';
  //   }
  // })();

  function LocationDisplay() {
    if (location === 'TF') {
      return (
        <Group wrap="nowrap" gap={4}>
          <Text lh={1.25} pt={2}>
            PDF
          </Text>
          <Center style={{ flexShrink: 0 }}>
            <PictureAsPDFIcon color="var(--colors-iconFill)" size={16} />
          </Center>
        </Group>
      );
    }
    if (location === 'HARD') {
      return (
        <Group wrap="nowrap" gap={4}>
          <Text lh={1.25} pt={2}>
            Hard
          </Text>
          <Center style={{ flexShrink: 0 }}>
            <LocalActivityIcon color="var(--colors-iconFill)" size={16} />
          </Center>
        </Group>
      );
    }
    if (location === 'FLASH') {
      return (
        <Group wrap="nowrap" gap={4}>
          <Text lh={1.25} pt={2}>
            Flash
          </Text>
          <Center style={{ flexShrink: 0 }}>
            <FlashOnIcon color="var(--colors-iconFill)" size={16} />
          </Center>
        </Group>
      );
    }
    if (location === 'WILL CALL') {
      return (
        <Group wrap="nowrap" gap={4}>
          <Text lh={1.25} pt={2}>
            Will Call
          </Text>
          <Center style={{ flexShrink: 0 }}>
            <PersonPinIcon color="var(--colors-iconFill)" size={16} />
          </Center>
        </Group>
      );
    }
    if (location === 'MOBILE_XFR') {
      return (
        <Group wrap="nowrap" gap={4}>
          <Text lh={1.25} pt={2}>
            Mobile Transfer
          </Text>
          <Center style={{ flexShrink: 0 }}>
            <OpenInPhoneIcon color="var(--colors-iconFill)" size={16} />
          </Center>
        </Group>
      );
    }
    if (location === 'MD') {
      return (
        <Group wrap="nowrap" gap={4}>
          <Text lh={1.25} pt={2}>
            Managed Delivery
          </Text>
          <Center style={{ flexShrink: 0 }}>
            <SmartphoneIcon color="var(--colors-iconFill)" size={16} />
          </Center>
        </Group>
      );
    }
    return '';
  }

  const countries = useMemo<BarkerCoreModelsDTICountry[]>(
    () => [
      {
        code: 'CAN',
        id: 0,
        label: 'Canada',
      },
      {
        code: 'GBR',
        id: 1,
        label: 'United Kingdom',
      },
      {
        code: 'USA',
        id: 2,
        label: 'United States',
      },
    ],
    [],
  );

  function generateRequestItems(items: BarkerCoreModelsDTIItem[] | null | undefined): BarkerCoreModelsDTISellPrivatelyListingItem[] {
    return (items ?? []).map(
      (i) =>
        ({
          account_info: i.account_info,
          alerts: '', // TODO: Ask about this
          autoconfirm: i.autoconfirm ?? false,
          barcode: i.barcode,
          cc_info: ' ', // This always seems to be a space
          cc_info_card: i.cc_info_card?.toString() ?? '',
          cc_info_purchaser: i.cc_info_purchaser ?? '',
          change_pending: null,
          checksum: i.checksum,
          confirmation_no: i.confirmation_no,
          cost: i.cost ?? 0,
          edelivery: i.edelivery ?? false,
          event_id: i.event_id ?? 0,
          exchange_sharing: i.exchange_sharing,
          flags: '', // TODO: Find out if we need anything here,
          inhand: i.inhand ?? false,
          inhand_date: i.inhand_date,
          item_id: i.item_id ?? 0,
          last_price_update: i.last_price_update,
          listing_id: i.listing_id ?? '0',
          listing_request_id: i.listing_request_id,
          location_id: i.location_id ?? 0,
          location_label: '', // TODO: Find out if we need to translate these
          my_notes: i.internal_notes ?? '',
          notes: i.notes ?? '',
          odd_even: !!i.odd_even,
          on_broker_genius: i.on_broker_genius,
          oneTMDState: i.oneTMD ? 1 : 0,
          order_number: i.order_number,
          original_cost: i.original_cost,
          original_row: i.original_row ?? '',
          original_section: i.original_section ?? '',
          owner_id: i.owner_id ?? 0,
          owner_name: i.owner_name ?? '',
          owner_notes: i.owner_notes ?? '',
          pdf: i.pdf ?? false,
          po_id: i.po_id ?? 0,
          price: i.price ?? 0,
          purchaser: i.purchaser,
          qty: 1,
          res_notes: [], // Determine if we need this,
          row: i.row ?? '',
          seat: i.seat ?? 0,
          section: i.section ?? '',
          share_exchange: i.share_exchange ?? false,
          share_hide_seats: i.share_hide_seats ?? false,
          share_splits: i.share_splits ?? 0,
          share_stubhub: i.share_stubhub ?? false,
          sharable_to_exchange: i.shareable_to_exchange ?? false,
          shareable_to_stubhub: i.shareable_to_stubhub ?? false,
          state: i.state ?? 0,
          tag_names: i.tags?.join(', ') ?? '',
          transferURL: i.transferURL ?? '',
          zones: [], // Determine if we need this,
        }) satisfies BarkerCoreModelsDTISellPrivatelyListingItem,
    );
  }

  function EventTitle() {
    if (!selectedListing) {
      return null;
    }

    return (
      <Group mt={-2} pos="relative" style={{ zIndex: 1 }}>
        <Box>
          <Text size="xs" fw={600}>
            {selectedListing.event.name}
          </Text>
          <Text p={0} c="var(--colors-gray-5)" size="xs" fw={500}>
            <span>
              <span>{formatDate(selectedListing.event.localDateTime, DateFormats.Extended)}</span> - {selectedListing.event.venue.name} · {selectedListing.event.venue.city},{' '}
              {selectedListing.event.venue.state}
            </span>
          </Text>
        </Box>
      </Group>
    );
  }

  return (
    <Modal
      centered
      size={640}
      opened={opened}
      closeButtonProps={{ size: 'md' }}
      onClose={onClose}
      title={
        <Group>
          <PointOfSaleIcon color="var(--colors-iconFill)" />
          Sell Privately
        </Group>
      }
    >
      <EventTitle />
      <Group mx="-1rem" px="md" pt="sm" pb="md" mb="sm" className={classes.gradient}>
        <Group align="start" gap={0} justify="space-between" style={{ border: '1px solid var(--colors-gray-3)', borderRadius: 3 }} p="sm" w="100%">
          <Box maw="20%" style={{ overflow: 'hidden' }}>
            <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
              Section
            </Text>
            <Text lh={1.25} pt={2} size="xs" style={{ whiteSpaceCollapse: 'collapse', overflowWrap: 'break-word' }}>
              {selectedListing?.section}
            </Text>
          </Box>
          <Box maw="20%" style={{ overflow: 'hidden' }}>
            <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
              Row
            </Text>
            <Text lh={1.25} pt={2} size="xs" style={{ whiteSpaceCollapse: 'collapse', overflowWrap: 'break-word' }}>
              {selectedListing?.row}
            </Text>
          </Box>
          <Box>
            <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
              Seats
            </Text>
            <Text lh={1.25} pt={2} size="xs">
              {selectedListing?.quantityRemaining ?? 0 > 1 ? `${selectedListing?.seatFrom}-${selectedListing?.seatThru}` : selectedListing?.seatFrom}
            </Text>
          </Box>
          <Box>
            <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
              Qty
            </Text>
            <Text lh={1.25} pt={2} size="xs">
              {selectedListing?.quantityRemaining}
            </Text>
          </Box>
          <Box>
            <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
              Unit Cost
            </Text>
            <Text lh={1.25} pt={2} size="xs">
              {formatCurrency(selectedListing?.unitCost ?? 0)}
            </Text>
          </Box>
          <Box>
            <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
              Current Price
            </Text>
            <Group gap={4}>
              <Text size="xs">{formatCurrency(selectedListing?.unitPrice ?? 0)}</Text>
              {selectedListing?.pricerStatusId === 'AutoPriced' && <AutoPriceIcon size={16} />}{' '}
              {/* TODO: Replace this with the logic for auto pricing indicators to be developed later */}
            </Group>
          </Box>
          <Box pr={0} w={108}>
            <Text style={{ whiteSpace: 'nowrap', lineHeight: 1 }} size="xs" c="var(--colors-gray-5)">
              Stock
            </Text>
            {!location ? <Skeleton w={108} h={8} mt={5} color="var(--colors-gray-5)" /> : <LocationDisplay />}
          </Box>
        </Group>
      </Group>
      {!location && (
        <Center h={144}>
          <Loader type="dots" color="gray" />
        </Center>
      )}
      {(location === 'MOBILE_XFR' || location === 'FLASH') && (
        <form
          onSubmit={mobileForm.onSubmit(async (values) => {
            await sellPrivately({
              accountId: accountId ?? 0,
              data: {
                item_ids: itemIds ?? [],
                location_id: locationId,
                keep_cost: values.keepCost,
                user_sold_price: values.totalPrice,
                sale_info: {
                  electronic_delivery: {
                    email: values.email,
                    first_name: values.firstName,
                    last_name: values.lastName,
                    address_one: '',
                    address_two: '',
                    city: '',
                    state: '',
                    country: '',
                    postal_code: '',
                    price: values.totalPrice,
                  },
                },
                items: generateRequestItems(selectedDtiListing!.items!),
              },
            });
            onClose();
          })}
        >
          <Stack gap={6}>
            <Group gap="lg" pb={6}>
              <BNNumberInput
                size="lg"
                decimalScale={2}
                fixedDecimalScale
                selectOnFocus
                leftSection={
                  <Text size="lg" ml={-2}>
                    $
                  </Text>
                }
                {...mobileForm.getInputProps('totalPrice')}
                label="Total Price"
                labelProps={{ fz: 'xs' }}
                w={168}
              />
              <Box pt="lg">
                <Checkbox size="sm" {...mobileForm.getInputProps('keepCost')} label={<Text size="xs">Keep Cost</Text>} />
              </Box>
            </Group>
            <Divider orientation="horizontal" color="var(--colors-borderDivider)" my="xs" />
            <Group gap="md" pb={4} mt={-4}>
              <BNTextInput w={140} size="xs" {...mobileForm.getInputProps('firstName')} maxLength={50} label="First Name" />
              <BNTextInput w={140} size="xs" {...mobileForm.getInputProps('lastName')} maxLength={50} label="Last Name" />
              <BNTextInput w={296} size="xs" {...mobileForm.getInputProps('email')} maxLength={100} label="Email" />
            </Group>
            <Divider orientation="horizontal" color="var(--colors-divider)" my="xs" mx={-16} />
            <Flex justify="flex-end" gap={12}>
              <BNButton size="xs" w={80} onClick={onClose} loading={isSellingPrivately}>
                Cancel
              </BNButton>
              <BNButton type="submit" size="xs" variant="filled" color="green" w={80} loading={!accountId || isSellingPrivately}>
                Save
              </BNButton>
            </Flex>
          </Stack>
        </form>
      )}

      {(location === 'HARD' || location === 'WILL CALL') && (
        <form
          onSubmit={hardForm.onSubmit(async (values) => {
            await sellPrivately({
              accountId: accountId ?? 0,
              data: {
                item_ids: itemIds ?? [],
                location_id: locationId,
                keep_cost: values.keepCost,
                user_sold_price: values.totalPrice,
                sale_info: {
                  address: {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    address_one: values.address1,
                    address_two: values.address2,
                    city: values.city,
                    state: values.region,
                    country: values.country!,
                    postal_code: values.postalCode,
                    price: values.totalPrice,
                    country_code: values.country!.code,
                  },
                },
                items: generateRequestItems(selectedDtiListing!.items!),
              },
            });
            onClose();
          })}
        >
          <Stack gap={6}>
            <Group gap="lg" pb={6}>
              <BNNumberInput
                size="lg"
                decimalScale={2}
                fixedDecimalScale
                selectOnFocus
                leftSection={
                  <Text size="lg" ml={-2}>
                    $
                  </Text>
                }
                {...hardForm.getInputProps('totalPrice')}
                label="Total Price"
                labelProps={{ fz: 'xs' }}
                w={168}
              />
              <Box pt="lg">
                <Checkbox size="xs" {...hardForm.getInputProps('keepCost')} label="Keep Cost" />
              </Box>
            </Group>
            <Divider orientation="horizontal" color="var(--colors-borderDivider)" my="xs" />
            <Group gap="md" mt={-4}>
              <BNTextInput w={140} size="xs" {...hardForm.getInputProps('firstName')} maxLength={50} label="First Name" />
              <BNTextInput w={140} size="xs" {...hardForm.getInputProps('lastName')} maxLength={50} label="Last Name" />
            </Group>
            <Group gap="md">
              <BNTextInput w={296} size="xs" {...hardForm.getInputProps('address1')} maxLength={50} label="Address" />
              <BNTextInput w={296} size="xs" {...hardForm.getInputProps('address2')} maxLength={50} label="Address 2" />
            </Group>
            <Group gap="md" pb={4}>
              <BNTextInput w={140} size="xs" {...hardForm.getInputProps('city')} maxLength={50} label="City" />
              <BNTextInput w={140} size="xs" {...hardForm.getInputProps('region')} maxLength={50} label="State/Region" />
              <BNTextInput w={140} size="xs" {...hardForm.getInputProps('postalCode')} maxLength={15} label="Postal Code" />
              <BNSelect
                size="xs"
                w={140}
                data={countries.map(
                  (c) =>
                    ({
                      value: c.id.toString(),
                      label: c.label,
                    }) satisfies ComboboxItem,
                )}
                {...hardForm.getInputProps('country')}
                label="Country"
              />
            </Group>
            <Divider orientation="horizontal" color="var(--colors-divider)" my="xs" mx={-16} />
            <Flex justify="flex-end" gap={12}>
              <BNButton size="xs" w={80} onClick={onClose} loading={isSellingPrivately}>
                Cancel
              </BNButton>
              <BNButton type="submit" size="xs" variant="filled" color="green" w={80} loading={!accountId || isSellingPrivately}>
                Save
              </BNButton>
            </Flex>
          </Stack>
        </form>
      )}

      {(location === 'TF' || location === 'MD') && (
        <form
          onSubmit={pdfForm.onSubmit((values) => {
            sellPrivately({
              accountId: accountId ?? 0,
              data: {
                item_ids: itemIds ?? [],
                location_id: locationId,
                keep_cost: values.keepCost,
                user_sold_price: values.totalPrice,
                sale_info: {},
                items: generateRequestItems(selectedDtiListing!.items!),
              },
            }).then(() => onClose());
          })}
        >
          <Stack gap={6}>
            <Group gap="lg" pb={6}>
              <BNNumberInput
                size="lg"
                decimalScale={2}
                fixedDecimalScale
                selectOnFocus
                leftSection={
                  <Text size="lg" ml={-2}>
                    $
                  </Text>
                }
                {...pdfForm.getInputProps('totalPrice')}
                label="Total Price"
                labelProps={{ fz: 'xs' }}
                w={168}
              />
              <Box pt="lg">
                <Checkbox size="xs" {...pdfForm.getInputProps('keepCost')} label="Keep Cost" />
              </Box>
            </Group>
            <Divider orientation="horizontal" color="var(--colors-divider)" my="xs" mx={-16} />
            <Flex justify="flex-end" gap={12}>
              <BNButton size="xs" w={80} onClick={onClose} loading={isSellingPrivately}>
                Cancel
              </BNButton>
              <BNButton type="submit" size="xs" variant="filled" color="green" w={80} loading={!selectedDtiListing || isSellingPrivately}>
                Save
              </BNButton>
            </Flex>
          </Stack>
        </form>
      )}
    </Modal>
  );
}
