import { ActionIcon, Box, Collapse, Divider, Group, Indicator, Menu, Paper, Text, Tooltip } from '@mantine/core';
import SettingsIcon from '../../components/icons/Settings';
import { BNButton } from '../../components/Button/Button';
import ResetIcon from '../../components/icons/Reset';
import SelectArrowsIcon from '../../components/icons/SelectArrows';
import DragIndicatorIcon from '../../components/icons/DragIndicator';
import { BNTextInput } from '../../components/TextInput/TextInput';
import FilterListIcon from '../../components/icons/FilterList';
import { BNSwitch } from '../../components/Switch/Switch';
import { TotalEventsLabel } from '../../components/Label/TotalEventsLabel';
import { ListingCostIconLabel } from '../../components/Label/ListingCostLabel';
import { OpenlistingsLabel } from '../../components/Label/OpenListingsLabel';
import ArrowForwardIcon from '../../components/icons/ArrowForward';
import CloseIcon from '../../components/icons/Close';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { SortItem } from './Inventory.SortItem';
import { useInventoryHeader } from './Inventory.Header.hooks';
import ArrowBackIcon from '../../components/icons/ArrowBack';
import classes from './Inventory.module.css';
import RefreshIcon from '../../components/icons/Refresh';
import { useFlag } from '@unleash/proxy-client-react';

export function Header() {
  const {
    columnSettings,
    columnSettingsHandlers,
    filter,
    setFilter,
    setInventoryQuickFilter,
    inventoryQuickFilter,
    selectedSeason,
    showSettings,
    listingSort,
    listingSortHandlers,
    resetAllDefaults,
    resetColumnDefaults,
    resetListingDefaults,
    toggleSettings,
    onMenuVisibilityChange,
  } = useInventoryHeader(
    'columnSettings',
    'columnSettingsHandlers',
    'filter',
    'setFilter',
    'setInventoryQuickFilter',
    'inventoryQuickFilter',
    'selectedSeason',
    'showSettings',
    'listingSort',
    'listingSortHandlers',
    'resetAllDefaults',
    'resetColumnDefaults',
    'resetListingDefaults',
    'toggleSettings',
    'onMenuVisibilityChange',
  );

  const columnSettingsItems = columnSettings.map((item, index) => (
    <Draggable key={item.key} index={index} draggableId={item.key}>
      {(provided: DraggableProvided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Menu.Item
            {...provided.dragHandleProps}
            component="a"
            leftSection={<DragIndicatorIcon color="var(--colors-gray-4)" size={20} />}
            c={item.visible ? 'var(--colors-gray-9)' : 'var(--colors-gray-5)'}
            fz="xs"
            py={6}
            tt="capitalize"
            style={{ borderBottom: '1px solid var(--colors-paperHover)' }}
            rightSection={
              <BNSwitch
                size="xs"
                label={undefined}
                checked={item.visible}
                onClick={() => {
                  columnSettingsHandlers.setItemProp(index, 'visible', !item.visible);
                }}
              />
            }
          >
            {item.name}
          </Menu.Item>
        </div>
      )}
    </Draggable>
  ));

  const stagedModeFlag = useFlag('staged-mode');

  return (
    <Paper bg="var(--colors-gray-0)">
      <Group px={16} className={classes.headerWrapper} bg="var(--colors-paper)">
        <Group gap="xs" wrap="nowrap" className={classes.leftSearch}>
          <BNButton
            onClick={() => {
              window.location.href = '/';
            }}
            className="exit-season-pricer"
            size="xs"
            leftSection={<ArrowBackIcon />}
            variant="filled"
            color="gray"
          >
            Exit Season Pricer
          </BNButton>
          <BNTextInput
            id="filter-text-box"
            leftSection={
              inventoryQuickFilter ? (
                <Indicator color="var(--colors-selectedBorder)" display="flex" size={4} offset={1}>
                  <FilterListIcon color="var(--colors-selectedBorder)" />
                </Indicator>
              ) : (
                <FilterListIcon />
              )
            }
            value={filter}
            size="xs"
            onChange={(e) => {
              setFilter(e.currentTarget.value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                setInventoryQuickFilter(filter);
              } else if (e.key === 'Escape') {
                setFilter('');
                setInventoryQuickFilter('');
              }
            }}
            placeholder="Filter Locations"
            aria-label="Locations Filter"
            className={inventoryQuickFilter ? `${classes.showRightSection}` : `${classes.clearableInputAndGo}`}
            rightSectionWidth={57}
            rightSection={
              <Group wrap="nowrap" gap={4} px="xs">
                <ActionIcon
                  size="sm"
                  className="clearButton"
                  onClick={() => {
                    setFilter('');
                    setInventoryQuickFilter('');
                  }}
                >
                  <CloseIcon />
                </ActionIcon>
                <ActionIcon variant="filled" size="sm" onClick={() => setInventoryQuickFilter(filter)}>
                  <ArrowForwardIcon color="var(--colors-paper)" size={20} />
                </ActionIcon>
              </Group>
            }
          />
        </Group>
        <Group gap="xs">
          <Group gap="xs" c="var(--colors-gray-5)" className={classes.statDisplay}>
            <TotalEventsLabel events={selectedSeason?.numEvents || 0} />
            <ListingCostIconLabel cost={selectedSeason?.totalCost || 0} />
            <OpenlistingsLabel listings={selectedSeason?.numLocations || 0} />
          </Group>
          {stagedModeFlag && (
            <>
              <Tooltip label="Refresh Data" position="right" withArrow>
                <ActionIcon data-pendo-name="Season Inventory Grid Refresh Button">
                  <RefreshIcon size={21} />
                </ActionIcon>
              </Tooltip>
              <Divider orientation="vertical" color="var(--colors-divider)" h={16} style={{ alignSelf: 'auto' }} />
            </>
          )}
          <Tooltip label="Grid Settings" position="right" withArrow>
            <ActionIcon onClick={() => toggleSettings()} bg={showSettings ? 'var(--colors-selectedBg)' : undefined} className={showSettings ? classes.showSettings : ''}>
              <SettingsIcon size={20} color={showSettings ? 'var(--colors-selectedBorder)' : undefined} />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>
      {/* Settings shelf */}
      <Collapse in={showSettings} transitionTimingFunction="ease-in-out">
        <Box px="md" className={classes.settingsShelf}>
          <Group wrap="nowrap" p={0} gap={0} justify="space-between" w="100%">
            <Group wrap="nowrap" pl={0} pr={0} gap="xs" w="100%" maw="calc(100% - 100px)" className={classes.settingsDropWrapper}>
              <Menu withArrow arrowPosition="center" width={240} closeOnItemClick={false} onChange={onMenuVisibilityChange}>
                <Menu.Target>
                  <BNButton size="xs" px={6} variant="subtle" maw="33%" className={classes.dropBtn} rightSection={<SelectArrowsIcon />}>
                    <Text truncate>Location Sorting</Text>
                  </BNButton>
                </Menu.Target>
                <DragDropContext
                  onDragEnd={({ destination, source }) => {
                    listingSortHandlers.setState((prevState) => {
                      const newState = structuredClone(prevState);
                      if (destination?.droppableId === 'unsorted') {
                        newState[source.index].dir = null;
                      } else {
                        newState[source.index].dir = newState[source.index].dir || 'asc';
                      }
                      const moveItem = newState.splice(source.index, 1)[0];
                      newState.splice(destination?.index || 0, 0, moveItem);
                      return newState
                        .map((item, index) => ({ ...item, index }))
                        .sort((a, b) => {
                          if (!a.dir && !b.dir) {
                            return a.name.localeCompare(b.name);
                          }
                          return a.index - b.index;
                        });
                    });
                  }}
                >
                  <Menu.Dropdown p={4} className={classes.menuDropdown}>
                    <Droppable droppableId="sorted" direction="vertical">
                      {(provided: DroppableProvided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {listingSort
                            .map((item, index) => ({ ...item, index }))
                            .filter((item) => item.dir != null)
                            .map((item) => (
                              <SortItem key={item.key} item={item} updateHandler={listingSortHandlers} />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Menu.Divider my={4} mx={-4} style={{ borderTop: '2px solid var(--colors-gray-3)', borderBottom: '4px solid var(--colors-paperHover)' }} />
                    <Droppable droppableId="unsorted" direction="vertical">
                      {(provided: DroppableProvided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {listingSort
                            .map((item, index) => ({ ...item, index }))
                            .filter((item) => item.dir == null)
                            .map((item) => (
                              <SortItem key={item.key} item={item} updateHandler={listingSortHandlers} />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Divider color="var(--colors-divider)" my={4} mx={-4} />
                    <Box>
                      <BNButton leftSection={<ResetIcon />} size="xs" variant="subtle" className={classes.resetBtn} fullWidth onClick={resetListingDefaults}>
                        Reset to Default
                      </BNButton>
                    </Box>
                  </Menu.Dropdown>
                </DragDropContext>
              </Menu>
              <Menu withArrow arrowPosition="center" width={240} closeOnItemClick={false} onChange={onMenuVisibilityChange}>
                <Menu.Target>
                  <BNButton size="xs" px={6} variant="subtle" maw="33%" className={classes.dropBtn} rightSection={<SelectArrowsIcon />}>
                    <Text truncate>Column Settings</Text>
                  </BNButton>
                </Menu.Target>
                <DragDropContext onDragEnd={({ destination, source }) => columnSettingsHandlers.reorder({ from: source.index, to: destination?.index || 0 })}>
                  <Menu.Dropdown p={4} className={classes.menuDropdown}>
                    <Droppable droppableId="key" direction="vertical">
                      {(provided: DroppableProvided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {columnSettingsItems}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Divider color="var(--colors-divider)" my={4} mx={-4} />
                    <Box>
                      <BNButton leftSection={<ResetIcon />} size="xs" variant="subtle" className={classes.resetBtn} fullWidth onClick={resetColumnDefaults}>
                        Reset to Default
                      </BNButton>
                    </Box>
                  </Menu.Dropdown>
                </DragDropContext>
              </Menu>
            </Group>
            <BNButton onClick={resetAllDefaults} size="xs" variant="subtle" color="gray" style={{ flexShrink: 0 }} className={classes.dropBtn} leftSection={<ResetIcon />}>
              Reset
              <span className="show-large">&nbsp;to Default</span>
            </BNButton>
          </Group>
        </Box>
      </Collapse>
    </Paper>
  );
}
