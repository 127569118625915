import { Accordion, Box, Center, FloatingPosition, Menu, MenuItemProps, PopoverProps } from '@mantine/core';
import { forwardRef, ReactElement, ReactNode, useRef } from 'react';
import { BNSwitch } from '../Switch/Switch';
import classes from './BNAccordion.module.css';

type ComponentProps<T> = T extends React.ComponentType<infer P> ? P : never;

type AccordionItemProps = {
  /**
   * Unique id used to identify item in accordion. Maps to Accordion.Item value internally
   */
  id: string;
  title: ReactNode;
  leftSection?: ReactNode;
  children: ReactNode | ReactNode[];
  ref?: HTMLDivElement;
  className?: string | undefined;
  rightSection?: ReactNode;
  onClick?: () => void;
};
export const BNAccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(({ id, leftSection, title, children, className, rightSection, onClick }, ref) => {
  return (
    <Accordion.Item value={id} ref={ref} className={className}>
      <Center>
        <Accordion.Control onClick={() => onClick && onClick()} icon={leftSection}>
          {title}
        </Accordion.Control>
        {rightSection}
      </Center>
      <Accordion.Panel pos={'relative'}>{children}</Accordion.Panel>
    </Accordion.Item>
  );
});

type AccordionSwitchProps = {
  checked: boolean;
  disabled?: boolean;
  leftSection?: ReactElement;
  onChange: (value: boolean) => void;
} & Omit<AccordionItemProps, 'children' | 'id'>;

function BNAccordionSwitch({ leftSection, title, checked, disabled, onChange }: AccordionSwitchProps) {
  return (
    <BNAccordionMenuItem
      leftSection={leftSection}
      closeMenuOnClick={false}
      onClick={() => {
        if (!disabled) {
          onChange(!checked);
        }
      }}
      rightSection={
        <BNSwitch
          size="xs"
          checked={checked}
          disabled={disabled}
          style={{
            pointerEvents: 'none',
          }}
        />
      }
    >
      {title}
    </BNAccordionMenuItem>
  );
}

function BNAccordionMenuItem({
  children,
  title,
  ...args
}: ComponentProps<typeof Menu.Item> & { disabled?: boolean; onClick?: () => void } & (
    | { title?: ReactNode; children: ReactNode }
    | {
        title: ReactNode;
      }
  )) {
  return (
    <Menu.Item fz="xs" fw={500} {...args}>
      {children ?? title}
    </Menu.Item>
  );
}

export type BNAccordionItems = ReactElement<AccordionSwitchProps | AccordionItemProps | MenuItemProps> | ReactNode;

type BNAccordionMenuProps = {
  target: ReactNode;
  width: number;
  children: BNAccordionItems[] | BNAccordionItems;
  position?: FloatingPosition;
  value?: string | null; // For controlled usage
} & Pick<PopoverProps, 'opened' | 'onClose' | 'offset'>;

export function BNAccordionMenu({ width, opened, target, children, onClose, position, value, ...props }: BNAccordionMenuProps) {
  const targetRef = useRef<HTMLDivElement | null>(null);
  return (
    <Menu withArrow withinPortal opened={opened} onClose={() => onClose?.()} offset={0} width={width} position={position} {...props}>
      <Menu.Target ref={targetRef}>
        <Center h="100%">{target}</Center>
      </Menu.Target>
      <Menu.Dropdown p={4} fz="xs">
        <BNAccordion value={value}>{children}</BNAccordion>
      </Menu.Dropdown>
    </Menu>
  );
}

BNAccordionMenu.Item = BNAccordionMenuItem;
BNAccordionMenu.Switch = BNAccordionSwitch;
BNAccordionMenu.Panel = BNAccordionItem;

// const BNAccordionMenuNamespace = Object.assign(BNAccordionMenu, { Item: BNAccordionMenuItem, Switch: BNAccordionSwitch, Panel: BNAccordionItem });
// export { BNAccordionMenuNamespace as BNAccordionMenu };

function BNAccordion({ children, ...props }: { children: BNAccordionItems[] | BNAccordionItems } & ComponentProps<typeof Accordion>) {
  return (
    <Accordion className={classes.BNAccordion} {...props}>
      <Box pos="relative">{children}</Box>
    </Accordion>
  );
}

BNAccordion.Item = BNAccordionItem;
BNAccordion.Switch = BNAccordionSwitch;

// const BNAccordionNamespace = Object.assign(BNAccordion, { Item: BNAccordionItem, Switch: BNAccordionSwitch });
// export { BNAccordionNamespace as BNAccordion };
