import yasml from '@thirtytech/yasml';
import { useAtomValue } from 'jotai/index';
import { seatingChartFiltersAtom, selectedEventAtom } from '../../data/atoms';
import { type BarkerCoreModelsDTISale, BarkerCoreModelsPricingRuleFilters, useGetDtiEventsEventIdMarketplaceSeatgeek } from '../../api';
import { useMemo } from 'react';
import { matchesRowFilter } from '../MarketListings/marketListingsFilter';
import { useLocalStorage } from '@mantine/hooks';
import { MarketplaceResultsSelection } from '../MarketplaceResults/MarketplaceResults.types';

function filterSeatGeekSales(sales: BarkerCoreModelsDTISale[], filters: BarkerCoreModelsPricingRuleFilters) {
  const rowParts = filters.rows?.split(/\s+/);

  return sales.filter((sale) => {
    if (filters.noFilters) {
      return true;
    }

    if (
      filters.sectionIds &&
      filters.sectionIds.length > 0 &&
      (sale.section === undefined || !filters.sectionIds.map((x) => x.toLowerCase()).includes(sale.section.toLowerCase()))
    ) {
      return false;
    }

    if (filters.rows) {
      if (!rowParts) {
        return false;
      }

      if (!matchesRowFilter(sale.row ?? 'GA', rowParts)) {
        return false;
      }
    }

    if (filters.splits && filters.splits.length > 0) {
      const quantities = [...Array(sale.quantity).keys()].map((x) => x + 1);
      if (filters.splits.filter((split) => quantities.includes(split)).length === 0) {
        return false;
      }
    }

    if (filters.minPrice && sale.price < filters.minPrice) {
      return false;
    }

    if (filters.maxPrice && sale.price > filters.maxPrice) {
      return false;
    }

    return true;
  });
}

const DTIMarketplaceState = () => {
  const selectedEvent = useAtomValue(selectedEventAtom);
  const [resultsSelection, setResultsSelection] = useLocalStorage<MarketplaceResultsSelection>({
    key: 'setting-marketplace-results-selection',
    defaultValue: 'Listings',
    getInitialValueInEffect: false,
  });

  const sgEventId = parseInt(selectedEvent?.eventId ?? 'invalid');

  const {
    data: seatGeekData,
    isFetching: isFetchingSeatGeek,
    refetch: refetchSeatGeek,
  } = useGetDtiEventsEventIdMarketplaceSeatgeek(sgEventId, {
    query: {
      enabled: sgEventId > 0 && resultsSelection === 'Sales',
      select: (data) => data.data.data,
    },
  });

  const salesRaw = useMemo(() => seatGeekData?.sales || [], [seatGeekData]);
  const seatingChartFilters = useAtomValue(seatingChartFiltersAtom);
  const sales = useMemo(() => filterSeatGeekSales(salesRaw, seatingChartFilters), [salesRaw, seatingChartFilters]);

  const salesTotals = useMemo(
    () => ({
      totalListings: salesRaw.length ?? 0,
      totalFilteredListings: sales.length,
      totalTickets: salesRaw.reduce((acc, listing) => acc + (listing.quantity || 0), 0),
      totalFilteredTickets: sales.reduce((acc, listing) => acc + (listing.quantity || 0), 0),
    }),
    [salesRaw, sales],
  );

  return {
    sales,
    salesTotals,
    isFetchingSeatGeek,
    refetchSeatGeek,
    resultsSelection,
    setResultsSelection,
  };
};

export const { Provider: DTIMarketplaceStateProvider, useSelector: useDTIMarketplace } = yasml(DTIMarketplaceState);
