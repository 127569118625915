import { useMutation, useQuery } from '@tanstack/react-query';
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  BarkerCoreEnumsMarketplace,
  BarkerCoreModelsAdministrativeCreateMarketplaceRequest,
  BarkerCoreModelsAdministrativeCreateTenantRequest,
  BarkerCoreModelsAdministrativeCreateUserInviteRequest,
  BarkerCoreModelsAdministrativeCreateUserRequest,
  BarkerCoreModelsAdministrativeTenant,
  BarkerCoreModelsAdministrativeTenantAutomator,
  BarkerCoreModelsAdministrativeTenantDetails,
  BarkerCoreModelsAdministrativeTenantMarketplace,
  BarkerCoreModelsAdministrativeTenantPointOfSale,
  BarkerCoreModelsAdministrativeTenantPricerSettings,
  BarkerCoreModelsAdministrativeUpdateAutomatorRequest,
  BarkerCoreModelsAdministrativeUpdatePointOfSaleRequest,
  BarkerCoreModelsAdministrativeUpdateUserRequest,
  BarkerCoreModelsAdministrativeUserDetails,
  BarkerCoreModelsAdministrativeUserInvite,
  SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull,
} from './models';

export const getApiTenants = (options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantDetails[]>> => {
  return axios.get(`/api/Tenants`, options);
};

export const getGetApiTenantsQueryKey = () => {
  return [`/api/Tenants`] as const;
};

export const getGetApiTenantsQueryOptions = <TData = Awaited<ReturnType<typeof getApiTenants>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenants>>, TError, TData>;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiTenantsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTenants>>> = ({ signal }) => getApiTenants({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiTenants>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTenants>>>;
export type GetApiTenantsQueryError = AxiosError<void>;

export const useGetApiTenants = <TData = Awaited<ReturnType<typeof getApiTenants>>, TError = AxiosError<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenants>>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTenantsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiTenants = (
  barkerCoreModelsAdministrativeCreateTenantRequest: BarkerCoreModelsAdministrativeCreateTenantRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenant>> => {
  return axios.post(`/api/Tenants`, barkerCoreModelsAdministrativeCreateTenantRequest, options);
};

export const getPostApiTenantsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiTenants>>, TError, { data: BarkerCoreModelsAdministrativeCreateTenantRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiTenants>>, TError, { data: BarkerCoreModelsAdministrativeCreateTenantRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiTenants>>, { data: BarkerCoreModelsAdministrativeCreateTenantRequest }> = (props) => {
    const { data } = props ?? {};

    return postApiTenants(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiTenantsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTenants>>>;
export type PostApiTenantsMutationBody = BarkerCoreModelsAdministrativeCreateTenantRequest;
export type PostApiTenantsMutationError = AxiosError<void>;

export const usePostApiTenants = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiTenants>>, TError, { data: BarkerCoreModelsAdministrativeCreateTenantRequest }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiTenants>>, TError, { data: BarkerCoreModelsAdministrativeCreateTenantRequest }, TContext> => {
  const mutationOptions = getPostApiTenantsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiTenantsTenantId = (tenantId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenant>> => {
  return axios.get(`/api/Tenants/${tenantId}`, options);
};

export const getGetApiTenantsTenantIdQueryKey = (tenantId: string) => {
  return [`/api/Tenants/${tenantId}`] as const;
};

export const getGetApiTenantsTenantIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiTenantsTenantId>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiTenantsTenantIdQueryKey(tenantId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTenantsTenantId>>> = ({ signal }) => getApiTenantsTenantId(tenantId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!tenantId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantId>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiTenantsTenantIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTenantsTenantId>>>;
export type GetApiTenantsTenantIdQueryError = AxiosError<void>;

export const useGetApiTenantsTenantId = <TData = Awaited<ReturnType<typeof getApiTenantsTenantId>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTenantsTenantIdQueryOptions(tenantId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const patchApiTenantsTenantId = (
  tenantId: string,
  systemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenant>> => {
  return axios.patch(
    `/api/Tenants/${tenantId}`,
    systemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull,
    options,
  );
};

export const getPatchApiTenantsTenantIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiTenantsTenantId>>,
    TError,
    { tenantId: string; data: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchApiTenantsTenantId>>,
  TError,
  { tenantId: string; data: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchApiTenantsTenantId>>,
    { tenantId: string; data: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull }
  > = (props) => {
    const { tenantId, data } = props ?? {};

    return patchApiTenantsTenantId(tenantId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiTenantsTenantIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiTenantsTenantId>>>;
export type PatchApiTenantsTenantIdMutationBody = SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull;
export type PatchApiTenantsTenantIdMutationError = AxiosError<void>;

export const usePatchApiTenantsTenantId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchApiTenantsTenantId>>,
    TError,
    { tenantId: string; data: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchApiTenantsTenantId>>,
  TError,
  { tenantId: string; data: SystemTextJsonPatchJsonPatchDocument1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull },
  TContext
> => {
  const mutationOptions = getPatchApiTenantsTenantIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiTenantsTenantIdPOS = (tenantId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantPointOfSale>> => {
  return axios.get(`/api/Tenants/${tenantId}/POS`, options);
};

export const getGetApiTenantsTenantIdPOSQueryKey = (tenantId: string) => {
  return [`/api/Tenants/${tenantId}/POS`] as const;
};

export const getGetApiTenantsTenantIdPOSQueryOptions = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdPOS>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdPOS>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiTenantsTenantIdPOSQueryKey(tenantId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTenantsTenantIdPOS>>> = ({ signal }) => getApiTenantsTenantIdPOS(tenantId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!tenantId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdPOS>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiTenantsTenantIdPOSQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTenantsTenantIdPOS>>>;
export type GetApiTenantsTenantIdPOSQueryError = AxiosError<void>;

export const useGetApiTenantsTenantIdPOS = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdPOS>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdPOS>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTenantsTenantIdPOSQueryOptions(tenantId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiTenantsTenantIdPOS = (
  tenantId: string,
  barkerCoreModelsAdministrativeUpdatePointOfSaleRequest: BarkerCoreModelsAdministrativeUpdatePointOfSaleRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantPointOfSale>> => {
  return axios.put(`/api/Tenants/${tenantId}/POS`, barkerCoreModelsAdministrativeUpdatePointOfSaleRequest, options);
};

export const getPutApiTenantsTenantIdPOSMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdPOS>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeUpdatePointOfSaleRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiTenantsTenantIdPOS>>,
  TError,
  { tenantId: string; data: BarkerCoreModelsAdministrativeUpdatePointOfSaleRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiTenantsTenantIdPOS>>, { tenantId: string; data: BarkerCoreModelsAdministrativeUpdatePointOfSaleRequest }> = (
    props,
  ) => {
    const { tenantId, data } = props ?? {};

    return putApiTenantsTenantIdPOS(tenantId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiTenantsTenantIdPOSMutationResult = NonNullable<Awaited<ReturnType<typeof putApiTenantsTenantIdPOS>>>;
export type PutApiTenantsTenantIdPOSMutationBody = BarkerCoreModelsAdministrativeUpdatePointOfSaleRequest;
export type PutApiTenantsTenantIdPOSMutationError = AxiosError<void>;

export const usePutApiTenantsTenantIdPOS = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdPOS>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeUpdatePointOfSaleRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiTenantsTenantIdPOS>>,
  TError,
  { tenantId: string; data: BarkerCoreModelsAdministrativeUpdatePointOfSaleRequest },
  TContext
> => {
  const mutationOptions = getPutApiTenantsTenantIdPOSMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiTenantsTenantIdAutomator = (tenantId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantAutomator>> => {
  return axios.get(`/api/Tenants/${tenantId}/Automator`, options);
};

export const getGetApiTenantsTenantIdAutomatorQueryKey = (tenantId: string) => {
  return [`/api/Tenants/${tenantId}/Automator`] as const;
};

export const getGetApiTenantsTenantIdAutomatorQueryOptions = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdAutomator>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdAutomator>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiTenantsTenantIdAutomatorQueryKey(tenantId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTenantsTenantIdAutomator>>> = ({ signal }) => getApiTenantsTenantIdAutomator(tenantId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!tenantId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdAutomator>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiTenantsTenantIdAutomatorQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTenantsTenantIdAutomator>>>;
export type GetApiTenantsTenantIdAutomatorQueryError = AxiosError<void>;

export const useGetApiTenantsTenantIdAutomator = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdAutomator>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdAutomator>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTenantsTenantIdAutomatorQueryOptions(tenantId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiTenantsTenantIdAutomator = (
  tenantId: string,
  barkerCoreModelsAdministrativeUpdateAutomatorRequest: BarkerCoreModelsAdministrativeUpdateAutomatorRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantAutomator>> => {
  return axios.put(`/api/Tenants/${tenantId}/Automator`, barkerCoreModelsAdministrativeUpdateAutomatorRequest, options);
};

export const getPutApiTenantsTenantIdAutomatorMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdAutomator>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeUpdateAutomatorRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiTenantsTenantIdAutomator>>,
  TError,
  { tenantId: string; data: BarkerCoreModelsAdministrativeUpdateAutomatorRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiTenantsTenantIdAutomator>>,
    { tenantId: string; data: BarkerCoreModelsAdministrativeUpdateAutomatorRequest }
  > = (props) => {
    const { tenantId, data } = props ?? {};

    return putApiTenantsTenantIdAutomator(tenantId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiTenantsTenantIdAutomatorMutationResult = NonNullable<Awaited<ReturnType<typeof putApiTenantsTenantIdAutomator>>>;
export type PutApiTenantsTenantIdAutomatorMutationBody = BarkerCoreModelsAdministrativeUpdateAutomatorRequest;
export type PutApiTenantsTenantIdAutomatorMutationError = AxiosError<void>;

export const usePutApiTenantsTenantIdAutomator = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdAutomator>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeUpdateAutomatorRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiTenantsTenantIdAutomator>>,
  TError,
  { tenantId: string; data: BarkerCoreModelsAdministrativeUpdateAutomatorRequest },
  TContext
> => {
  const mutationOptions = getPutApiTenantsTenantIdAutomatorMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiTenantsTenantIdMarketplaces = (tenantId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantMarketplace[]>> => {
  return axios.get(`/api/Tenants/${tenantId}/Marketplaces`, options);
};

export const getGetApiTenantsTenantIdMarketplacesQueryKey = (tenantId: string) => {
  return [`/api/Tenants/${tenantId}/Marketplaces`] as const;
};

export const getGetApiTenantsTenantIdMarketplacesQueryOptions = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdMarketplaces>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdMarketplaces>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiTenantsTenantIdMarketplacesQueryKey(tenantId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTenantsTenantIdMarketplaces>>> = ({ signal }) =>
    getApiTenantsTenantIdMarketplaces(tenantId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!tenantId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdMarketplaces>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiTenantsTenantIdMarketplacesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTenantsTenantIdMarketplaces>>>;
export type GetApiTenantsTenantIdMarketplacesQueryError = AxiosError<void>;

export const useGetApiTenantsTenantIdMarketplaces = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdMarketplaces>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdMarketplaces>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTenantsTenantIdMarketplacesQueryOptions(tenantId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiTenantsTenantIdMarketplaces = (
  tenantId: string,
  barkerCoreModelsAdministrativeCreateMarketplaceRequest: BarkerCoreModelsAdministrativeCreateMarketplaceRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantMarketplace>> => {
  return axios.post(`/api/Tenants/${tenantId}/Marketplaces`, barkerCoreModelsAdministrativeCreateMarketplaceRequest, options);
};

export const getPostApiTenantsTenantIdMarketplacesMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiTenantsTenantIdMarketplaces>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeCreateMarketplaceRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiTenantsTenantIdMarketplaces>>,
  TError,
  { tenantId: string; data: BarkerCoreModelsAdministrativeCreateMarketplaceRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiTenantsTenantIdMarketplaces>>,
    { tenantId: string; data: BarkerCoreModelsAdministrativeCreateMarketplaceRequest }
  > = (props) => {
    const { tenantId, data } = props ?? {};

    return postApiTenantsTenantIdMarketplaces(tenantId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiTenantsTenantIdMarketplacesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTenantsTenantIdMarketplaces>>>;
export type PostApiTenantsTenantIdMarketplacesMutationBody = BarkerCoreModelsAdministrativeCreateMarketplaceRequest;
export type PostApiTenantsTenantIdMarketplacesMutationError = AxiosError<void>;

export const usePostApiTenantsTenantIdMarketplaces = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiTenantsTenantIdMarketplaces>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeCreateMarketplaceRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiTenantsTenantIdMarketplaces>>,
  TError,
  { tenantId: string; data: BarkerCoreModelsAdministrativeCreateMarketplaceRequest },
  TContext
> => {
  const mutationOptions = getPostApiTenantsTenantIdMarketplacesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiTenantsTenantIdMarketplacesMarketplaceIdSettings = (
  tenantId: string,
  marketplaceId: BarkerCoreEnumsMarketplace,
  putApiTenantsTenantIdMarketplacesMarketplaceIdSettingsBody: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantMarketplace>> => {
  return axios.put(`/api/Tenants/${tenantId}/Marketplaces/${marketplaceId}/Settings`, putApiTenantsTenantIdMarketplacesMarketplaceIdSettingsBody, options);
};

export const getPutApiTenantsTenantIdMarketplacesMarketplaceIdSettingsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdSettings>>,
    TError,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdSettings>>,
  TError,
  { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdSettings>>,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string }
  > = (props) => {
    const { tenantId, marketplaceId, data } = props ?? {};

    return putApiTenantsTenantIdMarketplacesMarketplaceIdSettings(tenantId, marketplaceId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiTenantsTenantIdMarketplacesMarketplaceIdSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdSettings>>>;
export type PutApiTenantsTenantIdMarketplacesMarketplaceIdSettingsMutationBody = string;
export type PutApiTenantsTenantIdMarketplacesMarketplaceIdSettingsMutationError = AxiosError<void>;

export const usePutApiTenantsTenantIdMarketplacesMarketplaceIdSettings = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdSettings>>,
    TError,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdSettings>>,
  TError,
  { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
  TContext
> => {
  const mutationOptions = getPutApiTenantsTenantIdMarketplacesMarketplaceIdSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiTenantsTenantIdMarketplacesMarketplaceIdDeactivate = (
  tenantId: string,
  marketplaceId: BarkerCoreEnumsMarketplace,
  putApiTenantsTenantIdMarketplacesMarketplaceIdDeactivateBody: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Tenants/${tenantId}/Marketplaces/${marketplaceId}/Deactivate`, putApiTenantsTenantIdMarketplacesMarketplaceIdDeactivateBody, options);
};

export const getPutApiTenantsTenantIdMarketplacesMarketplaceIdDeactivateMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdDeactivate>>,
    TError,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdDeactivate>>,
  TError,
  { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdDeactivate>>,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string }
  > = (props) => {
    const { tenantId, marketplaceId, data } = props ?? {};

    return putApiTenantsTenantIdMarketplacesMarketplaceIdDeactivate(tenantId, marketplaceId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiTenantsTenantIdMarketplacesMarketplaceIdDeactivateMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdDeactivate>>
>;
export type PutApiTenantsTenantIdMarketplacesMarketplaceIdDeactivateMutationBody = string;
export type PutApiTenantsTenantIdMarketplacesMarketplaceIdDeactivateMutationError = AxiosError<void>;

export const usePutApiTenantsTenantIdMarketplacesMarketplaceIdDeactivate = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdDeactivate>>,
    TError,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdDeactivate>>,
  TError,
  { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
  TContext
> => {
  const mutationOptions = getPutApiTenantsTenantIdMarketplacesMarketplaceIdDeactivateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiTenantsTenantIdMarketplacesMarketplaceIdActivate = (
  tenantId: string,
  marketplaceId: BarkerCoreEnumsMarketplace,
  putApiTenantsTenantIdMarketplacesMarketplaceIdActivateBody: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.put(`/api/Tenants/${tenantId}/Marketplaces/${marketplaceId}/Activate`, putApiTenantsTenantIdMarketplacesMarketplaceIdActivateBody, options);
};

export const getPutApiTenantsTenantIdMarketplacesMarketplaceIdActivateMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdActivate>>,
    TError,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdActivate>>,
  TError,
  { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdActivate>>,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string }
  > = (props) => {
    const { tenantId, marketplaceId, data } = props ?? {};

    return putApiTenantsTenantIdMarketplacesMarketplaceIdActivate(tenantId, marketplaceId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiTenantsTenantIdMarketplacesMarketplaceIdActivateMutationResult = NonNullable<Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdActivate>>>;
export type PutApiTenantsTenantIdMarketplacesMarketplaceIdActivateMutationBody = string;
export type PutApiTenantsTenantIdMarketplacesMarketplaceIdActivateMutationError = AxiosError<void>;

export const usePutApiTenantsTenantIdMarketplacesMarketplaceIdActivate = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdActivate>>,
    TError,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiTenantsTenantIdMarketplacesMarketplaceIdActivate>>,
  TError,
  { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace; data: string },
  TContext
> => {
  const mutationOptions = getPutApiTenantsTenantIdMarketplacesMarketplaceIdActivateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteApiTenantsTenantIdMarketplacesMarketplaceId = (
  tenantId: string,
  marketplaceId: BarkerCoreEnumsMarketplace,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/Tenants/${tenantId}/Marketplaces/${marketplaceId}`, options);
};

export const getDeleteApiTenantsTenantIdMarketplacesMarketplaceIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiTenantsTenantIdMarketplacesMarketplaceId>>,
    TError,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiTenantsTenantIdMarketplacesMarketplaceId>>,
  TError,
  { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteApiTenantsTenantIdMarketplacesMarketplaceId>>,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace }
  > = (props) => {
    const { tenantId, marketplaceId } = props ?? {};

    return deleteApiTenantsTenantIdMarketplacesMarketplaceId(tenantId, marketplaceId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiTenantsTenantIdMarketplacesMarketplaceIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiTenantsTenantIdMarketplacesMarketplaceId>>>;

export type DeleteApiTenantsTenantIdMarketplacesMarketplaceIdMutationError = AxiosError<void>;

export const useDeleteApiTenantsTenantIdMarketplacesMarketplaceId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiTenantsTenantIdMarketplacesMarketplaceId>>,
    TError,
    { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteApiTenantsTenantIdMarketplacesMarketplaceId>>,
  TError,
  { tenantId: string; marketplaceId: BarkerCoreEnumsMarketplace },
  TContext
> => {
  const mutationOptions = getDeleteApiTenantsTenantIdMarketplacesMarketplaceIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiTenantsTenantIdUsers = (tenantId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeUserDetails[]>> => {
  return axios.get(`/api/Tenants/${tenantId}/Users`, options);
};

export const getGetApiTenantsTenantIdUsersQueryKey = (tenantId: string) => {
  return [`/api/Tenants/${tenantId}/Users`] as const;
};

export const getGetApiTenantsTenantIdUsersQueryOptions = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdUsers>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdUsers>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiTenantsTenantIdUsersQueryKey(tenantId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTenantsTenantIdUsers>>> = ({ signal }) => getApiTenantsTenantIdUsers(tenantId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!tenantId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdUsers>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiTenantsTenantIdUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTenantsTenantIdUsers>>>;
export type GetApiTenantsTenantIdUsersQueryError = AxiosError<void>;

export const useGetApiTenantsTenantIdUsers = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdUsers>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdUsers>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTenantsTenantIdUsersQueryOptions(tenantId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiTenantsTenantIdUsers = (
  tenantId: string,
  barkerCoreModelsAdministrativeCreateUserRequest: BarkerCoreModelsAdministrativeCreateUserRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeUserDetails>> => {
  return axios.post(`/api/Tenants/${tenantId}/Users`, barkerCoreModelsAdministrativeCreateUserRequest, options);
};

export const getPostApiTenantsTenantIdUsersMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiTenantsTenantIdUsers>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeCreateUserRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiTenantsTenantIdUsers>>, TError, { tenantId: string; data: BarkerCoreModelsAdministrativeCreateUserRequest }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiTenantsTenantIdUsers>>, { tenantId: string; data: BarkerCoreModelsAdministrativeCreateUserRequest }> = (
    props,
  ) => {
    const { tenantId, data } = props ?? {};

    return postApiTenantsTenantIdUsers(tenantId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiTenantsTenantIdUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTenantsTenantIdUsers>>>;
export type PostApiTenantsTenantIdUsersMutationBody = BarkerCoreModelsAdministrativeCreateUserRequest;
export type PostApiTenantsTenantIdUsersMutationError = AxiosError<void>;

export const usePostApiTenantsTenantIdUsers = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiTenantsTenantIdUsers>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeCreateUserRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof postApiTenantsTenantIdUsers>>, TError, { tenantId: string; data: BarkerCoreModelsAdministrativeCreateUserRequest }, TContext> => {
  const mutationOptions = getPostApiTenantsTenantIdUsersMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiTenantsTenantIdUsersUserId = (
  tenantId: string,
  userId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeUserDetails>> => {
  return axios.get(`/api/Tenants/${tenantId}/Users/${userId}`, options);
};

export const getGetApiTenantsTenantIdUsersUserIdQueryKey = (tenantId: string, userId: string) => {
  return [`/api/Tenants/${tenantId}/Users/${userId}`] as const;
};

export const getGetApiTenantsTenantIdUsersUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdUsersUserId>>, TError = AxiosError<void>>(
  tenantId: string,
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdUsersUserId>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiTenantsTenantIdUsersUserIdQueryKey(tenantId, userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTenantsTenantIdUsersUserId>>> = ({ signal }) =>
    getApiTenantsTenantIdUsersUserId(tenantId, userId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(tenantId && userId), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdUsersUserId>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiTenantsTenantIdUsersUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTenantsTenantIdUsersUserId>>>;
export type GetApiTenantsTenantIdUsersUserIdQueryError = AxiosError<void>;

export const useGetApiTenantsTenantIdUsersUserId = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdUsersUserId>>, TError = AxiosError<void>>(
  tenantId: string,
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdUsersUserId>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTenantsTenantIdUsersUserIdQueryOptions(tenantId, userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiTenantsTenantIdUsersUserId = (
  tenantId: string,
  userId: string,
  barkerCoreModelsAdministrativeUpdateUserRequest: BarkerCoreModelsAdministrativeUpdateUserRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeUserDetails>> => {
  return axios.put(`/api/Tenants/${tenantId}/Users/${userId}`, barkerCoreModelsAdministrativeUpdateUserRequest, options);
};

export const getPutApiTenantsTenantIdUsersUserIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdUsersUserId>>,
    TError,
    { tenantId: string; userId: string; data: BarkerCoreModelsAdministrativeUpdateUserRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiTenantsTenantIdUsersUserId>>,
  TError,
  { tenantId: string; userId: string; data: BarkerCoreModelsAdministrativeUpdateUserRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiTenantsTenantIdUsersUserId>>,
    { tenantId: string; userId: string; data: BarkerCoreModelsAdministrativeUpdateUserRequest }
  > = (props) => {
    const { tenantId, userId, data } = props ?? {};

    return putApiTenantsTenantIdUsersUserId(tenantId, userId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiTenantsTenantIdUsersUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiTenantsTenantIdUsersUserId>>>;
export type PutApiTenantsTenantIdUsersUserIdMutationBody = BarkerCoreModelsAdministrativeUpdateUserRequest;
export type PutApiTenantsTenantIdUsersUserIdMutationError = AxiosError<void>;

export const usePutApiTenantsTenantIdUsersUserId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdUsersUserId>>,
    TError,
    { tenantId: string; userId: string; data: BarkerCoreModelsAdministrativeUpdateUserRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiTenantsTenantIdUsersUserId>>,
  TError,
  { tenantId: string; userId: string; data: BarkerCoreModelsAdministrativeUpdateUserRequest },
  TContext
> => {
  const mutationOptions = getPutApiTenantsTenantIdUsersUserIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteApiTenantsTenantIdUsersUserId = (tenantId: string, userId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/Tenants/${tenantId}/Users/${userId}`, options);
};

export const getDeleteApiTenantsTenantIdUsersUserIdMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiTenantsTenantIdUsersUserId>>, TError, { tenantId: string; userId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiTenantsTenantIdUsersUserId>>, TError, { tenantId: string; userId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiTenantsTenantIdUsersUserId>>, { tenantId: string; userId: string }> = (props) => {
    const { tenantId, userId } = props ?? {};

    return deleteApiTenantsTenantIdUsersUserId(tenantId, userId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteApiTenantsTenantIdUsersUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiTenantsTenantIdUsersUserId>>>;

export type DeleteApiTenantsTenantIdUsersUserIdMutationError = AxiosError<void>;

export const useDeleteApiTenantsTenantIdUsersUserId = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiTenantsTenantIdUsersUserId>>, TError, { tenantId: string; userId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof deleteApiTenantsTenantIdUsersUserId>>, TError, { tenantId: string; userId: string }, TContext> => {
  const mutationOptions = getDeleteApiTenantsTenantIdUsersUserIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiTenantsTenantIdUsersSelf = (tenantId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeUserDetails>> => {
  return axios.get(`/api/Tenants/${tenantId}/Users/Self`, options);
};

export const getGetApiTenantsTenantIdUsersSelfQueryKey = (tenantId: string) => {
  return [`/api/Tenants/${tenantId}/Users/Self`] as const;
};

export const getGetApiTenantsTenantIdUsersSelfQueryOptions = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdUsersSelf>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdUsersSelf>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiTenantsTenantIdUsersSelfQueryKey(tenantId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTenantsTenantIdUsersSelf>>> = ({ signal }) => getApiTenantsTenantIdUsersSelf(tenantId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!tenantId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdUsersSelf>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiTenantsTenantIdUsersSelfQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTenantsTenantIdUsersSelf>>>;
export type GetApiTenantsTenantIdUsersSelfQueryError = AxiosError<void>;

export const useGetApiTenantsTenantIdUsersSelf = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdUsersSelf>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdUsersSelf>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTenantsTenantIdUsersSelfQueryOptions(tenantId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getApiTenantsTenantIdInvites = (tenantId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeUserInvite[]>> => {
  return axios.get(`/api/Tenants/${tenantId}/Invites`, options);
};

export const getGetApiTenantsTenantIdInvitesQueryKey = (tenantId: string) => {
  return [`/api/Tenants/${tenantId}/Invites`] as const;
};

export const getGetApiTenantsTenantIdInvitesQueryOptions = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdInvites>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdInvites>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiTenantsTenantIdInvitesQueryKey(tenantId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTenantsTenantIdInvites>>> = ({ signal }) => getApiTenantsTenantIdInvites(tenantId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!tenantId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdInvites>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiTenantsTenantIdInvitesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTenantsTenantIdInvites>>>;
export type GetApiTenantsTenantIdInvitesQueryError = AxiosError<void>;

export const useGetApiTenantsTenantIdInvites = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdInvites>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdInvites>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTenantsTenantIdInvitesQueryOptions(tenantId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postApiTenantsTenantIdInvites = (
  tenantId: string,
  barkerCoreModelsAdministrativeCreateUserInviteRequest: BarkerCoreModelsAdministrativeCreateUserInviteRequest,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeUserInvite>> => {
  return axios.post(`/api/Tenants/${tenantId}/Invites`, barkerCoreModelsAdministrativeCreateUserInviteRequest, options);
};

export const getPostApiTenantsTenantIdInvitesMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiTenantsTenantIdInvites>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeCreateUserInviteRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiTenantsTenantIdInvites>>,
  TError,
  { tenantId: string; data: BarkerCoreModelsAdministrativeCreateUserInviteRequest },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiTenantsTenantIdInvites>>,
    { tenantId: string; data: BarkerCoreModelsAdministrativeCreateUserInviteRequest }
  > = (props) => {
    const { tenantId, data } = props ?? {};

    return postApiTenantsTenantIdInvites(tenantId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiTenantsTenantIdInvitesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiTenantsTenantIdInvites>>>;
export type PostApiTenantsTenantIdInvitesMutationBody = BarkerCoreModelsAdministrativeCreateUserInviteRequest;
export type PostApiTenantsTenantIdInvitesMutationError = AxiosError<void>;

export const usePostApiTenantsTenantIdInvites = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiTenantsTenantIdInvites>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeCreateUserInviteRequest },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiTenantsTenantIdInvites>>,
  TError,
  { tenantId: string; data: BarkerCoreModelsAdministrativeCreateUserInviteRequest },
  TContext
> => {
  const mutationOptions = getPostApiTenantsTenantIdInvitesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiTenantsTenantIdInvitesInviteIdAccept = (
  tenantId: string,
  inviteId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeUserInvite>> => {
  return axios.put(`/api/Tenants/${tenantId}/Invites/${inviteId}/Accept`, undefined, options);
};

export const getPutApiTenantsTenantIdInvitesInviteIdAcceptMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdAccept>>, TError, { tenantId: string; inviteId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdAccept>>, TError, { tenantId: string; inviteId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdAccept>>, { tenantId: string; inviteId: string }> = (props) => {
    const { tenantId, inviteId } = props ?? {};

    return putApiTenantsTenantIdInvitesInviteIdAccept(tenantId, inviteId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiTenantsTenantIdInvitesInviteIdAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdAccept>>>;

export type PutApiTenantsTenantIdInvitesInviteIdAcceptMutationError = AxiosError<void>;

export const usePutApiTenantsTenantIdInvitesInviteIdAccept = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdAccept>>, TError, { tenantId: string; inviteId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdAccept>>, TError, { tenantId: string; inviteId: string }, TContext> => {
  const mutationOptions = getPutApiTenantsTenantIdInvitesInviteIdAcceptMutationOptions(options);

  return useMutation(mutationOptions);
};
export const putApiTenantsTenantIdInvitesInviteIdDecline = (
  tenantId: string,
  inviteId: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeUserInvite>> => {
  return axios.put(`/api/Tenants/${tenantId}/Invites/${inviteId}/Decline`, undefined, options);
};

export const getPutApiTenantsTenantIdInvitesInviteIdDeclineMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdDecline>>, TError, { tenantId: string; inviteId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdDecline>>, TError, { tenantId: string; inviteId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdDecline>>, { tenantId: string; inviteId: string }> = (props) => {
    const { tenantId, inviteId } = props ?? {};

    return putApiTenantsTenantIdInvitesInviteIdDecline(tenantId, inviteId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiTenantsTenantIdInvitesInviteIdDeclineMutationResult = NonNullable<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdDecline>>>;

export type PutApiTenantsTenantIdInvitesInviteIdDeclineMutationError = AxiosError<void>;

export const usePutApiTenantsTenantIdInvitesInviteIdDecline = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdDecline>>, TError, { tenantId: string; inviteId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof putApiTenantsTenantIdInvitesInviteIdDecline>>, TError, { tenantId: string; inviteId: string }, TContext> => {
  const mutationOptions = getPutApiTenantsTenantIdInvitesInviteIdDeclineMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApiTenantsTenantIdSettingsPricer = (tenantId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantPricerSettings>> => {
  return axios.get(`/api/Tenants/${tenantId}/Settings/Pricer`, options);
};

export const getGetApiTenantsTenantIdSettingsPricerQueryKey = (tenantId: string) => {
  return [`/api/Tenants/${tenantId}/Settings/Pricer`] as const;
};

export const getGetApiTenantsTenantIdSettingsPricerQueryOptions = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdSettingsPricer>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdSettingsPricer>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiTenantsTenantIdSettingsPricerQueryKey(tenantId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTenantsTenantIdSettingsPricer>>> = ({ signal }) =>
    getApiTenantsTenantIdSettingsPricer(tenantId, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!tenantId, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdSettingsPricer>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApiTenantsTenantIdSettingsPricerQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTenantsTenantIdSettingsPricer>>>;
export type GetApiTenantsTenantIdSettingsPricerQueryError = AxiosError<void>;

export const useGetApiTenantsTenantIdSettingsPricer = <TData = Awaited<ReturnType<typeof getApiTenantsTenantIdSettingsPricer>>, TError = AxiosError<void>>(
  tenantId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTenantsTenantIdSettingsPricer>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTenantsTenantIdSettingsPricerQueryOptions(tenantId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putApiTenantsTenantIdSettingsPricer = (
  tenantId: string,
  barkerCoreModelsAdministrativeTenantPricerSettings: BarkerCoreModelsAdministrativeTenantPricerSettings,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<BarkerCoreModelsAdministrativeTenantPricerSettings>> => {
  return axios.put(`/api/Tenants/${tenantId}/Settings/Pricer`, barkerCoreModelsAdministrativeTenantPricerSettings, options);
};

export const getPutApiTenantsTenantIdSettingsPricerMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdSettingsPricer>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeTenantPricerSettings },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiTenantsTenantIdSettingsPricer>>,
  TError,
  { tenantId: string; data: BarkerCoreModelsAdministrativeTenantPricerSettings },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiTenantsTenantIdSettingsPricer>>,
    { tenantId: string; data: BarkerCoreModelsAdministrativeTenantPricerSettings }
  > = (props) => {
    const { tenantId, data } = props ?? {};

    return putApiTenantsTenantIdSettingsPricer(tenantId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutApiTenantsTenantIdSettingsPricerMutationResult = NonNullable<Awaited<ReturnType<typeof putApiTenantsTenantIdSettingsPricer>>>;
export type PutApiTenantsTenantIdSettingsPricerMutationBody = BarkerCoreModelsAdministrativeTenantPricerSettings;
export type PutApiTenantsTenantIdSettingsPricerMutationError = AxiosError<void>;

export const usePutApiTenantsTenantIdSettingsPricer = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiTenantsTenantIdSettingsPricer>>,
    TError,
    { tenantId: string; data: BarkerCoreModelsAdministrativeTenantPricerSettings },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof putApiTenantsTenantIdSettingsPricer>>,
  TError,
  { tenantId: string; data: BarkerCoreModelsAdministrativeTenantPricerSettings },
  TContext
> => {
  const mutationOptions = getPutApiTenantsTenantIdSettingsPricerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const patchApiTenantsTenantIdPOSTestSettings = (tenantId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> => {
  return axios.patch(`/api/Tenants/${tenantId}/POS/TestSettings`, undefined, options);
};

export const getPatchApiTenantsTenantIdPOSTestSettingsMutationOptions = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof patchApiTenantsTenantIdPOSTestSettings>>, TError, { tenantId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<Awaited<ReturnType<typeof patchApiTenantsTenantIdPOSTestSettings>>, TError, { tenantId: string }, TContext> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiTenantsTenantIdPOSTestSettings>>, { tenantId: string }> = (props) => {
    const { tenantId } = props ?? {};

    return patchApiTenantsTenantIdPOSTestSettings(tenantId, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchApiTenantsTenantIdPOSTestSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiTenantsTenantIdPOSTestSettings>>>;

export type PatchApiTenantsTenantIdPOSTestSettingsMutationError = AxiosError<void>;

export const usePatchApiTenantsTenantIdPOSTestSettings = <TError = AxiosError<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof patchApiTenantsTenantIdPOSTestSettings>>, TError, { tenantId: string }, TContext>;
  axios?: AxiosRequestConfig;
}): UseMutationResult<Awaited<ReturnType<typeof patchApiTenantsTenantIdPOSTestSettings>>, TError, { tenantId: string }, TContext> => {
  const mutationOptions = getPatchApiTenantsTenantIdPOSTestSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};
