import { Paper } from '@mantine/core';
import { MarketListingProvider } from './MarketListings.hooks';
import classes from './MarketListings.styles.tsx.module.css';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import { DTIMarketplaceStateProvider } from '../DTI/DTI.Marketplace.hooks';
import { MarketListingsHeader } from './MarketListings.Header';
import { MarketListingsList } from './MarketListings.List';
import { MarketplaceResultsContainer } from '../MarketplaceResults/MarketplaceResultsContainer';

export function MarketListings() {
  return (
    <Paper className={classes.marketListingsWrapper}>
      {isDtiHosted ? (
        <DTIMarketplaceStateProvider>
          <MarketListingProvider>
            <MarketplaceResultsContainer />
          </MarketListingProvider>
        </DTIMarketplaceStateProvider>
      ) : (
        <MarketListingProvider>
          <MarketListingsHeader />
          <MarketListingsList />
        </MarketListingProvider>
      )}
    </Paper>
  );
}
