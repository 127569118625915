import { Box, Center, Collapse, Group, Indicator, List, MultiSelect, Popover, Text, Textarea, Tooltip } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import PriceChangeIcon from '../../components/icons/PriceChange';
import SelectArrowsIcon from '../../components/icons/SelectArrows';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import { useInventoryHeader } from './Inventory.Header.hooks';
import { useBulkState } from '../../data/BulkState';
import { useToggle } from '@mantine/hooks';
import { BNGridCheckbox } from '../../components/GridCheckbox/GridCheckbox';
import { Accordion_Edit } from './MultiSelectActionBar/Accordion_Edit';
import { EditPricing } from './MultiSelectActionBar/EditPricing';
import classes from './Inventory.MultiSelectActionbar.styles.tsx.module.css';
import { Grouping } from './MultiSelectActionBar/Grouping';
import DiscoverTuneIcon from '../../components/icons/DiscoverTune';
import VerticalAlignBottomIcon from '../../components/icons/VerticalAlignBottom';
import VerticalAlignTopIcon from '../../components/icons/VerticalAlignTop';
import pluralize from 'pluralize';
import { Admin } from './MultiSelectActionBar/Admin';
import { useFlag } from '@unleash/proxy-client-react';
import CompareArrowsIcon from '../../components/icons/CompareArrows';
import { BNAccordionMenu } from '../../components/Accordion/BNAccordion';
import { useGlobalState } from '../../data/GlobalState';
import { Merge } from './MultiSelectActionBar/Merge';
import { ArrowsJoin } from 'tabler-icons-react';
import WifiIcon from '../../components/icons/Wifi';
import { useState } from 'react';
import WifiOffIcon from '../../components/icons/WifiOff';
import InventoryIcon from '../../components/icons/Inventory';
import { isDtiHosted } from '../../utils/whitelabel-consts';
import ViewWeekIcon from '../../components/icons/ViewWeek';
import TableRowsIcon from '../../components/icons/TableRows';
import LocalActivityIcon from '../../components/icons/LocalActivity';
import RuleIcon from '../../components/icons/Rule';
import ExportNotesIcon from '../../components/icons/ExportNotes';
import CreditCardIcon from '../../components/icons/CreditCard';
import AlternateEmailIcon from '../../components/icons/AlternateEmail';
import StickyNoteIcon from '../../components/icons/StickyNote';
import { BNSelect } from '../../components/Select/Select';
import EditNoteIcon from '../../components/icons/EditNote';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { disclosures_options, stock_options } from '../Purchase/Purchase.hooks';
import { SharingMenuPanel } from '../DTI/Sharing/SharingMenuPanel';

function MultiSelectActionBar() {
  const { currentUser, needsFeatureHint, markFeatureSeen } = useGlobalState('currentUser', 'needsFeatureHint', 'markFeatureSeen');
  const { pendingInlineChanges, showBulkOptions, mergedEventListings, markedListings, toggleBulkSelectAll, selectedListingCount } = useInventoryHeader(
    'pendingInlineChanges',
    'showBulkOptions',
    'mergedEventListings',
    'markedListings',
    'toggleBulkSelectAll',
    'selectedListingCount',
  );
  const {
    isAutoPricingEnabled,
    isPricingEnabled,
    disableAutoPricing,
    onPricingCloseResetPending,
    editAutoPricerAdjustments,
    editAutoPricerCeiling,
    editAutoPricerFloor,
    isMultipleMarketplacesSelected,
    editComparables,
    isMergeEnabled,
    editBroadcasting,
    isBulkActionLoading,
  } = useBulkState(
    'isAutoPricingEnabled',
    'isPricingEnabled',
    'disableAutoPricing',
    'onPricingCloseResetPending',
    'editAutoPricerAdjustments',
    'editAutoPricerCeiling',
    'editAutoPricerFloor',
    'isMultipleMarketplacesSelected',
    'editComparables',
    'selectedListing',
    'isMergeEnabled',
    'editBroadcasting',
    'isBulkActionLoading',
  );
  const [isPriceDialogOpen, toggleNewPriceDialog] = useToggle();
  const [isAutoPriceDialogOpen, toggleAutoPriceDialog] = useToggle();
  const [isInventoryDialogOpen, toggleInventoryDialog] = useToggle();
  const [inventoryMenuValue, setInventoryMenuValue] = useState<string | null>(null);
  const removeRuleFlag = useFlag('admin-remove-rule');
  const needsBulkInventoryEditHint = needsFeatureHint('bulk-inventory-edit');
  const bulkEditInventoryFlag = useFlag('dti-bulk-edit-inventory') && isDtiHosted;

  /* TODO: Stubbed this in for Bulk Edit Properties, will need Jon's help to make it real */
  const [editPropertyValue, setEditPropertyValue] = useState<string | null>('Section');
  type PropertySelectIconProps = {
    iconValue?: string | null;
    color?: string;
  };
  const PropertySelectIcon = ({ iconValue, color }: PropertySelectIconProps) => {
    switch (iconValue) {
      case 'Section':
        return <ViewWeekIcon color={color} />;
      case 'Row':
        return <TableRowsIcon color={color} />;
      case 'Stock':
        return <LocalActivityIcon color={color} />;
      case 'Disclosures':
        return <RuleIcon color={color} />;
      case 'External Notes':
        return <ExportNotesIcon color={color} />;
      case 'CC Info':
        return <CreditCardIcon color={color} />;
      case 'Purchaser':
        return <AlternateEmailIcon color={color} />;
      case 'Internal Notes':
        return <StickyNoteIcon color={color} />;
      default:
        return <ViewWeekIcon color={color} />;
    }
  };

  return (
    <Collapse in={showBulkOptions} transitionDuration={0} animateOpacity={false}>
      <Group
        className={classes.bulkButtonsWrapper}
        bg="var(--colors-selectedBg)"
        wrap="nowrap"
        h={40}
        justify="space-between"
        pl={28}
        pr="md"
        style={{ borderBottom: '1px solid var(--colors-divider)' }}
      >
        <Group gap={6} wrap="nowrap" className={classes.bulkSelectionWrapper}>
          <BNGridCheckbox
            className={classes.checkbox}
            onChange={toggleBulkSelectAll}
            checked
            indeterminate={markedListings.length < mergedEventListings.length}
            size="xs"
            pl={2}
            label={
              <Text truncate>
                <strong>{selectedListingCount}</strong>{' '}
                <Box component="span" className={classes.textTrim}>
                  {pluralize('Listing', selectedListingCount)}{' '}
                </Box>
                Selected
              </Text>
            }
          />
        </Group>
        <Group className={classes.actionButtonWrapper} gap={0} wrap="nowrap" justify="right">
          <Popover
            withArrow
            offset={0}
            opened={isPriceDialogOpen}
            withinPortal={false}
            onClose={() => {
              onPricingCloseResetPending();
              toggleNewPriceDialog(false);
            }}
          >
            <Popover.Target>
              <Tooltip
                label={pendingInlineChanges ? 'Pending inline pricing must be saved first' : 'Cannot reprice auto-priced listings'}
                withArrow
                disabled={!pendingInlineChanges && (isPricingEnabled || currentUser?.roleId === 'ReadOnlyUser')}
              >
                <Box className={classes.actionButtonContainer}>
                  <BNButton
                    c="var(--colors-selectedBorder)"
                    disabled={!isPricingEnabled || pendingInlineChanges}
                    variant="subtle"
                    size="xs"
                    onClick={() => toggleNewPriceDialog()}
                    leftSection={<PriceChangeIcon />}
                    rightSection={<SelectArrowsIcon />}
                    className={classes.actionButton}
                    data-pendo-name="Bulk Pricing Button"
                  >
                    <Text fw={600} component="span" truncate>
                      Pricing
                    </Text>
                  </BNButton>
                </Box>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>{isPriceDialogOpen && <EditPricing toggleNewPriceDialog={toggleNewPriceDialog} />}</Popover.Dropdown>
          </Popover>
          <BNAccordionMenu
            width={332}
            opened={isAutoPriceDialogOpen}
            onClose={() => toggleAutoPriceDialog(false)}
            target={
              <Box>
                {isAutoPricingEnabled && (
                  <BNButton
                    c="var(--colors-selectedBorder)"
                    variant="subtle"
                    disabled={!isAutoPricingEnabled}
                    onClick={() => toggleAutoPriceDialog()}
                    size="xs"
                    leftSection={<AutoPriceIcon size={18} />}
                    rightSection={<SelectArrowsIcon />}
                    className={classes.actionButton}
                  >
                    <Text component="span" fw={600} truncate>
                      Auto-Pricing
                    </Text>
                  </BNButton>
                )}
                {!isAutoPricingEnabled && (
                  <Tooltip label="Cannot edit non-auto-priced listings" withArrow>
                    <Box className={classes.actionButtonContainer}>
                      <BNButton
                        c="var(--colors-selectedBorder)"
                        variant="subtle"
                        disabled={!isAutoPricingEnabled}
                        size="xs"
                        leftSection={<AutoPriceIcon size={18} />}
                        rightSection={<SelectArrowsIcon />}
                        className={classes.actionButton}
                      >
                        <Text fw={600} truncate>
                          Auto-Pricing
                        </Text>
                      </BNButton>
                    </Box>
                  </Tooltip>
                )}
              </Box>
            }
          >
            <BNAccordionMenu.Switch
              title="Auto-Price"
              leftSection={<AutoPriceIcon />}
              disabled={currentUser?.roleId === 'ReadOnlyUser'}
              checked
              onChange={() => {
                toggleAutoPriceDialog(false);
                disableAutoPricing();
              }}
            />
            <Accordion_Edit
              toggleAutoPriceDialog={toggleAutoPriceDialog}
              leftSection={<VerticalAlignBottomIcon />}
              title="Edit Floors"
              itemType="floor"
              updateFn={editAutoPricerFloor}
            />
            <Accordion_Edit
              toggleAutoPriceDialog={toggleAutoPriceDialog}
              disabled={isMultipleMarketplacesSelected}
              errorMessage={<Text size="xs">Cannot edit adjustment with multiple marketplaces selected.</Text>}
              leftSection={<DiscoverTuneIcon />}
              title="Edit Adjustments"
              itemType="adjustment"
              updateFn={editAutoPricerAdjustments}
            />
            <Accordion_Edit
              toggleAutoPriceDialog={toggleAutoPriceDialog}
              leftSection={<VerticalAlignTopIcon />}
              title="Edit Ceilings"
              itemType="ceiling"
              updateFn={editAutoPricerCeiling}
            />
            <Accordion_Edit
              toggleAutoPriceDialog={toggleAutoPriceDialog}
              leftSection={<CompareArrowsIcon />}
              title="Edit # Comparables"
              itemType="comparables"
              updateFn={editComparables}
            />
          </BNAccordionMenu>

          <Grouping />
          <BNAccordionMenu
            value={inventoryMenuValue}
            width={298}
            opened={isInventoryDialogOpen}
            onClose={() => toggleInventoryDialog(false)}
            target={
              <Box>
                <Indicator size={needsBulkInventoryEditHint ? 6 : 0} offset={3} color="blue" display="flex">
                  <BNButton
                    c="var(--colors-selectedBorder)"
                    variant="subtle"
                    disabled={currentUser?.roleId === 'ReadOnlyUser'}
                    onClick={() => {
                      toggleInventoryDialog();
                      if (needsBulkInventoryEditHint) {
                        markFeatureSeen('bulk-inventory-edit');
                      }
                    }}
                    size="xs"
                    leftSection={<InventoryIcon size={19} />}
                    rightSection={<SelectArrowsIcon />}
                    className={classes.actionButton}
                    data-pendo-name="Bulk Inventory Button"
                  >
                    <Text component="span" fw={600} truncate>
                      Inventory
                    </Text>
                  </BNButton>
                </Indicator>
              </Box>
            }
          >
            <BNAccordionMenu.Item
              closeMenuOnClick={false}
              component="div"
              classNames={{ item: classes.disableItemHover }}
              data-pendo-name="Bulk Broadcasting MenuItem"
              styles={{ item: { pointerEvents: 'none' }, itemSection: { pointerEvents: 'auto' } }}
              leftSection={<WifiIcon size={18} />}
              rightSection={
                <Group gap={4} align="center">
                  <Tooltip label={`Un-broadcast Selected ${pluralize('Listing', markedListings.length + 1)}`} withArrow>
                    <BNButton
                      size="compact-xs"
                      leftSection={<WifiOffIcon color="var(--colors-gray-5)" size={12} />}
                      fz={11}
                      c="var(--colors-paperReverse)"
                      variant="subtle"
                      onClick={async () => {
                        await editBroadcasting(false);
                        toggleInventoryDialog(false);
                      }}
                      loading={isBulkActionLoading}
                    >
                      OFF
                    </BNButton>
                  </Tooltip>{' '}
                  <Tooltip label={`Broadcast Selected ${pluralize('Listing', markedListings.length + 1)}`} withArrow>
                    <BNButton
                      size="compact-xs"
                      leftSection={<WifiIcon color="var(--colors-gray-5)" size={12} />}
                      fz={11}
                      c="var(--colors-paperReverse)"
                      variant="subtle"
                      onClick={async () => {
                        await editBroadcasting(true);
                        toggleInventoryDialog(false);
                      }}
                      loading={isBulkActionLoading}
                    >
                      ON
                    </BNButton>
                  </Tooltip>
                </Group>
              }
            >
              Broadcasting
            </BNAccordionMenu.Item>
            {isDtiHosted && (
              <SharingMenuPanel bulk onClick={() => (inventoryMenuValue === 'sharing-options' ? setInventoryMenuValue(null) : setInventoryMenuValue('sharing-options'))} />
            )}
            <BNAccordionMenu.Panel
              id="merge-listings"
              title="Merge Listings"
              leftSection={<ArrowsJoin size={18} />}
              data-pendo-name="Merge Listings MenuItem"
              onClick={() => (inventoryMenuValue === 'merge-listings' ? setInventoryMenuValue(null) : setInventoryMenuValue('merge-listings'))}
            >
              {isMergeEnabled ? (
                <Merge toggleMergeListingDialog={() => setInventoryMenuValue(null)} />
              ) : (
                <Box w={247} pl={20}>
                  <strong>Merge conditions not met:</strong>
                  <List size="xs">
                    <List.Item>All listings must belong to the same company</List.Item>
                    <List.Item>All listings must share the same event, section, and row</List.Item>
                    <List.Item>Seats must be together</List.Item>
                  </List>
                </Box>
              )}
            </BNAccordionMenu.Panel>
            {bulkEditInventoryFlag && (
              <BNAccordionMenu.Panel
                id="accordion-edit-properties"
                title="Edit Properties"
                data-pendo-name="Edit Properties MenuItem"
                leftSection={
                  <Center h={20} w={20} ml={-2}>
                    <EditNoteIcon size={24} />
                  </Center>
                }
                onClick={() => (inventoryMenuValue === 'accordion-edit-properties' ? setInventoryMenuValue(null) : setInventoryMenuValue('accordion-edit-properties'))}
              >
                <Group wrap="wrap" gap="sm" align="flex-end" justify="right">
                  <Box>
                    <BNSelect
                      value={editPropertyValue}
                      data={['Section', 'Row', 'Stock', 'Disclosures', 'External Notes', 'CC Info', 'Purchaser', 'Internal Notes']}
                      onChange={setEditPropertyValue}
                      w={236}
                      maxDropdownHeight={240}
                      size="xs"
                      placeholder="Section Name"
                      leftSection={<PropertySelectIcon iconValue={editPropertyValue} color="var(--colors-selectedBorder)" />}
                      aria-label="Section"
                      renderOption={({ option: { label, value } }) => (
                        <Group>
                          <PropertySelectIcon iconValue={value} color="var(--colors-brandcolor-5)" /> {label}
                        </Group>
                      )}
                    />
                  </Box>
                  {editPropertyValue === 'Section' && (
                    <Box>
                      <BNTextInput w={236} size="xs" placeholder="Section Name" aria-label="Section" />
                    </Box>
                  )}
                  {editPropertyValue === 'Row' && (
                    <Box>
                      <BNTextInput w={236} size="xs" placeholder="Row Name" aria-label="Row" />
                    </Box>
                  )}
                  {editPropertyValue === 'Stock' && (
                    <Box>
                      <BNSelect w={236} size="xs" placeholder="Select Stock Type" data={stock_options} aria-label="Stock" />
                    </Box>
                  )}
                  {editPropertyValue === 'Disclosures' && (
                    <Box>
                      <MultiSelect w={236} size="xs" placeholder="Select Disclosures" data={disclosures_options} aria-label="Stock" />
                    </Box>
                  )}
                  {editPropertyValue === 'External Notes' && (
                    <Box>
                      <Textarea w={236} size="xs" placeholder="External Notes" aria-label="External Notes" />
                    </Box>
                  )}
                  {editPropertyValue === 'CC Info' && (
                    <Box>
                      <BNTextInput w={236} size="xs" placeholder="CC Info" aria-label="CC Info" />
                    </Box>
                  )}
                  {editPropertyValue === 'Purchaser' && (
                    <Box>
                      <BNTextInput w={236} size="xs" placeholder="purchaser@email.com" aria-label="Purchaser" />
                    </Box>
                  )}
                  {editPropertyValue === 'Internal Notes' && (
                    <Box>
                      <Textarea w={236} size="xs" placeholder="Internal Notes" aria-label="Internal Notes" />
                    </Box>
                  )}
                  <Group gap="sm" w={236} align="flex-end" justify="right">
                    <BNButton fullWidth variant="default" size="xs">
                      Cancel
                    </BNButton>
                    <BNButton fullWidth variant="filled" color="green" size="xs">
                      Apply
                    </BNButton>
                  </Group>
                </Group>
              </BNAccordionMenu.Panel>
            )}
          </BNAccordionMenu>
          {removeRuleFlag && <Admin />}
        </Group>
      </Group>
    </Collapse>
  );
}

export default MultiSelectActionBar;
