import { ActionIcon, Center, Modal, Paper, Text, RingProgress, Tooltip, Overlay, Box, SimpleGrid } from '@mantine/core';
import MenuOpenIcon from '../components/icons/MenuOpen';
import { SearchResultStateProvider } from '../hoc/SeasonList/SearchResults.hooks';
import { SeatingChartProvider } from '../hoc/SeatingChart/SeatingChart.hooks';
import { RuleStateProvider } from '../data/RuleState';
import { BulletinModal } from '../hoc/Bulletin/BulletinModal';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import { useDidUpdate, useHover, useLocalStorage } from '@mantine/hooks';
import { VersionNotificationBanner } from '../hoc/Banners/VersionNotificationBanner';
import { SupportActivationBanner } from '../hoc/Banners/SupportActivationBanner';
import { BulkStateProvider } from '../data/BulkState';
import { PlanExpirationBanner } from '../hoc/Banners/PlanExpirationBanner';
import { UnpaidInvoiceBanner } from '../hoc/Banners/UnpaidInvoiceBanner';
import { useEffect, useState } from 'react';
import { BNEmptyState } from '../components/EmptyState/EmptyState';
import SearchIcon from '../components/icons/Search';
import { SeasonPricerProvider, useSeasonPricer } from '../data/SeasonsPricerState';
import { EventMappingProvider } from '../hoc/EventMapping/EventMapping.hooks';
import classes from './SeasonPricer.tsx.module.css';
import { marketListingsLimit } from '../hoc/MarketListings/MarketListings.hooks';
import CheckIcon from '../components/icons/Check';
import { ListingStateProvider } from '../data/ListingState';
import { useFlag } from '@unleash/proxy-client-react';
import { SeatingChart } from '../hoc/SeatingChart/SeatingChart';
import { BNSidebar } from '../hoc/NavBar/Sidebar';
import { SeasonList } from '../hoc/SeasonList/SeasonList';
import { NavBar } from '../hoc/NavBar/NavBar';
import { SeasonInventory } from '../hoc/SeasonInventory/Inventory';
import { EventMapping } from '../hoc/EventMapping/EventMapping';
import { SeasonNowPricing } from '../hoc/SeasonNowPricing/NowPricing';

export default function SeasonPricer() {
  return (
    <ListingStateProvider>
      <RuleStateProvider>
        <BulkStateProvider>
          <BulletinModal />
          <InviteModal />
          <div className="outer-appWrap">
            <VersionNotificationBanner />
            <SupportActivationBanner />
            <PlanExpirationBanner />
            <UnpaidInvoiceBanner />
            <SeasonPricerProvider>
              <SeasonPricerInterior />
            </SeasonPricerProvider>
          </div>
        </BulkStateProvider>
      </RuleStateProvider>
    </ListingStateProvider>
  );
}

function SeasonPricerInterior() {
  const [showSidebar, setShowSidebar] = useLocalStorage({
    key: 'setting-sidebar-season',
    defaultValue: true,
    getInitialValueInEffect: false,
  });
  const {
    showEventMapping,
    eventMappingEvent,
    selectedLocation,
    isLoading,
    isSaving,
    eventMappingProcessingCount,
    inventoryEventsQueryProcessingCount,
    listingsProcessingCount,
    listingsMax,
  } = useSeasonPricer(
    'showEventMapping',
    'eventMappingEvent',
    'selectedLocation',
    'isLoading',
    'isSaving',
    'eventMappingProcessingCount',
    'inventoryEventsQueryProcessingCount',
    'listingsProcessingCount',
    'listingsMax',
    'ruleGroupCount',
  );
  const [showEmptyState] = useState(false);
  const maxItems = selectedLocation?.listings?.length || 0;
  const [marketListingsCount, setMarketListingsCount] = useState(Infinity);
  const { ref: hoverRef, hovered: isHovered } = useHover();
  const newSidebarFlag = useFlag('updated-flyout-sidebar');

  useDidUpdate(() => {
    setMarketListingsCount(maxItems);
  }, [maxItems, selectedLocation]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedLocation && !isLoading && eventMappingProcessingCount === 0) {
        setMarketListingsCount(marketListingsLimit.pendingCount + marketListingsLimit.activeCount);
      }
      if (selectedLocation && !isLoading && eventMappingProcessingCount === 0 && marketListingsCount === 0) {
        clearInterval(interval);
      }
    }, 250);
    return () => clearInterval(interval);
  }, [eventMappingProcessingCount, inventoryEventsQueryProcessingCount, isLoading, marketListingsCount, selectedLocation]);

  return (
    <div className="inner-appWrap season-pricer">
      <div className={showSidebar ? 'appWrap sidebar-open' : 'appWrap sidebar-collapse'}>
        <div className="col1">
          {newSidebarFlag ? (
            <BNSidebar>
              <Modal
                centered
                size="xxs"
                opened={!!isSaving}
                closeOnClickOutside={false}
                closeOnEscape={false}
                withCloseButton={false}
                onClose={() => {
                  throw new Error('Function not implemented.');
                }}
              >
                <Box bg="var(--colors-paper)" className={classes.loadingWrapper} c="var(--colors-paperReverse)" p="xs">
                  <Box className={classes.loadingGrid}>
                    <Center py="lg">
                      <RingProgress
                        className={classes.ringProgress}
                        rootColor="var(--colors-divider)"
                        size={64}
                        thickness={4}
                        sections={[{ value: (1 - isSaving / maxItems) * 100, color: 'var(--colors-successButton)' }]}
                        label={
                          <Center
                            bg={(1 - isSaving / maxItems) * 100 === 100 ? 'var(--colors-successButton)' : 'var(--colors-paper)'}
                            h={48}
                            w={48}
                            className={classes.ringProgressInner}
                          >
                            {(1 - isSaving / maxItems) * 100 === 100 ? (
                              <CheckIcon size={32} color="white" />
                            ) : (
                              <Text fz="sm" c="var(--colors-paperReverse)">
                                {((1 - isSaving / maxItems) * 100).toFixed(0)}%
                              </Text>
                            )}
                          </Center>
                        }
                      />
                      <Text fz="xs" fw="500" mt="xs" c="var(--colors-paperReverse)">
                        Rules & Listings
                      </Text>
                    </Center>
                  </Box>
                </Box>
              </Modal>
              <Paper className="sidebarSearchWrapper" pos="relative">
                <SearchResultStateProvider>
                  <SeasonList />
                </SearchResultStateProvider>
              </Paper>
            </BNSidebar>
          ) : (
            <>
              <Modal
                centered
                size="xxs"
                opened={!!isSaving}
                closeOnClickOutside={false}
                closeOnEscape={false}
                withCloseButton={false}
                onClose={() => {
                  throw new Error('Function not implemented.');
                }}
              >
                <Box bg="var(--colors-paper)" className={classes.loadingWrapper} c="var(--colors-paperReverse)" p="xs">
                  <Box className={classes.loadingGrid}>
                    <Center py="lg">
                      <RingProgress
                        className={classes.ringProgress}
                        rootColor="var(--colors-divider)"
                        size={64}
                        thickness={4}
                        sections={[{ value: (1 - isSaving / maxItems) * 100, color: 'var(--colors-successButton)' }]}
                        label={
                          <Center
                            bg={(1 - isSaving / maxItems) * 100 === 100 ? 'var(--colors-successButton)' : 'var(--colors-paper)'}
                            h={48}
                            w={48}
                            className={classes.ringProgressInner}
                          >
                            {(1 - isSaving / maxItems) * 100 === 100 ? (
                              <CheckIcon size={32} color="white" />
                            ) : (
                              <Text fz="sm" c="var(--colors-paperReverse)">
                                {((1 - isSaving / maxItems) * 100).toFixed(0)}%
                              </Text>
                            )}
                          </Center>
                        }
                      />
                      <Text fz="xs" fw="500" mt="xs" c="var(--colors-paperReverse)">
                        Rules & Listings
                      </Text>
                    </Center>
                  </Box>
                </Box>
              </Modal>
              <Paper>
                {/* Add sidebar collapse control here so it can apply a css class that can then cascade throughout the app? */}
                <Tooltip label={showSidebar ? 'Collapse Sidebar' : 'Expand Sidebar'} position="right" withArrow>
                  <ActionIcon className="menuTrigger" onClick={() => setShowSidebar(!showSidebar)}>
                    <MenuOpenIcon color="var(--colors-gray-5)" size={24} />
                  </ActionIcon>
                </Tooltip>
                <NavBar
                  title={
                    <Text
                      c="var(--colors-gray-5)"
                      style={{
                        textTransform: 'uppercase',
                        letterSpacing: '0.05em',
                        fontSize: '0.6875rem',
                      }}
                    >
                      Seasons
                    </Text>
                  }
                />
                <Paper
                  className="searchWrapper"
                  pos="relative"
                  onMouseLeave={() => {
                    document.body.classList.remove('flyout');
                  }}
                >
                  <SearchResultStateProvider>
                    <SeasonList />
                  </SearchResultStateProvider>
                </Paper>
              </Paper>
            </>
          )}
        </div>
        {!showEmptyState ? (
          <>
            <div className="col2">
              <SeasonInventory />
            </div>
            <div className="appRight" ref={hoverRef}>
              {!isSaving && isHovered && maxItems > 0 && (isLoading || marketListingsCount > 0) && (
                <Overlay bg="var(--colors-overlay)" center>
                  <Box bg="var(--colors-paper)" className={classes.loadingWrapper} c="var(--colors-paperReverse)" p="xs">
                    {/* <h2>Loading</h2> */}
                    <SimpleGrid cols={4} spacing={0} className={classes.loadingGrid} py="lg">
                      <Center>
                        <RingProgress
                          className={classes.ringProgress}
                          rootColor="var(--colors-divider)"
                          size={64}
                          thickness={4}
                          sections={[{ value: (1 - inventoryEventsQueryProcessingCount / maxItems) * 100, color: 'var(--colors-successButton)' }]}
                          label={
                            <Center
                              bg={(1 - inventoryEventsQueryProcessingCount / maxItems) * 100 === 100 ? 'var(--colors-successButton)' : 'var(--colors-paper)'}
                              h={48}
                              w={48}
                              className={classes.ringProgressInner}
                            >
                              {(1 - inventoryEventsQueryProcessingCount / maxItems) * 100 === 100 ? (
                                <CheckIcon size={32} color="white" />
                              ) : (
                                <Text fz="sm" c="var(--colors-paperReverse)">
                                  {((1 - inventoryEventsQueryProcessingCount / maxItems) * 100).toFixed(0)}%
                                </Text>
                              )}
                            </Center>
                          }
                        />
                        <Text fz="xs" fw="500" mt="xs" c="var(--colors-paperReverse)">
                          Events
                        </Text>
                      </Center>
                      <Center>
                        <RingProgress
                          className={classes.ringProgress}
                          rootColor="var(--colors-divider)"
                          size={64}
                          thickness={4}
                          sections={[{ value: (1 - listingsProcessingCount / listingsMax) * 100, color: 'var(--colors-successButton)' }]}
                          label={
                            <Center
                              bg={(1 - listingsProcessingCount / maxItems) * 100 === 100 ? 'var(--colors-successButton)' : 'var(--colors-paper)'}
                              h={48}
                              w={48}
                              className={classes.ringProgressInner}
                            >
                              {(1 - listingsProcessingCount / listingsMax) * 100 === 100 ? (
                                <CheckIcon size={32} color="white" />
                              ) : (
                                <Text fz="sm" c="var(--colors-paperReverse)">
                                  {((1 - listingsProcessingCount / listingsMax) * 100).toFixed(0)}%
                                </Text>
                              )}
                            </Center>
                          }
                        />
                        <Text fz="xs" fw="500" mt="xs" c="var(--colors-paperReverse)">
                          Listings
                        </Text>
                      </Center>
                      <Center>
                        <RingProgress
                          className={classes.ringProgress}
                          rootColor="var(--colors-divider)"
                          size={64}
                          thickness={4}
                          sections={[
                            {
                              value: (1 - eventMappingProcessingCount / maxItems) * 100,
                              color: 'var(--colors-successButton)',
                            },
                          ]}
                          label={
                            <Center
                              bg={(1 - eventMappingProcessingCount / maxItems) * 100 === 100 ? 'var(--colors-successButton)' : 'var(--colors-paper)'}
                              h={48}
                              w={48}
                              className={classes.ringProgressInner}
                            >
                              {(1 - eventMappingProcessingCount / maxItems) * 100 === 100 ? (
                                <CheckIcon size={32} color="white" />
                              ) : (
                                <Text fz="sm" c="var(--colors-paperReverse)">
                                  {((1 - eventMappingProcessingCount / maxItems) * 100).toFixed(0)}%
                                </Text>
                              )}
                            </Center>
                          }
                        />
                        <Text fz="xs" fw="500" mt="xs" c="var(--colors-paperReverse)">
                          Mappings
                        </Text>
                      </Center>
                      <Center>
                        <RingProgress
                          className={classes.ringProgress}
                          rootColor="var(--colors-divider)"
                          size={64}
                          thickness={4}
                          sections={[{ value: (1 - marketListingsCount / maxItems) * 100, color: 'var(--colors-successButton)' }]}
                          label={
                            <Center
                              bg={(1 - marketListingsCount / maxItems) * 100 === 100 ? 'var(--colors-successButton)' : 'var(--colors-paper)'}
                              h={48}
                              w={48}
                              className={classes.ringProgressInner}
                            >
                              {(1 - marketListingsCount / maxItems) * 100 === 100 ? (
                                <CheckIcon size={32} color="white" />
                              ) : (
                                <Text fz="sm" c="var(--colors-paperReverse)">
                                  {((1 - marketListingsCount / maxItems) * 100).toFixed(0)}%
                                </Text>
                              )}
                            </Center>
                          }
                        />
                        <Text fz="xs" fw="500" mt="xs" c="var(--colors-paperReverse)">
                          Marketplace
                        </Text>
                      </Center>
                    </SimpleGrid>
                  </Box>
                </Overlay>
              )}
              <div className="col3">
                <Paper className="seatingChartWrapper" pos="relative">
                  <SeatingChartProvider isSeasonView>
                    <SeatingChart />
                    {showEventMapping && (
                      <EventMappingProvider overrideEvent={eventMappingEvent}>
                        <EventMapping />
                      </EventMappingProvider>
                    )}
                  </SeatingChartProvider>
                </Paper>
              </div>
              <div className="col4">
                <SeasonNowPricing />
              </div>
            </div>
          </>
        ) : (
          <div className="sp-empty">
            <Center bg="var(--colors-paper)" w={600} h={320}>
              <BNEmptyState
                title="No Eligible Seasons Found"
                description="Season pricing requires similar listings for multiple events."
                icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
                buttonText="Exit Season Pricer"
                buttonVariant="filled"
                buttonColor="gray"
                buttonOnClick={() => {
                  window.location.href = '/';
                }}
              />
            </Center>
          </div>
        )}
      </div>
    </div>
  );
}
