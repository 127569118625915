import { ActionIcon } from '@mantine/core';
import { useMemo } from 'react';
import MoreVertIcon from '../../components/icons/MoreVert';
import { useToggle } from '@mantine/hooks';
import { useGlobalState } from '../../data/GlobalState';
import { useFlag } from '@unleash/proxy-client-react';
import { BNAccordionMenu } from '../../components/Accordion/BNAccordion';
import WarningIcon from '../../components/icons/Warning';
import { Sale } from './Sales.types';
import { BarkerCoreModelsSalesDtiPortalFulfillmentStatus, BarkerCoreModelsSalesListingSoldVendorPropertiesDtiPortal } from '../../api';

export type SaleActionProps = {
  sale: Sale;
};
export const SalesAction = ({ sale }: SaleActionProps) => {
  const [menuOpen, toggleMenu] = useToggle();

  return (
    <>
      <BNAccordionMenu
        width={200}
        opened={menuOpen}
        onClose={() => toggleMenu(false)}
        position="right"
        offset={15}
        target={
          <ActionIcon onClick={() => toggleMenu()}>
            <MoreVertIcon size={20} color="var(--colors-gray-5)" />
          </ActionIcon>
        }
      >
        {menuOpen && <SalesActionForm sale={sale} toggleMenu={toggleMenu} />}
      </BNAccordionMenu>
    </>
  );
};

export const SalesActionForm = ({ sale, toggleMenu }: SaleActionProps & { toggleMenu: (value?: boolean) => void }) => {
  const { currentUser, tenants } = useGlobalState('currentUser', 'tenants');
  const tenantPointOfSale = useMemo(() => tenants?.find((t) => t.tenantId === sale?.tenantId)?.pointOfSaleId || 'Unknown', [sale.tenantId, tenants]);

  const isDev = useFlag('dev');

  const vendorProperties = sale.vendorProperties as BarkerCoreModelsSalesListingSoldVendorPropertiesDtiPortal;
  const fulfillmentStatus = sale.fulfillmentStatus as BarkerCoreModelsSalesDtiPortalFulfillmentStatus;

  const isEligibleForAirbill =
    !vendorProperties.hasAirbill && fulfillmentStatus.status === 'Incomplete' && (vendorProperties.location === 'HARD' || vendorProperties.location === 'DROPSHIP');

  const isEligibleForSelfService = false; // Disabled until we get this actually implemented

  return (
    <>
      <BNAccordionMenu.Item
        title="Complete Self-Service"
        leftSection={<WarningIcon />}
        disabled={!isEligibleForSelfService}
        onClick={() => {}}
        data-pendo-name="Complete Self-Service MenuItem"
      />
      <BNAccordionMenu.Item title="Request Airbill" leftSection={<WarningIcon />} disabled={!isEligibleForAirbill} onClick={() => {}} data-pendo-name="Request Airbill MenuItem" />
    </>
  );
};
