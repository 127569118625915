import { Box, Divider, Group, SimpleGrid, Slider, Stack, Text, Title } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { BNSelect } from '../../components/Select/Select';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { AdminContentHeader } from '../../hoc/Admin/AdminContentHeader';
import { useGlobalState } from '../../data/GlobalState';
import {
  BarkerCoreEnumsDeliveryMethod,
  BarkerCoreEnumsListingDisclosure,
  BarkerCoreEnumsMarketplace,
  BarkerCoreModelsAdministrativePricerSettings,
  BarkerCoreModelsAdministrativePrincipalSettings,
  getGetApiPrincipalsSelfQueryKey,
  usePatchApiPrincipalsSelf,
} from '../../api';
import { useForm } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';
import { StaggerByValueInput } from '../../components/AdjustmentInput/StaggerByValueInput';
import { DEFAULT_RULE, DEFAULT_RULE_SETTINGS } from '../../data/constants';
import { BNSwitch } from '../../components/Switch/Switch';
import { AdjustmentValueInputDiagonal } from '../../components/AdjustmentInput/AdjustmentValueInputDiagonal';
import { Link } from 'react-router-dom';
import BNInputGroup from '../../components/Group/InputGroup';
import { FlattenObjectKeys, MakeNonNullable } from '../../ts-utils';
import classes from './PricingSettings.module.css';
import { BNExclusionsMultiSelect } from '../../components/Select/ExclusionsMultiSelect';
import { isNumber } from '../../utils/price-utils';
import { SplitsButtonGroup } from '../../hoc/SeatingChart/Filters/Filters.SplitsButtonGroup';

function getFormValues(pricerSettings: BarkerCoreModelsAdministrativePricerSettings): BarkerCoreModelsAdministrativePricerSettings & {
  searchRangeCustom: number | undefined;
} & MakeNonNullable<BarkerCoreModelsAdministrativePricerSettings, 'schedulePricerSettings' | 'outlierCriteria'> {
  return {
    ...pricerSettings,
    floorMultiplier: !pricerSettings?.floorMultiplier ? 0 : pricerSettings.floorMultiplier * 100,
    ceilingMultiplier: !pricerSettings?.ceilingMultiplier ? 0 : pricerSettings.ceilingMultiplier * 100,
    floorCostMultiplier: !pricerSettings?.floorCostMultiplier ? 0 : pricerSettings.floorCostMultiplier * 100,
    ceilingCostMultiplier: !pricerSettings?.ceilingCostMultiplier ? 0 : pricerSettings.ceilingCostMultiplier * 100,
    searchRange: pricerSettings?.searchRange ? (pricerSettings.searchRange.startsWith('c') ? 'c' : pricerSettings.searchRange) : 'M1',
    searchRangeCustom: pricerSettings?.searchRange.startsWith('c') ? parseInt(pricerSettings?.searchRange.slice(1)) : undefined,
    outlierCriteria: pricerSettings.outlierCriteria
      ? {
          ...pricerSettings.outlierCriteria,
          percentUnder: pricerSettings.outlierCriteria.percentUnder * 100,
          comparableIndex: pricerSettings.outlierCriteria.comparableIndex + 1,
        }
      : { comparableIndex: 0, percentUnder: 0 },
    schedulePricerSettings: pricerSettings.schedulePricerSettings
      ? {
          ...pricerSettings.schedulePricerSettings,
          floorMultiplier: !pricerSettings.schedulePricerSettings?.floorMultiplier ? 0 : pricerSettings.schedulePricerSettings.floorMultiplier * 100,
          ceilingMultiplier: !pricerSettings.schedulePricerSettings?.ceilingMultiplier ? 0 : pricerSettings.schedulePricerSettings.ceilingMultiplier * 100,
        }
      : {
          numActive: 1,
          adjustmentTypeId: 'Amount',
          adjustmentValue: 0,
          intervalMinutes: 1440,
          floorMultiplier: undefined,
          ceilingMultiplier: undefined,
          staggerByTypeId: 'Percentage',
          staggerByValue: 1.05,
        },
  };
}

export function PricingSettings() {
  const { principal, tenants } = useGlobalState('principal', 'tenants');
  const queryClient = useQueryClient();
  const settings = principal?.settings ?? ({} as BarkerCoreModelsAdministrativePrincipalSettings);
  const pricerSettings =
    settings.pricerSettings ??
    ({
      defaultMarketplace: DEFAULT_RULE.marketplaceId,
      numActive: DEFAULT_RULE.numActive,
      staggerByTypeId: DEFAULT_RULE.staggerByTypeId,
      adjustmentTypeId: DEFAULT_RULE.adjustmentTypeId,
      adjustmentValue: DEFAULT_RULE.adjustmentValue,
      staggerByValue: DEFAULT_RULE.staggerByValue,
      broadcastSingleListing: DEFAULT_RULE_SETTINGS.broadcastSingleListing,
      autoAdjustSplits: DEFAULT_RULE.autoAdjustSplits,
      numComparables: [],
      searchRange: 'M1',
      rowComparisons: [],
      quantitySplitMatrix: {},
      exclusions: [],
      appearanceSettings: {
        colorPricesBelowCost: false,
        highlightListingsAtFloor: false,
        showWeather: 'Never',
        highlightListingsOnHold: true,
      },
    } satisfies BarkerCoreModelsAdministrativePricerSettings);

  if ((pricerSettings.numComparables?.length ?? 0) === 0) {
    pricerSettings.numComparables = [{ from: 0, to: 9999, numComparables: DEFAULT_RULE.numComparables }];
  }

  const isMultiTenantUser = (tenants?.length ?? 0) > 1;

  const isTicketNetworkMarketplaceEnabled = useFlag('ticketnetwork-marketplace');

  const { mutateAsync: updateSelf } = usePatchApiPrincipalsSelf();
  const form = useForm({
    initialValues: getFormValues(pricerSettings),
    validate: (values) => {
      const errors: Partial<Record<FlattenObjectKeys<typeof values>, string>> = {};
      if (values.ceilingMultiplier && values.ceilingMultiplier > 0 && values.floorMultiplier && values.floorMultiplier > values.ceilingMultiplier) {
        errors.ceilingMultiplier = 'Ceiling must be greater than floor';
      }
      if (values.ceilingCostMultiplier && values.ceilingCostMultiplier > 0 && values.floorCostMultiplier && values.floorCostMultiplier > values.ceilingCostMultiplier) {
        errors.ceilingCostMultiplier = 'Ceiling must be greater than floor';
      }
      if (
        values.schedulePricerSettings &&
        values.schedulePricerSettings.ceilingMultiplier &&
        values.schedulePricerSettings.ceilingMultiplier > 0 &&
        values.schedulePricerSettings.floorMultiplier &&
        values.schedulePricerSettings.floorMultiplier > values.schedulePricerSettings.ceilingMultiplier
      ) {
        errors['schedulePricerSettings.ceilingMultiplier'] = 'Ceiling must be greater than floor';
      }
      if (values.searchRange === 'c' && !values.searchRangeCustom) {
        errors.searchRangeCustom = 'Search number of days value is required when custom is set';
      }
      if (typeof values.outlierCriteria?.percentUnder === 'number' && values.outlierCriteria?.percentUnder > 0 && !values.outlierCriteria.comparableIndex) {
        errors['outlierCriteria.comparableIndex'] = 'Compare to is required with percent under set';
      }
      if (typeof values.outlierCriteria?.percentUnder === 'string' && values.outlierCriteria.comparableIndex !== 0) {
        errors['outlierCriteria.percentUnder'] = 'Percent under is required with compare to set. Value can be 0% up to 20%';
      }
      values.rowComparisons.forEach((rowComparison, index) => {
        if (rowComparison.rowRange === '') {
          errors[`rowComparisons.${index}.rowRange`] = 'Row Range is required';
        } else if (!/^\w+-\w+$/.test(rowComparison.rowRange)) {
          errors[`rowComparisons.${index}.rowRange`] = 'Row Range must be in the format of 1-10';
        } else {
          const [from, to] = rowComparison.rowRange.split('-');
          const isFromNumber = isNaN(Number(from));
          const isToNumber = isNaN(Number(to));
          // Check if both from and to are numbers
          if (isFromNumber !== isToNumber) {
            errors[`rowComparisons.${index}.rowRange`] = 'Row Range must be entirely numeric or alphabetical';
          }
        }
        // @ts-ignore
        if (rowComparison.threshold === '') {
          errors[`rowComparisons.${index}.threshold`] = 'Value is required';
        }
      });

      return errors;
    },
    transformValues: (values) => ({
      ...values,
      floorMultiplier: typeof values.floorMultiplier === 'string' ? null : values.floorMultiplier === 0 ? null : values.floorMultiplier! / 100,
      ceilingMultiplier: typeof values.ceilingMultiplier === 'string' ? null : values.ceilingMultiplier === 0 ? null : values.ceilingMultiplier! / 100,
      floorCostMultiplier: typeof values.floorCostMultiplier === 'string' ? null : values.floorCostMultiplier === 0 ? null : values.floorCostMultiplier! / 100,
      ceilingCostMultiplier: typeof values.ceilingCostMultiplier === 'string' ? null : values.ceilingCostMultiplier === 0 ? null : values.ceilingCostMultiplier! / 100,
      searchRange: values.searchRange === 'c' ? `c${values.searchRangeCustom}` : values.searchRange,
      outlierCriteria: values.outlierCriteria?.comparableIndex
        ? {
            comparableIndex: values.outlierCriteria.comparableIndex - 1,
            percentUnder: values.outlierCriteria.percentUnder / 100,
          }
        : undefined,
      schedulePricerSettings: {
        ...values.schedulePricerSettings,
        floorMultiplier: !values.schedulePricerSettings?.floorMultiplier ? null : values.schedulePricerSettings.floorMultiplier / 100,
        ceilingMultiplier: !values.schedulePricerSettings?.ceilingMultiplier ? null : values.schedulePricerSettings.ceilingMultiplier / 100,
      },
    }),
  });

  return (
    <>
      <AdminContentHeader
        title="Pricing Settings"
        sticky
        rightSection={
          <>
            <BNButton
              size="xs"
              variant="default"
              disabled={!form.isDirty()}
              onClick={() => {
                form.setValues(getFormValues(pricerSettings));
              }}
            >
              Cancel
            </BNButton>
            <BNButton
              size="xs"
              variant="filled"
              color="green"
              disabled={!form.isDirty()}
              onClick={() =>
                form.onSubmit((values) => {
                  const newSettings: BarkerCoreModelsAdministrativePrincipalSettings = {
                    ...settings,
                    pricerSettings: values,
                  };

                  updateSelf(
                    { data: [{ op: 'Replace', path: '/settings', value: newSettings }] },
                    {
                      onSuccess: (data) => {
                        queryClient.setQueryData(getGetApiPrincipalsSelfQueryKey(), () => data);
                      },
                    },
                  ).then(() => {
                    form.resetDirty();
                  });
                })()
              }
            >
              Save
            </BNButton>
          </>
        }
      />
      <Box pt="xl" pb={0}>
        <Title order={2} size="h4" pt="sm" mb="lg">
          Search Defaults
        </Title>
        <Box>
          <div className={classes.searchRangeWrapper}>
            <BNInputGroup label={form.values.searchRange.startsWith('c') ? 'Search Range (# of days)' : 'Search Range'}>
              <BNInputGroup.Item w={140}>
                <BNSelect
                  size="xs"
                  style={{ flex: 1 }}
                  miw={120}
                  label={form.values.searchRange.startsWith('c') ? 'Search Range (# of days)' : 'Search Range'}
                  data={[
                    { value: 'd1', label: 'Today' },
                    { value: 'w1', label: '1 Week' },
                    { value: 'M1', label: '1 Month' },
                    { value: 'y1', label: '1 Year' },
                    { value: 'all', label: 'All' },
                    { value: 'c', label: 'Custom' },
                  ]}
                  {...form.getInputProps('searchRange')}
                />
              </BNInputGroup.Item>
              {form.values.searchRange.startsWith('c') && (
                <BNInputGroup.Item w={140}>
                  <BNNumberInput size="xs" min={0} placeholder="# of Days" {...form.getInputProps('searchRangeCustom')} />
                </BNInputGroup.Item>
              )}
            </BNInputGroup>
          </div>
        </Box>
      </Box>
      <Box py="xl" pb="lg">
        <Title order={2} size="h4" pt="sm">
          Marketplace Defaults
        </Title>
        <Text size="xs" mb="lg" c="var(--colors-gray-5)">
          These settings will be used as defaults for the pricer and can be overridden for each listing.
        </Text>
        <Box>
          <div style={{ maxWidth: 280 }}>
            <BNSelect
              size="xs"
              label="Default Marketplace"
              data={
                isTicketNetworkMarketplaceEnabled
                  ? [
                      { value: BarkerCoreEnumsMarketplace.TicketEvolution, label: 'Ticket Evolution' },
                      { value: BarkerCoreEnumsMarketplace.SeatGeek, label: 'SeatGeek' },
                      { value: BarkerCoreEnumsMarketplace.TicketNetwork, label: 'TicketNetwork' },
                      { value: BarkerCoreEnumsMarketplace.Ticketmaster, label: 'Ticketmaster' },
                    ]
                  : [
                      { value: BarkerCoreEnumsMarketplace.TicketEvolution, label: 'Ticket Evolution' },
                      { value: BarkerCoreEnumsMarketplace.SeatGeek, label: 'SeatGeek' },
                      { value: BarkerCoreEnumsMarketplace.Ticketmaster, label: 'Ticketmaster' },
                    ]
              }
              value={form.values.defaultMarketplace}
              onChange={(e) => form.setFieldValue('defaultMarketplace', e! as BarkerCoreEnumsMarketplace)}
            />
          </div>
        </Box>
        <Box py="xl">
          <Title order={3} size="h5" mt="xs">
            Rows
          </Title>
          <Text size="xs" mb="xs" c="var(--colors-gray-5)">
            Based on your listing&apos;s row, the pricer can decide which rows to compare against.
          </Text>
          {form.values.rowComparisons.map((rowComparison, index) => (
            <Group mb="md" gap={12} align="end" key={index}>
              <div>
                <BNTextInput label="Row Range" w={132} size="xs" placeholder="1-10" {...form.getInputProps(`rowComparisons.${index}.rowRange`)} />
              </div>
              <div>
                <BNNumberInput label="+/- Rows" w={132} size="xs" placeholder="5" {...form.getInputProps(`rowComparisons.${index}.threshold`)} />
              </div>
              <BNButton
                variant="light"
                size="xs"
                onClick={() => {
                  form.removeListItem('rowComparisons', index);
                }}
              >
                Remove
              </BNButton>
            </Group>
          ))}
          <BNButton variant="default" size="xs" onClick={() => form.insertListItem('rowComparisons', { rowRange: '', threshold: 0 })}>
            Add a Row Range
          </BNButton>
        </Box>
        <Box pb="xl">
          <Title order={3} size="h5" mt="xs">
            Splits
          </Title>
          <Text size="xs" mb="xs" c="var(--colors-gray-5)">
            Select the splits you would like to include by default for specific ticket quantities.
          </Text>
          <Box maw={280}>
            <Group wrap="nowrap" gap={0}>
              <Text w={44} size="xs" c="var(--colors-gray-5)">
                Qty
              </Text>
              <Text size="xs" c="var(--colors-gray-5)">
                Splits
              </Text>
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                1
              </Text>
              <Box w="100%">
                <SplitsButtonGroup
                  splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['1']}
                  disabled={form.values.autoAdjustSplits}
                  onChange={(splits) => {
                    form.setFieldValue('quantitySplitMatrix', {
                      ...form.values.quantitySplitMatrix,
                      1: splits,
                    });
                  }}
                  size="xs"
                />
              </Box>
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                2
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['2']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    2: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                3
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['3']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    3: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                4
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['4']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    4: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                5
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['5']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    5: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                6
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['6']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    6: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                7
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['7']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    7: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                8+
              </Text>
              <SplitsButtonGroup
                splits={form.values.quantitySplitMatrix && form.values.quantitySplitMatrix['8']}
                disabled={form.values.autoAdjustSplits}
                onChange={(splits) => {
                  form.setFieldValue('quantitySplitMatrix', {
                    ...form.values.quantitySplitMatrix,
                    8: splits,
                  });
                }}
                size="xs"
              />
            </Group>
            <Text size="xs" mb="xs" c="var(--colors-gray-5)" style={{ textWrap: 'nowrap' }}>
              Listings identified as general admission will use the following splits. If none are selected, the pricer will fallback to the appropriate split based on the quantity.
            </Text>
            <Group wrap="nowrap" my="xs" gap="xs">
              <Text size="sm" w={40} pl="xs">
                GA
              </Text>
              <SplitsButtonGroup
                splits={form.values.generalAdmissionSplits}
                onChange={(splits) => {
                  form.setFieldValue('generalAdmissionSplits', splits);
                }}
                size="xs"
              />
            </Group>
            <Text size="xs" mb="xs" c="var(--colors-gray-5)" style={{ textWrap: 'nowrap' }}>
              If your row isn&#39;t numeric, single-letter, double-letter, or triple-letter, the pricer will assume it&#39;s general admission.
            </Text>
          </Box>
          <Box py="xs">
            <BNSwitch className={classes.switchOutline} {...form.getInputProps('autoAdjustSplits', { type: 'checkbox' })} outlined label="Auto-Splits" size="sm" />
            {isMultiTenantUser ? (
              <>
                <Text size="xs" c="var(--colors-gray-5)" py="xs">
                  Let the auto-pricer maintain your splits based on the quantity of tickets in the listing.
                </Text>
                <Text maw={480} component="p" size="xs" c="var(--colors-gray-5)" my={0} bg="var(--colors-paperHover)" p="xs" px="sm" style={{ borderRadius: 3 }}>
                  Auto-splits are based on company-wide settings. To adjust these settings you must select a company from the{' '}
                  <Link to="/companies">
                    <Text component="span" td="underline" c="var(--colors-brandcolor-5)">
                      Companies
                    </Text>
                  </Link>{' '}
                  page.
                </Text>
              </>
            ) : (
              <>
                <Text size="xs" c="var(--colors-gray-5)" py="xs">
                  Let the auto-pricer maintain your splits based on the quantity of tickets in the listings.
                </Text>
                <Text maw={480} component="p" size="xs" c="var(--colors-gray-5)" my={0} bg="var(--colors-paperHover)" p="xs" px="sm" style={{ borderRadius: 3 }}>
                  Auto-splits are based on company-wide settings available in the{' '}
                  <Link to={`/companies/${tenants![0].tenantId}/settings/pricing`}>
                    <Text component="span" td="underline" c="var(--colors-brandcolor-5)">
                      Company Settings
                    </Text>
                  </Link>{' '}
                  page.
                </Text>
              </>
            )}
          </Box>
        </Box>
        <Box mb="xl" pb="xl" style={{ maxWidth: 280 }}>
          <Title order={3} size="h5">
            Exclusions
          </Title>
          <Text size="xs" c="var(--colors-gray-5)" mb="xs">
            Exclude listings by default with these traits.
          </Text>
          <BNExclusionsMultiSelect
            removeSelected
            value={((form.values.exclusions ?? []) as (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod)[]).concat(form.values.deliveryExclusions ?? [])}
            placeholder="None"
            onChange={(value: (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod | string)[]) => {
              const deliveryExclusions = value.filter((v) =>
                Object.values(BarkerCoreEnumsDeliveryMethod).includes(v as unknown as BarkerCoreEnumsDeliveryMethod),
              ) as BarkerCoreEnumsDeliveryMethod[];
              const disclosureExclusions = value.filter((v) =>
                Object.values(BarkerCoreEnumsListingDisclosure).includes(v as unknown as BarkerCoreEnumsListingDisclosure),
              ) as BarkerCoreEnumsListingDisclosure[];

              form.setFieldValue('exclusions', disclosureExclusions);
              form.setFieldValue('deliveryExclusions', deliveryExclusions);
            }}
          />
        </Box>
        <Box mb="xl" pb="xl" style={{ maxWidth: 280 }}>
          <Title order={3} size="h5">
            Outliers
          </Title>
          <Text size="xs" c="var(--colors-gray-5)" mb="xs">
            Exclude outlier listings based on their percentage below the market.
          </Text>
          <SimpleGrid cols={2}>
            <BNSelect
              clearable
              data={[
                { value: '2', label: '2nd Position' },
                { value: '3', label: '3rd Position' },
                { value: '4', label: '4th Position' },
                { value: '5', label: '5th Position' },
                { value: '6', label: '6th Position' },
              ]}
              label="Compare to"
              size="xs"
              placeholder="Select a position"
              onChange={(value) => {
                if (!value) {
                  form.setFieldValue('outlierCriteria.percentUnder', 0);
                  form.setFieldValue('outlierCriteria.comparableIndex', 0);
                } else if (isNumber(value)) {
                  form.setFieldValue('outlierCriteria.comparableIndex', parseInt(value));
                }
              }}
              error={form.errors['outlierCriteria.comparableIndex']}
              value={(form.values.outlierCriteria?.comparableIndex ?? 0) > 0 ? form.values.outlierCriteria?.comparableIndex.toString() : null}
              className={classes.outlierInput}
            />
            <Stack gap={1} justify="end">
              <Text component="label" c={form.errors['outlierCriteria.percentUnder'] ? 'var(--colors-red-5)' : 'var(--colors-gray-5)'} size="xs" lh="1.25rem">
                Percentage Under
              </Text>
              <Group gap="xs" wrap="nowrap" pos="relative">
                <Slider size="sm" min={0} max={20} step={1} label={null} w={220} className={classes.pctSlider} {...form.getInputProps('outlierCriteria.percentUnder')} />
                <BNNumberInput
                  label=""
                  size="xs"
                  min={0}
                  max={20}
                  hideControls
                  w={240}
                  fixedDecimalScale
                  decimalScale={0}
                  className={classes.pctInput}
                  selectOnFocus
                  rightSection={
                    <Text size="xs" c="var(--colors-gray-4)">
                      %
                    </Text>
                  }
                  rightSectionWidth={24}
                  error={form.errors['outlierCriteria.percentUnder']}
                  {...form.getInputProps('outlierCriteria.percentUnder')}
                />
              </Group>
            </Stack>
          </SimpleGrid>
        </Box>
        <Divider color="var(--colors-gray-1)" variant="dotted" my="xl" />
        <Box py="xl">
          <Title order={2} size="h3">
            Automation
          </Title>
          <Text size="xs" c="var(--colors-gray-5)">
            These settings will be used as defaults for the auto pricer and scheduled pricer and can be overridden for each listing.
          </Text>
          <Box py="xs">
            <BNSwitch outlined className={classes.switchOutline} {...form.getInputProps('broadcastSingleListing', { type: 'checkbox' })} label="Auto-Broadcast" size="sm" />

            <Text size="xs" c="var(--colors-gray-5)" py="xs">
              Automatically broadcast listings any time you enable auto-pricing.
            </Text>
          </Box>

          <Divider color="var(--colors-gray-1)" variant="dotted" my="xl" />
          <Title order={2} size="h4">
            Auto-Pricing Defaults
          </Title>
          <Text size="xs" c="var(--colors-gray-5)">
            These settings will be used as defaults for the auto pricer and can be overridden for each listing.
          </Text>

          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <AdjustmentValueInputDiagonal
                label="Adjustment"
                onChange={(value) => {
                  form.getInputProps('adjustmentValue').onChange(value.value);
                  form.getInputProps('adjustmentTypeId').onChange(value.type);
                }}
                value={form.values.adjustmentValue || 0}
                type={form.values.adjustmentTypeId || 'Amount'}
                disabled={false}
                numInputWidth={83}
              />
            </Box>
            <Box py="xs" w={134}>
              <BNNumberInput
                label="# Comparables"
                size="xs"
                min={1}
                selectOnFocus
                value={form.values.numComparables?.[0].numComparables}
                onChange={(value) => form.setFieldValue('numComparables.0.numComparables', value)}
              />
            </Box>
          </Group>
          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <BNNumberInput label="Floor" size="xs" step={1} min={0} decimalScale={2} fixedDecimalScale selectOnFocus {...form.getInputProps('floorMultiplier')} />
              <Text fz={11} mt={4} c="gray.5">
                % of lowest comparable
              </Text>
            </Box>
            <Box py="xs" w={134}>
              <BNNumberInput label="Ceiling" size="xs" step={1} min={0} decimalScale={2} fixedDecimalScale selectOnFocus {...form.getInputProps('ceilingMultiplier')} />
              <Text fz={11} mt={4} c="gray.5">
                % of lowest comparable
              </Text>
            </Box>
          </Group>
          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <BNNumberInput label="Floor" size="xs" step={1} min={0} decimalScale={2} fixedDecimalScale selectOnFocus {...form.getInputProps('floorCostMultiplier')} />
              <Text fz={11} mt={4} c="gray.5">
                % of listing cost
              </Text>
            </Box>
            <Box py="xs" w={134}>
              <BNNumberInput label="Ceiling" size="xs" step={1} min={0} decimalScale={2} fixedDecimalScale selectOnFocus {...form.getInputProps('ceilingCostMultiplier')} />
              <Text fz={11} mt={4} c="gray.5">
                % of listing cost
              </Text>
            </Box>
          </Group>
          <Group w={300} gap={12}>
            <Box py="xs" w={134}>
              <BNNumberInput label="# Active" min={1} size="xs" selectOnFocus {...form.getInputProps('numActive')} />
            </Box>
            <Box py="xs" w={134}>
              <StaggerByValueInput
                numInputWidth={83}
                disabled={false}
                label="Stagger By"
                onChange={(value) => {
                  form.getInputProps('staggerByValue').onChange(value.value);
                  form.getInputProps('staggerByTypeId').onChange(value.type);
                }}
                value={form.values.staggerByValue || 0}
                type={form.values.staggerByTypeId || 'Amount'}
              />
            </Box>
          </Group>
        </Box>
        <>
          <Divider color="var(--colors-gray-1)" my="xl" />
          <Box py="xl">
            <Title order={2} size="h4">
              Scheduled Pricing Defaults
            </Title>
            <Text size="xs" c="var(--colors-gray-5)">
              These settings will be used as defaults for the scheduled pricer and can be overridden for each listing.
            </Text>
            <Group w={300} gap={12}>
              <Box py="xs" w={134}>
                <AdjustmentValueInputDiagonal
                  label="Adjustment"
                  onChange={(value) => {
                    form.getInputProps('schedulePricerSettings.adjustmentValue').onChange(value.value);
                    form.getInputProps('schedulePricerSettings.adjustmentTypeId').onChange(value.type);
                  }}
                  value={form.values.schedulePricerSettings?.adjustmentValue || 0}
                  type={form.values.schedulePricerSettings?.adjustmentTypeId || 'Amount'}
                  disabled={false}
                  numInputWidth={83}
                />
              </Box>
              <Box py="xs" w={134}>
                <BNSelect
                  size="xs"
                  style={{ flex: 1 }}
                  miw={120}
                  label="Interval"
                  data={[
                    { value: '5', label: '5 Minutes' },
                    { value: '15', label: '15 Minutes' },
                    { value: '60', label: '1 Hour' },
                    { value: '1440', label: '1 Day' },
                  ]}
                  {...form.getInputProps('schedulePricerSettings.intervalMinutes')}
                  value={form.values.schedulePricerSettings?.intervalMinutes?.toString()}
                />
              </Box>
            </Group>
            <Group w={300} gap={12}>
              <Box py="xs" w={134}>
                <BNNumberInput
                  label="Floor"
                  size="xs"
                  step={1}
                  min={0}
                  decimalScale={2}
                  fixedDecimalScale
                  selectOnFocus
                  {...form.getInputProps('schedulePricerSettings.floorMultiplier')}
                />
                <Text fz={11} mt={4} color="var(--colors-gray-5)">
                  % of cost
                </Text>
              </Box>
              <Box py="xs" w={134}>
                <BNNumberInput
                  label="Ceiling"
                  size="xs"
                  step={1}
                  min={0}
                  decimalScale={2}
                  fixedDecimalScale
                  selectOnFocus
                  {...form.getInputProps('schedulePricerSettings.ceilingMultiplier')}
                />
                <Text fz={11} mt={4} color="var(--colors-gray-5)">
                  % of cost
                </Text>
              </Box>
            </Group>
            <Group w={300} gap={12}>
              <Box py="xs" w={134}>
                <BNNumberInput label="# Active" min={1} size="xs" selectOnFocus {...form.getInputProps('schedulePricerSettings.numActive')} />
              </Box>
              <Box py="xs" w={134}>
                <StaggerByValueInput
                  numInputWidth={83}
                  disabled={false}
                  label="Stagger By"
                  onChange={(value) => {
                    form.getInputProps('schedulePricerSettings.staggerByValue').onChange(value.value);
                    form.getInputProps('schedulePricerSettings.staggerByTypeId').onChange(value.type);
                  }}
                  value={form.values.schedulePricerSettings?.staggerByValue || 0}
                  type={form.values.schedulePricerSettings?.staggerByTypeId || 'Amount'}
                />
              </Box>
            </Group>
          </Box>
        </>
      </Box>
    </>
  );
}
