import { IconPropsBase } from './IconPropsBase';

export default function GroupIcon({ size = 20, color = 'currentColor' }: IconPropsBase) {
  if (size > 20) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M71.925 868.306v-88.922q0-30.923 15.962-55.191 15.961-24.269 42.632-37.764 57.021-27.89 114.674-43.005 57.654-15.115 126.731-15.115t126.73 15.115q57.654 15.115 114.675 43.005 26.671 13.495 42.632 37.764 15.961 24.268 15.961 55.191v88.922H71.925Zm679.997 0v-93.845q0-39.384-19.286-75.069-19.287-35.685-54.712-61.237 40.23 6 76.383 18.577 36.153 12.577 68.999 29.73 31 16.538 47.884 38.984 16.885 22.447 16.885 49.015v93.845H751.922ZM371.924 563.691q-57.749 0-98.874-41.124-41.125-41.125-41.125-98.874 0-57.75 41.125-98.874 41.125-41.125 98.874-41.125 57.749 0 98.874 41.125 41.124 41.124 41.124 98.874 0 57.749-41.124 98.874-41.125 41.124-98.874 41.124Zm345.381-139.998q0 57.749-41.125 98.874-41.125 41.124-98.874 41.124-6.769 0-17.23-1.538t-17.23-3.384q23.662-28.447 36.369-63.117t12.707-72.007q0-37.336-12.961-71.721Q566 317.54 542.846 288.617q8.615-3.077 17.23-4t17.23-.923q57.749 0 98.874 41.125 41.125 41.124 41.125 98.874ZM131.924 808.307h480v-28.923q0-12.538-6.269-22.308-6.27-9.769-19.885-17.077-49.385-25.461-101.692-38.577-52.308-13.115-112.154-13.115T259.77 701.422q-52.307 13.116-101.692 38.577-13.615 7.308-19.885 17.077-6.269 9.77-6.269 22.308v28.923Zm240-304.614q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0 304.614Zm0-384.614Z"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 96 960 960">
        <path
          fill={color}
          d="M127.925 836.306v-68.922q0-22.703 10.962-40.082 10.961-17.378 30.632-28.873 49.021-28.89 103.174-45.505 54.154-16.615 123.231-16.615t123.23 16.615q54.154 16.615 103.175 45.505 19.671 11.495 30.632 28.873 10.961 17.379 10.961 40.082v68.922H127.925Zm607.997 0v-65.845q0-37.384-14.286-68.569-14.287-31.185-37.712-50.737 28.23 8 55.883 19.577 27.653 11.577 54.499 27.73 17 9.538 27.384 29.158 10.385 19.619 10.385 42.841v65.845h-96.153ZM395.924 563.691q-51.749 0-87.874-36.124-36.125-36.125-36.125-87.874 0-51.75 36.125-87.874 36.125-36.125 87.874-36.125 51.749 0 87.874 36.125 36.124 36.124 36.124 87.874 0 51.749-36.124 87.874-36.125 36.124-87.874 36.124Zm281.381-123.998q0 51.749-36.125 87.874-36.125 36.124-87.874 36.124-3.769 0-4.23.462-.461.462-4.23-.384 21.662-25.447 34.369-56.617 12.707-31.17 12.707-67.507 0-36.336-12.961-67.221Q566 341.54 544.846 315.617q2.615-.077 4.23 0 1.615.077 4.23.077 51.749 0 87.874 36.125 36.125 36.124 36.125 87.874ZM179.924 784.307h432v-16.923q0-8.009-3.794-14.072-3.795-6.064-13.36-11.313-42.385-25.461-91.692-39.577-49.308-14.115-107.154-14.115T288.77 701.922q-49.307 13.616-91.692 40.077-9.565 5.134-13.36 10.99-3.794 5.857-3.794 14.302v17.016Zm216.212-272.614q29.788 0 50.788-21.213 21-21.212 21-51 0-29.787-21.212-50.787-21.213-21-51-21-29.788 0-50.788 21.212-21 21.213-21 51 0 29.788 21.212 50.788 21.213 21 51 21Zm-.212 272.614Zm0-344.614Z"
        />
      </svg>
    );
  }
}
