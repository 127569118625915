import { Group, HoverCard, NumberInput, NumberInputFactory, NumberInputProps, StylesApiProps, Text } from '@mantine/core';
import { forwardRef, ReactNode } from 'react';
import ErrorFilledIcon from '../icons/ErrorFilled';
import WarningIcon from '../icons/Warning';
import classes from './NumberInput.module.css';
import cx from 'clsx';
import { BNNumberInputBase } from './BNNumberInputBase';
import { useFlag } from '@unleash/proxy-client-react';

export type BNNumberInputProps = {
  errorVariant?: 'error' | 'warning';
  errorPosition?: 'auto' | 'bottom';
  selectOnFocus?: boolean;
  forceIconPointerEvents?: boolean;
  errorWithinPortal?: boolean;
  value?: number | '';
  onChange?: (value: number | '') => void;
  classNames?: StylesApiProps<NumberInputFactory>['classNames'];
} & Omit<NumberInputProps, 'onChange'>;

export interface ErrorHoverCardProps {
  label: ReactNode;
  error: ReactNode;
  inline?: boolean;
  errorVariant?: 'error' | 'warning';
  withinPortal?: boolean;
}

export const ErrorHoverCard = ({ label, error, inline, errorVariant, withinPortal }: ErrorHoverCardProps) => {
  return (
    <HoverCard
      position="top"
      shadow="md"
      withArrow
      withinPortal={withinPortal}
      styles={{
        arrow: {
          borderColor: errorVariant === 'warning' ? 'var(--colors-yellow-6)' : 'var(--colors-red-error)',
        },
        dropdown: {
          maxWidth: 220,
          borderColor: errorVariant === 'warning' ? 'var(--colors-yellow-6)' : 'var(--colors-red-error)',
          color: 'var(--colors-gray-7)',
          letterSpacing: '-0.0125em !important',
          textAlign: 'center',
        },
      }}
    >
      <HoverCard.Target>
        <Group wrap="nowrap" className={errorVariant === 'warning' ? 'warning' : 'error'} p={0} pl={inline ? 2 : 0} gap={inline ? 0 : 4} lh="1.25rem">
          {!inline && (
            <Text c={errorVariant === 'warning' ? 'var(--colors-yellow-6)' : 'var(--colors-red-error)'} lh="1.25rem">
              {label}
            </Text>
          )}
          {errorVariant === 'warning' ? <WarningIcon color="var(--colors-yellow-6)" size={20} /> : <ErrorFilledIcon color="var(--colors-red-error)" size={20} />}
        </Group>
      </HoverCard.Target>
      {typeof error === 'string' && (
        <HoverCard.Dropdown>
          <Text size="xs">{error}</Text>
        </HoverCard.Dropdown>
      )}
    </HoverCard>
  );
};

export const BNNumberInput = forwardRef<HTMLInputElement, BNNumberInputProps>(
  ({ label, error, leftSection, errorPosition, errorVariant, selectOnFocus, forceIconPointerEvents, errorWithinPortal, onChange, ...props }, ref) => {
    const newInputFlag = useFlag('bn-number-input');
    
    return (
      <>
        {newInputFlag && (
          <BNNumberInputBase
            {...props}
            onChange={(x) => onChange?.(x as number | '')}
            value={props.value ?? ''}
            inputMode="decimal"
            allowNegative={false}
            ref={ref}
            label={
              error ? (
                errorPosition === 'bottom' ? (
                  label
                ) : label ? (
                  <ErrorHoverCard withinPortal={errorWithinPortal} label={label} error={error} errorVariant={errorVariant} />
                ) : undefined
              ) : (
                label
              )
            }
            error={error ? (errorPosition === 'bottom' ? error : true) : false}
            errorProps={{ className: errorVariant === 'warning' ? 'warning' : 'error' }}
            leftSection={
              error ? (
                errorPosition === 'bottom' ? (
                  leftSection
                ) : label ? (
                  leftSection
                ) : (
                  <ErrorHoverCard withinPortal={errorWithinPortal} label={label} error={error} errorVariant={errorVariant} inline />
                )
              ) : (
                leftSection
              )
            }
            leftSectionProps={{
              className: error || forceIconPointerEvents ? classes.iconPointerInherit : classes.iconPointerNone,
            }}
            classNames={{
              root: cx(props.hideControls ? classes.hideControls : null, classes.root),
              input: cx(`${classes.numberInput} size-${props.size}`, error ? (errorVariant === 'warning' ? classes.errorWarning : classes.error) : null),
            }}
            onFocus={(e) => {
              if (selectOnFocus) {
                e.target.select();
              }

              if (props.onFocus) {
                props.onFocus(e);
              }
            }}
          />
        )}
        {!newInputFlag && (
          <NumberInput
            {...props}
            onChange={(x) => onChange?.(x as number | '')}
            value={props.value ?? ''}
            inputMode="decimal"
            allowNegative={false}
            ref={ref}
            label={
              error ? (
                errorPosition === 'bottom' ? (
                  label
                ) : label ? (
                  <ErrorHoverCard withinPortal={errorWithinPortal} label={label} error={error} errorVariant={errorVariant} />
                ) : undefined
              ) : (
                label
              )
            }
            error={error ? (errorPosition === 'bottom' ? error : true) : false}
            errorProps={{ className: errorVariant === 'warning' ? 'warning' : 'error' }}
            leftSection={
              error ? (
                errorPosition === 'bottom' ? (
                  leftSection
                ) : label ? (
                  leftSection
                ) : (
                  <ErrorHoverCard withinPortal={errorWithinPortal} label={label} error={error} errorVariant={errorVariant} inline />
                )
              ) : (
                leftSection
              )
            }
            leftSectionProps={{
              className: error || forceIconPointerEvents ? classes.iconPointerInherit : classes.iconPointerNone,
            }}
            classNames={{
              root: cx(props.hideControls ? classes.hideControls : null, classes.root),
              input: cx(`${classes.numberInput} size-${props.size}`, error ? (errorVariant === 'warning' ? classes.errorWarning : classes.error) : null),
            }}
            onFocus={(e) => {
              if (selectOnFocus) {
                e.target.select();
              }

              if (props.onFocus) {
                props.onFocus(e);
              }
            }}
          />
        )}
      </>
    );
  },
);
