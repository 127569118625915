import { Text } from '@mantine/core';
import { useDebouncedValue, useDidUpdate, useListState, useToggle } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mergedEventListingsUnfilteredRulesAtom } from '../../data/atoms';
import { SortItem } from './Inventory.SortItem';
import { onColumnMoved, onColumnSorted, useInventory } from './Inventory.hooks';
import yasml from '@thirtytech/yasml';
import { ColumnPinnedType } from '@ag-grid-community/core';
import { seasons } from '../../data/atoms.seasons';

type ColumnSettingItem = {
  sortIndex?: number;
  key: string;
  name: string;
  visible: boolean; // TODO: Rename this to hide to match the grid eventually.
  width: number | undefined;
  pinned: ColumnPinnedType;
};

const ALLOWED_SORTING_EVENT_COLUMNS: SortItem[] = [
  { key: 'event.localDateTime', name: 'Date', dir: 'asc' },
  { key: 'event.name', name: 'Name', dir: null },
  { key: 'event.venue.name', name: 'Venue Name', dir: null },
  { key: 'event.venue.city', name: 'City', dir: null },
  { key: 'event.openCost', name: 'Open Cost', dir: null },
  { key: 'event.openTickets', name: 'Open Tickets', dir: null },
  { key: 'event.openListings', name: 'Open Listings', dir: null },
];
ALLOWED_SORTING_EVENT_COLUMNS.sort((a, b) => a.name.localeCompare(b.name));
const InventoryHeaderState = () => {
  const {
    inventoryQuickFilter,
    setInventoryQuickFilter,
    toggleShowSort,
    gridRef,
    gridReady,
    clearInventoryGridState,
    inventoryGridState,
    defaultColumnDefs,
    filter,
    setFilter,
  } = useInventory(
    'inventoryQuickFilter',
    'setInventoryQuickFilter',
    'toggleShowSort',
    'gridRef',
    'gridReady',
    'clearInventoryGridState',
    'inventoryGridState',
    'defaultColumnDefs',
    'filter',
    'setFilter',
  );

  const selectedSeason = useAtomValue(seasons.selectedSeasonAtom);
  const allListings = useAtomValue(mergedEventListingsUnfilteredRulesAtom);
  const [showSettings, setShowSettings] = useState(false);
  const [listingSort, listingSortHandlers] = useListState<SortItem>([]);
  const [columnSettings, columnSettingsHandlers] = useListState<ColumnSettingItem>([]);
  const [enableUpdates, toggleUpdates] = useToggle();
  const inventoryGridStateRef = useRef(inventoryGridState);
  const [columnSettingsDebounced] = useDebouncedValue(columnSettings, 250);
  const [listingSortDebounced] = useDebouncedValue(listingSort, 250);

  const allowedSortingColumns = useMemo(
    () =>
      structuredClone(
        defaultColumnDefs
          .filter((x) => x.sortIndex != null || x.allowSorting !== false)
          .map((x) => ({ key: x.field || x.colId!, name: x.headerName || x.field || x.colId!, dir: x.sort, sortIndex: x.sortIndex })),
      ).sort((a, b) => {
        const aIndex = typeof a.sortIndex === 'number' ? a.sortIndex : Infinity;
        const bIndex = typeof b.sortIndex === 'number' ? b.sortIndex : Infinity;
        return aIndex - bIndex || (a.dir || '').localeCompare(b.dir || '') || a.name.localeCompare(b.name);
      }),
    [defaultColumnDefs],
  );

  const defaultColumnSettings = useMemo(() => {
    const columnsWithDefaultWidths = defaultColumnDefs
      .filter((x) => !x.exclude)
      .map((x) => ({ key: x.field || x.colId!, name: x.headerName!, visible: !x.hide, width: x.width ?? undefined, pinned: null }));
    return columnsWithDefaultWidths;
  }, [defaultColumnDefs]);

  useDidUpdate(() => {
    inventoryGridStateRef.current = inventoryGridState;
  }, [inventoryGridState]);

  const resetListingDefaults = useCallback(() => {
    listingSortHandlers.setState(allowedSortingColumns);
  }, [allowedSortingColumns, listingSortHandlers]);

  const resetColumnDefaults = useCallback(() => {
    columnSettingsHandlers.setState(defaultColumnSettings);
  }, [defaultColumnSettings, columnSettingsHandlers]);

  const resetAllDefaults = useCallback(() => {
    openConfirmModal({
      title: 'Reset confirmation',
      children: <Text size="sm">Are you sure you want to reset all the grid settings to their defaults?</Text>,
      labels: { confirm: 'Reset', cancel: 'Cancel' },
      confirmProps: { className: 'confirmButton', variant: 'filled', color: 'gray', size: 'sm' },
      cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm' },
      closeButtonProps: { size: 'md' },
      onConfirm: () => {
        clearInventoryGridState();
        resetColumnDefaults();
        resetListingDefaults();
      },
    });
  }, [clearInventoryGridState, resetColumnDefaults, resetListingDefaults]);

  useDidUpdate(() => {
    if (enableUpdates) {
      const updateOrder = listingSortDebounced.map((x, i) => ({ colId: x.key, sort: x.dir, sortIndex: i }));
      gridRef.current?.columnApi.applyColumnState({
        state: updateOrder,
      });
    }
  }, [listingSortDebounced]);

  useDidUpdate(() => {
    if (enableUpdates) {
      gridRef.current?.columnApi.applyColumnState({
        applyOrder: true,
        state: [{ colId: 'dragCell' }, ...columnSettingsDebounced.map((x) => ({ colId: x.key, hide: !x.visible, width: x.width, pinned: x.pinned }))],
      });
    }
  }, [columnSettingsDebounced]);

  const isMenuOpened = useRef(false);
  const onMenuVisibilityChange = useCallback(() => {
    isMenuOpened.current = !isMenuOpened.current;
  }, []);

  const processListingSort = useCallback(
    ({ initialRender }: { initialRender?: boolean } = { initialRender: false }) => {
      const columnState = gridRef.current!.columnApi.getColumnState();
      const columnSort = columnState
        .sort((x) => x.sortIndex || Infinity)
        .map((x) => {
          const columnDef = gridRef.current?.columnApi.getColumn(x.colId)?.getColDef();
          return {
            key: x.colId,
            name: columnDef?.headerName ?? columnDef?.field ?? '',
            dir: x.sort,
            sortIndex: x.sortIndex,
          } satisfies SortItem;
        });

      let finalListingSort = [...columnSort.filter((x) => allowedSortingColumns.map((y) => y.key.toLowerCase()).includes(x.key.toLowerCase()))];
      if (initialRender) {
        finalListingSort = structuredClone(
          finalListingSort.map((x) => {
            const savedState = inventoryGridStateRef.current?.find((y) => y.colId === x.key) || {};
            const initialValues = allowedSortingColumns.find((y) => y.key === x.key) || {};
            return { ...x, ...initialValues, ...savedState };
          }),
        );
      }
      finalListingSort.sort((a, b) => {
        const aIndex = typeof a.sortIndex === 'number' ? a.sortIndex : Infinity;
        const bIndex = typeof b.sortIndex === 'number' ? b.sortIndex : Infinity;
        return aIndex - bIndex || (a.dir || '').localeCompare(b.dir || '') || a.name.localeCompare(b.name);
      });
      listingSortHandlers.setState(finalListingSort);
    },
    [allowedSortingColumns, gridRef, listingSortHandlers],
  );

  const processColumnSettings = useCallback(() => {
    const ignoredColIds = defaultColumnDefs.filter((x) => x.exclude).map((x) => x.field || x.colId!);
    const columns = gridRef.current!.columnApi.getColumns();
    const columnState = gridRef.current!.columnApi.getColumnState()!;
    const _columnSettingItems = columnState
      .filter((col) => !ignoredColIds.includes(col.colId))
      .map((col) => {
        const columnDef = columns?.find((x) => x.getColId() === col.colId)?.getColDef();
        return {
          key: col.colId,
          name: columnDef?.headerName ?? columnDef?.field ?? '',
          visible: !col.hide,
          width: col.width,
          pinned: col.pinned,
          sortIndex: col.sortIndex || 0,
        };
      });

    columnSettingsHandlers.setState(_columnSettingItems);
  }, [columnSettingsHandlers, defaultColumnDefs, gridRef]);

  const onColumnMovedEvt = useCallback(() => {
    if (!isMenuOpened.current) {
      processColumnSettings();
    }
  }, [processColumnSettings]);

  const onColumnSortedEvt = useCallback(() => {
    if (!isMenuOpened.current) {
      processListingSort();
    }
  }, [processListingSort]);

  useEffect(() => {
    if (enableUpdates) {
      document.addEventListener(onColumnMoved.type, onColumnMovedEvt);
      document.addEventListener(onColumnSorted.type, onColumnSortedEvt);
    }
    return () => {
      document.removeEventListener(onColumnMoved.type, onColumnMovedEvt);
      document.removeEventListener(onColumnSorted.type, onColumnSortedEvt);
    };
  }, [enableUpdates, onColumnMovedEvt, onColumnSortedEvt]);

  useEffect(() => {
    if (gridReady) {
      processListingSort({ initialRender: true });
      processColumnSettings();

      setTimeout(() => toggleUpdates(), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridReady]);

  const toggleSettings = useCallback(() => {
    setShowSettings(!showSettings);
    toggleShowSort();
  }, [showSettings, toggleShowSort]);

  return {
    selectedSeason,
    columnSettings,
    columnSettingsHandlers,
    filter,
    setFilter,
    setInventoryQuickFilter,
    inventoryQuickFilter,
    showSettings,
    listingSort,
    listingSortHandlers,
    resetAllDefaults,
    resetColumnDefaults,
    resetListingDefaults,
    toggleSettings,
    onMenuVisibilityChange,
    allListings,
  };
};

export const { Provider: InventoryHeaderStateProvider, useSelector: useInventoryHeader } = yasml(InventoryHeaderState);
