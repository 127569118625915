import { ActionIcon, Box, Paper, Text, Tooltip } from '@mantine/core';
import MenuOpenIcon from '../components/icons/MenuOpen';
import { SearchResultStateProvider } from '../hoc/EventSearch/SearchResults.hooks';
import { SeatingChartProvider } from '../hoc/SeatingChart/SeatingChart.hooks';
import { RuleStateProvider } from '../data/RuleState';
import { BulletinModal } from '../hoc/Bulletin/BulletinModal';
import { InviteModal } from '../hoc/InviteModal/InviteModal';
import { useLocalStorage } from '@mantine/hooks';
import { VersionNotificationBanner } from '../hoc/Banners/VersionNotificationBanner';
import { SupportActivationBanner } from '../hoc/Banners/SupportActivationBanner';
import { BulkStateProvider, useBulkState } from '../data/BulkState';
import { BulkExitOverlay } from '../components/Overlay/BulkExitOverlay';
import { PlanExpirationBanner } from '../hoc/Banners/PlanExpirationBanner';
import { UnpaidInvoiceBanner } from '../hoc/Banners/UnpaidInvoiceBanner';
import { EventPerformance } from '../hoc/EventPerformance/EventPerformance';
import { IntegrationProblemBanner } from '../hoc/Banners/IntegrationProblemBanner';
import { EventPerformanceProvider } from '../hoc/EventPerformance/EventPerformance.hooks';
import { ListingStateProvider } from '../data/ListingState';
import { useAtomValue } from 'jotai';
import pluralize from 'pluralize';
import { selectedSearchEventsAtom } from '../data/atoms';
import cx from 'clsx';
import { useFlag } from '@unleash/proxy-client-react';
import { HomeStateProvider, useHomeState } from './Home.hooks';
import { StackedFilters } from '../hoc/EventSearch/Search.StackedFilters';
import { PricerView } from '../types';
import { isDtiHosted } from '../utils/whitelabel-consts';
import { DTIInventoryStateProvider } from '../hoc/DTI/DTI.Inventory.hooks';
import { DTIModals } from '../hoc/DTI/DTIModals';
import { NowPricing } from '../hoc/NowPricing/NowPricing';
import { SeatingChart } from '../hoc/SeatingChart/SeatingChart';
import { BNSidebar } from '../hoc/NavBar/Sidebar';
import { Search } from '../hoc/EventSearch/Search';
import { SearchResults } from '../hoc/EventSearch/SearchResults';
import { NavBar } from '../hoc/NavBar/NavBar';
import { Inventory } from '../hoc/Inventory/Inventory';
import { MarketListings } from '../hoc/MarketListings/MarketListings';
import { useGlobalState } from '../data/GlobalState';

export default function Home() {
  return (
    <ListingStateProvider>
      <RuleStateProvider>
        <BulkStateProvider>
          <BulletinModal />
          <InviteModal />
          <div className="outer-appWrap">
            <VersionNotificationBanner />
            <SupportActivationBanner />
            <PlanExpirationBanner />
            <UnpaidInvoiceBanner />
            <IntegrationProblemBanner />
            <HomeStateProvider>
              {isDtiHosted ? (
                <DTIInventoryStateProvider>
                  <HomeInterior />
                  <DTIModals />
                </DTIInventoryStateProvider>
              ) : (
                <HomeInterior />
              )}
            </HomeStateProvider>
          </div>
        </BulkStateProvider>
      </RuleStateProvider>
    </ListingStateProvider>
  );
}

function HomeInterior() {
  const [showSidebar, setShowSidebar] = useLocalStorage({
    key: 'setting-sidebar',
    defaultValue: true,
    getInitialValueInEffect: false,
  });
  const searchAdvancedFlag = useFlag('search-advanced');
  const inventoryEventsFlag = useFlag('split-event-inventory');
  const { isTrendsCollapsed, handleTrendsToggle } = useHomeState('isTrendsCollapsed', 'handleTrendsToggle');
  const { pricerView } = useGlobalState('pricerView');
  const selectedEvents = useAtomValue(selectedSearchEventsAtom);
  const { showBulkOptions } = useBulkState('showBulkOptions');
  const newSidebarFlag = useFlag('updated-flyout-sidebar');

  return (
    <div className="inner-appWrap">
      <div className={cx('appWrap', showSidebar ? 'sidebar-open' : 'sidebar-collapse', isTrendsCollapsed && 'trends-collapse')}>
        <div className="col1">
          {newSidebarFlag ? (
            <BNSidebar>
              {showBulkOptions && <BulkExitOverlay />}
              <Paper className="sidebarSearchWrapper" pos="relative">
                <SearchResultStateProvider>
                  <Search />
                  {!inventoryEventsFlag && <SearchResults />}
                  {inventoryEventsFlag && pricerView !== PricerView.Split && <SearchResults />}
                  {searchAdvancedFlag && pricerView === PricerView.Split && <StackedFilters />}
                </SearchResultStateProvider>
              </Paper>
            </BNSidebar>
          ) : (
            <Paper>
              {showBulkOptions && <BulkExitOverlay />}
              {/* Add sidebar collapse control here so it can apply a css class that can then cascade throughout the app? */}
              <Tooltip label={showSidebar ? 'Collapse Sidebar' : 'Expand Sidebar'} position="right" withArrow>
                <ActionIcon className="menuTrigger" onClick={() => setShowSidebar(!showSidebar)} data-pendo-name="NavBar Collapse Button">
                  <MenuOpenIcon color="var(--colors-gray-5)" size={24} />
                </ActionIcon>
              </Tooltip>
              <NavBar
                title={
                  <Text
                    c="var(--colors-gray-5)"
                    style={{
                      textTransform: 'uppercase',
                      letterSpacing: '0.05em',
                      fontSize: '0.6875rem',
                    }}
                    fz={11}
                  >
                    {pricerView === PricerView.Split ? 'Search' : `${selectedEvents.length.toLocaleString()} ${pluralize('Event', selectedEvents.length)}`}
                  </Text>
                }
              />
              <Paper
                className="searchWrapper"
                pos="relative"
                onMouseLeave={() => {
                  document.body.classList.remove('flyout');
                }}
              >
                <SearchResultStateProvider>
                  <Search />
                  {!inventoryEventsFlag && <SearchResults />}
                  {inventoryEventsFlag && pricerView !== PricerView.Split && <SearchResults />}
                  {searchAdvancedFlag && pricerView === PricerView.Split && <StackedFilters />}
                </SearchResultStateProvider>
              </Paper>
            </Paper>
          )}
        </div>
        <div className={cx('col2', inventoryEventsFlag && pricerView === PricerView.Split ? 'stacked-grids' : null)}>
          <Inventory />
        </div>
        {showBulkOptions && (
          <Box className="bulk-overlay-right">
            <BulkExitOverlay />
          </Box>
        )}
        <div className="appRight">
          <div className="col3">
            <Paper className="seatingChartWrapper" pos="relative">
              <SeatingChartProvider>
                <SeatingChart isTrendsCollapsed={isTrendsCollapsed} handleTrendsToggle={handleTrendsToggle} />
              </SeatingChartProvider>
            </Paper>
          </div>
          <div className="col4">
            <NowPricing />
            <MarketListings />
          </div>
          <EventPerformanceProvider>
            <EventPerformance />
          </EventPerformanceProvider>
        </div>
      </div>
    </div>
  );
}
