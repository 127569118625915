import classes from './TextInput.tsx.module.css';
import { ActionIcon, Group, HoverCard, Text, TextInput, TextInputProps } from '@mantine/core';
import { ReactNode, RefObject, useRef } from 'react';
import CloseIcon from '../icons/Close';
import ErrorFilledIcon from '../icons/ErrorFilled';
import WarningIcon from '../icons/Warning';
import cx from 'clsx';

interface BNTextInputProps extends TextInputProps {
  clearable?: boolean;
  clearableOnClick?: () => void;
  errorVariant?: 'error' | 'warning';
  errorPosition?: 'auto' | 'bottom';
}

interface ErrorHoverCardProps {
  label: ReactNode;
  error: ReactNode;
  inline?: boolean;
  errorVariant?: 'error' | 'warning';
}

const ErrorHoverCard = ({ label, error, inline, errorVariant }: ErrorHoverCardProps) => {
  return (
    <HoverCard
      position="top"
      shadow="md"
      withArrow
      styles={{
        arrow: {
          borderColor: errorVariant === 'warning' ? 'var(--colors-yellow-6)' : 'var(--colors-red-error)',
        },
        dropdown: {
          maxWidth: 220,
          borderColor: errorVariant === 'warning' ? 'var(--colors-yellow-6)' : 'var(--colors-red-error)',
          color: 'var(--colors-gray-7)',
          letterSpacing: '-0.0125em !important',
          textAlign: 'center',
        },
      }}
    >
      <HoverCard.Target>
        <Group className={errorVariant === 'warning' ? 'warning' : 'error'} p={0} pl={inline ? 2 : 0} gap={inline ? 0 : 4} lh="1.25rem">
          {!inline && (
            <Text c={errorVariant === 'warning' ? 'var(--colors-yellow-6)' : 'var(--colors-red-error)'} lh="1.25rem">
              {label}
            </Text>
          )}
          {errorVariant === 'warning' ? <WarningIcon color="var(--colors-yellow-6)" size={20} /> : <ErrorFilledIcon color="var(--colors-red-error)" size={20} />}
        </Group>
      </HoverCard.Target>
      {typeof error === 'string' && (
        <HoverCard.Dropdown>
          <Text size="xs">{error}</Text>
        </HoverCard.Dropdown>
      )}
    </HoverCard>
  );
};

interface ClearableProps {
  clearableOnClick?: () => void;
  onChange: () => void;
  inputRef?: RefObject<HTMLInputElement>;
}

const Clearable = ({ clearableOnClick, inputRef, onChange }: ClearableProps) => {
  return (
    <ActionIcon
      size="sm"
      c="var(--colors-gray-4)"
      tabIndex={-1}
      className="clearButton"
      onClick={() => {
        if (clearableOnClick) {
          clearableOnClick();
        } else {
          onChange();
        }

        if (inputRef) {
          inputRef.current?.focus();
        }
      }}
    >
      <CloseIcon />
    </ActionIcon>
  );
};

/**
 * Text Input Field
 */
export const BNTextInput = ({ label, error, leftSection, errorVariant, errorPosition, clearable, clearableOnClick, ...props }: BNTextInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  if (clearable && props.value) {
    props.rightSection = (
      <Clearable
        onChange={() => {
          if (ref.current) {
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
            nativeInputValueSetter?.call(ref.current, '');
            const ev2 = new Event('input', { bubbles: true });
            ref.current.dispatchEvent(ev2);
          }
        }}
        clearableOnClick={clearableOnClick}
        inputRef={ref}
      />
    );
  }
  return (
    <TextInput
      {...props}
      label={error ? errorPosition === 'bottom' ? label : label ? <ErrorHoverCard label={label} error={error} errorVariant={errorVariant} /> : undefined : label}
      error={error ? (errorPosition === 'bottom' ? error : true) : false}
      errorProps={{ className: errorVariant === 'warning' ? 'warning' : 'error' }}
      rightSectionWidth={props.rightSectionWidth || '1.875rem'}
      leftSection={
        error ? errorPosition === 'bottom' ? leftSection : label ? leftSection : <ErrorHoverCard label={label} error={error} errorVariant={errorVariant} inline /> : leftSection
      }
      mt={props.mt ?? 0}
      pt={props.pt ?? 0}
      classNames={{
        root: cx(classes.root, error && (errorVariant === 'warning' ? classes.inputWarning : classes.inputError), null),
        input: cx(clearable ? classes.clearableInput : null),
      }}
      leftSectionPointerEvents={error ? 'inherit' : 'none'}
      ref={ref}
    />
  );
};
