import { Accordion, Flex, ScrollArea, SimpleGrid, Stack, Text } from '@mantine/core';
import StadiumIcon from '../../components/icons/Stadium';
import ReceiptIcon from '../../components/icons/Receipt';
import classes from './Sales.Filters.module.css';
import { BNSelect } from '../../components/Select/Select';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { BNDatePicker } from '../../components/DatePicker/DatePicker';
import DateRangeIcon from '../../components/icons/DateRange';
import { useSales } from './Sales.hooks';
import { BarkerCoreEnumsFulfillmentStatus, BarkerCoreEnumsPaymentStatus } from '../../api';

export function SalesFilters() {
  const { form, search } = useSales('form', 'search');
  // const { isDtiNative, isDtiOpu } = useGlobalState('isDtiNative', 'isDtiOpu');
  //
  // const isNotDti = !isDtiNative && !isDtiOpu;

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      search(form.values);
    }
  }

  return (
    <Flex direction="column" h="100%" flex={1} style={{ overflow: 'hidden' }}>
      <Flex h={32} align="center" px="sm" justify="space-between" style={{ borderBottom: '0.0625rem solid var(--colors-paperHover)' }}>
        <Text tt="uppercase" lts={0.5} c="var(--colors-gray-5)" fz={11}>
          Advanced Search
        </Text>
      </Flex>
      <Flex h="100%" flex={1} style={{ overflow: 'hidden' }} pos="relative">
        <ScrollArea.Autosize h="100%" w="100%" pos="absolute" inset={0}>
          <Accordion className={classes.accordion} multiple defaultValue={['Events', 'Listings', 'Invoices']}>
            <Accordion.Item key="events" value="Events">
              <Accordion.Control icon={<StadiumIcon />}>
                <Text fw={600}>Events</Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Stack gap={4} mt={-4}>
                  <SimpleGrid cols={2} spacing="sm">
                    <BNDatePicker
                      leftSection={<DateRangeIcon />}
                      valueFormat="MM/DD/YY"
                      clearable
                      rightSectionWidth={24}
                      label="Date From"
                      size="xs"
                      {...form.getInputProps('eventDateFrom')}
                      onChange={(dateValue) => {
                        if (dateValue === null) {
                          form.setFieldValue('eventDateFrom', dateValue);
                          return;
                        }

                        form.setFieldValue('eventDateFrom', dateValue);

                        if (form.values.eventDateTo && form.values.eventDateTo < dateValue) {
                          form.setFieldValue('eventDateTo', dateValue);
                        } else if (!form.values.eventDateTo) {
                          form.setFieldValue('eventDateTo', dateValue);
                        }
                      }}
                      onKeyUp={handleKeyUp}
                      data-pendo-name="Sales - Event Date From Input"
                    />
                    <BNDatePicker
                      leftSection={<DateRangeIcon />}
                      valueFormat="MM/DD/YY"
                      clearable
                      rightSectionWidth={24}
                      label="Date To"
                      size="xs"
                      {...form.getInputProps('eventDateTo')}
                      onChange={(dateValue) => {
                        if (dateValue === null) {
                          form.setFieldValue('eventDateTo', dateValue);
                          return;
                        }

                        form.setFieldValue('eventDateTo', dateValue);

                        if (form.values.eventDateFrom && form.values.eventDateFrom > dateValue) {
                          form.setFieldValue('eventDateFrom', dateValue);
                        } else if (!form.values.eventDateFrom) {
                          form.setFieldValue('eventDateFrom', dateValue);
                        }
                      }}
                      onKeyUp={handleKeyUp}
                      data-pendo-name="Sales - Event Date To Input"
                    />
                  </SimpleGrid>
                  {/*{isDtiNative && (*/}
                  {/*  <>*/}
                  {/*    <BNSelect size="xs" label="Performer" data={exampleHeadliners} defaultValue="All" />*/}
                  {/*    <BNSelect size="xs" label="Location" data={exampleHeadliners} defaultValue="All" />*/}
                  {/*    <BNSelect size="xs" label="Completion Status" data={exampleHeadliners} defaultValue="All" />*/}
                  {/*  </>*/}
                  {/*)}*/}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
            {/*{isDtiNative && (*/}
            {/*  <Accordion.Item key="listings" value="Listings">*/}
            {/*    <Accordion.Control icon={<ListIcon />}>*/}
            {/*      <Text fw={600}>Listings</Text>*/}
            {/*    </Accordion.Control>*/}
            {/*    <Accordion.Panel>*/}
            {/*      <Stack gap={4} mt={-4}>*/}
            {/*        <BNTextInput size="xs" placeholder="Section Name" label="Section" clearable clearableOnClick={() => {}} />*/}
            {/*        <BNTextInput size="xs" placeholder="Row Name" label="Row" value="AA" clearable clearableOnClick={() => {}} />*/}
            {/*      </Stack>*/}
            {/*    </Accordion.Panel>*/}
            {/*  </Accordion.Item>*/}
            {/*)}*/}
            <Accordion.Item key="invoices" value="Invoices">
              <Accordion.Control icon={<ReceiptIcon />}>
                <Text fw={600}>Invoice</Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Stack gap={4} mt={-4}>
                  {/*{isNotDti && (*/}
                  {/*  <>*/}
                  <BNTextInput
                    size="xs"
                    label="Invoice ID"
                    {...form.getInputProps('invoiceId')}
                    clearable
                    clearableOnClick={() => form.setFieldValue('invoiceId', '')}
                    onKeyUp={handleKeyUp}
                    data-pendo-name="Sales - Invoice ID Input"
                  />
                  <BNSelect
                    size="xs"
                    label="Fulfillment Status"
                    {...form.getInputProps('fulfillmentStatusId')}
                    data={Object.keys(BarkerCoreEnumsFulfillmentStatus).filter((x) => x !== 'Unknown')}
                    clearable
                    onKeyUp={handleKeyUp}
                    data-pendo-name="Sales - Fulfillment Status Select"
                  />
                  <BNSelect
                    size="xs"
                    label="Payment Status"
                    {...form.getInputProps('paymentStatusId')}
                    data={Object.keys(BarkerCoreEnumsPaymentStatus).filter((x) => x !== 'Unknown')}
                    clearable
                    withinPortal
                    onKeyUp={handleKeyUp}
                    data-pendo-name="Sales - Payment Status Select"
                  />
                  <BNTextInput
                    size="xs"
                    label="External Reference"
                    {...form.getInputProps('externalReference')}
                    clearable
                    clearableOnClick={() => form.setFieldValue('externalReference', '')}
                    onKeyUp={handleKeyUp}
                    data-pendo-name="Sales - External Reference Input"
                  />
                  {/*  </>*/}
                  {/*)}*/}
                  {/*{isDtiNative && (*/}
                  {/*  <>*/}
                  {/*    <BNTextInput size="xs" label="Invoice ID" clearable clearableOnClick={() => {}} />*/}
                  {/*    <BNSelect size="xs" label="Invoice Status" data={exampleHeadliners} defaultValue="All" />*/}
                  {/*    <BNTextInput size="xs" label="Buyer" clearable clearableOnClick={() => {}} />*/}
                  {/*  </>*/}
                  {/*)}*/}
                  {/*{isDtiOpu && <BNSelect size="xs" label="OPU Invoice ID" data={exampleHeadliners} defaultValue="All" />}*/}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </ScrollArea.Autosize>
      </Flex>
    </Flex>
  );
}
