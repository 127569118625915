import { ActionIcon, Avatar, Badge, Box, Card, Container, Group, Loader, Paper, Popover, Tabs, Text, Tooltip } from '@mantine/core';
import { BNButton } from '../../components/Button/Button';
import CloseIcon from '../../components/icons/Close';
import SearchIcon from '../../components/icons/Search';
import { BNTextInput } from '../../components/TextInput/TextInput';
import DateRangeIcon from '../../components/icons/DateRange';
import LinkIcon from '../../components/icons/Link';
import { useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import { openConfirmModal } from '@mantine/modals';
import { DateFormats } from '../../utils/globals';
import { useEventMapping } from './EventMapping.hooks';
import { EventMappingSearchResults } from './EventMapping.SearchResults';
import pluralize from 'pluralize';
import { EventMappingSearchResultsCard } from './EventMapping.SearchResultsCard';
import { BarkerCoreEnumsMarketplace } from '../../api';
import { ReactComponent as SeatGeekLogo } from '../../img/sg.svg';
import { useFocusTrap } from '@mantine/hooks';
import { formatDate } from '../../utils/formatters';
import tevo from '../../img/tevo.png';
import tnet from '../../img/tnet.jpeg';
import tm from '../../img/tm.png';
import SyncAltIcon from '../../components/icons/SyncAlt';
import classes from './EventMapping.styles.tsx.module.css';
import { MarketplaceSelector } from '../SeatingChart/SeatingChart.MarketplaceSelector';

export function EventMapping() {
  const {
    setShowEventMapping,
    selectedEvent,
    searchForm,
    searchEvents,
    searchResults,
    selectedMarketplaceEvent,
    pasteUrlForm,
    pastedEvent,
    fetchPastedEvent,
    isPastedEventLoading,
    unlinkEvent,
    isUnlinkingEvent,
    linkEvent,
    clearSearchResults,
  } = useEventMapping(
    'setShowEventMapping',
    'selectedEvent',
    'searchForm',
    'searchEvents',
    'searchResults',
    'selectedMarketplaceEvent',
    'pasteUrlForm',
    'pastedEvent',
    'fetchPastedEvent',
    'isPastedEventLoading',
    'unlinkEvent',
    'isUnlinkingEvent',
    'linkEvent',
    'clearSearchResults',
  );
  const [isMarketSelectorOpen, setIsMarketSelectorOpen] = useState(false);

  const focusTrapRef = useFocusTrap();

  return selectedEvent ? (
    <Paper className={classes.eventMappingPanel}>
      <Group p={16} pt={12} pb={0} wrap="wrap" justify="space-between" className={classes.wrapper}>
        {/* Header Area */}
        <Group p={0} h={40} gap={8}>
          <Popover
            position="bottom"
            withArrow
            opened={isMarketSelectorOpen}
            closeOnClickOutside
            onClose={() => {
              setIsMarketSelectorOpen(false);
            }}
          >
            <Tooltip label="Switch Marketplace" position="right" withArrow>
              <Popover.Target>
                {selectedMarketplaceEvent?.marketplaceId === BarkerCoreEnumsMarketplace.SeatGeek ? (
                  <Avatar
                    key="seatgeek"
                    m={0}
                    radius="xl"
                    variant="outline"
                    className={classes.avatar}
                    color="var(--colors-darkPaper)"
                    onClick={() => {
                      setIsMarketSelectorOpen(!isMarketSelectorOpen);
                    }}
                  >
                    <ActionIcon>
                      <SyncAltIcon color="var(--colors-gray-5)" size={20} />
                    </ActionIcon>
                    <SeatGeekLogo />
                  </Avatar>
                ) : (
                  <Avatar
                    key="other"
                    m={0}
                    radius="xl"
                    variant="outline"
                    className={classes.avatar}
                    color="var(--colors-darkPaper"
                    onClick={() => setIsMarketSelectorOpen(!isMarketSelectorOpen)}
                  >
                    <ActionIcon>
                      <SyncAltIcon color="var(--colors-gray-5)" size={20} />
                    </ActionIcon>
                    <img
                      className="mantine-Avatar-image"
                      height={40}
                      width={40}
                      src={
                        selectedMarketplaceEvent?.marketplaceId === BarkerCoreEnumsMarketplace.TicketEvolution
                          ? tevo
                          : selectedMarketplaceEvent?.marketplaceId === BarkerCoreEnumsMarketplace.TicketNetwork
                            ? tnet
                            : tm
                      }
                      alt="Selected Marketplace"
                    />
                  </Avatar>
                )}
              </Popover.Target>
            </Tooltip>
            <Popover.Dropdown>
              <MarketplaceSelector onChange={() => setIsMarketSelectorOpen(false)} />
            </Popover.Dropdown>
          </Popover>
          <Box className={classes.listingText}>
            <>
              <Text size="sm" fw={600} className={classes.name}>
                Edit Event Mapping
              </Text>
            </>
          </Box>
        </Group>
        <ActionIcon
          onClick={() => {
            setShowEventMapping((x) => !x);
          }}
        >
          <CloseIcon />
        </ActionIcon>
        {/* Event POS Data Card */}
        <Card mx={0} p="md" py="sm" mt="sm" mb={16} className={classes.card}>
          <Group wrap="nowrap" justify="space-between" gap={8}>
            <Text c="var(--colors-brandcolor-7)" size="xs" fw={700} tt="uppercase">
              {formatDate(selectedEvent.localDateTime, DateFormats.Standard)}
            </Text>
            <Badge variant="light" size="xs" color="var(--colors-gray-5)" bg="var(--colors-divider)">
              POS Data
            </Badge>
          </Group>
          <Group>
            <Box pr={20} style={{ flex: 1, overflow: 'hidden' }}>
              <Text size="sm" fw={600} mt={6} lh={1.3}>
                {selectedEvent.name}
              </Text>
              <Group wrap="nowrap" w="100%" gap={0} mt={6}>
                <Text size="xs" c="var(--colors-gray-5)" truncate>
                  {selectedEvent.venue.name}
                </Text>
                <Text size="xs" c="gray.5" style={{ whiteSpace: 'nowrap' }}>
                  &nbsp;·&nbsp;{selectedEvent.venue.city}, {selectedEvent.venue.state}
                </Text>
              </Group>
            </Box>
          </Group>
        </Card>
      </Group>
      <Tabs defaultValue="search" className={classes.tabs}>
        <Tabs.List pl={16} pr={16} className={classes.tabList}>
          <Tabs.Tab value="search">Search</Tabs.Tab>
          <Tabs.Tab value="link">Paste URL</Tabs.Tab>
          <Tabs.Tab
            value="unlink"
            disabled={selectedMarketplaceEvent === undefined || selectedMarketplaceEvent?.marketplaceEventId === null || selectedMarketplaceEvent?.isUserMapped !== true}
          >
            Un-link
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="search" pl={16} pr={16} pt={20} pb={20} className={classes.tabPanel}>
          <Box className={classes.tabPanelContentWrapper}>
            <Group className={classes.searchFormRow}>
              <Box className={classes.flex1}>
                <Group wrap="nowrap" pb={4} gap={12}>
                  <Box w={142}>
                    <DatePickerInput
                      type="range"
                      leftSection={<DateRangeIcon />}
                      valueFormat="MM/DD"
                      size="xs"
                      placeholder="Pick date range"
                      label=""
                      allowSingleDateInRange
                      firstDayOfWeek={0}
                      {...searchForm.getInputProps('dateRange')}
                    />
                  </Box>
                  <Box className={classes.fullFlex1} ref={focusTrapRef}>
                    <BNTextInput
                      leftSection={<SearchIcon />}
                      size="xs"
                      className={classes.flex1}
                      label=""
                      placeholder="Search Marketplace Events"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          searchEvents();
                        }
                      }}
                      {...searchForm.getInputProps('searchTerm')}
                      clearable
                      clearableOnClick={() => searchForm.setFieldValue('searchTerm', '')}
                      data-autofocus
                    />
                  </Box>
                </Group>
                <Group justify="space-between" align="center" mt={8}>
                  <Text size="xs" c="var(--colors-gray-5)">
                    {searchResults && searchResults.length > 0 && (
                      <span>
                        {searchResults.length} {pluralize('result', searchResults.length)} from the marketplace
                      </span>
                    )}
                  </Text>
                  <Group justify="right" align="center" gap={12}>
                    <BNButton
                      variant="default"
                      color="gray"
                      size="xs"
                      onClick={() => {
                        clearSearchResults();
                        searchForm.reset();
                      }}
                    >
                      Reset
                    </BNButton>
                    <BNButton variant="filled" color="gray" size="xs" onClick={() => searchForm.onSubmit(() => searchEvents())()}>
                      Search
                    </BNButton>
                  </Group>
                </Group>
              </Box>
            </Group>
            <EventMappingSearchResults />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="link" pl={16} pr={16} pt={20} pb={20} className={classes.tabPanel}>
          <Box className={classes.tabPanelContentWrapper}>
            <Group className={classes.searchFormRow} gap={0}>
              <Box className={classes.flex1} w="100%">
                <BNTextInput
                  leftSection={<LinkIcon />}
                  size="xs"
                  className={classes.flex1}
                  label=""
                  placeholder="Paste an Event URL"
                  {...pasteUrlForm.getInputProps('url')}
                  clearable
                  clearableOnClick={() => pasteUrlForm.setFieldValue('url', '')}
                />
              </Box>
            </Group>
            <Group justify="right">
              <BNButton variant="filled" color="gray" size="xs" onClick={() => pasteUrlForm.onSubmit(() => fetchPastedEvent())()}>
                Submit
              </BNButton>
            </Group>
            <Container p={0} m={0} className={classes.resultsContainer}>
              <Box className={classes.resultsScroller}>
                {!pastedEvent && !isPastedEventLoading && (
                  <Box p={20} className={classes.emptyState}>
                    <Text c="gray.5" size="xs">
                      Result will appear here
                    </Text>
                  </Box>
                )}
                {!pastedEvent && isPastedEventLoading && (
                  <Box className={classes.loadingState}>
                    <Loader color="var(--colors-gray-5)" type="dots" />
                  </Box>
                )}
                {pastedEvent && (
                  <EventMappingSearchResultsCard
                    event={pastedEvent}
                    selectedMarketplaceEventId={selectedMarketplaceEvent.marketplaceEventId!}
                    posEvent={selectedEvent}
                    marketplaceId={selectedMarketplaceEvent.marketplaceId!}
                    onLinkEvent={() => {
                      linkEvent(selectedMarketplaceEvent.marketplaceId!, selectedEvent.eventId, {
                        marketplaceId: selectedMarketplaceEvent.marketplaceId!,
                        marketplaceEventId: pastedEvent.eventId,
                        marketplaceEvent: pastedEvent,
                        posEvent: selectedEvent,
                      });
                      setShowEventMapping(false);
                    }}
                  />
                )}
              </Box>
            </Container>
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="unlink" pl={16} pr={16} pt={20} pb={20} className={classes.tabPanel}>
          <Box className={classes.tabPanelContentWrapper}>
            <Container p={0} m={0} className={classes.resultsContainer}>
              <Box className={classes.resultsScroller} mb="xs">
                <EventMappingSearchResultsCard
                  marketplaceId={selectedMarketplaceEvent.marketplaceId!}
                  event={selectedMarketplaceEvent.marketplaceEvent!}
                  posEvent={selectedEvent}
                  selectedMarketplaceEventId={selectedMarketplaceEvent.marketplaceEventId!}
                />
              </Box>
              <BNButton
                variant="outline"
                size="xs"
                color="red"
                fullWidth
                loading={isUnlinkingEvent}
                onClick={() => {
                  openConfirmModal({
                    title: 'Remove Event Mapping',
                    children: <Text size="sm">Are you sure you want to un-link this event?</Text>,
                    labels: { confirm: 'Confirm', cancel: 'Cancel' },
                    confirmProps: { className: 'confirmButton', variant: 'filled', color: 'gray', size: 'sm' },
                    cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm' },
                    closeButtonProps: { size: 'md' },
                    onConfirm: () => {
                      unlinkEvent(selectedMarketplaceEvent.marketplaceId!, selectedEvent.eventId);
                    },
                  });
                }}
              >
                Un-Link This Event
              </BNButton>
            </Container>
          </Box>
        </Tabs.Panel>
      </Tabs>
    </Paper>
  ) : (
    <span />
  );
}
