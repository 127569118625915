import { Box, SimpleGrid, Text } from '@mantine/core';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { StaggerByValueInput } from '../../components/AdjustmentInput/StaggerByValueInput';
import { useNowPricing } from './NowPricing.hooks';
import { AdjustmentValueInputDiagonal } from '../../components/AdjustmentInput/AdjustmentValueInputDiagonal';

export const RuleForm = () => {
  const { form, isGroupedListing, isFormReady, setAutoUpdateRuleOnTargetComparableChanges, onSave } = useNowPricing(
    'form',
    'isGroupedListing',
    'isFormReady',
    'setAutoUpdateRuleOnTargetComparableChanges',
    'onSave',
  );

  return (
    <Box>
      <SimpleGrid cols={2} spacing="sm" my={8}>
        <AdjustmentValueInputDiagonal
          label="Adjustment"
          numInputWidth={65}
          disabled={!isFormReady}
          onChange={(value) => {
            form.getInputProps('adjustmentValue').onChange(value.value);
            form.getInputProps('adjustmentTypeId').onChange(value.type);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          value={form.values.adjustmentValue || 0}
          type={form.values.adjustmentTypeId || 'Amount'}
          error={form.errors.adjustmentValue}
        />

        <BNNumberInput selectOnFocus disabled={!isFormReady} {...form.getInputProps('numComparables')} label="# Comparables" decimalScale={0} min={1} size="xs" defaultValue={1} />
      </SimpleGrid>
      <SimpleGrid mb={8} cols={2} spacing="sm">
        <BNNumberInput
          selectOnFocus
          disabled={!isFormReady}
          leftSection={<Text size="xs">$</Text>}
          {...form.getInputProps('floorPrice')}
          onChange={(value) => {
            setAutoUpdateRuleOnTargetComparableChanges(false);
            form.getInputProps('floorPrice').onChange(typeof value === 'string' ? undefined : value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          label="Floor"
          decimalScale={2}
          fixedDecimalScale
          step={1}
          stepHoldDelay={500}
          stepHoldInterval={100}
          min={0}
          size="xs"
        />
        <BNNumberInput
          selectOnFocus
          disabled={!isFormReady}
          {...form.getInputProps('ceilingPrice')}
          onChange={(value) => {
            setAutoUpdateRuleOnTargetComparableChanges(false);
            form.getInputProps('ceilingPrice').onChange(typeof value === 'string' ? undefined : value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSave();
            }
          }}
          leftSection={<Text size="xs">$</Text>}
          label="Ceiling"
          decimalScale={2}
          fixedDecimalScale
          step={1}
          stepHoldDelay={500}
          stepHoldInterval={100}
          min={0}
          size="xs"
        />
      </SimpleGrid>
      {isGroupedListing && (
        <SimpleGrid mb={8} cols={2} spacing="sm">
          <BNNumberInput
            disabled={!isFormReady}
            {...form.getInputProps('numActive')}
            label="# Active"
            decimalScale={0}
            fixedDecimalScale
            allowDecimal={false}
            min={0}
            step={1}
            size="xs"
          />
          <StaggerByValueInput
            numInputWidth={78}
            disabled={!isFormReady}
            label="Stagger By"
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onSave();
              }
            }}
            onChange={(value) => {
              form.getInputProps('staggerByValue').onChange(value.value);
              form.getInputProps('staggerByTypeId').onChange(value.type);
            }}
            value={form.values.staggerByValue || 0}
            type={form.values.staggerByTypeId || 'Amount'}
          />
        </SimpleGrid>
      )}
    </Box>
  );
};
