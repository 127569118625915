import { ActionIcon, Badge, Group, Menu, Paper, Popover, Tooltip, UnstyledButton, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import AccountIcon from '../../components/icons/Account';
import CorporateIcon from '../../components/icons/Corporate';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import LogoutIcon from '../../components/icons/Logout';
import SearchIcon from '../../components/icons/Search';
import SettingsIcon from '../../components/icons/Settings';
import { useGlobalState } from '../../data/GlobalState';
import RoutineIcon from '../../components/icons/Routine';
import pluralize from 'pluralize';
import classes from './NavBar.styles.tsx.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import RequestQuoteIcon from '../../components/icons/RequestQuote';
import GroupIcon from '../../components/icons/Group';
import TuneIcon from '../../components/icons/Tune';
import { NotificationMenu } from '../Notifications/NotificationMenu';
import { NotificationsProvider } from '../Notifications/Notifications.hooks';
import { SupportForm } from '../Support/SupportForm';
import BNLogoHover from '../../components/icons/BNLogoHover';
import CompanyPicker from './CompanyPicker';
import { useFlag } from '@unleash/proxy-client-react';
import CompanyModal from './CompanyModal';
import SyncAltIcon from '../../components/icons/SyncAlt';
import { GiSettingsKnobs } from 'react-icons/gi';
import { BNAvatar } from '../../components/Avatar/Avatar';
import ArrowBackIcon from '../../components/icons/ArrowBack';
import dayjs from 'dayjs';
import SportsBaseballIcon from '../../components/icons/SportsBaseball';
import SportsFootballIcon from '../../components/icons/SportsFootball';
import SportsHockeyIcon from '../../components/icons/SportsHockey';
import SportsBasketballIcon from '../../components/icons/SportsBasketball';
import LineChartIcon from '../../components/icons/LineChart';
import { useAuth0 } from '@auth0/auth0-react';

type NavBarProps = {
  title: ReactNode;
};

export function NavBar({ title }: NavBarProps) {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme();
  const dark = computedColorScheme === 'dark';
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
  };
  const { logout } = useAuth0();
  const isDev = useFlag('dev');
  const hideSalesFlag = useFlag('hide-sales');
  const { principal, tenants } = useGlobalState('principal', 'tenants');
  const navigate = useNavigate();
  const location = useLocation();
  const [opened, setOpened] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isSupportPopoverOpened, setIsSupportPopoverOpened] = useState(false);
  const handleMouseEnter = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      document.body.classList.add('flyout');
      timeoutRef.current = null;
    }, 50);
  }, []);

  const handleMouseout = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  }, []);

  const isCompanyPickerEnabled = useFlag('company-picker');

  useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    [],
  );

  const seasonPricerIcon = () => {
    const today = dayjs();

    if (today.month() >= 4 && today.month() < 7) {
      return <SportsFootballIcon />;
    }

    if (today.month() >= 7 && today.month() < 8) {
      return <SportsHockeyIcon />;
    }

    if (today.month() >= 8 && today.month() < 11) {
      return <SportsBasketballIcon />;
    }

    return <SportsBaseballIcon />;
  };

  return (
    <Paper px="md" py={8} className="navBar">
      <Group justify="center">
        <UnstyledButton
          className="logo"
          data-pendo-name="Color Scheme Toggle"
          onClick={() => {
            toggleColorScheme();
          }}
        >
          <BNLogoHover size={53} />
        </UnstyledButton>
      </Group>

      {/* Hover area for flyout sidebar */}
      <div className="sidebar-flyout-hover-area" onMouseLeave={handleMouseout} onMouseEnter={handleMouseEnter} />
      {/* Group hidden unless the sidebar is collapsed -- maybe it should move to the Home.tsx level as well so it can collapse/uncollapse the sidebar */}
      <Group className={`sidebar-search-stats ${classes.sidebarSearchStats}`} gap={16} p={0}>
        <Group>
          <ActionIcon>
            <SearchIcon size={24} color="var(--colors-gray-5)" />
          </ActionIcon>
        </Group>
        <Group className={classes.eventCountWrapper}>{title}</Group>
      </Group>

      <Group className={classes.navLinks} gap="0.75rem" justify="center" wrap="wrap">
        <Popover width={320} withArrow opened={isSupportPopoverOpened} onChange={setIsSupportPopoverOpened} closeOnClickOutside={false}>
          <Popover.Target>
            <Tooltip label="Support" position="left" withArrow>
              <ActionIcon onClick={() => setIsSupportPopoverOpened(!isSupportPopoverOpened)}>
                <LiveHelpIcon size={24} />
              </ActionIcon>
            </Tooltip>
          </Popover.Target>
          <Popover.Dropdown pb="md">
            <SupportForm type="other" onClose={() => setIsSupportPopoverOpened(false)} />
          </Popover.Dropdown>
        </Popover>
        <NotificationsProvider>
          <NotificationMenu />
        </NotificationsProvider>
        <Menu withArrow width={200}>
          <Menu.Target>
            <UnstyledButton data-pendo-name="Account Menu">
              <BNAvatar principal={principal} isClickable />
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            {location.pathname !== '/seasons' && (
              <Menu.Item leftSection={seasonPricerIcon()} pos="relative" component="a" href="/seasons" data-pendo-name="Season Pricer MenuItem">
                Season Pricer
              </Menu.Item>
            )}
            {location.pathname === '/seasons' && (
              <Menu.Item leftSection={<ArrowBackIcon />} pos="relative" component="a" href="/">
                Pricer
              </Menu.Item>
            )}
            {!hideSalesFlag && location.pathname !== '/sales' && (
              <Menu.Item leftSection={<LineChartIcon />} pos="relative" component="a" href="/sales" data-pendo-name="Sales MenuItem">
                Sales
              </Menu.Item>
            )}
            {!hideSalesFlag && location.pathname === '/sales' && (
              <Menu.Item leftSection={<ArrowBackIcon />} pos="relative" component="a" href="/">
                Pricer
              </Menu.Item>
            )}
            {tenants && tenants?.length > 1 && (
              <Menu.Item data-pendo-name="Company Picker" leftSection={<SyncAltIcon />} onClick={() => setOpened(true)}>
                Company Picker
              </Menu.Item>
            )}
            <Menu.Item leftSection={<AccountIcon />} pos="relative" onClick={() => navigate('/settings/profile')} data-pendo-name="Profile MenuItem">
              Profile
            </Menu.Item>
            <Menu.Item leftSection={<TuneIcon />} pos="relative" onClick={() => navigate('/settings/pricing')} data-pendo-name="Settings MenuItem">
              Settings
            </Menu.Item>
            <Menu.Item leftSection={<CorporateIcon />} onClick={() => navigate('/companies')} data-pendo-name="Company MenuItem">
              {pluralize('Company', tenants?.length || 0)}
            </Menu.Item>
            {tenants && tenants?.length === 1 && (
              <Menu.Item disabled leftSection={<GroupIcon />} pos="relative" onClick={() => navigate(`/companies/${tenants[0].tenantId}/users`)}>
                Users
                <Badge size="xs" variant="transparent" color="var(--colors-gray-5)" className={classes.comingSoonBadge}>
                  Coming Soon
                </Badge>
              </Menu.Item>
            )}
            {tenants && tenants?.length === 1 && (
              <Menu.Item leftSection={<RequestQuoteIcon />} onClick={() => navigate(`/companies/${tenants[0].tenantId}/billing`)}>
                Billing
              </Menu.Item>
            )}
            {tenants && tenants?.length === 1 && (
              <Menu.Item disabled leftSection={<SettingsIcon />} pos="relative" onClick={() => navigate(`/companies/${tenants[0].tenantId}/integrations`)}>
                Integrations
                <Badge size="xs" variant="transparent" color="var(--colors-gray-5)" className={classes.comingSoonBadge}>
                  Coming Soon
                </Badge>
              </Menu.Item>
            )}
            <Menu.Item
              data-pendo-name="Color Scheme Toggle"
              onClick={() => {
                toggleColorScheme();
              }}
              leftSection={<RoutineIcon />}
            >
              {dark ? 'Light Mode' : 'Dark Mode'}
            </Menu.Item>
            {isDev && (
              <Menu.Item component="a" href="http://172.29.190.95:32000/" target="_blank" leftSection={<GiSettingsKnobs size={20} />}>
                Control Panel
              </Menu.Item>
            )}
            <Menu.Item style={{ borderBottom: 'none' }} leftSection={<LogoutIcon />} onClick={() => logout({ returnTo: window.location.origin })}>
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      {isCompanyPickerEnabled ? <CompanyPicker opened={opened} setOpened={setOpened} /> : <CompanyModal opened={opened} setOpened={setOpened} />}
    </Paper>
  );
}
