import { AgGridReact } from '@ag-grid-community/react';
import { Center, Loader, UnstyledButton } from '@mantine/core';
import SearchIcon from '../../components/icons/Search';
import { useInventory } from './Inventory.hooks';
import classes from './Inventory.styles.tsx.module.css';
import { useInventoryEvents } from './Inventory.Events.hooks';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';

export default function EventsGrid() {
  const { inventoryQuickFilter, setInventoryQuickFilter, setFilter } = useInventory('onCellClickedWhenDirty', 'inventoryQuickFilter', 'setInventoryQuickFilter', 'setFilter');
  const { gridRef, events, columnDefs, defaultColumnSettingDefs, onRowSelectionChanged, onGridReady, onRowDataUpdated, trySaveGridState, onCellClickedWhenDirty } =
    useInventoryEvents(
      'gridRef',
      'events',
      'columnDefs',
      'defaultColumnSettingDefs',
      'onRowSelectionChanged',
      'onGridReady',
      'onRowDataUpdated',
      'trySaveGridState',
      'onCellClickedWhenDirty',
    );

  function CustomNoRowsOverlay() {
    return (
      <Center>
        <BNEmptyState
          title="No Results Found"
          description="Try adjusting the filter options"
          bottomContent={
            inventoryQuickFilter && (
              <UnstyledButton
                mt={10}
                onClick={() => {
                  setFilter('');
                  setInventoryQuickFilter('');
                }}
                className={classes.grayLink}
              >
                or clear quick filter
              </UnstyledButton>
            )
          }
          icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
          iconBg="var(--colors-opacity-hover)"
          h={320}
          w={320}
          border={false}
        />
      </Center>
    );
  }

  function CustomLoadingOverlay() {
    return (
      <div className={`ag-overlay-loading-center ${classes.agOverlayLoadingCenter}`}>
        <Loader color="var(--colors-gray-5)" type="dots" />
      </div>
    );
  }

  return (
    <div id="inventory-events-grid" className="ag-theme-alpine">
      <AgGridReact
        ref={gridRef}
        rowData={events}
        suppressScrollOnNewData
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        suppressRowClickSelection
        onRowDataUpdated={onRowDataUpdated}
        rowSelection="multiple"
        onRowSelected={onRowSelectionChanged}
        rowBuffer={50}
        getRowId={(params) => params.data?.eventId}
        onCellClicked={onCellClickedWhenDirty}
        onColumnMoved={trySaveGridState}
        onColumnResized={trySaveGridState}
        onSortChanged={trySaveGridState}
        onColumnVisible={trySaveGridState}
        defaultColDef={defaultColumnSettingDefs}
        enableCellChangeFlash
        headerHeight={32}
        rowHeight={36}
        rowStyle={{
          lineHeight: '28px',
          fontSize: '12px',
        }}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        loadingOverlayComponent={CustomLoadingOverlay}
        suppressContextMenu
      />
    </div>
  );
}
