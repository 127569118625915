import { useAtomValue } from 'jotai';
import { useGlobalState } from '../data/GlobalState';
import { selectedMergedListingAtom } from '../data/atoms';

export const useIsDrasticChange = (tenantId?: string) => {
  const selectedTenantId = useAtomValue(selectedMergedListingAtom)?.tenantId;
  const { tenants } = useGlobalState('tenants');
  const companySettings = tenants?.find((x) => x.tenantId === tenantId || x.tenantId === selectedTenantId)?.settings;
  const percentage = companySettings?.pricerSettings?.drasticPriceThresholdPercentage ?? 0;
  const threshold = companySettings?.pricerSettings?.drasticPriceThresholdAmount ?? 0;
  return isDrasticChange.bind(null, percentage, threshold);
};

export function isDrasticChange(drasticPriceChange: number, minThreshold: number, price: number, unitPrice: number, unitCost: number) {
  let _isDrasticChange = false;

  if (price !== 0) {
    const priceDifference = Math.abs(unitPrice - price);
    const upperThreshold = unitPrice * (1 + drasticPriceChange);
    const lowerThreshold = unitPrice * (1 - drasticPriceChange);

    if (unitPrice === 0 && price < unitCost) {
      _isDrasticChange = true;
    } else if (unitPrice !== 0 && priceDifference > minThreshold && (price > upperThreshold || price < lowerThreshold)) {
      _isDrasticChange = true;
    }
  }

  return _isDrasticChange;
}

export function parseCurrency(value: string) {
  return parseFloat(value.replace(/[^0-9.-]+/g, ''));
}

export function isNumber(value: string) {
  return !isNaN(parseFloat(value));
}
