import { useLocalStorage } from '@mantine/hooks';
import yasml from '@thirtytech/yasml';
import { useCallback } from 'react';
import { PricerView } from '../types';

function HomeState() {
  const [isTrendsCollapsed, setIsTrendsCollapsed] = useLocalStorage({
    key: 'setting-trendsPanel',
    defaultValue: false,
    getInitialValueInEffect: false,
  });

  const handleTrendsToggle = useCallback(() => {
    setIsTrendsCollapsed((x) => !x);
  }, [setIsTrendsCollapsed]);

  return { isTrendsCollapsed, setIsTrendsCollapsed, handleTrendsToggle };
}

export const { Provider: HomeStateProvider, useSelector: useHomeState } = yasml(HomeState);
