import { useQuery } from '@tanstack/react-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { BarkerCoreModelsSalesGetSalesResponse, GetApiSalesParams } from './models';

export const getApiSales = (params?: GetApiSalesParams, options?: AxiosRequestConfig): Promise<AxiosResponse<BarkerCoreModelsSalesGetSalesResponse>> => {
  return axios.get(`/api/Sales`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetApiSalesQueryKey = (params?: GetApiSalesParams) => {
  return [`/api/Sales`, ...(params ? [params] : [])] as const;
};

export const getGetApiSalesQueryOptions = <TData = Awaited<ReturnType<typeof getApiSales>>, TError = AxiosError<void>>(
  params?: GetApiSalesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSales>>, TError, TData>; axios?: AxiosRequestConfig },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiSalesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSales>>> = ({ signal }) => getApiSales(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiSales>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiSalesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSales>>>;
export type GetApiSalesQueryError = AxiosError<void>;

export const useGetApiSales = <TData = Awaited<ReturnType<typeof getApiSales>>, TError = AxiosError<void>>(
  params?: GetApiSalesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSales>>, TError, TData>; axios?: AxiosRequestConfig },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSalesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
