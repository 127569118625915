import { AgGridReact } from '@ag-grid-community/react';
import { Center, Loader } from '@mantine/core';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import SearchIcon from '../../components/icons/Search';
import classes from './Purchase.module.css';
import cx from 'clsx';
import { usePurchaseState } from './Purchase.hooks';
import { GetRowIdParams, RowClickedEvent, RowDataUpdatedEvent, SelectionChangedEvent } from '@ag-grid-community/core';
import { BarkerCoreModelsDTIGetEventsResponseItem } from '../../api';
import { useCallback } from 'react';

export function CatalogGrid() {
  const { eventSearchResults, catalogGridRef, catalogColumnDefs, setSelectedEvents, onCatalogGridReady, searchForm, selectedEvents } = usePurchaseState(
    'eventSearchResults',
    'catalogGridRef',
    'catalogColumnDefs',
    'setSelectedEvents',
    'onCatalogGridReady',
    'searchForm',
    'selectedEvents',
  );
  function CustomNoRowsOverlay() {
    return (
      <Center>
        <BNEmptyState
          title={searchForm.isTouched() ? 'No Results Found' : 'Search for events'}
          description="Try adjusting the filter options"
          icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
          iconBg="var(--colors-opacity-hover)"
          h={320}
          w={320}
          border={false}
        />
      </Center>
    );
  }
  function CustomLoadingOverlay() {
    return (
      <div className={cx('ag-overlay-loading-center', classes.agOverlayLoadingCenter)}>
        <Loader color="var(--colors-gray-5)" type="dots" />
      </div>
    );
  }

  const onRowClicked = useCallback((event: RowClickedEvent<BarkerCoreModelsDTIGetEventsResponseItem>) => {
    event.node.setSelected(!event.node.isSelected());
  }, []);

  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent<BarkerCoreModelsDTIGetEventsResponseItem>) => {
      if (event.source !== 'rowDataChanged') {
        const selectedNodes = event.api.getSelectedNodes();
        const selectedData = selectedNodes.map((node) => node.data!);
        setSelectedEvents(selectedData);
      }
    },
    [setSelectedEvents],
  );

  const onRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent<BarkerCoreModelsDTIGetEventsResponseItem>) => {
      event.api.forEachNode((node: any) => {
        if (selectedEvents.map((x) => x.id).includes(node.data?.id ?? -1)) {
          node.setSelected(true);
        }
      });
    },
    [selectedEvents],
  );

  return (
    <div id="catalog-events-grid" className={cx('ag-theme-alpine', classes['purchase-catalog-grid'])}>
      <AgGridReact
        ref={catalogGridRef}
        rowData={eventSearchResults ?? []}
        onRowDataUpdated={onRowDataUpdated}
        onRowClicked={onRowClicked}
        onSelectionChanged={onSelectionChanged}
        suppressRowClickSelection
        onGridReady={onCatalogGridReady}
        suppressScrollOnNewData
        columnDefs={catalogColumnDefs}
        rowSelection="multiple"
        rowBuffer={50}
        getRowId={(params: GetRowIdParams<BarkerCoreModelsDTIGetEventsResponseItem>) => params.data?.id.toString()}
        enableCellChangeFlash
        headerHeight={32}
        rowHeight={36}
        defaultColDef={{
          sortable: true,
          resizable: true,
        }}
        rowStyle={{
          lineHeight: '28px',
          fontSize: '12px',
        }}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        loadingOverlayComponent={CustomLoadingOverlay}
        suppressContextMenu
      />
    </div>
  );
}
